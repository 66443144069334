import axiosClient from "../../config/axiosClient";

let getCallbackUrl = async function( kushkiToken, amount, idBolsa) {
  return await axiosClient.post(
    "/payment/kushki-divinobolsa-webpay/init",
    {
      kushkiFrontendToken: kushkiToken,
      kushkiAmount: {
        subtotalIva: 0,
        subtotalIva0: amount,
        iva: 0,
      },
      idBolsa
    },
    {
      headers: { Authorization: `Bearer ${this.jwt}` },
    }
  );
};

let checkDebitPayment = async function(roomId, kushkiToken, idBolsa) {
  return await axiosClient.post(
    "/payment/kushki-divinobolsa-webpay/verify",
    {
      roomId: roomId,
      kushkiFrontendToken: kushkiToken,
      idBolsa
    },
    {
      headers: { Authorization: `Bearer ${this.jwt}` },
    }
  );
};

export { getCallbackUrl, checkDebitPayment };
