<template>
    <div class="container-fluid">
        <div class="row">
            <div class="col-12 p-0 bg-banner">
                <div class="container">
                    <div class="row pb-4">
                        <div class="col-12 p-0">
                            <p class="submenu">
                                Inicio - <span>Servicios</span>
                            </p>
                        </div>
                    </div>
                </div>

                <div class="col-12 p-0 mt-4">
                    <Lectura />
                </div>
            </div>

            <div class="col-12">
                <Ambitos />
            </div>
            <div class="col-12 bg-violeta">
                <Especialidades />
            </div>
        </div>
    </div>
</template>

<script>
import Lectura from './Lectura'
/* import Cursos from "./Cursos"; */
import Ambitos from './Ambitos'
import Especialidades from './Especialidades'
export default {
    name: 'Servicio',
    components: {
        Lectura,
        /*     Cursos, */
        Ambitos,
        Especialidades,
    },
}
</script>

<style scoped>
.bg-banner {
    background-image: url('../../assets/img/servicios/banner.png');
    background-position-x: right;
    background-repeat: no-repeat;
    background-color: #f5f5f5;
    background-position-x: center;
    background-size: cover;
}
.title {
    text-align: center;
    margin-bottom: 0px;
}
.bg-violeta {
    background: #501682;
}
</style>