<template>
  <div>
    <div class="card shadow">
      <div class="card-body">
        <div class="row">
          <div class="col col-img">
            <div class="image">
              <img v-if="avatar != null" :src="tarotistaInfo.avatar" alt="" />
              <img v-else src="../../assets/img/avatar.jpg" alt="" />
            </div>
          </div>
          <div class="col">
            <div class="text">
              <p class="welcome">Bienvenido</p>
              <h5 v-if="tokenData">{{ tokenData.personName }}</h5>
              <!--  <span>CódigoReferido</span>
              <button class="btn btn-suscrip">Suscripcion</button> -->
            </div>
          </div>
        </div>
        <!-- <div class="row " v-if="roomInProgress">
          <div class="col-12">
            <h5 class="alert-room-progress">
              Tienes una llamada en progreso
            </h5>
          </div>
          <div class="col text-center">
            <button @click="goToRoom()" class="btn btn-sm btn-sala">
              Entrar a sala
            </button>
          </div>
        </div> -->
        <div class="row mt-2">
          <div class="col-12">
            <h5 class="title">Cupones & Promociones</h5>
          </div>
          <div class="col content-text">
            <p class="text-disponible">
              Sin registro de Cupones & Promociones.
            </p>
          </div>
          <!-- <div class="col-12 mb-3">
            <div class="row">
              <div class="col descuento">
                <div class="off">
                  <span>15%</span>
                </div>
              </div>
              <div class="col text-descrip m-auto">
                <span class="descripcion">Lorem ipsum dolor sit</span>
              </div>
            </div>
          </div>

           <div class="col-12 mb-3">
            <div class="row">
              <div class="col descuento">
                <div class="off">
                  <span>15%</span>
                </div>
              </div>
              <div class="col text-descrip m-auto">
                <span class="descripcion">Lorem ipsum dolor sit</span>
              </div>
            </div>
          </div>
          <div class="col-12 mb-3">
            <div class="row">
              <div class="col descuento">
                <div class="off">
                  <span>15%</span>
                </div>
              </div>
              <div class="col text-descrip m-auto">
                <span class="descripcion">Lorem ipsum dolor sit</span>
              </div>
            </div>
          </div>
          <div class="col-12 mb-3">
            <div class="row">
              <div class="col descuento">
                <div class="off">
                  <span>15%</span>
                </div>
              </div>
              <div class="col text-descrip m-auto">
                <span class="descripcion">Lorem ipsum dolor sit </span>
              </div>
            </div>
          </div>
          <div class="col-12 mb-3">
            <div class="row">
              <div class="col descuento">
                <div class="off">
                  <span>15%</span>
                </div>
              </div>
              <div class="col text-descrip m-auto">
                <span class="descripcion">Lorem ipsum dolor sit </span>
              </div>
            </div>
          </div>
          <div class="col-12 mb-3">
            <div class="row">
              <div class="col descuento">
                <div class="off">
                  <span>15%</span>
                </div>
              </div>
              <div class="col text-descrip m-auto">
                <span class="descripcion">Lorem ipsum dolor sit</span>
              </div>
            </div>
          </div>
          <div class="col-12 mb-3">
            <div class="row">
              <div class="col descuento">
                <div class="off">
                  <span>15%</span>
                </div>
              </div>
              <div class="col text-descrip m-auto">
                <span class="descripcion">Lorem ipsum dolor sit</span>
              </div>
            </div>
          </div>
          <div class="col-12 mb-3">
            <div class="row">
              <div class="col descuento">
                <div class="off">
                  <span>15%</span>
                </div>
              </div>
              <div class="col text-descrip m-auto">
                <span class="descripcion">Lorem ipsum dolor sit </span>
              </div>
            </div>
          </div> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axiosClient from "../../config/axiosClient";
import moment from "moment";
import { enableLogs } from '../../config';

export default {
  computed: {
    tokenData() {
      //console.log(this.$store.state.tokenData);
      return this.$store.state.tokenData;
    },
    jwt() {
      return this.$store.state.jwt;
    },
    roomInProgress() {
      if (this.latestRoom) {
        if (this.latestRoom.channel == "VOICE") return false;

        if(enableLogs) console.log(`Latest room date:
        init: ${this.latestRoom.creationDate}
        end: ${this.latestRoom.finishDate}
        `)
        if (
          moment().isBetween(
            this.latestRoom.creationDate,
            this.latestRoom.finishDate
          )
        )
          return true;
        return false;
      }
      return false;
    },
    roomUrl() {
      if (this.latestRoom) {
        let url;
        switch (this.latestRoom.channel) {
          case "CHAT":
            url = `/chat?roomId=${this.latestRoom.roomId}`;

            break;
          case "VOICE":
            url = `/room-llamada?roomId=${this.latestRoom.roomId}`;

            break;
          case "VIDEO":
            url = `/room?roomId=${this.latestRoom.roomId}`;

            break;
          default:
            url = "";
        }
        return url;
      } else {
        return "";
      }
    },
  },

  data() {
    return {
      latestRoom: null,
    };
  },
  created() {
    if (this.jwt) this.getLatestRoom();
  },
  watch: {
    jwt: function(jwt) {
      if (jwt) {
        this.getLatestRoom();
      }
    },
  },
  methods: {
    goToRoom() {
      if (this.roomUrl == "") return;
     if(enableLogs) console.log(this.roomUrl);
      this.$router.push(this.roomUrl);
    },
    async getLatestRoom() {
     if(enableLogs) console.log("Fetching latest room");
      try {
        let response = await axiosClient.get("/users/get-latest-room", {
          headers: { Authorization: `Bearer ${this.$store.state.jwt}` },
        });
        this.latestRoom = response.data.latestRoom;
       if(enableLogs) console.log(this.latestRoom);
      } catch (err) {
       if(enableLogs) console.log("Error fetching latest room");
       if(enableLogs) console.log(err);
      }
    },
  },
};
</script>
<style scoped>
.card {
  border-radius: 10px;
  height: 674.8px;
}
.col-img {
  max-width: 90px;
  padding-right: 0;
}
.image img {
  width: 100%;
  border-radius: 50%;
  height: 100%;
  object-fit: cover;
}
.text {
  font-family: maven-regular;
  vertical-align: middle;
}
.text .welcome {
  margin-bottom: 0px;
  font-size: 15px;
}
.text h5 {
  font-weight: bold;
  font-size: 15px;
  margin-bottom: 0px;
}
.text span {
  font-size: 12px;
  text-transform: uppercase;
}
.text .btn-suscrip {
  background: #501682;
  color: #fff;
  font-size: 9px;
  margin-top: 4px;
}
.alert-room-progress {
  font-family: maven-bold;
  color: #501682;
  margin-top: 10px;
  font-size: 15px;
  text-align: center;
}
.title {
  font-family: maven-bold;
  color: #501682;
  margin-bottom: 20px;
  margin-top: 20px;
  font-size: 15px;
}
.descuento {
  max-width: 60px;
  padding-right: 0;
}
.off {
  min-width: 40px;
  min-height: 40px;
  max-width: 40px;
  max-height: 40px;
  background: #dddddd;
  border-radius: 50%;
  padding: 7px 6px;
}
.off span {
  font-size: 14px;
  font-family: maven-bold;
}
.text-descrip {
  padding-left: 0px;
  margin: auto;
}
.descripcion {
  font-size: 12px;
  font-family: maven-regular;
}
.content-text {
  padding-top: 210px;
}
.content-text .text-disponible {
  font-family: maven-semibold;
  font-size: 13px;
  color: #707070;
  text-align: center;
}
.btn-sala {
  margin-top: 10px;
  background: #501682;
  color: #fff;
  font-family: maven-semibold;
  font-size: 11px;
  text-transform: uppercase;
}
.btn-sala:hover {
  background: #52099b;
  color: #fff;
}
</style>
