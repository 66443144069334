import axiosClient from "../../config/axiosClient";

let getCallbackUrlTransfer = async function(kushkiToken, amount, idBolsa) {
  return await axiosClient.post(
    "/payment/kushki-divinobolsa-transfer/init",
    {
      kushkiFrontendToken: kushkiToken,
      kushkiAmount: {
        subtotalIva: 0,
        subtotalIva0: amount,
        iva: 0,
      }, 
      idBolsa
    },
    {
      headers: { Authorization: `Bearer ${this.jwt}` },
    }
  );
};

let checkTransferPayment = async function(roomId, kushkiToken,) {


  return await axiosClient.post(
    "/payment/kushki-divinobolsa-transfer/verify",
    {
      kushkiFrontendToken: kushkiToken,
      roomId,
    },
    {
      headers: { Authorization: `Bearer ${this.jwt}` },
    }
  );
};

export { getCallbackUrlTransfer, checkTransferPayment };
