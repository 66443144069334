<template>
  <div>
    <div v-if="tarotistaInfo.type === 'agendada'" class="col-12">
      <div class="card shadow">
        <div class="card-body mt-3">
          <div class="col-12 my-3">
            <div class="row">
              <div class="col-md-4 col-12">
                <h5 class="title">¡Lecturas agendadas proximamente!</h5>
              </div>
              <div class="col-12 my-3">
                <div class="content-table">
                  <table class="table" v-if="eventos.length != 0">
                    <thead
                      :style="eventos.length > 8 ? 'margin-right: 11px;' : ''"
                    >
                    <tr>
                      <th scope="col">Fecha</th>
                      <th scope="col">Hora</th>
                      <th scope="col">Tipo de Sala</th>
                      <th/>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="event in eventos" :key="event.roomId">
                      <td>{{ event.startDate.split("T")[0] }}</td>
                      <td>{{ event.startDate.split("T")[1].split('.')[0] }}</td>
                      <td>{{ tipoSala(event.tipo) }}</td>
                      <td>
                        <div class="col">
                          <button @click="atender(event.roomId, event.tipo)"
                                  class="btn"
                                  :class="{'button-green': tiempoParaLlegar(event.startDate) >= -5, 'button-red': tiempoParaLlegar(event.startDate) < -5}"
                                  :disabled="tiempoParaLlegar(event.startDate) < -5 || (tiempoParaLlegar(event.startDate) >= -5 && tiempoParaLlegar(event.startDate) >= 0)">
                            <i v-if="tiempoParaLlegar(event.startDate) >= -5" :class="{
                                'fas fa-phone': tiempoParaLlegar(event.startDate) >= -5 && event.tipo === 3,
                                'fas fa-envelope': tiempoParaLlegar(event.startDate) >= -5 && event.tipo === 1,
                                'fas fa-microphone': tiempoParaLlegar(event.startDate) >= -5 && event.tipo === 2,
                                'fas fa-phone-slash': tiempoParaLlegar(event.startDate) < -5
                            }"></i>
                            <i v-else :class="{
                                'fas fa-phone-slash': tiempoParaLlegar(event.startDate) < -5 && event.tipo === 3,
                                'fas fa-envelope-open': tiempoParaLlegar(event.startDate) < -5 && event.tipo === 1,
                                'fas fa-microphone-slash': tiempoParaLlegar(event.startDate) < -5 && event.tipo === 2,
                            }"></i> <!-- Icono de llamada finalizada -->
                          </button>
                        </div>
                      </td>
                    </tr>
                    </tbody>
                  </table>
                  <div v-else>
                    <p class="text-disponible">No se han encontrado lecturas</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>


import axiosClient from '@/config/axiosClient';
import {enableLogs} from "@/config";

export default {
   components: {

  },
  created() {
    if (this.jwt) {
      this.getTarotistaInfo();
    }
  },
  mounted() {
     this.getEventos();
  },
  data() {
    return {
      eventos: [],
      tarotistaInfo: null,
    };
  },
  computed: {
    jwt() {
      return this.$store.state.jwt;
    },
    tokenData() {
      return this.$store.state.tokenData;
    },
  },
  methods: {
    async getTarotistaInfo() {
      if (enableLogs) console.log('fetching tarotista info...');
      try {
        const response = await axiosClient.get(
            `tarot-reader/get-tarot-reader-uid/${this.tokenData.userId}`
        );
        if (enableLogs) console.log('successfully fetched tarotista info');
        if (enableLogs) console.log(response.data);
        this.tarotistaInfo = response.data.tarotReaderInfo;
      } catch (error) {
        if (enableLogs) console.log('could not retrieve tarotista info');
        if (enableLogs) console.log(error);
      }
    },
    tiempoParaLlegar(fechaObjetivo) {
      // Obtener la fecha actual
      const fechaActual = new Date();

      // Agregar 3 horas (180 minutos) a la hora actual para tener en cuenta la diferencia GMT
      fechaActual.setMinutes(fechaActual.getMinutes());

      // Convertir la fecha objetivo en un objeto Date
      const fechaObjetivoDate = new Date(fechaObjetivo);
      fechaObjetivoDate.setMinutes(fechaObjetivoDate.getMinutes() + 240);

      // Restar 5 minutos a la fecha objetivo
      fechaObjetivoDate.setTime(fechaObjetivoDate.getTime() - 5 * 60 * 1000);

      // Calcular la diferencia en milisegundos
      const diferenciaEnMilisegundos = fechaObjetivoDate - fechaActual;

      // Convertir la diferencia en minutos
      const diferenciaEnMinutos = diferenciaEnMilisegundos / (60 * 1000);
      return diferenciaEnMinutos;
    },
     async getEventos(){
       try {
         const datos = await axiosClient.get(
           '/users/get-users/eventos-tarotista',
           {
             headers: { Authorization: `Bearer ${this.$store.state.jwt}` },
           }
         );
         console.log(datos.data.eventos)
         this.eventos = datos.data.eventos;
       } catch (error) {
         console.error('Error during eventos-tarotista:', error);
       }
     },
    atender(roomId, canal){
      this.$router.push(this.getNextLink(roomId, canal));
    },
    getNextLink(roomId, canal) {
      let nextLink = ``;
      switch (canal) {
        case 3:
          nextLink = `/sala-espera?roomId=${roomId}`;
          break;
        case 1:
          nextLink = `/chat?roomId=${roomId}`;
          break;
        case 2:
          nextLink = `/room-llamada?roomId=${roomId}`;
          break;
      }
      return nextLink;
    },
    tipoSala(tipo) {
      switch (tipo) {
        case 3:
          return 'Video';
        case 2:
          return 'Voz';
        case 1:
          return 'Chat';
      }
    },
  },
};
</script>


<style scoped>
.button-red {
  background-color: #e61212; /* Cambia el color de fondo a rojo */
  font-weight: bold !important;
  text-transform: uppercase !important;
  font-size: 11px !important;
  padding: 6px 16px !important;
  font-family: maven-bold !important;
}
.button-green {
  background-color: #1bcc2c; /* Cambia el color de fondo a verde */
  font-weight: bold !important;
  text-transform: uppercase !important;
  font-size: 11px !important;
  padding: 6px 16px !important;
  font-family: maven-bold !important;
}
.card {
  border-radius: 10px;
  min-height: 504px;
}

.title {
  font-family: maven-bold;
  color: #501682;
  font-size: 15px;
}

.imagen {
  max-width: 100px;
  text-align: center;
}
.imagen img {
  width: 100%;
  border-radius: 50%;
}
.name-tarotista {
  font-family: maven-regular;
  font-size: 13px;
  text-align: center;
}
.content-text{
  padding-top:200px ;
}
.content-text .text-disponible {
  font-family: maven-semibold;
  font-size: 16px;
  text-align: center;
  color: #707070;
}
@media screen and (max-width: 991px){
.card {
  min-height: 466px;
}
} 
@media screen and (max-width: 767px){
.card {
  min-height: 200px;
}
.content-text{
  padding-top:55px ;
}
} 
</style>
<style>
.fc-media-screen {
  font-family: maven-regular;
}
.fc-toolbar-title {
  font-size: 12px;
}
.fc .fc-col-header-cell-cushion {
  text-decoration: none !important;
  color: #333333;
  font-size: 10px;
}
.fc-daygrid-day-number {
  text-decoration: none !important;
  color: #333333;
  font-size: 10px;
}
.fc .fc-timegrid-axis-cushion{
    text-decoration: none !important;
  color: #333333;
  font-size: 10px;
}
.fc .fc-toolbar.fc-header-toolbar {
  font-size: 15px !important;
}
.fc-toolbar-title {
  font-size: 15px !important;
}

.fc .fc-button-primary {
  color: #fff;
  color: var(--fc-button-text-color, #7e8299);
  background-color: #f2f3f7;
  background-color: var(--fc-button-bg-color, #f2f3f7);
  border-color: #f2f3f7;
  border-color: var(--fc-button-border-color, #f2f3f7);
}
.fc .fc-button-primary:hover {
  color: #fff;
  color: var(--fc-button-text-color, #fff);
  background-color: #04d9b2;
  background-color: var(--fc-button-hover-bg-color, #04d9b2);
  border-color: #04d9b2;
  border-color: var(--fc-button-hover-border-color, #04d9b2);
}
.fc .fc-button-primary:focus {
  color: #fff;
  color: var(--fc-button-text-color, #fff);
  background-color: #04d9b2;
  background-color: var(--fc-button-hover-bg-color, #04d9b2);
  border-color: #04d9b2;
  border-color: var(--fc-button-hover-border-color, #04d9b2);
}
.fc .fc-button-primary:active {
  color: #fff;
  color: var(--fc-button-text-color, #fff);
  background-color: #04d9b2 !important;
  background-color: var(--fc-button-hover-bg-color, #04d9b2);
  border-color: #04d9b2 !important;
  border-color: var(--fc-button-hover-border-color, #04d9b2);
}
.fc-button-active {
  color: #fff;
  color: var(--fc-button-text-color, #fff);
  background-color: #04d9b2 !important;
  background-color: var(--fc-button-hover-bg-color, #04d9b2);
  border-color: #04d9b2 !important;
  border-color: var(--fc-button-hover-border-color, #04d9b2);
}
.fc .fc-daygrid-day.fc-day-today {
  background-color: #04d9b260 !important;
}
.fc .fc-scroller-liquid-absolut{
  overflow: hidden !important;
}
.fc-view-harness{
  height: 400px !important;
}


</style>