<template>
  <div class="container bg-green">
    <div class="row px-2">
      <div class="col-12">
        <p class="submenu">
          Inicio - tarotista - <span>{{ tarotista.alias }}</span>
        </p>
      </div>
    </div>
    <div class="row justify-content-center py-5 pb-5">
      <div class="col col-img">
        <!-- <div class="bg-green"></div> -->
        <div class="image">
          <img :src="tarotista.avatar" alt="" />
          <i
            v-if="(status ? status.status == 'Disponible' : false) || tarotista.type === 'agendada'"
            class="fas fa-circle tarotista-disponible"
          ></i>
          <i
            :key="tarotista.userId + '/' + seed"
            v-if="(status ? status.status == 'Ocupado' : false) && tarotista.type !== 'agendada'"
            class="fas fa-circle tarotista-occupied"
          ></i>
          <i
            v-if="(status ? (!(status.status == 'Ocupado') && !(status.status == 'Disponible')):true) && tarotista.type !== 'agendada'"
            class="fas fa-circle tarotista-no-disponible"
          ></i>
        </div>
        <h4
          v-if="status ? status.status == 'Disponible' : false"
          class="tarotista-estado tarotista-disponible"
        >
          {{tarotista.type === 'agendada' ? 'Disponible' : 'Disponible'}}
        </h4>
        <h4
          v-if="status ? (!(status.status == 'Ocupado') && !(status.status == 'Disponible')):true"
          class="tarotista-estado tarotista-no-disponible"
        >
          {{tarotista.type === 'agendada' ? 'Disponible' : 'No Disponible'}}
        </h4>
        <h4
          :key="tarotista.userId + '/' + seed"
          v-if="status ? status.status == 'Ocupado' : false"
          class="tarotista-estado tarotista-occupied"
        >
          {{tarotista.type === 'agendada' ? 'Disponible' : 'Ocupado'}}
        </h4>
      </div>
      <div class="col-md-6 col-12 ml-3">
        <h5 class="name-tarotista">{{ tarotista.alias }}</h5>
        <p v-if="tarotista.specialties" class="astrologer">{{
                    tarotista.specialties[0].specialtyName
                  }}</p>
                  <p v-else class="subtitle" style="opacity: 0">lorem</p>
        <p class="py-2 description">{{ tarotista.description }}</p>
        <div class="row first-container justify-content-between">
          <div
            class="col-lg-6 col-12 col-puntaje order-lg-3 order-md-2 order-2"
          >
            <div
              class="container-puntaje pb-2"
              style="border-bottom: 1px solid #e0e0e0"
            >
              <div class="row">
                <div class="score col-12">
                  <!-- <div class="star">
                    <i
                      v-for="i in tarotista.calification"
                      :key="i"
                      class="fas fa-star"
                    ></i>
                    <i
                      v-for="i in 5 - tarotista.calification"
                      :key="i * 77"
                      class="fas fa-star grey-star"
                    ></i>
                  </div>
                  <span class="puntaje">{{ tarotista.calification }}.0</span>
                  <span class="cantidad-personas">(175)</span> -->
                <Star :value="tarotista.valoracion_promedio" :shownumer="true" :size="1" :showperson="true" :person="tarotista.cantidad_valoracion" />
                </div>
                <div class="readings col-12">
                  <div class="glass">
                    <i class="far fa-eye"></i>
                  </div>
                  <div class="text-read">
                    <h5>
                      <span>{{ tarotista.amountOfReadings }}</span> Lecturas
                    </h5>
                  </div>
                </div>
              </div>
            </div>
            <div class="container-lectura pt-2">
              <div class="tipo-lectura" v-if="tarotista.type === 'agendada'">
                <p><i class="fas fa-calendar-alt"></i> Lectura Agendada</p>
              </div>
              <div class="tipo-lectura" v-else>
                <p><i class="fas fa-bolt"></i> Lectura Inmediata</p>
              </div>
              <div class="btn btn-sm btn-descuento" v-if="tarotista.desc">-50%</div>
            </div>
            <div class="container-lectura pt-2">
              <div class="tipo-lectura">
                <p><i class="fa fa-globe"></i> Idiomas</p>
              </div>
              <div v-for="taro in tarotista.languages" :key="taro">
                <p class="descripcion">
                  {{ textoIdioma(taro.idioma) }}
                </p>
              </div>
            </div>
          </div>

          <div
            class="
              col-lg-6 col-12
              order-lg-4 order-md-3 order-sm-4 order-4
              col-btn
              mt-lg-0 mt-4
            "
          >
            <div class="row">
              <div class="col-12">
                <p class="titulo-btn">Comprar</p>
                <div v-if="tarotista.chatPrice !== null">
                  <button
                    :disabled="disableButtonFuntion(tarotista, 'chat')"
                    @click="
                      goToPay(tarotista.tarotReaderId, tarotista.type, 'Chat')
                    "
                    type="button"
                    class="btn btn-green btn-block"
                  >
                    <i class="fas fa-comments icon-btn"></i>
                    <span>Chat $ {{ numberWithCommas(tarotista.chatPrice) }}/min</span>
                  </button>
                  <!-- <label class="descuento">$400/min</label> -->
                </div>

                <div v-if="tarotista.voicePrice !== null">
                  <button
                    :disabled="disableButtonFuntion(tarotista, 'voice')"
                    @click="
                      goToPay(tarotista.tarotReaderId, tarotista.type, 'Voz')
                    "
                    type="button"
                    class="btn btn-green btn-block"
                  >
                    <i class="fas fa-phone-alt icon-btn"></i>
                    <span>Audio ${{ numberWithCommas(tarotista.voicePrice) }}/min</span>
                  </button>
                  <!-- <label class="descuento">$400/min</label> -->
                </div>

                <div v-if="tarotista.videoPrice !== null">
                  <button
                    :disabled="disableButtonFuntion(tarotista ,'video')"
                    @click="
                      goToPay(tarotista.tarotReaderId, tarotista.type, 'Video')
                    "
                    type="button"
                    class="btn btn-green btn-block"
                  >
                    <i class="fas fa-video icon-btn"></i>
                    <span>Video ${{ numberWithCommas(tarotista.videoPrice) }}/min</span>
                  </button>
                  <!-- <label class="descuento">$400/min</label> -->
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { enableLogs } from '../../config';
import Star from '../util/Stars.vue'
export default {
  components: {
    Star
  },
  props: {
    tarotista: Object,
    scopes: Array,
    state: String,
    favourites: Array,
    liked: Boolean,
    addToFavourites: Function,
    removeFromFavourites: Function,
    status: Object,
  },
  data: () => {
    return {
      statust: {}
    };
  },
  created: function () {
    if (enableLogs) console.log(this.state);
  },
  mounted() {
    this.statust = this.$store.state.statust;
  },
  computed: {
    isDisponible: function () {
      if (
        this.$store.state.tokenData &&
        this.$store.state.tokenData.permissions.isTarotReader
      )
        return false;
      return this.state == 'Disponible';
    },
  },
  methods: {
    textoIdioma(idioma) {
      switch (idioma){
      case 'ES':
        return 'Español';
      case 'EN':
        return 'Inglés';
      case 'PT':
        return 'Portugués';
        default:
          return idioma;
      }
    },
    disableButtonFuntion(tarotista, tipo){
      if((tarotista.type === 'agendada') && (this.statust['t'+tarotista.userId] ? this.statust['t'+tarotista.userId].chanels[tipo] : false)){
        return false;
      }else if (tarotista.type === 'agendada' && !this.statust['t'+tarotista.userId]) {
        return false;
      }else if ((this.statust['t'+tarotista.userId] ? (this.statust['t'+tarotista.userId].status === 'Disponible') : false) &&
          (this.statust['t'+tarotista.userId] ? this.statust['t'+tarotista.userId].chanels[tipo] : false)) {
        return false;
      }else {
        return true;
      }
    },
    numberWithCommas(x) {
            return x.toLocaleString('es-AR')
        },
    goToPay(tarotReaderId, type, channel) {
      this.$router.push(
        `/compra?tarotista=${tarotReaderId}&tipo=${type}&canal=${channel}`
      );
    },
    addFavourites() {
      this.$emit('addToFavourites');
    },
    removeFavourites() {
      this.$emit('removeFromFavourites');
    },
    parseYoutubeLink(link) {
      let url = link;
      return url.replace('watch?v=', 'embed/');
    },
  },
};
</script>

<style scoped>
.bg-green {
  background: linear-gradient(
    to right,
    #04d9b2 0%,
    #04d9b2 25%,
    #ffffff 25%,
    #ffffff 100%
  );
  padding: 0px;
}
.name-tarotista {
  font-family: maven-bold;
  margin-bottom: 0px;
  font-size: 28px;
  color: #501682;
}
.astrologer {
  font-family: maven-medium;
  color: #979797;
  font-size: 18px;
}
.description {
  font-family: maven-regular;
  font-size: 15px;
}
.col-img {
  max-width: 302px;
  margin-right: 15px;
  text-align: center;
}
.image {
  width: 280px;
  height: 280px;
  position: relative;
}
.image img {
  width: 100%;
  border-radius: 50%;
  height: 100%;
  object-fit: cover;
  background: white;
}
.image i {
  position: absolute;
  font-size: 45px;
  right: 15px;
  top: 20px;
  border: 4px solid white;
  border-radius: 50%;
}

.icon-btn {
  float: left;
  margin: auto;
  margin-top: 3px;
}

.grey-star {
  color: grey !important;
}
.tarotista-disponible {
  color: #04D925;
}
.tarotista-no-disponible {
  color: grey;
}
.tarotista-occupied {
  color: #ffc30b;
}

.col-puntaje {
  max-width: 180px;
  font-family: maven-semibold;
  font-size: 14px;
  color: #979797;
}
.puntaje {
  margin-top: 1px;
  float: left;
  margin-left: 10px;
}
.cantidad-personas {
  font-family: maven-regular;
  margin-left: 5px;
}
.star {
  float: left;
  /* margin-left: 5px; */

}

.star i {
  color: #ffc209;
  margin-left: 2px;
  font-size: 13px;
}

.readings {
  margin: 0px;
  /*  margin-top: 10px; */
}
.glass {
  float: left;
}
.glass i {
  margin-top: 3px;
  font-size: 14px;
  color: #979797;
}
.text-read {
  float: left;
  margin-left: 8px;
  /* text-align: center; */
  font-size: 14px;
}
.text-read h5 {
  margin-bottom: 0;
  color: #3f4254;
  font-size: 14px;
  font-family: maven-regular;
  margin-top: 4px;
  color: #979797;
}
.text-read span {
  font-family: maven-semibold;
}
.tipo-lectura {
  color: #501682;
}
.tipo-lectura p {
  font-family: maven-bold;
}
.btn-green {
  background: #04d9b2;
  color: #ffffff;
  margin-bottom: 10px;
  font-family: maven-semibold;
  font-size: 12px;
  text-align: center;
  border-radius: 15px;
  width: 165px;
}
.btn-green:hover {
  background: #00bf9c;

  color: #ffffff;
}

.btn-green:disabled {
  background-color: #e8e8e8;
  color: #dddddd;
}

.descuento {
  color: #979797;
  font-family: maven-regular;
  margin-left: 10px;
  font-size: 11px;
  vertical-align: middle;
  margin-bottom: 10px;
  text-decoration: line-through;
}
.btn-descuento {
  background: #501682;
  font-family: maven-semibold;
  color: #ffffff;
  margin-right: 12px;
  padding: 3px 20px;
  /* margin-left: 20px; */
  cursor: auto;
  border-radius: 0px;
  margin-top: -10px;
}
.price {
  margin-top: 8px;
  font-family: maven-semibold;
  font-size: 20px;
  margin-bottom: 0;
}

/* Nuevos cambios */

.tarotista-estado {
  text-align: center;
  text-decoration: none;
  font-family: 'maven-bold';
  margin: 10px 0;
  text-shadow: 1px 0 0 #fff, -1px 0 0 #fff, 0 1px 0 #fff, 0 -1px 0 #fff, 1px 1px #fff, -1px -1px 0 #fff, 1px -1px 0 #fff, -1px 1px 0 #fff;
}
.titulo-btn {
  font-family: 'maven-bold';
  color: #501682;
}
@media (max-width:768px) {
  .bg-green .bg-green {
    padding-right: calc(var(--bs-gutter-x)* 0.5);
    padding-left: calc(var(--bs-gutter-x)* 0.5);
  }
  .bg-green .col-img {
    margin-right: 0;
  }
}
@media (max-width:768px) {
  .col-btn .btn-green {
    width: calc(165px *1.25);
    font-size: .875rem;
    border-radius: 20px;
    color: #501682;
    padding: .5rem 1.25rem;
    margin: .75rem auto;
    display: block;
  }
}
</style>
