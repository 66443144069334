<template>
  <div>
    <div class="card shadow">
      <div class="card-body">
        <div class="row">
          <div class="col col-img">
            <div class="image">
              <img :src="tarotistaInfo ? tarotistaInfo.avatar : '../../assets/img/avatar.jpg'" alt="" />
            </div>
          </div>
          <div class="col">
            <div class="text" v-if="tarotistaInfo">
              <h5>{{ tarotistaInfo.alias }}</h5>
              <span v-if="tarotistaInfo.type === 'instantanea'"
                >Lecturas Instantaneas <i class="fas fa-bolt"></i
              ></span>
              <span v-else>Lecturas Agendadas</span>
            </div>
            <div class="col mt-3">
              <div class="toggle">
                <label class="switch">
                  <input v-model="isAvailable" type="checkbox" />
                  <span class="slider round"></span>
                </label>
                <span class="text-disponible" v-if="isAvailable"
                  >Estoy disponible</span
                >
                <span class="text-disponible" v-else>No estoy disponible</span>
              </div>
            </div>
          </div>
          <div class="col-3 text-end">
            <router-link to="/setting" class="">
              <button class="btn-transparet">
                <i class="fas fa-cog setting"></i>
              </button>
            </router-link>

            <!-- <button class="btn-transparet">
              <i class="fas fa-sign-out-alt logout"></i>
            </button> -->
          </div>
        </div>

        <!-- <ToggleMedios /> -->

        <!-- <div class="row " v-if="roomInProgress">
          <div class="col-12">
            <h5 class="alert-room-progress">
              Tienes una llamada en progreso
            </h5>
          </div>
          <div class="col text-center">
            <button @click="goToRoom()" class="btn btn-sm btn-sala">
              Entrar a sala
            </button>
          </div>
        </div> -->
        <div class="row" style="padding-top: 20px;">
          <div class="col-12 text-center" v-if="obtain">
            <i v-if="status.chat != null" v-on:click="()=>changeStatusChannel(1)" :class="'fas fa-comment-dots canalIcon '+(isAvailable ? 'pointer '+ (status.chat ? 'activeIcon' : 'inactiveIcon'):'disableIcon')"></i>
            <i v-if="status.video != null" v-on:click="()=>changeStatusChannel(2)" :class="'fas fa-video canalIcon '+(isAvailable ? 'pointer '+(status.video ? 'activeIcon' : 'inactiveIcon'):'disableIcon')"></i>
            <i v-if="status.voice != null" v-on:click="()=>changeStatusChannel(3)" :class="'fas fa-phone canalIcon '+(isAvailable ? 'pointer '+(status.voice ? 'activeIcon' : 'inactiveIcon'):'disableIcon')"></i>
          </div>
        </div>
      </div>
    </div>
    <ModalAvisoBancario/>
  </div>
</template>
<script>
import axiosClient from '../../config/axiosClient';
import { enableLogs } from '../../config';
import ModalAvisoBancario from "@/components/DashboardTarotista/Modal/ModalAvisoBancario.vue";
import moment from 'moment';
/* import ToggleMedios from './toggleMedios.vue'; */
export default {
  data() {
    return {
      isAvailable: false,
      tarotistaInfo: null,
      latestRoom: null,
      obtain: false,
      status:{
        chat: true,
        video: true,
        voice: true
      }
    };
  },
  components: {
    ModalAvisoBancario,
    /* ToggleMedios, */
  },
  created() {
    if (this.$store.state.socket) this.getStatus();

    if (this.jwt) {
      this.getLatestRoom();
      this.getTarotistaInfo();
    }
  },
  mounted(){
    let ss = this.$store.state.socket;
      if(ss){
        ss.on('getMyStatus', (chanels)=>{
          if(enableLogs) console.log('MyStatus')
          if(enableLogs) console.log(chanels)
          this.status.chat = chanels.chat;
          this.status.video = chanels.video;
          this.status.voice = chanels.voice;
          this.obtain = true;
          this.isAvailable = chanels.status == 'Disponible';
          let status = window.localStorage.getItem('ST');
          if(status != null)
          {
            status = JSON.parse(status);
            if(enableLogs) console.log('detectLoad')
            let cha = {
              chat: this.status.chat != null ? (status.chat ? true : false):null,
              video: this.status.video != null ? (status.video ? true : false):null,
              voice: this.status.voice != null ? (status.voice ? true : false):null
            };
            
            this.status.chat = cha.chat;
            this.status.video = cha.video;
            this.status.voice = cha.voice;
            setTimeout(() => {
              this.isAvailable = (status.value ? status.value : 0) == 1 ? false:true;
            }, 200);
          }
        });
      ss.emit('getMyStatus',1);
      this.$store.commit('activePing');
    }
  },
  computed: {
    socket: function() {
      return this.$store.state.socket;
    },
    jwt: function() {
      return this.$store.state.jwt;
    },
    disableNotPresent: function() {
      return this.$store.state.disableNotPresent;
    }, 
    tokenData: function() {
      return this.$store.state.tokenData;
    },
    roomInProgress() {
      if (this.latestRoom) {
        if (this.latestRoom.channel == 'VOICE') return false;

       if(enableLogs) console.log(`Latest room date:
        init: ${this.latestRoom.creationDate}
        end: ${this.latestRoom.finishDate}
        `);
        if (
          moment().isBetween(
            this.latestRoom.creationDate,
            this.latestRoom.finishDate
          )
        )
          return true;
        return false;
      }
      return false;
    },
    roomUrl() {
      if (this.latestRoom) {
        let url;
        switch (this.latestRoom.channel) {
          case 'CHAT':
            url = `/chat?roomId=${this.latestRoom.roomId}`;

            break;
          case 'VOICE':
            url = `/room-llamada?roomId=${this.latestRoom.roomId}`;

            break;
          case 'VIDEO':
            url = `/room?roomId=${this.latestRoom.roomId}`;

            break;
          default:
            url = '';
        }
        return url;
      } else {
        return '';
      }
    },
  },
  watch: {
    disableNotPresent: function(disableNotPresent){
      if(disableNotPresent){
        this.$store.commit('disableNotPresentFun', false);
        this.isAvailable = false
      }
    },
    jwt: function(jwt) {
      if (jwt) {
        this.getLatestRoom();
        this.getTarotistaInfo();
      }
    },
    socket: function(socket) {
      if(enableLogs) console.log('Cambio en socket')
      if (socket == null || socket == undefined){
        return;
      } else{
        socket.on('getMyStatus', (chanels)=>{
          if(enableLogs) console.log('MyStatus')
          if(enableLogs) console.log(chanels)
          this.status.chat = chanels.chat;
          this.status.video = chanels.video;
          this.status.voice = chanels.voice;
          this.obtain = true;
          this.isAvailable = true;
          
          let status = window.localStorage.getItem('ST');
          if(status != null)
          {
            setTimeout(() => {
              if(enableLogs) console.log('detectLoad')
              let cha = {
                chat: this.status.chat != null ? (status.chat ? true : false):null,
                video: this.status.video != null ? (status.video ? true : false):null,
                voice: this.status.voice != null ? (status.voice ? true : false):null
              };
              
              this.status.chat = cha.chat;
              this.status.video = cha.video;
              this.status.voice = cha.voice;
              this.isAvailable = (status.value ? (status.value == 1 ? 1 : 0 ) : 1) == 1 ? false:true;
            }, 5000);
          }
        });
        for (let u = 0; u < 10; u++) {
          setTimeout(() => {
            if(!this.obtain){
              socket.emit('getMyStatus',1);
            }
          }, 1000*u);
        }
        this.getStatus();
      }


    },
    isAvailable: function(isAvailable) {
      if (this.socket == null || this.socket == undefined) {
        return;
      }
      // let state;
      // if (isAvailable) {
      //   state = 'AVAILABLE';
      // } else {
      //   state = 'LOGGED_IN';
      // }
      if (enableLogs) console.log('Changing state...');
      // this.socket.emit('change-state', state);
      if (isAvailable) {
        if(enableLogs) console.log('#################################')
        if(enableLogs) console.log('emitiendo disponible')
        if(enableLogs) console.log('#################################')
        setTimeout(() => {
          this.socket.emit('hide', {
            value: 0,
            chanels: this.status
          });
          window.localStorage.setItem('ST',JSON.stringify({
            value:0,
            chat:this.status.chat,
            video:this.status.video,
            voice:this.status.voice,
          }));
        }, 100);
      } else {
        if(enableLogs) console.log('#################################')
        if(enableLogs) console.log('emitiendo no disponible')
        if(enableLogs) console.log('#################################')
        setTimeout(() => {
          this.socket.emit('hide', {
            value: 1,
            chanels: this.status
          });
          window.localStorage.setItem('ST',JSON.stringify({
            value:1,
            chat:this.status.chat,
            video:this.status.video,
            voice:this.status.voice,
          }));
        },100);
      }
      if (enableLogs) console.log('Changed state!');
    },
  },
  methods: {
    showModalAvisoBancario(){
      this.$bvModal.show('modalAvisoBancario');
    },
    changeStatusChannel(chanel){
      if(this.isAvailable){
        switch (chanel) {
          case 1:
            this.status.chat = !this.status.chat
            break;
          case 2:
            this.status.video = !this.status.video
            break;
          case 3:
            this.status.voice = !this.status.voice
            break;
          default:
            break;
        }
        //? si todos los canales se inhabilitan pasar a no disponible
        if(!this.status.chat && !this.status.video && !this.status.voice ){
          //? invertir cambio realizado
          switch (chanel) {
            case 1:
              this.status.chat = !this.status.chat
              break;
            case 2:
              this.status.video = !this.status.video
              break;
            case 3:
              this.status.voice = !this.status.voice
              break;
            default:
              break;
          }
          this.isAvailable = false;
          /*console.log('#################################')
          console.log('emitiendo disponible')
          console.log('#################################')
          this.socket.emit('hide', {
            value: 1,
            chanels: this.status
          });*/
        }else{
          this.socket.emit('hide', {
            value: 0,
            chanels: this.status
          });
          window.localStorage.setItem('ST',JSON.stringify({
            value:0,
            chat:this.status.chat,
            video:this.status.video,
            voice:this.status.voice,
          }));
        }
        
      }
    },
    goToRoom() {
      if (this.roomUrl == '') return;
      if (enableLogs) console.log(this.roomUrl);
      this.socket.emit('change-state', 'OCCUPIED');
      this.$router.push(this.roomUrl);
    },
    async getLatestRoom() {
      if (enableLogs) console.log('Fetching latest room');
      try {
        let response = await axiosClient.get('/users/get-latest-room', {
          headers: { Authorization: `Bearer ${this.$store.state.jwt}` },
        });
        this.latestRoom = response.data.latestRoom;
        if (enableLogs) console.log(this.latestRoom);
      } catch (err) {
        if (enableLogs) console.log('Error fetching latest room');
        if (enableLogs) console.log(err);
      }
    },
    getStatus() {
      const socket = this.$store.state.socket;
      if (enableLogs) console.log('Getting tarot reader status...');

      socket.on('get-my-status', status => {
        if (enableLogs) console.log(`Tarot reader status is ${status}`);
        if (status === 'AVAILABLE') this.isAvailable = true;
        else this.isAvailable = false;
      });
    },
    async getTarotistaInfo() {
      if (enableLogs) console.log('fetching tarotista info...');
      try {
        const response = await axiosClient.get(
          `tarot-reader/get-tarot-reader-uid/${this.tokenData.userId}`
        );
        if (enableLogs) console.log('successfully fetched tarotista info');
        if (enableLogs) console.log(response.data);
        this.tarotistaInfo = response.data.tarotReaderInfo;
        if (!this.tarotistaInfo.rut || !this.tarotistaInfo.bankName || !this.tarotistaInfo.accountNumber || !this.tarotistaInfo.typeAccount) {
          if (this.tarotistaInfo.notificacion_bancaria !== 1) {
            await axiosClient.get(
                `tarot-reader/get-tarot-reader-uid/notiBank/${this.tarotistaInfo.tarotReaderId}`
            );
            this.$router.push('/setting#datos-bancarios');
            /*setTimeout(() => {
              this.$router.push('/setting#datos-bancarios');
            }, 3000);*/
            this.sendEmail(this.tokenData.email);
          }
        }
      } catch (error) {
        if (enableLogs) console.log('could not retrieve tarotista info');
        if (enableLogs) console.log(error);
      }
    },
    async sendEmail(email) {
      try {
        const response = await axiosClient.post(
            '/email-send/email-notificacion',
            {
              email: email,
              datosVariados: {},
              type: 'aviso-bancario',
            }
        );
        if(enableLogs) console.log(response);
      } catch (error) {
        if(enableLogs) console.log('could not send inscription form');
        if(enableLogs) console.log(error);
      }
    },
  },
};
</script>
<style scoped>
.card {
  border-radius: 10px;
  min-height: 174px;
}
.col-img {
  max-width: 90px;
  padding-right: 0;
}
.image {
  height: 78px;
  width: 78px;
}
.image img {
  width: 100%;
  border-radius: 50%;
  height: 100%;
  object-fit: cover;
}
.text {
  font-family: maven-regular;
}
.text h5 {
  font-weight: bold;
  font-size: 12px;
  margin-bottom: 0px;
}
.text span {
  font-size: 10px;
}
.text p {
  font-size: 12px;
  margin-bottom: 0;
}
.text span i {
  font-size: 10px;
  color: #04d9b2;
  margin-left: 8px;
}

/*toggle*/
/* .toggle span {
    font-family: maven-semibold;
    font-size: 12px;
  } */
.switch {
  position: relative;
  display: inline-block;
  width: 40px;
  height: 20px;
  margin-right: 10px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.pointer{
  cursor: pointer;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #fff;
  border: 1px solid #ffc209;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: '';
  height: 13px;
  width: 13px;
  left: 4px;
  bottom: 3px;
  background-color: #fff;
  border: 1px solid #ffc209;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: #ffc209;
  border: 1px solid #fff;
}

input:focus + .slider {
  box-shadow: 0 0 3px #fff;
}

input:checked + .slider:before {
  -webkit-transform: translateX(16px);
  -ms-transform: translateX(16px);
  transform: translateX(16px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

.icon-container {
  width: 45px;
  background-color: #f6f6f6;
  margin: auto;
  border-radius: 8px;
}
.icon-container:focus,
.icon-container:active {
  width: 45px;
  background-color: #501682;
  margin: auto;
  border-radius: 8px;
}
.icon-container:hover {
  width: 45px;
  background-color: #5c3b79;
  margin: auto;
  border-radius: 8px;
}
.medios {
  text-align: center;
  margin-top: 10px;
  margin-bottom: 10px;
}
.medios a {
  text-decoration: none;
  color: #501682;
}
.medios i {
  font-size: 25px;
  color: #501682b9;
  padding: 5px;
}
.medios i:hover {
  font-size: 25px;
  color: #f6f6f6;
  padding: 5px;
}
.medios p {
  font-family: maven-bold;
  font-size: 13px;
  margin-bottom: 0;
}
.medios span {
  font-family: maven-bold;
  font-size: 9px;
}

.container-precio {
  text-align: center;
  margin-top: 15px;
}
.precio {
  font-family: maven-semibold;
  font-size: 18px;
}

.canalIcon{
  font-size: 20px;
  padding: 8px;
  margin-left: 10px;
  margin-right: 10px;
  color: white;
  border-radius: 5px;
}
.activeIcon{
  background-color: #501682;
}
.inactiveIcon{
  background-color: #797878;
}
.disableIcon{
  background-color: rgb(235, 235, 235);
}

.btn-grey {
  font-family: maven-semibold;
  background: #f6f6f6;
  border: 1px solid #501682;
  color: #501682;
  padding: 6px;
  text-transform: uppercase;
  font-size: 10px;
}
.btn-grey:hover {
  background: #dfdede;
  color: #650bbf;
}
.btn-transparet {
  background: transparent;
  border: none;
}
.btn-transparet:hover i {
  color: #333;
  transition: all 0.5s ease !important;
}
.setting {
  color: #707070;
}
.logout {
  color: #501682;
}
.text-disponible {
  font-family: maven-semibold;
  font-size: 12px;
  vertical-align: text-top;
}
.alert-room-progress {
  font-family: maven-bold;
  color: #501682;
  margin-top: 10px;
  font-size: 15px;
  text-align: center;
}
.btn-sala {
  background: #501682;
  color: #fff;
  font-family: maven-semibold;
  font-size: 11px;
  text-transform: uppercase;
}
.btn-sala:hover {
  background: #52099b;
  color: #fff;
}
</style>
