import Vue from 'vue';
import App from './App.vue';

import { BootstrapVue, IconsPlugin } from 'bootstrap-vue';
import axiosClient from './config/axiosClient';
import jwt_decode from 'jwt-decode';

import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';

import VueRouter from 'vue-router';

import routes from './router';

import store from './store/store';
import VueGtag from 'vue-gtag';

const router = new VueRouter({
  mode: 'history',
  routes,
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
});

Vue.use(VueGtag, {
  config: { id: 'G-ZGFYX0TP7P' }
}, router);

router.beforeEach(async (to, from, next) => {
  const getUserToken = async () => {
    if (store.state.tokenData) return store.state.tokenData;
    try {
      let response = await axiosClient.post('/users/refresh-token');
      if (response.data.token) return jwt_decode(response.data.token);
    } catch (err) {
      return null;
    }

    return null;
  };

  let decodedToken;
  // If the route needs a token, fetch it (either from store, quick or from the backend, slower)
  if (
    to.matched.some(record => record.meta.isAdmin) ||
    to.matched.some(record => record.meta.userOnly) ||
    to.matched.some(record => record.meta.isLoggedIn) ||
    to.matched.some(record => record.meta.isTarotReader)
  ) {
    decodedToken = await getUserToken();
  }

  // If the meta is userOnly, only registered users that are not tarot readers or
  if (to.matched.some(record => record.meta.userOnly)) {
    if (
      decodedToken == null ||
      decodedToken.permissions.isAdmin ||
      decodedToken.permissions.isTarotReader
    ) {
      next({
        path: '/',
        params: { nextUrl: to.fullPath },
      });
      return;
    }
    next();
    return;
  }

  if (to.matched.some(record => record.meta.isLoggedIn)) {
    if (decodedToken == null) {
      next({
        path: '/',
        params: { nextUrl: to.fullPath },
      });
      return;
    }
    next();
    return;
  }

  if (to.matched.some(record => record.meta.isTarotReader)) {
    if (decodedToken == null || !decodedToken.permissions.isTarotReader) {
      next({
        path: '/',
        params: { nextUrl: to.fullPath },
      });
      return;
    }
    next();
    return;
  }

  if (to.matched.some(record => record.meta.isAdmin)) {
    if (decodedToken == null || !decodedToken.permissions.isAdmin) {
      next({
        path: '/',
        params: { nextUrl: to.fullPath },
      });
      return;
    }
    next();
    return;
  }

  next();
  return;
});

Vue.use(VueRouter);

// Make BootstrapVue available throughout your project
Vue.use(BootstrapVue);
// Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin);
Vue.config.devtools = true;
Vue.config.productionTip = false;

new Vue({
  render: h => h(App),
  router,
  store,
}).$mount('#app');
