<template>
  <div class="container">
    <div class="row justify-content-center mt-5">
      <div class="col-12">
        <h5 class="title">Finalizar compra</h5>
      </div>
      <div class="col-7">
        <Calendario/>
      </div>
      <div class="col-5">
        <div class="row">
          <Wallet/>
          <Detalle/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Calendario from "./Calendario";
import Wallet from "./Wallet";
import Detalle from "./Detalle"
export default {
  name: "DashboardUser",
  components: {
    Calendario,
    Wallet,
    Detalle
  },
};
</script>

<style scoped>
.title {
  color: #501682;
  font-weight: bold;
  text-transform: uppercase;
}


</style>