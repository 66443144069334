<template>
  <div>
    <div class="container">
      <div class="row justify-content-center mt-5 " v-if="isLoading">
        <Spinner />
      </div>
    </div>
    <div v-if="!isLoading">
      <div class=" bg-green">
          <InfoPersonal
            :state="estado"
            :tarotista="tarotista"
            :favourites="favourites"
            :liked="liked"
            @removeFromFavourites="removeFromFavourites"
            @addToFavourites="addToFavourites"
            :status="statust['t'+tarotista.userId]"
            :statename="statust['t'+tarotista.userId] ? statust['t'+tarotista.userId].status : 'Desconectado'"
          />
      </div>

      <div class=" bg-lila mb-0">

          <AboutMe :tarotista="tarotista" :status="statust['t'+tarotista.userId]" :statename="statust['t'+tarotista.userId] ? statust['t'+tarotista.userId].status : 'Desconectado'" />

      </div>
      <div class=" bg-white" v-if="similarTarotReaders.length > 0">
          <Tarotista :similarTarotistas="similarTarotReaders" />
      </div>
    </div>
  </div>
</template>

<script>
import axiosClient from '../../config/axiosClient';
import InfoPersonal from './InfoPersonal';
import AboutMe from './AboutMe.vue';
import Tarotista from './Tarotistas.vue';
import Spinner from '../loadingspinner/Spinner.vue';
import { enableLogs } from '../../config';
export default {
  name: 'Layout',
  data() {
    return {
      favourites: [],
      tarotista: null,
      languages: null,
      scopes: [],
      isLoading: true,
      estado: '',
      liked: false,
      similarTarotReaders: [],

      statust: {}
    };
  },
  mounted(){
    this.statust = this.$store.state.statust;
  },
  components: {
    InfoPersonal,
    AboutMe,
    Tarotista,
    Spinner,
  },
  computed: {
    socket: function() {
      return this.$store.state.socket;
    },
    jwt: function() {
      return this.$store.state.jwt;
    },
  },
  watch: {
    socket: function(socket) {
      if (socket) {
        this.stateWatcher();
        this.getState();
        this.socket.on('statusTarotReaderChange', ()=>{
          setTimeout(() => {
            this.statust = {...this.$store.state.statust}
          }, 100);
        })
      }
    },
    jwt: function(jwt) {
      if (jwt == null) {
        return;
      } else {
        if (enableLogs) console.log('found jwt');
        this.getFavouriteTarotistas();
      }
    },
  },
  async created() {
    await this.getInfoTarotista();
    await this.getSimilarTarotReaders();
    if (this.$store.state.jwt) {
      this.getFavouriteTarotistas();
    }

    if (this.$store.state.socket) {
      this.stateWatcher();
      this.getState();
    }
  },
  methods: {
    stateWatcher() {
      if (enableLogs) console.log('Setting up the state watcher');

      const socket = this.$store.state.socket;

      socket.removeAllListeners('state-update');

      const updateTarotista = state => {
        const setState = this.setState;
        setState(state);
      };

      socket.on('state-update', (id, state) => {
        if (enableLogs) console.log(`New state detected: ${id} ${state}`);
        updateTarotista(state);
      });
    },
    setState(status) {
      switch (status) {
        case 'LOGGED_IN':
          this.estado = 'No Disponible';
          break;
        case 'AVAILABLE':
          this.estado = 'Disponible';
          break;
        default: {
          this.estado = 'Ocupado';
        }
      }
    },
    getState() {
      const socket = this.$store.state.socket;
      const idTarotista = this.$route.query.id_tarotista;
      if (enableLogs) console.log('Fetching tarot reader status');

      const setState = this.setState;
      socket.emit('get-status', idTarotista, status => {
        if (enableLogs) console.log(`Tarot reader status: ${status}`);
        setState(status);
      });
    },
    async getInfoTarotista() {
      const idTarotista = this.$route.query.id_tarotista;
      if (enableLogs) console.log(`Fetching tarot reader ${idTarotista} info`);

      try {
        const response = await axiosClient.get(
          `/tarot-reader/get-tarot-reader-info/${idTarotista}`
        );
        this.tarotista = response.data.tarotReaderInfo;
        if(enableLogs) console.log('INFO TAROTISTAAAAAA', this.tarotista);
        if (enableLogs) console.log(this.tarotista);
      } catch (error) {
        if (enableLogs) console.log('No se ha encontrado la info del tarotista.');
      } finally {
        this.isLoading = false;
      }
    },
    async getFavouriteTarotistas() {
      try {
        const response = await axiosClient.get('/tarot-reader/get-favourites', {
          headers: { Authorization: `Bearer ${this.$store.state.jwt}` },
        });
        this.favourites = response.data.favourites;
        this.isLoading = false;
        if (enableLogs) console.log(this.favourites);
        this.checkLiked();
      } catch (error) {
        if (enableLogs) console.log('could not get favourite tarot readers for the user');
        if (enableLogs) console.log(error);
        this.isLoading = false;
      }
    },
    async removeFromFavourites() {
      try {
        const response = await axiosClient.post(
          '/tarot-reader/remove-favourite',
          {
            tarotReaderId: this.tarotista.tarotReaderId,
          },
          {
            headers: { Authorization: `Bearer ${this.$store.state.jwt}` },
          }
        );
        if (enableLogs) console.log(
            'successfully removed tarot reader from favourites',
            response.data.removedTarotista
          );
        if (enableLogs) console.log(response.data);
        this.liked = false;
      } catch (error) {
        if (enableLogs) console.log(error);
        if (enableLogs) console.log('could not remove tarotista from favourites');
      }
    },
    async addToFavourites() {
      try {
        const response = await axiosClient.post(
          `/tarot-reader/add-to-favourites`,
          {
            tarotReaderId: this.tarotista.tarotReaderId,
          },
          {
            headers: { Authorization: `Bearer ${this.$store.state.jwt}` },
          }
        );
        if (enableLogs) console.log(response);
        this.liked = true;
      } catch (error) {
        if (enableLogs) console.log('could not add tarot reader to favourites');
        if (enableLogs) console.log(error);
      }
    },
    checkLiked() {
      for (let favTarotista of this.favourites) {
        if (enableLogs) console.log(favTarotista.tarotReaderId);
        if (favTarotista.tarotReaderId === this.tarotista.tarotReaderId) {
          this.liked = true;
          break;
        }
      }
    },
    async getSimilarTarotReaders() {
      try {
        const {
          data: { tarotReaders },
        } = await axiosClient.get('tarot-reader/get-tarot-readers');
        //Recorre los tarotistas

        for (let i = 0; i < tarotReaders.length; i++) {
          let match = false;
          //Recorrer ambitos de tarotista actual
          if (this.tarotista.scopes)
            for (let j = 0; j < this.tarotista.scopes.length; j++) {
              //Recorrer ambitos del tarotista obtenido del primer ciclo
              if (tarotReaders[i].scopes)
                for (let k = 0; k < tarotReaders[i].scopes.length; k++) {
                  //Validar que concuerdan los dos scopenames
                  if (
                    tarotReaders[i].scopes[k].scopeName ===
                    this.tarotista.scopes[j].scopeName
                  ) {
                    if(tarotReaders[i].tarotReaderId != this.tarotista.tarotReaderId){
                      match = true;
                      break;
                    }
                  }
                }
              //Agregar el tarotista al array de similares
              if (match) {
                this.similarTarotReaders.push(tarotReaders[i]);
                break;
              }
            }
          if (!match) {
            if (this.tarotista.specialties)
              for (let l = 0; l < this.tarotista.specialties.length; l++) {
                if (tarotReaders[i].specialties)
                  for (let t = 0; t < tarotReaders[i].specialties.length; t++) {
                    if (
                      tarotReaders[i].specialties[t].specialtyName ===
                      this.tarotista.specialties[l].specialtyName
                    ) {
                      if(tarotReaders[i].tarotReaderId != this.tarotista.tarotReaderId){
                        match = true;
                        break;
                      }
                    }
                  }
                if (match) {
                  this.similarTarotReaders.push(tarotReaders[i]);
                  break;
                }
              }
          }

          if (this.similarTarotReaders.length == 4) {
            break;
          }
        }
      } catch (error) {
        if(enableLogs) console.log(error);
      }
    },
  },
};
</script>
<style scoped>
.bg-lila {
  background-color: #501682;
  padding-top: 80px;
  padding-bottom: 80px;
}
.bg-white{
  padding-top: 80px;
  padding-bottom: 80px;
}
.bg-green {
  background: linear-gradient(
    to right,
    #04d9b2 0%,
    #04d9b2 25%,
    #ffffff 25%,
    #ffffff 100%
  );
  padding: 0px;
}
.bg-green {
  background: linear-gradient(
    to right,
    #04d9b2 0%,
    #04d9b2 25%,
    #ffffff 25%,
    #ffffff 100%
  );
  padding: 0px;
}

@media screen and (max-width: 767px) {
  .bg-green {
    background: linear-gradient(
      to bottom,
      #04d9b2 0%,
      #04d9b2 30%,
      #ffffff 30%,
      #ffffff 100%
    );
    padding: 0px;
    margin-top: -60px;
  }
  .bg-subtitle {
    background-color: #04d9b2 !important;
  }
}
</style>
