<template>
  <div>
    <div class="card card-pagos">
      <div v-if="!cardSaved" class="card-body">
        <h5 class="title-card-pago">Ingresa tus datos</h5>

        <img class="logo-kushky" src="../../assets/img/kushki.png" alt="">

        <form autocomplete="off">
          <div class="row">
            <div class="form-group">
              <label class="label-card-pago" for="titular">Titular</label>
              <input
                v-model="personName"
                type="text"
                class="form-control form-input"
                id="titular"
                v-on:click="changeInput"
                placeholder="Nombre completo"
                autocomplete="off"
                name="editName"
              />
              <p class="alert" v-if="validarNombre(personName.toString())">
                Este campo no acepta números
              </p>
              <p class="alert" v-if="personName.length === 0 && editName">
                Campo obligatorio
              </p>
            </div>
            <div class="form-group mt-1">
              <label class="label-card-pago"  for="tarjeta">Número tarjeta</label>
              <input
                v-model="cardNum"
                class="form-control form-input"
                @keypress="onlyNumber"
                id="tarjeta"
                placeholder="Número de tarjeta"
                autocomplete="off"
                v-on:click="changeInput"
                maxlength="20"
                name="editCardNum"
                min="0"
                inputmode="numeric"
                pattern="[0-9]*"
                type="number"
              />
              <p class="alert" v-if="cardNum.length === 0 && editCardNum">
                Campo obligatorio
              </p>
            </div>
            <div class="form-group mt-1 col-6">
              <label class="label-card-pago"  for="vto">Vto.</label>
              <input
                v-model="expiration"
                type="text"
                class="form-control form-input"
                id="vto"
                placeholder="MM/AA"
                maxlength="5"
                v-on:click="changeInput"
                autocomplete="off"
                name="editVto"
              />
              <p class="alert" v-if="!vtoValid() && editVto">Fecha inválida</p>
            </div>
            <div class="form-group mt-1 col-6">
              <label class="label-card-pago"  for="cvv">CVV</label>
              <input
                v-model="cvv"
                type="number"
                @keypress="onlyNumber"
                class="form-control form-input"
                id="cvv"
                placeholder="123"
                v-on:click="changeInput"
                @keyup="inputCvv"
                min="0"
                max="9999"
                name="editCvv"
                pattern="[0-9]*"
              />
              <p class="alert" v-if="cvv.length === 0 && editCvv">
                Campo obligatorio
              </p>
            </div>
            <!-- 
            <div class="form-group mt-3">
              <label for="formGroupExampleInput2">RUT</label>
              <input
                v-model="rut"
                type="text"
                class="form-control"
                id="formGroupExampleInput2"
                placeholder="-"
              />
            </div>
            -->
            <div class="text-center mt-3">
              <b-button
                :disabled="!allowForm"
                @click="askForConfirmPayment()"
                class="btn btn-pagar"
                >Ir a pagar</b-button
              >
            </div>
          </div>
        </form>
      </div>
      <div v-else class="card-body text-center">
        <p class="text-medio-pago">Su tarjeta guardada: <span>****-{{ lastDigits }}</span></p>   
        
        <b-button @click="askForConfirmPayment()" class="btn btn-pagar"
          >Ir a pagar</b-button
        >
        <b-button @click="deleteTarjeta()" class="btn btn-delete-card mt-3">Borrar tarjeta</b-button>
      </div>
    </div>
  </div>
</template>

<script>
import axiosClient from '../../config/axiosClient';
import { enableLogs } from '../../config';
export default {
  data: () => {
    return {
      personName: '',
      cardNum: '',
      expiration: '',
      cvv: '',
      rut: '',
      editApellido: false,
      editCardNum: false,
      editName: false,
      editVto: false,
      editCvv: false,

      dataUser: {},
      nombre:'',
      apellido:'',
      validarInfo:null,
      habilitado: false,
      fieldTypes: {
        // add this for change input type
        password: 'text',
      },
    };
  },
  computed: {
    allowForm: function () {
      return this.validForm();
    },
    infoCredit: function(){
      return this.$store.state.infoCredit
    },
    infoCreditGuest: function(){
      return this.$store.state.infoGuestCredit
    }
  },
  methods: {
    async getDatosAdicional(){
      try {
        
      let email = window.localStorage.getItem('emailGuest')
       const info = await axiosClient.post(
          "/users/additional-info/",
          {email},
        );
        this.dataUser = info.data.info[0]
        this.personName = info.data.info[0].nombre +  " " + info.data.info[0].apellido
      } catch (error) {
       if(enableLogs) console.log(error.response.data);
      }
    },

    async deleteTarjeta() {
      await axiosClient.delete('/payment/kushki-delete-card', {
        headers: {
          Authorization: `Bearer ${this.$store.state.jwt}`,
        },
      });
      this.$emit('tarjetaDeleted');
    },
    vtoValid() {
      let vv = true;
      let fecV = this.expiration.split('/');
      vv = vv && fecV.length == 2;
      vv = vv && parseInt(fecV[0]) > 0 && parseInt(fecV[0]) <= 12;
      vv = vv && parseInt(fecV[1]) > 21;
      vv = vv && this.expiration.length == 5 && this.expiration[2] === '/';
      return vv;
    },
    validForm() {
      // Name validation
      let validForm = this.personName !== '';
      // Card num validation
      validForm = validForm && this.cardNum.length <= 20;
      // Expiration validation
      validForm = validForm && this.vtoValid();
      // Cvv validation
      validForm = validForm && (this.cvv.length === 3 || this.cvv.length === 4);

     /*  validForm = this.habilitado = this.infoCreditGuest != null; */

      return validForm;
    },
    askForConfirmPayment() {
      this.habilitado = this.infoCreditGuest != null;

      if(this.habilitado){
        var resbool = false;
        try {
          resbool =
            this.validarEmail(window.valid.email) &&
            window.valid.email == window.valid.repeatEmail;
        } catch (e) {
          if (enableLogs) console.log(e);
        }

        if (resbool || this.$store.state.isLoggedIn === true) {
          this.$emit('askForConfirmPayment', {
            type: 'kushkiCreditCard',
            personName: this.personName,
            cardNum: this.cardNum,
            expiration: this.expiration,
            cvv: this.cvv,
          });
        } else {
          alert('Ingrese un email válido');
        }
      }else{
        if(enableLogs) console.log('es igual a null')
        this.$store.commit("showModalNotLogin", true);
      }

      

    },
    maskImput() {
      var newInput = document.getElementById('vto');
      newInput.addEventListener('keydown', function (e) {
        if (e.which !== 8) {
          var numChars = e.target.value.length;
          if (numChars === 2) {
            var thisVal = e.target.value;
            thisVal += '/';
            e.target.value = thisVal;
          }
        }
      });
    },
    validarEmail(valor) {
      if (valor.length > 0) {
        if (
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
            valor
          )
        ) {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    },
    validarNombre(valor) {
      if (valor.length > 0) {
        if (!/^[a-zA-Z_ ]+$/.test(valor)) {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    },
    changeInput(event) {
      this[event.target.name] = true;
    },
    inputCvv() {
      this.cvv = this.cvv.substr(0, 4);
      /* console.log(event.target.value.substr(0,4)) */
      if (enableLogs) console.log(this.cvv.substr(0, 4));
    },
    /* onlyNumber($event) {
      //if(enableLogs) console.log($event.keyCode); //keyCodes value
      let keyCode = $event.keyCode ? $event.keyCode : $event.which;
      if ((keyCode < 48 || keyCode > 57) && keyCode !== 46) {
        // 46 is dot
        $event.preventDefault();
      }
    }, */

    onlyNumber(event) {
      if (enableLogs) console.log(event.keycode);
      let keyCode = event.keyCode ? event.keyCode : event.which;
      if ((keyCode < 48 || keyCode > 57) && keyCode !== 46) {
        // 46 is dot
        event.preventDefault();
      }
    },
  },
  watch: {
    tokenData: function() {
      this.getDatosAdicional()
    },
    infoCredit: function(){
      this.getDatosAdicional()
    },
    infoCreditGuest: function(){
      /* console.log('AAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAa') */
      this.habilitado = this.infoCreditGuest != null;
      if(this.infoCreditGuest != null){
        this.personName = this.infoCreditGuest.firstName + " " + this.infoCreditGuest.lastName
      }
    }
  },
  mounted() {
    this.maskImput();
    this.getDatosAdicional();

    this.habilitado = this.$store.state.infoGuestCredit != null
  },
  props: {
    cardSaved: Boolean,
    lastDigits: String,
  },
  
};
</script>

<style scoped>
.title-card-pago{
  margin-bottom: 10px !important;
}
.logo-kushky{
  max-width: 130px;
  margin-left: calc(50% - 65px);
}
.card-body {
  position: relative;
}
.btn-pagar {
  position: absolute;
  bottom: 20px;
  right: calc(50% - 63px);
}
/deep/.close {
  background: transparent;
  border: none;
}
/deep/.modal-header {
  border-bottom: none;
}

/deep/.modal-body {
  padding: 0 50px;
}
.text-modal {
  color: #501682;
  font-family: maven-bold;
  text-align: center;
  text-transform: uppercase;
  font-size: 20px;
}
.text-saldo {
  text-align: center;
  font-family: maven-regular;
  font-size: 20px;
}
.text-saldo span {
  font-family: maven-bold;
  color: #3b5998;
}
/deep/.modal-footer {
  border-top: none;
  padding: 10px 50px 30px 50px;
}
/deep/.btn-modal {
  background-color: #04d9b2;
  color: #fff;
  font-family: maven-bold;
  text-transform: uppercase;
  border: none;
}
.alert {
  color: red;
  padding: 0px;
  font-size: 9px;
  font-family: maven-semibold;
  margin-bottom: 0px;
}
</style>
