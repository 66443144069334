<template>
  <div>
    <div class="card card-pagos">
      <div class="card-body">
<!--         <h5 class="title-card-pago">Formulario de registro</h5> -->
        <form>
          <div class="form-group">
            <label class="label-card-pago" for="formGroupExampleInput">Email(*)</label>
            <input
              type="email"
              class="form-control form-input"
              id="formGroupExampleInput"
              placeholder="mail@mail.com"
              v-model="email"
              autocomplete="off"
              @change="updateValid"
              v-on:click="changeInput"
              name="editEmail"
            />
            <p class="alert" v-if="validarEmail(email.toString())">
              Campo obligatorio
            </p>
            <p class="alert" v-if="email.length === 0 && editEmail">
              Campo obligatorio
            </p>
          </div>
          <div class="form-group mt-1">
            <label class="label-card-pago " for="formGroupExampleInput23">Repetir Email (*)</label>
            <input
              type="email"
              class="form-control form-input"
              id="formGroupExampleInput2"
              placeholder="mail@mail.com"
              v-model="repeatEmail"
              autocomplete="off"
              @change="updateValid"
              v-on:click="changeInput"
              name="editRepeatEmail"
            />
            <p class="alert" v-if="validarEmail(repeatEmail.toString())">
              Campo obligatorio
            </p>
            <!-- <p class="alert" v-if="repeatEmail.length === 0 && editRepeatEmail">
              Campo obligatorio
            </p> -->
            <p class="alert" v-if="validarRepeatEmail(repeatEmail.toString())">
              Los correos deben coincidir.
            </p>
          </div>
          <!-- s -->
          <h5 class="title-card-pago">Medio de Pago</h5>
          <div class="margin-component">
          <div
            class="col-12 mb-3"
          >
            <!-- <div class="card card-form-input" v-if=" duration == 0 ? (option.libre) : true ">
              <div class="body-card">
                <div class="form-check">
                  <input
                    class="form-check-input pagos-check-input"
                    type="radio"
                    name="radio-pago"
                    :id="option.value "
                    :value="option.value"
                    v-model="selectedOption"
                    :disabled="!(email.length > 0 && repeatEmail == email)"
                  />
                  <label class="form-check-label label-card-pago" :for="option.value ">
                    {{ option.name }}
                  </label>
                </div>
              </div>
            </div> -->
            <div class="card card-form-input mt-3" v-if="duration !== 0 && mediosPago['debito'] == 1">
              <div class="body-card">
                <div class="form-check">
                  <input
                    class="form-check-input pagos-check-input"
                    type="radio"
                    name="radio-pago"
                    id="Debit"
                    value="Debit"
                    v-model="selectedOption"
 
                  />
                  <label class="form-check-label label-card-pago" for="Debit">
                   WebPay1
                  </label>
                </div>
              </div>
            </div>

            <div class="card card-form-input mt-3" v-if="duration !== 0 && mediosPago['transbank'] == 1">
              <div class="body-card">
                <div class="form-check">
                  <input
                    class="form-check-input pagos-check-input"
                    type="radio"
                    name="radio-pago"
                    id="Transbank"
                    value="transbank"
                    v-model="selectedOption"
                  />
                  <label class="form-check-label label-card-pago" for="Transbank">
                   WebPay Plus
                  </label>
                </div>
              </div>
            </div>
            <div class="card card-form-input mt-3" v-if="duration == 0 && mediosPago['credito'] == 1">
              <div class="body-card">
                <div class="form-check">
                  <input
                    class="form-check-input pagos-check-input"
                    type="radio"
                    name="radio-pago"
                    id="Credit"
                    value="Credit"
                    v-model="selectedOption"
 
                  />
                  <label class="form-check-label label-card-pago" for="Credit">
                   Tarjeta de Crédito
                  </label>
                </div>
              </div>
            </div>
            <div class="card card-form-input mt-3" v-if="duration !== 0 && mediosPago['transferencia'] == 1">
              <div class="body-card">
                <div class="form-check">
                  <input
                    class="form-check-input pagos-check-input"
                    type="radio"
                    name="radio-pago"
                    id="Transfer"
                    value="Transfer"
                    v-model="selectedOption"
 
                  />
                  <label class="form-check-label label-card-pago" for="Transfer">
                   Transferencia
                  </label>
                </div>
              </div>
            </div>
          </div>
          </div>
          <div v-if="type === 'agendada'">
            <h5 class="title-card-pago">Calendario</h5>
            <div class="col-12 mb-3 text-end text-center" @click="showCalendly">
              <button
                  class="btn btn-pagar"
                  type="button"
                  style='padding: 8px 40px!important;'
              >
                Agendar
              </button>
            </div>
          </div>
          <!-- <div class="form-group mt-1">
            <label class="label-card-pago " for="formGroupExampleInput23">Repetir Email (*)</label>
            <input
              type="email"
              class="form-control form-input"
              id="formGroupExampleInput2"
              placeholder="mail@mail.com"
              v-model="repeatEmail"
              autocomplete="off"
              @change="updateValid"
              v-on:click="changeInput"
              name="editRepeatEmail"
            />
            <p class="alert" v-if="validarEmail(repeatEmail.toString())">
              Campo obligatorio
            </p>
            <p class="alert" v-if="repeatEmail.length === 0 && editRepeatEmail">
              Campo obligatorio
            </p>
            <p class="alert" v-else-if="repeatEmail !== email">
              Los correos deben coincidir.
            </p>
          </div>
          <div class="form-group mt-1">
            <label class="label-card-pago" for="formGroupExampleInput2">Telefono (opcional)</label>
            <input
              type="number"
              @keypress="onlyNumber"
              class="form-control form-input"
              id="formGroupExampleInput2"
              placeholder="+56911222333"
              autocomplete="off"
              pattern="[0-9]*"
            />
          </div>
          <div class="form-group mt-1">
            <label class="label-card-pago" for="formGroupExampleInput3">Medio de Pago</label>
            <select
              v-model="selectedOption"
              class="form-select form-input"
              name=""
              id=""
              required
            >
              <option value="Credit">Tarjeta de Crédito</option>
              <option value="Debit" v-if="duration > 0">Tarjeta de Débito</option>
              <option value="Transfer" v-if="duration > 0">Transferencia</option>
            </select>
          </div> -->
        </form>
        <div v-if="this.calendlyShow">
          <div class="col-12 mb-3 text-end">
            <ModalShowCalendly :success="success" :linkCalendly='linkCalendly' @modal-closed="onModalClosed"/>
          </div>
        </div>
        <!-- <ModalDatos v-if="" :emailNotUser="email" :disableBtn="disableBtn"/> -->
        <ModalDatosNotLogin v-if="selectedOption == 'Credit' && infoo == null" :email="email"/>
        <Spinner v-if="isLoading" />
      </div>
    </div>
  </div>
</template>
<script>
import axiosClient from '../../config/axiosClient';
import { enableLogs } from '../../config';
import ModalDatosNotLogin from '../util/ModalDatosNotLogin.vue';
import ModalShowCalendly from '../util/ModalShowCalendly.vue'
import Spinner from '../loadingspinner/Spinner.vue';
export default {
  components: {
    ModalDatosNotLogin,
    ModalShowCalendly,
    Spinner,
  },
  watch: {
    selectedOption: function(newOption) {
      if(newOption == 'Credit'){
        
        this.verifyEmail(newOption)
        
      }else{
        this.$emit('metodoPagoChange', newOption);
      }
    },
    duration: function()
    {
      if(this.duration == 0){
        this.selectedOption = 'Comodin';
      }if(this.duration != 0){
        this.selectedOption = 'transbank';
      }
    }
  },
  props: {
    tarotistaId: Number,
    tipo: String,
    duration: Number,
    isLoggedIn: Boolean,
    existe: Boolean,
  },
  mounted(){
    this.type = this.$route.query.tipo;
    this.idTarotista = this.$route.query.tarotista;
    this.getMediosPago()
    if(this.$route.query.c){
      this.selectedOption = "Credit";
    }else{
      this.selectedOption = "transbank";
    }
    this.type = this.$route.query.tipo;
  },
  data() {
    return {
      codeUserEmail: null,
      activo: null,
      linkCalendly: "",
      calendlyShow: false,
      type: '',
      infoo: false,
      selectedOption: 'null',
      email: '',
      repeatEmail: '',
      editEmail: false,
      editRepeatEmail: false,
      isLoading: false,
      opcionesInstantanea: [
        {
          name: "WebPay",
          value: "Debit",
          libre: false,
        },
        {
          name: "Transbank",
          value: "transbank",
          libre: false,
        },
/*         {
          name: "Tarjeta de Crédito",
          value: "Credit",
          libre: true,
        }, */
        {
          name: "Transferencia",
          value: "Transfer",
          libre: false,
        },
        /*{
          name: "Efectivo",
          value: "Cash",
          libre: false,
        },*/
      ],
      disableBtn: true,
      mediosPago: {},
    };
  },
  methods: {
    async UserEmail() {
      try {
        if (this.email !== '') {
          const datos = await axiosClient.post(
              '/users/get-users/email',
              {
                userEmail: this.email,
              },
          );
          this.codeUserEmail = datos.data.code;
        }
      } catch (error) {
        console.error('Error during registroUse:', error);
      }
    },
    onModalClosed() {
      this.calendlyShow = false;
    },
    async showCalendly() {
      if (this.email === '') {
        this.$store.commit("setMessage", "Ingrese un Correo Válido");
      }else {
        this.codeUserEmail = null;
        await this.UserEmail();
        if (this.codeUserEmail === 2) {
          this.$emit("valorLoginFunc");
        } else {
          this.$emit("registroUseInvitado", {
            correo_inv: this.email,
          });
          await this.getLinkCalendly();

          if (this.type === "agendada" && this.activo !== 3) {
            this.$store.commit("setMessage", "Disponible Próximamente");
          } else if (this.type === "agendada" && this.activo === 3) {
            this.calendlyShow = true;
          }
        }
      }
    },
    async getLinkCalendly(){
      let url = await axiosClient.post(
        '/tarot-calendly/get-event-type/linkCalendly',
        {
          idTarotista: this.tarotistaId,
        },
      );
      this.activo = url.data.status.activeInvitation;
      switch (this.duration){
        case 15:
          this.linkCalendly = url.data.status.tarifa_uno+"?email="+this.email+"&name=Invitado";
          break;
        case 30:
          this.linkCalendly = url.data.status.tarifa_dos+"?email="+this.email+"&name=Invitado";
          break;
        case 60:
          this.linkCalendly = url.data.status.tarifa_tres+"?email="+this.email+"&name=Invitado";
          break;
      }
    },
    async getMediosPago(){
      try{
        const response = await axiosClient.get('/status-buttons/')
        console.log('getMediosPago response')
        console.log(response.data)
        return this.mediosPago = response.data
      }catch(e){
        console.log(e)
      }
  
    },
    async verifyEmail(newOption){
      try {
      let email = this.email
      this.infoo = false;
       const info = await axiosClient.post(
          "/users/additional-info/",
          {email},
        );
        
        if(info.data.info == null){
          this.infoo = null;
          this.$store.commit('setInfoGuestCredit', null)
          this.disableBtn = false 
          this.$emit('metodoPagoChange', newOption);
        }else{
          window.localStorage.setItem("info", JSON.stringify(info.data.info[0]))
          this.infoo = info.data.info;
          this.$store.commit('setInfoGuestCredit', info.data.info)
          this.disableBtn = false 
          this.$emit('metodoPagoChange', newOption);
        }
      } catch (error) {
       if(enableLogs) console.log(error.response.data);
      }
    },
    /** Esta funcion retorna true si el email es invalido */
    validarEmail(valor) {
      if (valor.length > 0) {
        if (
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
            valor
          )
        ) {
          return false;
        } else {
          return true;
        }
      } else {
        return false;
      }
    },
    validarRepeatEmail(valor) {
      if (valor.length > 0) {
        if (this.repeatEmail === this.email) {
          return false;
        } else {
          return true;
        }
      }else {
        return false;
      }
    },
    updateValid() {
      window.valid.email = this.email;
      window.valid.repeatEmail = this.repeatEmail;
      // this.$store.commit(
      //   "setEmailGuest",
      //   this.email
      // );

      window.localStorage.setItem('emailGuest', this.email);
    },
    changeInput(event) {
      this[event.target.name] = true;
    },
    onlyNumber($event) {
      //console.log($event.keyCode); //keyCodes value
      let keyCode = $event.keyCode ? $event.keyCode : $event.which;
      if ((keyCode < 48 || keyCode > 57) && keyCode !== 46) {
        // 46 is dot
        $event.preventDefault();
      }
    },
  },
  computed: {
    validForm() {
      let validForm = true;
      this.validarEmail(this.email) ? (validForm = false) : null;
      this.validarRepeatEmail(this.repeatEmail) ? (validForm = false) : null;
      return validForm;
    },
  },
};
</script>

<style scoped>
@media (max-width:768px) {
  .card-pagos {
    min-height: auto !important;
  }
  .card-pagos .btn-pagar {
    width: 100%;
  }
}
.alert {
  color: red;
  padding: 0px;
  font-size: 9px;
  font-family: maven-semibold;
  margin-bottom: 0px;
}
.margin-component{
  margin-top: 22px;
}
.btn-pagar {

    padding: 6px 15px !important;
    font-size: 10px !important;
    text-transform: uppercase;
}
.title-card-pago{
  margin-bottom: 10px;
}
</style>

