<template>
  <div class="col-12 video">
    <iframe
      width="100%"
      height="315"
      :src="parseVideoLink(tarotista.link)"
      frameborder="0"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      allowfullscreen
    ></iframe>
  </div>
</template>

<script>
export default {
  methods: {
    parseVideoLink(link) {
        if (!link) {
          console.error("No link provided");
          return '';
        }

        let parsedUrl = '';
        if (link.includes('youtube.com') || link.includes('youtu.be')) {
          // Manejar URLs de YouTube
          let videoId;
          if (link.includes('watch?v=')) {
            videoId = link.split('watch?v=')[1].split('&')[0];
          } else if (link.includes('youtu.be/')) {
            videoId = link.split('youtu.be/')[1].split('?')[0];
          } else if (link.includes('shorts/')) {
            videoId = link.split('shorts/')[1].split('?')[0];
          }

          if (!videoId) {
            console.error("Invalid YouTube link provided");
            return '';
          }

          parsedUrl = `https://www.youtube.com/embed/${videoId}`;
        } else if (link.includes('vimeo.com')) {
          // Manejar URLs de Vimeo
          let videoId;
          if (link.includes('vimeo.com/')) {
            videoId = link.split('vimeo.com/')[1].split('?')[0];
          } else {
            console.error("Invalid Vimeo link provided");
            return '';
          }

          parsedUrl = `https://player.vimeo.com/video/${videoId}`;
        } else {
          console.error("Unsupported link type");
          return '';
        }

        console.log("Parsed URL:", parsedUrl);
        return parsedUrl;
      }
},
  props: {
    tarotista: Object,
  },
};
</script>

<style scoped>
@media (max-width:768px) {
  .bg-lila .video {
    margin: 0;
  }
}
.video {
  border-bottom: 1px solid #98989838;
  margin: 0px 12px;
  padding: 0 0 35px 0;
}
</style>
