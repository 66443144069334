<template>
  <div>
    <b-modal
        :no-close-on-esc="true"
        :no-close-on-backdrop="true"
        id="modalVideosChat"
        centered
        hide-footer
        hide-header-close
    >
      <p class="mt-2 mb-5 text-modal">
        Para asegurar una buena experiencia durante tu lectura, ya sea agendada o inmediata, sigue estas recomendaciones:
      </p>
      <p class="mt-2 mb-5 text-modal-2">
        - Asegúrate de tener una buena conexión a internet.
      </p>
      <p class="mt-2 mb-5 text-modal-2">
        - <b>Nunca</b> refresques la página en plena lectura, ya que esto puede terminar la sesión.
      </p>
      <div class="center-flex">
        <button class="btn btn-modal" @click="closeModal">Ok</button>
      </div>
    </b-modal>
  </div>
</template>
<script>
export default {
  watch: {

  },
  props: [],

  data: () => {
    return {
      showModal: true,
    };
  },
  methods: {
    closeModal() {
      this.$bvModal.hide('modalVideosChat');
      //this.$emit('closeModalVideosChat');
    },
  },
};
</script>

<style scoped>
/deep/.close {
  background: transparent;
  border: none;
}
/deep/.modal-header {
  border-bottom: none;
}
/deep/.modal-body {
  padding: 0 50px;
}
/deep/ .center-flex {
  display: flex;
  justify-content: center; /* Centra horizontalmente */
  align-items: center; /* Centra verticalmente si el contenedor tiene altura específica */
  margin: 5px;
}
.text-modal {
  color: #501682;
  font-family: maven-bold;
  text-align: center;
  text-transform: uppercase;
  font-size: 20px;
}
.text-saldo {
  text-align: center;
  font-family: maven-regular;
  font-size: 20px;
}
.text-saldo span {
  font-family: maven-bold;
  color: #3b5998;
}
/deep/.modal-footer {
  border-top: none;
  padding: 10px 50px 30px 50px;
}
/deep/.btn-modal {
  background-color: #04d9b2;
  color: #fff;
  font-family: maven-bold;
  text-transform: uppercase;
  border: none;
}
</style>
