<template>
  <div>
    <b-modal
      :no-close-on-esc="true"
      :no-close-on-backdrop="true"
      id="modal-usuario"
      ref="modal-admin"
      centered
      hide-footer
    >
      <template #modal-title> </template>
      <h3 class="title">Nuevo usuario</h3>
      <div class="mb-3">
        <label class="label-modal" for="">Email del usuario</label>
        <div>
          <input
            v-model="user.email"
            type="text"
            class="form-control inputPadding"
            aria-label="Username"
            aria-describedby="basic-addon1"
            placeholder="email@email.com.ar"
          />
        </div>
      </div>
      <div class="mb-3">
        <label class="label-modal" for="">Nombre</label>
        <div>
          <input
            v-model="user.name"
            type="text"
            class="form-control inputPadding"
            aria-label="Username"
            aria-describedby="basic-addon1"
            placeholder="Nombre apellido"
          />
        </div>
      </div>
      <div class="mb-3">
        <label class="label-modal" for="">Contraseña</label>
        <div>
          <input
            v-model="user.password"
            type="password"
            class="form-control inputPadding"
            aria-label="Username"
            aria-describedby="basic-addon1"
            placeholder="********"
          />
        </div>
      </div>
      <div class="mb-3">
        <label class="label-modal" for="">Teléfono</label>
        <div>
          <input
            v-model="user.phoneNumber"
            class="form-control inputPadding"
            aria-label="Username"
            aria-describedby="basic-addon1"
            placeholder="0000-000000000"
          />
        </div>
      </div>
      <div class="mb-3">
        <label class="label-modal" for="">Permiso para el usuario</label>
        <div
          class="form-check"
          v-for="permiso in permisos"
          :key="permiso.permissionId"
        >
          <input
            class="form-check-input"
            type="checkbox"
            v-model="permissions"
            :value="permiso.permissionId"
            id="flexCheckDefault"
          />
          <label class="form-check-label" for="flexCheckDefault">
            {{ permiso.permissionName }}
          </label>
        </div>
      </div>

      <button class="btn btn-yellow" @click="createAdmin">Crear usuario</button>
    </b-modal>
  </div>
</template>

<script>
import axiosClient from '../../../../config/axiosClient';
import { enableLogs } from '../../../../config/index';
import Swal from 'sweetalert2';
export default {
  name: 'Catalogo',
  components: {},
  data: () => {
    return {
      user: {
        email: null,
        name: null,
        password: null,
        phoneNumber: null,
      },
      permissions: [],
      permisos: [],
      isLoading: false,
    };
  },
  created() {
    this.getPermisos();
  },
  methods: {
    async getPermisos() {
      try {
        const response = await axiosClient.get('users/get-permissions', {
          headers: { Authorization: `Bearer ${this.$store.state.jwt}` },
        });
        if (enableLogs) console.log('Successfully fetched permissions');
        this.permisos = response.data.permissions;
      } catch (error) {
        if (enableLogs) console.log(error);
        if (enableLogs) console.log('Could not fetch admin permissions');
      }
    },
    async createAdmin() {
      this.isLoading = true;
      try {
        await axiosClient.post(
          'users/create-admin',
          {
            user: this.user,
            permissions: this.permissions,
          },
          {
            headers: { Authorization: `Bearer ${this.$store.state.jwt}` },
          }
        );
        this.isLoading = false;
        Swal.fire({
          title: 'Exito!',
          text: 'El usuario administrador fue creado con éxito',
          icon: 'success',
          iconColor: '#501682',
          confirmButtonColor: '#501682',
          confirmButtonText: 'OK',
        });
        this.$refs['modal-admin'].hide();
        this.$router.push('/dashboard-admin');
      } catch (error) {
        this.isLoading = false;
        if (enableLogs) console.log('could not create admin user');
        if (enableLogs) console.log(error);
        Swal.fire({
          title: 'Error',
          text:
            'El usuario administrador no se pudo crear. Verifica que el usuario no exista o que no sea administrador.',
          icon: 'error',
          confirmButtonColor: '#501682',
          confirmButtonText: 'OK',
        });
      }
    },
  },
};
</script>
<style scoped>
/deep/.close {
  background: transparent;
  border: none;
}
/deep/ .modal-header {
  border-bottom: none;
  padding-bottom: 0px;
}
/deep/ .modal-body {
  padding: 48px;
  padding-top: 0px;
  font-family: maven-medium;
}
.title {
  font-family: maven-semibold;
  font-size: 18px;
  margin-bottom: 20px;
  text-transform: uppercase;
}

.btn-yellow {
  background: #ffc109bb;
  font-family: maven-semibold;
  text-transform: uppercase;
  color: #501682;
  border-radius: 10px;
  padding: 8px 16px;
  font-size: 12px;
  float: right;
}
.btn-email {
  width: 100%;
  font-family: maven-semibold;
  border-radius: 10px;
  background: #333;
  color: #fff;
  font-size: 12px;
}
label {
  font-size: 14px;
}
.label-modal {
  font-family: maven-semibold;
  font-size: 16px;
  margin-bottom: 3px;
}
</style>
