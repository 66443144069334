<template>
  <div>
    <div class="card card-pagos">
      <div class="card-body">
        <h5 class="title-card-pago">Formulario</h5>
        <img class="logo-kushky" src="../../../assets/img/kushki.png" alt="">
        <form autocomplete="off">
          <div class="row">
            <div class="form-group">
              <label class="label-card-pago" for="titular">Titular</label>
              <input
                v-model="personName"
                type="text"
                class="form-control form-input"
                for="titular"
                v-on:click="changeInput"
                placeholder="Nombre Completo"
                autocomplete="off"
                name="editName"
                value="sssssss"
              />
              <p class="alert" v-if="validarNombre(personName.toString())">
                Este campo no acepta números
              </p>
              <p class="alert" v-if="personName.length === 0 && editName">
                Campo obligatorio
              </p>
            </div>
            <div class="form-group mt-1">
              <label class="label-card-pago"  for="tarjeta">Número tarjeta</label>
              <input
                v-model="cardNum"
                type="number"
                @keypress="onlyNumber"
                class="form-control form-input"
                id="tarjeta"
                placeholder="Número de tarjeta"
                v-on:click="changeInput"
                autocomplete="off"
                maxlength="20"
                name="editCardNum"
                pattern="[0-9]*"
              />
              <p class="alert" v-if="cardNum.length === 0 && editCardNum">
                Campo obligatorio
              </p>
            </div>
            <div class="form-group mt-1 col-6">
              <label class="label-card-pago" for="vto">Vto.</label>
              <input
                v-model="expiration"
                type="text"
                class="form-control form-input"
                id="vto"
                placeholder="MM/AA"
                maxlength="5"
                v-on:click="changeInput"
                autocomplete="off"
                name="editVto"
              />
              <p class="alert" v-if="!vtoValid() && editVto">Fecha inválida</p>
            </div>
            <div class="form-group mt-1 col-6">
              <label class="label-card-pago" for="cvv">CVV</label>
              <input
                v-model="cvv"
                type="password"
                class="form-control form-input"
                id="cvv"
                placeholder="123"
                v-on:click="changeInput"
                @keyup="inputCvv"
                min="0"
                max="9999"
                name="editCvv"
              />
              <p class="alert" v-if="cvv.length === 0 && editCvv">
                Campo obligatorio
              </p>
            </div>
            <!-- 
            <div class="form-group mt-3">
              <label for="formGroupExampleInput2">RUT</label>
              <input
                v-model="rut"
                type="text"
                class="form-control"
                id="formGroupExampleInput2"
                placeholder="-"
              />
            </div>
            -->
            <div class="text-center mt-3">
              <b-button
                :disabled="!allowForm"
                @click="askForConfirmPayment()"
                class="btn btn-pagar"
                >Ir a pagar</b-button
              >
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import { enableLogs } from '../../../config';
import axiosClient from "../../../config/axiosClient";

export default {
    data: () => {
    return {
      personName: '',
      cardNum: '',
      expiration: '',
      cvv: '',
      rut: '',

      editApellido: false,
      editCardNum: false,
      editName: false,
      editVto: false,
      editCvv: false,
      dataUser: {},
      nombre:'',
      apellido:'',
      habilitado: false,

    };
  },
  computed: {
    allowForm: function() {
      return this.validForm();
    },
    tokenData() {
      //console.log(this.$store.state.tokenData);
      return this.$store.state.tokenData;
    },
    infoCredit: function(){
      return this.$store.state.infoCredit
    }

  },

  methods: {
    async getDatosAdicional(){
      if(this.$store.state.tokenData != null){
        try {
        if(enableLogs) console.log("tokendata",this.$store.state.tokenData)
        let email = this.$store.state.tokenData.email
        const info = await axiosClient.post(
            "/users/additional-info/",
            {email},
          );
          if(enableLogs) console.log(info.data)
          this.dataUser = info.data.info[0]
          this.personName = info.data.info[0].nombre +  " " + info.data.info[0].apellido
          
        } catch (error) {
          if(enableLogs) console.log(error.response.data);
        }
      }
    },
    
    vtoValid() {
      let vv = true;
      let fecV = this.expiration.split('/');
      vv = vv && fecV.length == 2;
      vv = vv && parseInt(fecV[0]) > 0 && parseInt(fecV[0]) <= 12;
      vv = vv && parseInt(fecV[1]) > 21;
      vv = vv && this.expiration.length == 5 && this.expiration[2] === '/';
      return vv;
    },
    validForm() {
      // Name validation
      let validForm = this.personName !== '';
      // Card num validation
      validForm = validForm && this.cardNum.length <= 20;
      // Expiration validation
      validForm = validForm && this.vtoValid();
      // Cvv validation
      validForm = validForm && (this.cvv.length === 3 || this.cvv.length === 4);
      return validForm;
    },
    askForConfirmPayment() {
      this.habilitado = this.infoCredit != null;
      console.log('estoy en datos tarjeta de planes', this.habilitado)
      if(this.habilitado){
        this.$emit('askForConfirmPayment', {
          type: 'kushkiCreditCard',
          personName: this.personName,
          cardNum: this.cardNum,
          expiration: this.expiration,
          cvv: this.cvv,
        });
      }else{
        console.log('es igual a null')
        this.$store.commit("showModalDatos", true);
      }
    },
    maskImput() {
      var newInput = document.getElementById('vto');
      newInput.addEventListener('keydown', function(e) {
        if (e.which !== 8) {
          var numChars = e.target.value.length;
          if (numChars === 2) {
            var thisVal = e.target.value;
            thisVal += '/';
            e.target.value = thisVal;
          }
        }
      });
    },
    validarNombre(valor) {
      if (valor.length > 0) {
        if (!/^[a-zA-Z_ ]+$/.test(valor)) {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    },
    changeInput(event) {
      this[event.target.name] = true;
    },
    inputCvv() {
      this.cvv = this.cvv.substr(0, 4);
      /* console.log(event.target.value.substr(0,4)) */
      if(enableLogs) (this.cvv.substr(0, 4));
    },
    onlyNumber($event) {
      //console.log($event.keyCode); //keyCodes value
      let keyCode = $event.keyCode ? $event.keyCode : $event.which;
      if ((keyCode < 48 || keyCode > 57) && keyCode !== 46) {
        // 46 is dot
        $event.preventDefault();
      }
    },
  },
  
  mounted() {
    this.maskImput();
    this.getDatosAdicional();

    /* console.log("data user",this.dataUser)
            this.nombre = this.dataUser.nombre
        this.apellido = this.dataUser.apellido
        console.log("nombreeeeeeee", this.dataUser.nombre)
    this.personName = this.nombre +  " " + this.nombre */
  },
  created() {
  },
   watch: {
    tokenData: function() {
      this.getDatosAdicional()
    },
    infoCredit: function(){
      /* console.log('AAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAa') */
      this.habilitado = this.infoCredit != null;
      if(this.infoCredit != null){
        this.personName = this.infoCredit.firstName + " " + this.infoCredit.lastName
      }
    }
  },

};
</script>

<style scoped>
.title-card-pago{
  margin-bottom: 10px !important;
}
.logo-kushky{
  max-width: 130px;
  margin-left: calc(50% - 65px);
}
.card-body {
  position: relative;
}
.btn-pagar {
  position: absolute;
  bottom: 20px;
  right: calc(50% - 63px);
}
/deep/.close {
  background: transparent;
  border: none;
}
/deep/.modal-header {
  border-bottom: none;
}

/deep/.modal-body {
  padding: 0 50px;
}
.text-modal {
  color: #501682;
  font-family: maven-bold;
  text-align: center;
  text-transform: uppercase;
  font-size: 20px;
}
.text-saldo {
  text-align: center;
  font-family: maven-regular;
  font-size: 20px;
}
.text-saldo span {
  font-family: maven-bold;
  color: #3b5998;
}
/deep/.modal-footer {
  border-top: none;
  padding: 10px 50px 30px 50px;
}
/deep/.btn-modal {
  background-color: #04d9b2;
  color: #fff;
  font-family: maven-bold;
  text-transform: uppercase;
  border: none;
}
input {
  font-size: 13px;
}
.alert {
  color: red;
  padding: 0px;
  font-size: 9px;
  font-family: maven-semibold;
  margin-bottom: 0px;
}
</style>
