<template>
  <div class="container">
    <div class="row">
      <form method="post" id="formwebpay" ref="form" :action="url">
        <input type="hidden" name="token_ws" v-model="token" />
        <input class="buttonpay" type="submit" value="Ir a pagar" />
      </form>
    </div>
  </div>
</template>

<script>
export default {
  name: 'FormWebpay',
  data() {
    return {
      token: null,
      url: null,
    };
  },
  created() {
    this.token = this.$route.query.token;
    this.url = this.$route.query.url;
    console.log('url:', this.url);
  },
  mounted() {
    console.log('mounted');
      this.$nextTick(() => {
        console.log('Submitting form');
        if (this.$refs.form) {
          this.$refs.form.submit();
        } else {
          alert('Form not found');
        }
      });
  },
  methods: {

  },
};
</script>

<style scoped>
.buttonpay {
  display: none;
}
</style>
