<template>
  <!--Modal registro exitoso-->
  <b-modal
    :no-close-on-esc="true"
    :no-close-on-backdrop="true"
    id="modal-registro-tarotista-exitoso"
    centered
  >
    <div class="col-12 text-center my-4" v-if="isLoading">
      <b-spinner variant="" label="Spinning"></b-spinner>
    </div>
    <div v-if="!isLoading">
      <p class="my-2 text-modal" v-if="success">
        ¡Tu inscripción se ha enviado exitosamente!
      </p>
      <p class="my-2 text-mensaje" v-if="success">
        Muy pronto nos estaremos comunicando contigo para finalizar tu alta como
        Divino Tarotista
      </p>

      <p v-if="!success && success != null" class="my-2 text-mensaje">
        No hemos podido registrar tu inscripción. Vuelve a intentarlo más tarde.
        Asegúrate de no haber utilizado el mismo mail anteriormente.
      </p>
    </div>

    <template #modal-footer="{ hide }">
      <b-button
        size="sm"
        class="col-12 btn-sm btn btn-modal"
        @click="hide('forget')"
        @click.self="sendHome"
      >
        Ok!
      </b-button>
    </template>
  </b-modal>
</template>

<script>
export default {
  props: ["success", "isLoading"],
  methods: {
    sendHome() {
      this.$router.push("/");
    },
  },
  components: {},
};
</script>

<style scoped>
/deep/.close {
  background: transparent;
  border: none;
}
/deep/.modal-header {
  border-bottom: none;
}

/deep/.modal-body {
  padding: 0 50px;
}
.text-modal {
  color: #501682;
  font-family: maven-bold;
  text-align: center;
  text-transform: uppercase;
  font-size: 20px;
}
.text-saldo {
  text-align: center;
  font-family: maven-regular;
  font-size: 20px;
}
.text-saldo span {
  font-family: maven-bold;
  color: #3b5998;
}
/deep/.modal-footer {
  border-top: none;
  padding: 10px 50px 30px 50px;
}
/deep/.btn-modal {
  background-color: #04d9b2;
  color: #fff;
  font-family: maven-bold;
  text-transform: uppercase;
  border: none;
}
.text-mensaje {
  text-align: center;
  font-family: maven-regular;
}
</style>
