<template>
  <div>
    <div class="card shadow">
      <div class="card-body">
        <div class="row justify-content-center">
          <div class="col-12">
            <h1>Gestion Tarotista</h1>
          </div>
          <div class="row justify-content-center mt-5" v-if="isLoading">
            <Spinner />
          </div>
          <div class="col-11" v-else>
            <div class="content-table">
              <table class="table table-striped ">
                <thead>
                  <tr>
                    <th scope="col">Nombre</th>
                    <th scope="col">Email</th>
                    <th scope="col">Teléfono</th>
                    <th scope="col">Estado</th>
                    <th scope="col">Detalle Inscripción</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="inscription in inscriptions" :key="inscription.id">
                    <td>{{ inscription.data.nombre }}</td>
                    <td>{{ inscription.data.email }}</td>
                    <td>{{ inscription.data.telefono }}</td>
                    <td v-if="inscription.estado">{{ inscription.estado }}</td>
                    <td>
                      <div class="toggle">
                        <button
                          class="btn-table"
                          @click="triggerModal(`modalDatos-${inscription.id}`)"
                        >
                          Ver Detalle
                          <i class="fas fa-eye" style="margin-left: 10px"></i>
                        </button>
                      </div>
                      <ModalDatos
                        :triggerModal="
                          modalTriggered === `modalDatos-${inscription.id}`
                        "
                        :dato="inscription.data"
                        :inscription="inscription"
                        @updateInscriptionStatus="updateInscriptionStatus"
                        v-on:editStatus="updateInscriptionStatus"
                      />
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
    <ModalError2/>
    <ModalSuccess2/>
  </div>
</template>

<script>
import axiosClient from '../../config/axiosClient';
import ModalDatos from './modal/ModalDatos.vue';
import Spinner from '../loadingspinner/Spinner.vue';
import { enableLogs } from '../../config';
import ModalError2 from "@/components/dashboardAdmin/modal/ModalError2.vue";
import ModalSuccess2 from "@/components/dashboardAdmin/modal/ModalSuccess2.vue";
export default {
  name: '',
  components: {
    ModalDatos,
    Spinner,
    ModalError2,
    ModalSuccess2,
  },
  props: {
    inscriptions: Array,
    isLoading: Boolean,
  },
  data: () => {
    return {
      tarotistas: [],
      modalTriggered: null,
      forceClose: null,
    };
  },
  created() {
    this.getTarotistas();
  },
  methods: {
    async getTarotistas() {
      try {
        const response = await axiosClient.get(
          `/tarot-reader/get-tarot-readers`
        );
        this.tarotistas = response.data.tarotReaders;
        if (enableLogs) console.log('fetched data');
        if (enableLogs) console.log(response.data);
      } catch (error) {
        if (enableLogs) console.log('could not fetch data');
        if (enableLogs) console.log(error);
      }
    },
    waitForLocalStorageVariable(variableName, timeout = 5000) {
  return new Promise((resolve, reject) => {
    const startTime = Date.now();

    function checkLocalStorage() {
      const variableValue = localStorage.getItem(variableName);

      if (variableValue !== null) {
        resolve(variableValue);
      } else if (Date.now() - startTime > timeout) {
        reject(new Error(`Timeout waiting for localStorage variable: ${variableName}`));
      } else {
        setTimeout(checkLocalStorage, 100); // Revisa cada 100 milisegundos
      }
    }

    checkLocalStorage();
  });
},
    async triggerModal(modal) {
      if (enableLogs) console.log(`Show Modal: ${modal}`);

      this.modalTriggered = modal;

      // Variables de estado para evitar el bucle infinito
      let errorModalShown = false;
      let successModalShown = false;

      // Crear una promesa que se resolverá cuando se cierre el modal
      const modalClosedPromise = new Promise((resolve) => {
        this.$root.$on('bv::modal::hidden', () => {
          // Se ejecuta cuando se cierra el modal
          const estado = localStorage.getItem('estado');
          if (!errorModalShown && estado === "error") {
            this.$bvModal.show('modalError2');
            localStorage.removeItem('estado');
            errorModalShown = true; // Marcar que se ha mostrado el modal de error
          } else if (!successModalShown && estado === "success") {
            this.$bvModal.show('modalSuccess2');
            localStorage.removeItem('estado');
            successModalShown = true; // Marcar que se ha mostrado el modal de éxito
          }
          resolve();
        });
      });

      // Esperar a que se resuelva la promesa (modal cerrado)
      await modalClosedPromise;


      console.log("success2")
      this.modalTriggered = null;
      this.forceClose = null;
    },
    updateInscriptionStatus(inscriptionData) {
      let foundInscription = this.inscriptions.find(
        ins => ins.id === inscriptionData.id
      );
      foundInscription.estado = inscriptionData.estado;
    },
  },
};
</script>

<style scoped>
.card {
  border-radius: 10px;
  min-height: 100%;
}
.card-body {
  padding: 30px 20px;
}
.card-body h1 {
  font-size: 25px;
  font-family: maven-semibold;
}
table {
  font-size: 12px;
}
table th {
  font-family: maven-semibold;
}
table td {
  font-family: maven-medium;
}
/*toggle*/
.switch {
  position: relative;
  display: inline-block;
  width: 40px;
  height: 20px;
  margin-right: 10px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #fff;
  border: 1px solid #ffc209;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: '';
  height: 13px;
  width: 13px;
  left: 4px;
  bottom: 3px;
  background-color: #fff;
  border: 1px solid #ffc209;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: #ffc209;
  border: 1px solid #fff;
}

input:focus + .slider {
  box-shadow: 0 0 3px #fff;
}

input:checked + .slider:before {
  -webkit-transform: translateX(16px);
  -ms-transform: translateX(16px);
  transform: translateX(16px);
}
/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

.btn-table {
  background-color: transparent;
  border: none;
}
/*table fixed*/

.table tbody {
  max-height: 300px;
  overflow-y: auto;
  width: 100%;
}

table {
  display: flex;
  flex-flow: column;
  width: 100%;
}

thead {
  flex: 0 0 auto;
  margin-right: 11px;
}

tbody {
  flex: 1 1 auto;
  display: block;
  overflow-y: auto;
  overflow-x: hidden;
}

tr {
  width: 100%;
  display: table;
  table-layout: fixed;
}

/*color scroll*/
tbody {
  --scrollbarBG: #d1d1d1;
  --thumbBG: #333333;
}
tbody::-webkit-scrollbar {
  width: 11px;
}
tbody {
  scrollbar-width: thin;
  scrollbar-color: var(--thumbBG) var(--scrollbarBG);
}
tbody::-webkit-scrollbar-track {
  background: var(--scrollbarBG);
}
tbody::-webkit-scrollbar-thumb {
  background-color: var(--thumbBG);
  border-radius: 6px;
  border: 3px solid var(--scrollbarBG);
}
@media screen and (max-width: 768px) {
  .content-table {
    overflow-x: scroll;
  }
  .table {
    min-width: 500px;
  }
  .content-table {
    --scrollbarBG: #d1d1d1;
    --thumbBG: #333333;
  }
  .content-table::-webkit-scrollbar {
    height: 11px;
  }
  .content-table {
    scrollbar-width: thin;
    scrollbar-color: var(--thumbBG) var(--scrollbarBG);
  }
  .content-table::-webkit-scrollbar-track {
    background: var(--scrollbarBG);
  }
  .content-table::-webkit-scrollbar-thumb {
    background-color: var(--thumbBG);
    border-radius: 6px;
    border: 3px solid var(--scrollbarBG);
  }
}
</style>
