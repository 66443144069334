<template>
  <div class="row row-content " v-if="isLoggedIn || idInv != null">
    <div class="col-md col-12 my-5 m-auto text-center">
      <div v-if="localTrackVisible" id="local-media-div"></div>
      <div v-if="!localTrackVisible" class="no-media-div"></div>
      <div class="text-center">
        <button :class="claseVoice" @click="voice">
          <i :class="IconVoice" class="fas fa-microphone"></i>
        </button>
        <button :class="claseCamera" @click="camera">
          <i :class="claseIcon" class="fas fa-video"></i>
        </button>
      </div>
    </div>

    <div class="col-md col-12 m-auto text-center">
      <div class="col-12 mb-3">
        <div class="text-back" @click="goBack">
          <i class="fas fa-chevron-left"></i>
          <span>Volver Atrás</span>
        </div>
      </div>
      <div class="text">
        <p>Esperando la conexión del otro participante.</p>
        <div class="spinner-border" role="status">
          <span class="sr-only">Loading...</span>
        </div>
      </div>
    </div>
  </div>
  <div class="row row-content" v-else>
    <LoginEspera
      :url="url"
      v-on:triggerModal="triggerModal"
      :triggerModal="modalTriggered === 'login'"/>
  </div>
</template>

<script>
import axiosClient from '@/config/axiosClient';

const { createLocalVideoTrack } = require("twilio-video");
import { enableLogs } from '../../config';
import LoginEspera from '@/components/login/LoginEspera.vue';


export default {
  name: "SalaEspera",
  components: { LoginEspera },
  data() {
    return {
      aux: null,
      url: null,
      modalTriggered: null,
      localTrackVisible: false,
      voiceEnabled: false,
      localTrackCreated: false,
      localTrack: null,
      roomId: null,
      roomType: null,
      id_invitado: null,
      idInv: null,
    };
  },
  async beforeDestroy() {
    console.info("@@@@@@@@@@@@@@@@@@@");
   if(enableLogs) console.log("Destroying twilio devices");
   if(enableLogs) console.log(this.localTrack);
    if (this.localTrack) {
      this.localTrack.stop();
    }
    try {
      let stream = await navigator.mediaDevices.getUserMedia({
        video: true,
      });
      stream.getTracks().forEach(function(track) {
        track.stop();
      });
    } catch {
     if(enableLogs) console.log("");
    }
    try {
      let stream = await navigator.mediaDevices.getUserMedia({
        voice: true,
      });
      stream.getTracks().forEach(function(track) {
        track.stop();
      });
    } catch {
     if(enableLogs) console.log("");
    }
  },

  mounted() {
  if(enableLogs) console.log("Asking for I/O permission");
    // Get query parameters and set them as states
    this.roomId = this.$route.query.roomId;
    this.roomType = this.$route.query.tipo;
    this.idInv = this.$route.query.idInv;
    if(enableLogs) console.log(this.$store.state.socket)
    // Ask for video/audio permissions and display the local video track
    this.obtenerUrl();
    if (!this.isLoggedIn && this.idInv == null){
      return this.triggerModal('login')
    }else {
      this.displayLocalTrack();
      this.enableMic();
      if (this.$store.state.socket) {
        this.startSocket();
      }
    }
  },
  watch: {
    // Listen for when the jwt is available, so that you can connect to the socket server
    socket: function(socket) {
      if (socket === null || socket === undefined) return;
     if(enableLogs) console.log("Trying to emmit room event to socket server");

      this.startSocket();
    },
  },
  computed: {
    isLoggedIn () {
      return this.$store.state.isLoggedIn
    },
    socket() {
      return this.$store.state.socket;
    },
    claseVoice() {
      return this.voiceEnabled ? "btn-voice" : "btn-voice active";
    },
    IconVoice() {
      return this.voiceEnabled
        ? "fas fa-microphone"
        : "fas fa-microphone-slash active";
    },
    claseCamera() {
      return this.localTrackVisible ? "btn-camera" : "btn-camera active";
    },
    claseIcon() {
      return this.localTrackVisible
        ? "fas fa-video"
        : "fas fa-video-slash active";
    },
  },
  methods: {
    obtenerUrl(){
      // Obtener la URL actual
      const urlActual = window.location.href;

      // Crear un elemento "a" oculto para parsear la URL
      const a = document.createElement('a');
      a.href = urlActual;

      // Obtener la parte de la URL que sigue al dominio en el formato deseado
      this.url = a.pathname + a.search;
    },
    async triggerModal(modal) {
    console.log(`Showing ${modal} modal`)
      switch (modal) {
        case 'login':
          this.modalTriggered = 'login'
          break
        case 'register':
          this.modalTriggered = 'register'
          break
        case 'password':
          this.modalTriggered = 'password'
          break
        case 'register-success':
          this.modalTriggered = 'register-success'
          break
      }
      await this.$nextTick()
      this.modalTriggered = null
    },
    async getInInvitado(){
      const usado = await axiosClient.post(
        '/tarot-calendly/post-registro-use/getIdInvitado',
        {
          room_uuid: this.roomId,
        },
      );

      if (usado.data.id_invitado != null) {
        this.id_invitado = usado.data.id_invitado;
      }
    },
    async startSocket() {
      console.log("startSocket")

      await this.getInInvitado();
      if (this.id_invitado !== null) localStorage.setItem("guestId", this.id_invitado)

      let socket = this.$store.state.socket;
      let tokenData = this.$store.state.tokenData;
      let roomId = this.roomId;
      let userId;

      console.log("socket " , socket)
      console.log("tokenData " , tokenData)
      console.log("roomId " + roomId)

      tokenData
        ? (userId = tokenData.userId)
        : ((this.$store.state.guestId === this.id_invitado) ? userId = this.$store.state.guestId : userId = this.id_invitado);

      console.log("userId " + userId)
      console.log("this.$store.state.guestId " + this.$store.state.guestId)

      socket.emit("request-tarot-reader", this.roomId, "VIDEO");

      if (tokenData === null) {
        await this.$store.commit('guestLogin', this.id_invitado)
      }
      console.log(`Emmiting event join-video-room to room ${roomId}`);
      // Enviar al servidor de socket una petición para entrar a la sala
      socket.emit("join-video-room", roomId, (response) => {
      console.log(response);

        if (response.code === 2) {
          console.log("entre")
          // Si ya esta el otro usuario, creamos la sala
          socket.emit("get-room-users", roomId, (response) => {
            console.log("get-room-users" + response.peers);
            for (let peer of response.peers) {
              if (peer !== userId) {
              console.log("There is already another peer!");
                // Send request to create room
                socket.emit("create-room", roomId);
              }
            }
          });

          // Loguear cuando se conecta un peer
          socket.on("peer-joined", (data) => {
            if (data.peerId !== userId) {
           console.log("remote peer joined");
            }
          });

          socket.on(roomId, (data) => {
            // Procesar los mensajes que se recibieron...
          if(enableLogs) console.log(data);
          });

          // Loguear el token
          socket.on("twilio-token", () => {
            this.$router.push(`/room?roomId=${roomId}`);
          });

          // Evento de sala terminada
          socket.on("room-ended", (data) => {
          console.log("room-ended    ",data);
          });
        }
      });
    },
    // Ask for mic permissions
    async enableMic() {
      try {
        this.voiceEnabled = true;
        await navigator.mediaDevices.getUserMedia({ audio: true });
       if(enableLogs) console.log("Successfully enabled voice track");
      } catch (err) {
        this.voiceEnabled = false;

        alert("Debes darle permiso de microfono al navegador!");
       if(enableLogs) console.log(err);
       if(enableLogs) console.log("Error enabling voice track");
      }
    },
    // Creates a twilio local video track
    async createLocalTrack() {
      let track = await createLocalVideoTrack();
      this.localTrack = track;
    },
    // Append the twilio local video track to the local media div as a local-video
    appendLocalTrack() {
      const localMediaContainer = document.getElementById("local-media-div");
      localMediaContainer.appendChild(this.localTrack.attach());
      localMediaContainer.childNodes[0].classList.add("local-video");
    },
    // Display the local video track, creating it if necessary
    async displayLocalTrack() {
      try {
        this.localTrackVisible = true;

        if (!this.localTrackCreated) {
          await this.createLocalTrack();
          this.localTrackCreated = true;
        }

        await this.$nextTick();
        this.appendLocalTrack();

       if(enableLogs) console.log("Successfully shown video track");
      } catch (err) {
        this.localTrackVisible = false;
        alert("Debes darle permiso de camara al navegador!");
       if(enableLogs) console.log(err);
       if(enableLogs) console.log("Error showing local video track");
      }
    },
    // Toggle voice
    voice() {
      if (this.voiceEnabled == false) {
        this.enableMic();
      } else {
        this.voiceEnabled = false;
       if(enableLogs) console.log("Voice disabled");
      }
    },
    // Toggle video
    camera() {
      if (this.localTrackVisible == false) {
        this.displayLocalTrack();
      } else {
        var elem = document.getElementsByClassName("local-video");
        elem[0].remove();

        this.localTrackVisible = false;
       if(enableLogs) console.log("Video disabled");
      }
    },
    goBack() {
      this.$router.go(-1);
    },
  },
};
</script>

<style scoped>
.text-back {
  color: #501682;
  text-transform: uppercase;
  font-family: maven-semibold;
  font-size: 13px;
  cursor: pointer;
}
.text-back:hover {
  color: #934dd8;
}
.text-back span {
  margin-left: 6px;
}
.row-content {
  min-height: calc(100vh - 161px);
  align-content: center;
}
video {
  max-height: 500px !important;
}
.text {
  text-align: center;
  margin: auto;
}
p {
  font-family: maven-semibold;
}
button {
  margin: 0 5px;
}
button.btn-voice,
.btn-camera {
  height: 40px;
  width: 40px;
  display: inline-flex;
  margin-top: 16px;
  border: 1px solid #dadce0;
  border-radius: 50%;
  transition-property: all;
  transition-duration: 0.8s;
  background-color: #ffffff;
}
button.btn-voice:hover,
.btn-camera:hover {
  background-color: #ffffff;
  -webkit-box-shadow: 0px 0px 5px 4px rgba(209, 209, 209, 1);
  -moz-box-shadow: 0px 0px 5px 4px rgba(209, 209, 209, 1);
  box-shadow: 0px 0px 5px 4px rgba(209, 209, 209, 1);
}
button.btn-voice i,
.btn-camera i {
  margin: auto;
  color: #707070;
}

button.btn-voice.active,
.btn-camera.active {
  background-color: #d93025;
}
button.btn-voice.active i,
.btn-camera.active i {
  color: #f5f5f5;
}

.btn-container {
  left: 50%;
  align-items: center;
  box-sizing: border-box;
  display: flex;
  padding: 8px 0;
  position: absolute;
  top: 5%;
  z-index: 99 !important;
}

.icon-mute {
  height: 28px;
  width: 28px;
  background-color: red;
  border-radius: 50%;
  padding: 3px;
}

.icon-mute i {
  width: 5px;
  height: 5px;
  color: #fff;
}
.no-media-div {
  height: 337.5px;
  background-color: #333;
  display: flex;
  max-width: 450px !important;
  margin: auto;
  max-width: 337px;
}
.local-media-div {
  max-width: 450px !important;
}
/deep/video {
  max-width: 450px !important;
}
</style>
