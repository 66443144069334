<template>
  <!--Modal pago exitoso-->
  <b-modal
    :no-close-on-esc="true"
    :no-close-on-backdrop="true"
    v-model="showModal"
    id="modal-compra-pago-exitoso"
    centered
  >
    <p class="my-2 text-modal">¡Transaccion exitosa!</p>
    <p class="my-2 text-body" v-if="this.$route.query.mode !== 'transbank'">
      Tu DivinoSaldo restante: $ {{ numberWithCommas(balance) }}
    </p>
    <p class="my-2 text-body" v-else-if="this.$route.query.mode === 'transbank'">
      Se ha procesado con éxito el pago.
    </p>

    <p class="my-2 text-body" v-if="tipo === 'instantanea'">
      Disfruta tu lectura, serás redireccionado a la sala de espera...
    </p>
    <p class="my-2 text-body" v-else>
      Disfruta tu lectura, serás redireccionado al inicio
    </p>
    <!--
    <p class="mt-4 text-saldo">
      Tu nuevo divino saldo es: <span>$17.234</span>
    </p>
    -->
    <template #modal-footer="{ hide }">
      <b-button
        size="sm"
        class="col-12 btn-sm btn btn-modal"
        @click="hide('forget')"
      >
        Ok!
      </b-button>
    </template>
  </b-modal>
</template>

<script>
export default {
  watch: {
    triggerModal: function(value) {
      if (value) this.showModal = true;
    },
  },
  props: ['triggerModal', 'paymentMethod', 'balance', 'tipo'],

  data: () => {
    return {
      showModal: false,
    };
  },
  methods: {
    hideModal() {
      this.showModal = false;
    },
    numberWithCommas(x) {
      var a = x.toLocaleString('es-AR');
        if(a.indexOf(',') == -1){
            return x.toLocaleString('es-AR');
        }else{
          return a.substr(0, a.indexOf(','));
        }
    },
  },
};
</script>

<style scoped>
/deep/.close {
  background: transparent;
  border: none;
}
/deep/.modal-header {
  border-bottom: none;
}

/deep/.modal-body {
  padding: 0 50px;
}
.text-modal {
  color: #501682;
  font-family: maven-bold;
  text-align: center;
  text-transform: uppercase;
  font-size: 20px;
}
.text-body {
  text-align: center;
  font-family: maven-semibold;
  font-size: 16px;
}
.text-saldo span {
  font-family: maven-bold;
  color: #3b5998;
}
/deep/.modal-footer {
  border-top: none;
  padding: 10px 50px 30px 50px;
}
/deep/.btn-modal {
  background-color: #04d9b2;
  color: #fff;
  font-family: maven-bold;
  text-transform: uppercase;
  border: none;
}
</style>
