import { render, staticRenderFns } from "./ModalConfirmacion.vue?vue&type=template&id=5dd3bbe1&scoped=true"
import script from "./ModalConfirmacion.vue?vue&type=script&lang=js"
export * from "./ModalConfirmacion.vue?vue&type=script&lang=js"
import style0 from "./ModalConfirmacion.vue?vue&type=style&index=0&id=5dd3bbe1&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5dd3bbe1",
  null
  
)

export default component.exports