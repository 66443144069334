<template>
  <!--Modal contacto exitoso-->
  <b-modal
    :no-close-on-esc="true"
    :no-close-on-backdrop="true"
    v-model="showModal"
    id="modal-contacto-exitoso"
    centered
  >
    <p class="my-2 text-modal" v-if="success">
      ¡Tu consulta se ha enviado exitosamente!
    </p>
    <p class="my-2 text-mensaje" v-if="success">
      Muy pronto nos estaremos comunicando contigo
    </p>

    <p v-else class="my-2 text-mensaje">
      No hemos podido enviar tu consulta. Por favor intentalo más tarde.
    </p>

    <!--
    <p class="mt-4 text-saldo">
      Tu nuevo divino saldo es: <span>$17.234</span>
    </p>
    -->
    <template #modal-footer="{ hide }">
      <b-button
        size="sm"
        class="col-12 btn-sm btn btn-modal"
        @click="hide('forget')"
        @click.self="sendHome"
      >
        Ok!
      </b-button>
    </template>
  </b-modal>
</template>

<script>
export default {
  props: ["success"],
  methods: {
    sendHome() {
      this.$router.push("/");
    },
  },
};
</script>

<style scoped>
/deep/.close {
  background: transparent;
  border: none;
}
/deep/.modal-header {
  border-bottom: none;
}

/deep/.modal-body {
  padding: 0 50px;
}
.text-modal {
  color: #501682;
  font-family: maven-bold;
  text-align: center;
  text-transform: uppercase;
  font-size: 20px;
}

.text-saldo span {
  font-family: maven-bold;
  color: #3b5998;
}
/deep/.modal-footer {
  border-top: none;
  padding: 10px 50px 30px 50px;
}
/deep/.btn-modal {
  background-color: #04d9b2;
  color: #fff;
  font-family: maven-bold;
  text-transform: uppercase;
  border: none;
}
.text-mensaje {
  text-align: center;
  font-family: maven-regular;
}
</style>
