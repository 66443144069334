import axiosClient from '../config/axiosClient';

const activateModal = async (token, idTarotista) => {

    const headers = { headers: { Authorization: `Bearer ${token}`} };

    const responseRoomId = await axiosClient.get(`/users/get-latest-room/valoracion/${idTarotista}`, headers);

    if(responseRoomId.data.code === -1 || responseRoomId.data.code === 1)
        return responseRoomId.data.code;

    /* if(!responseRoomId.data.existRoom)
        return false;

    return true; */
     /* console.log('>>>>>>>>>', responseRoomId.data.existRoom) */
    return responseRoomId.data.existRoom
};

export { activateModal };