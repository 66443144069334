import axiosClient from "../../config/axiosClient";
import { enableLogs } from '../../config';

let getCallbackUrlTransfer = async function(
  roomId,
  kushkiToken,
  amount,
  guestId = null
) {
  if (guestId)
    return await axiosClient.post("/payment/kushki-room-transfer/init/guest", {
      kushkiFrontendToken: kushkiToken,
      kushkiAmount: {
        subtotalIva: 0,
        subtotalIva0: amount,
        iva: 0,
      },
      roomId,
      guestId,
    });
  return await axiosClient.post(
    "/payment/kushki-room-transfer/init",
    {
      kushkiFrontendToken: kushkiToken,
      kushkiAmount: {
        subtotalIva: 0,
        subtotalIva0: amount,
        iva: 0,
      },
      roomId,
    },
    {
      headers: { Authorization: `Bearer ${this.jwt}` },
    }
  );
};

let checkTransferPayment = async function(roomId, kushkiToken, guestId = null) {
  if(enableLogs) console.log(guestId);

  if (guestId)
    return await axiosClient.post("/payment/kushki-room-transfer/verify/guest", {
      kushkiFrontendToken: kushkiToken,
      guestId,
      roomId,
    });

  return await axiosClient.post(
    "/payment/kushki-room-transfer/verify",
    {
      kushkiFrontendToken: kushkiToken,
      roomId,
    },
    {
      headers: { Authorization: `Bearer ${this.jwt}` },
    }
  );
};

export { getCallbackUrlTransfer, checkTransferPayment };
