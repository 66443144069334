<template>
  <div>
    <b-modal
      :no-close-on-esc="true"
      :no-close-on-backdrop="true"
      id="modalBolsas"
      ref="modal-plan-bolsas"
      centered
      hide-footer
    >
      <template #modal-title> </template>
      <h3 class="title">Nueva divinobolsa</h3>
      <div class="mb-3">
        <label class="label-modal" for="">Nombre</label>
        <div>
          <input
            v-model="divinoBolsa.description"
            type="text"
            class="form-control inputPadding"
            aria-label="Username"
            aria-describedby="basic-addon1"
            placeholder="Nombre"
          />
        </div>
      </div>
      <div class="mb-3">
        <label class="label-modal" for="">Precio</label>
        <div>
          <input
            v-model="divinoBolsa.price"
            type="text"
            class="form-control inputPadding"
            aria-label="Username"
            aria-describedby="basic-addon1"
            placeholder="12.345"
          />
        </div>
      </div>
      <div class="mb-3">
        <label class="label-modal" for="">Bonificación</label>
        <div>
          <input
            type="text"
            class="form-control inputPadding"
            aria-label="Username"
            aria-describedby="basic-addon1"
            placeholder="15%"
            v-model="divinoBolsa.bonification"
          />
        </div>
      </div>

      <button class="btn btn-yellow" @click="createDivinoBolsa">
        Crear DivinoBolsa
      </button>
    </b-modal>
  </div>
</template>

<script>
import axiosClient from "../../../../config/axiosClient";
import { enableLogs } from '../../../../config';

export default {
  name: "",
  components: {},
  data() {
    return {
      divinoBolsa: {
        price: null,
        description: null,
        bonification: null,
      },
    };
  },
  methods: {
    async createDivinoBolsa() {
      try {
        const response = await axiosClient.post(
          "/divino-bolsas/create-bolsa",
          {
            divinoBolsa: this.divinoBolsa,
          },
          { headers: { Authorization: `Bearer ${this.$store.state.jwt}` } }
        );
        if(enableLogs) console.log(
          "divino bolsa created successfully with id: ",
          response.data.createdBolsa
        );
        this.$emit("add-bolsa", {
          id: response.data.createdBolsa,
          ...this.divinoBolsa,
        });
        this.$refs["modal-bolsas"].hide();
      } catch (error) {
        if(enableLogs) console.log("could not create divino bolsa");
        if(enableLogs) console.log(error.response);
      }
    },
  },
};
</script>
<style scoped>
/deep/.close {
  background: transparent;
  border: none;
}
/deep/ .modal-header {
  border-bottom: none;
  padding-bottom: 0px;
}
/deep/ .modal-body {
  padding: 48px;
  padding-top: 0px;
  font-family: maven-medium;
}
.title {
  font-family: maven-semibold;
  font-size: 18px;
  margin-bottom: 20px;
  text-transform: uppercase;
}

.btn-yellow {
  background: #ffc109bb;
  font-family: maven-semibold;
  text-transform: uppercase;
  color: #501682;
  border-radius: 10px;
  padding: 8px 16px;
  font-size: 12px;
  float: right;
}
.btn-email {
  width: 100%;
  font-family: maven-semibold;
  border-radius: 10px;
  background: #333;
  color: #fff;
  font-size: 12px;
}
label {
  font-size: 14px;
}
.label-modal {
  font-family: maven-semibold;
  font-size: 16px;
  margin-bottom: 3px;
}
</style>
