<template>
  <div>
    <b-modal
      :no-close-on-esc="true"
      :no-close-on-backdrop="true"
      id="modalDatosCorregidos"
      centered
      hide-footer
    >
      <p class="mt-2 mb-5 text-modal">
        <b>Configuración actualizada correctamente</b>
      </p>
    </b-modal>
  </div>
</template>
<script>
export default {
  watch: {

  },
  props: [],

  data: () => {
    return {
      showModal: true,
    };
  },
  methods: {
  },
};
</script>

<style scoped>
/deep/.close {
  background: transparent;
  border: none;
}
/deep/.modal-header {
  border-bottom: none;
}
/deep/.modal-body {
  padding: 0 50px;
}
.text-modal {
  color: #501682;
  font-family: maven-bold;
  text-align: center;
  text-transform: uppercase;
  font-size: 20px;
}
.text-saldo {
  text-align: center;
  font-family: maven-regular;
  font-size: 20px;
}
.text-saldo span {
  font-family: maven-bold;
  color: #3b5998;
}
/deep/.modal-footer {
  border-top: none;
  padding: 10px 50px 30px 50px;
}
/deep/.btn-modal {
  background-color: #04d9b2;
  color: #fff;
  font-family: maven-bold;
  text-transform: uppercase;
  border: none;
}
</style>
