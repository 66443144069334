<template>
  <!--Modal pago exitoso-->
  <b-modal
    id="modal-compra-pago-exitoso"
    centered
    :visible="true"
  >
    <p class="my-2 text-modal">Tiene una sala para este momento, favor de aceptar !!!</p>

    <template #modal-footer="{
          /*hide*/
        }">
      <b-button
        size="sm"
        class="col-12 btn-sm btn btn-modal btn-yellow"
        @click="irSala"
      >
        Atender!
      </b-button>
    </template>
  </b-modal>
</template>
<script>
  export default {
    props: ["url"],
    methods: {
      irSala(){
        this.$router.push(this.url);
      },
      
    },
  };
</script>

<style scoped>
  /deep/.close {
    background: transparent;
    border: none;
    display: none;
  }
  /deep/.modal-header {
    border-bottom: none;
  }

  /deep/.modal-body {
    padding: 0 50px;
    font-family: maven-regular;
  }
  .text-modal {
    color: #333;
    font-family: maven-bold;
    text-align: center;
    text-transform: uppercase;
  }
  .text-saldo {
    text-align: center;
    font-family: maven-regular;
    font-size: 20px;
  }
  .text-saldo span {
    font-family: maven-bold;
    color: #3b5998;
  }
  /deep/.modal-footer {
    border-top: none;
    padding: 10px 50px 30px 50px;
    display: block;
  }
  /deep/.btn-yellow {
    background-color: #ffc209;
    color: #501682;
    font-family: maven-bold;
    text-transform: uppercase;
    border: none;
    padding: 6px 20px;
  }
  .btn-yellow:hover {
  background: #dba501 !important;
  color: #501682 !important;
  box-shadow: #501682;
}
  /deep/.btn-line {
    border: 1px solid #501682;
    background-color: transparent;
    color: #501682;
    font-family: maven-bold;
    text-transform: uppercase;
    padding: 6px 20px;
  }

  .text-sutitulo {
    font-size: 13px;
  }
</style>
