<template>
  <div>
    <div class="card shadow">
      <div class="card-body">
        <div class="row">
          <div class="col-md-4 col-12">
            <h5 class="title">Historial de Eventos</h5>
          </div>
          <div class="col-md-8 col-12">
            <button @click="filter" class="btn btn-filtro">Filtrar</button>
            <select
              v-model="selectedDay"
              class="form-select form-control filtro"
              name=""
              id=""
            >
              <option value="">Día</option>
              <option v-for="day in days" :key="day" :value="day">
                {{ day }}
              </option>
            </select>

            <select
              v-model="selectedMonth"
              class="form-select form-control filtro"
              name=""
              id=""
            >
              <option value="">Mes</option>
              <option v-for="month in months" :key="month" :value="month">
                {{ month }}
              </option>
            </select>

            <select
              v-model="selectedYear"
              class="form-select form-control filtro"
              name=""
              id=""
            >
              <option v-for="year in years" :key="year" :value="year">
                {{ year }}
              </option>
            </select>
          </div>
          <div class="col-12 my-3">
            <div class="content-table">
              <table class="table" v-if="shownLectures.length != 0">
                <thead
                  :style="shownLectures.length > 8 ? 'margin-right: 11px;' : ''"
                >
                  <tr>
                    <th scope="col">N°</th>
                    <th scope="col">Tipo</th>
                    <th scope="col">Fecha</th>
                    <th scope="col">Canal</th>
                    <th scope="col">Duración</th>
                    <th scope="col">Ingresos</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="lecture in shownLectures" :key="lecture.roomId">
                    <th scope="row">{{ lecture.roomId }}</th>
                    <td>{{(lecture.tarotReaderType === "agendada") ? "Agendada" : "Instantánea" }}</td>
                    <td>{{ parseDate(lecture.createdDate) }}</td>
                    <td>{{ parseToSpanish(lecture.roomType) }}</td>
                    <td>
                      {{
                        parseRoomDuration(
                          lecture.createdDate,
                          lecture.endDate,
                          lecture.realDuration
                        )
                      }}
                      min
                    </td>
                    <td>${{ numberWithCommas(lecture.amount) }}</td>
                  </tr>
                </tbody>
              </table>
              <div v-else>
                <p class="text-disponible">No se han encontrado lecturas</p>
              </div>
            </div>
            <div class="row">
              <div class="col">
                <a download>
                  <button @click="exportar" class="btn button-yellow">
                    exportar reporte
                  </button>
                </a>
              </div>
              <div class="text-end text-total pr-5 col">
                <p>
                  Total de ingresos:<span>
                    ${{ numberWithCommas(incomeAmount) }}
                  </span>
                </p>
              </div>
            </div>
          </div>
          <!-- <div class="col-12 text-center">
          <button class="btn btn-yellow">Ver más</button>
        </div> -->
        </div>
      </div>
    </div>
    <div class="col-12 my-3">
      <p class="div-grey" for="">
        Estos ingresos generados por el Usuario en el mes precedente se
        calcularán de la siguiente manera: Honorarios del Usuario recibidos por
        Divino Tarot menos la comisión de transacción del banco o institución
        financiera y menos la comisión de Divino Tarot
      </p>
    </div>
  </div>
</template>

<script>
import moment from 'moment';
import axiosClient from '../../config/axiosClient';
import { enableLogs } from '../../config';

export default {
  props: {
    latestLectures: Array,
  },
  watch: {
    selectedYear: function () {
      this.selectedDay = '';
      this.selectedMonth = '';
    },
    selectedMonth: function (month) {
      const year = this.selectedYear;
      let days;

      // If no month selected, set days to empty
      if (month == '') {
        this.selectedDay = '';
        this.days = [];
        return;
      }

      // If the selected month is february, set 28 days or 29 if leap year
      if (month == 2) {
        days = [...Array.from({ length: 28 }, (_, i) => i + 1)];

        if ((year % 4 == 0 && year % 100 != 0) || year % 400 == 0)
          days.push(29);

        this.days = days;
        return;
      }

      // Set 30 days, or 31 depending on the month
      days = [
        ...Array.from(
          { length: [1, 3, 5, 7, 8, 10, 12].includes(month) ? 31 : 30 },
          (_, i) => i + 1
        ),
      ];

      this.days = days;
      return;
    },
  },
  data() {
    const baseYear = 2021;

    return {
      days: [],
      months: Array.from({ length: 12 }, (_, i) => i + 1),

      // Get all years from and including the base year
      years: [
        baseYear,
        ...Array.from(
          { length: new Date().getFullYear() - baseYear },
          (_, i) => i + 1 + baseYear
        ),
      ],

      selectedDay: '',
      selectedMonth: '',
      selectedYear: new Date().getFullYear(),

      shownLectures: this.latestLectures,

      incomeAmount: this.calculateTotalAmount(this.latestLectures),
    };
  },

  methods: {
    async exportar() {
      try {
        const response = await axiosClient.get('/reportes/DT', {
          headers: { Authorization: `Bearer ${this.$store.state.jwt}` },
        });
        if (enableLogs) console.log(response.data);
        var a = document.createElement('a');
        a.href = 'data:text/txt;base64,' + response.data.contents;
        a.download = 'ReporteDTTarotista.xlsx';
        a.click();
      } catch (e) {
        e;
      }
    },

    filter() {
      const selectedYear = this.selectedYear;
      const selectedMonth = this.selectedMonth;
      const selectedDay = this.selectedDay;
      let sum = 0;

      const filteredLectures = this.latestLectures.filter(
        ({ createdDate, amount }) => {
          const lectureDate = new Date(createdDate);

          const lectureDay = lectureDate.getDate();
          const lectureMonth = lectureDate.getMonth() + 1;
          const lectureYear = lectureDate.getFullYear();

          if (selectedYear != lectureYear) return false;

          if (selectedMonth != '' && selectedMonth != lectureMonth)
            return false;

          if (selectedDay != '' && selectedDay != lectureDay) return false;

          sum += amount;

          return true;
        }
      );

      this.shownLectures = filteredLectures;
      this.incomeAmount = sum;
    },
    calculateTotalAmount(latestLectures) {
      let sum = 0;
      latestLectures.forEach(({ amount }) => (sum += amount));

      return sum;
    },
    calculateRoomDuration(createdDate, endDate) {
      const initDate = new Date(createdDate);
      const finishDate = new Date(endDate);
      const difference = finishDate.getTime() - initDate.getTime();
      return Math.round(difference / 60000);
    },
    parseDate(date) {
      const dateToParse = date;
      const stringDate = new Date(dateToParse).toLocaleDateString();
      return moment().format(stringDate);
    },
    parseToSpanish(channel) {
      switch (channel) {
        case 'VOICE':
          return 'VOZ';
        default:
          return channel;
      }
    },
    parseRoomDuration(createdDate, endDate, realDuration) {
      let seconds = Math.abs(new Date(endDate) - new Date(createdDate)) / 1000;
      if (seconds == 0) {
        return this.secondsToString(realDuration);
      } else {
        return this.secondsToString(seconds);
      }
    },

    secondsToString(seconds) {
      // let hour = Math.floor(seconds / 3600);
      // hour = (hour < 10)? '0' + hour : hour;
      // let minute = Math.floor((seconds / 60) % 60);
      // minute = (minute < 10)? '0' + minute : minute;
      // let second = seconds % 60;
      // second = (second < 10)? '0' + second : second;
      // return hour + ':' + minute + ':' + second;

      return Math.ceil(seconds / 60);
    },

    numberWithCommas(x) {
      var a = x.toLocaleString('es-AR');
      if (a.indexOf(',') == -1) {
        return x.toLocaleString('es-AR');
      } else {
        return a.substr(0, a.indexOf(','));
      }
    },
    getRealDuration(realDuration) {
      return Math.ceil(realDuration / 60);
    },
  },
};
</script>

<style scoped>
.div-grey {
  background-color: #e9ecef;
  padding: 15px;
  font-family: maven-regular;
  font-size: 12px;
}
.card {
  border-radius: 10px;
}
.title {
  font-family: maven-bold;
  color: #501682;
  font-size: 15px;
}
.btn-filtro {
  background: #ffc209;
  color: #501682;
  font-family: maven-bold;
  font-size: 10px;
  text-transform: uppercase;
  float: right;
  padding: 6px 10px;
}
.btn-filtro:hover {
  background: #dba501;
  color: #650bbf;
}
.filtro {
  max-width: 80px;
  border: 1px solid #f3f6f9;
  border-radius: 0.25rem;
  font-family: maven-semibold;
  font-size: 10px;
  color: #333333;
  float: right;
  margin-right: 15px;
}

.btn-yellow {
  font-family: maven-semibold;
  background: #ffc209;
  color: #501682;
  padding: 6px 30px;
  text-transform: uppercase;
  font-size: 12px;
}
.btn-yellow:hover {
  background: #dba501;
  color: #650bbf;
}

table {
  font-family: maven-regular;
  font-size: 13px;
}

/*table fixed*/

.table tbody {
  max-height: 300px;
  overflow-y: auto;
  width: 100%;
}

table {
  display: flex;
  flex-flow: column;
  width: 100%;
}

thead {
  flex: 0 0 auto;
}

tbody {
  flex: 1 1 auto;
  display: block;
  overflow-y: auto;
  overflow-x: hidden;
}

tr {
  width: 100%;
  display: table;
  table-layout: fixed;
}

.text-total {
  font-family: maven-regular;
}
.text-total p {
  margin-bottom: 0;
}
.text-total span {
  font-family: maven-semibold;
}
/*color scroll*/
tbody {
  --scrollbarBG: #d1d1d1;
  --thumbBG: #333333;
}
tbody::-webkit-scrollbar {
  width: 11px;
}
tbody {
  scrollbar-width: thin;
  scrollbar-color: var(--thumbBG) var(--scrollbarBG);
}
tbody::-webkit-scrollbar-track {
  background: var(--scrollbarBG);
}
tbody::-webkit-scrollbar-thumb {
  background-color: var(--thumbBG);
  border-radius: 6px;
  border: 3px solid var(--scrollbarBG);
}
.text-disponible {
  font-family: maven-semibold;
  font-size: 16px;
  text-align: center;
  color: #707070;
  margin-top: 15px;
}
@media screen and (max-width: 768px) {
  .content-table {
    overflow-x: scroll;
  }
  .table {
    min-width: 500px;
  }
  .content-table {
    --scrollbarBG: #d1d1d1;
    --thumbBG: #333333;
  }
  .content-table::-webkit-scrollbar {
    height: 11px;
  }
  .content-table {
    scrollbar-width: thin;
    scrollbar-color: var(--thumbBG) var(--scrollbarBG);
  }
  .content-table::-webkit-scrollbar-track {
    background: var(--scrollbarBG);
  }
  .content-table::-webkit-scrollbar-thumb {
    background-color: var(--thumbBG);
    border-radius: 6px;
    border: 3px solid var(--scrollbarBG);
  }
}
</style>
