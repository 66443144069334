<template>
  <div class="star">
    <i :class="'fas fa-star ' + sizeStart + ' ' + (value >= 1 ? 'star-on' : 'star-off')"></i>
    <i :class="'fas fa-star ' + sizeStart + ' ' +(value >= 2 ? 'star-on' : 'star-off')"></i>
    <i :class="'fas fa-star ' + sizeStart + ' ' +(value >= 3 ? 'star-on' : 'star-off')"></i>
    <i :class="'fas fa-star ' + sizeStart + ' ' +(value >= 4 ? 'star-on' : 'star-off')"></i>
    <i :class="'fas fa-star ' + sizeStart + ' ' +(value >= 5 ? 'star-on' : 'star-off')"></i>
    <span class="puntaje" v-if="show">{{ value.toFixed(1) }}</span>
    <span class="personas" v-if="showPerson" >({{ person }})</span>
  </div>
</template>
<script>
export default {
     /*  */
    props: {
        value: Number,
        shownumer: Boolean,
        size: Number,
        person: Number,
        showperson: Boolean,
    },
    data: () => {
        return {
            show: true,
            showPerson: true,
            sizeStart: 'start-s-1'
        };
    },
  mounted(){
      this.show = this.shownumer;
      this.showPerson = this.showperson;
      this.sizeStart = 'start-s-'+(this.size ? this.size : 1);
  }
}
</script>

<style scoped>
.star-on {
  margin-right: 4px;
  color: #ffc30b;
}
.star-off {
  margin-right: 4px;
  color: grey;
}
.start-s-1{
  font-size: 12px;
}
.start-s-2{
  font-size: 10px;
}
.puntaje {
  font-family: maven-semibold;
  margin-top: 1px;
  font-size: 14px;
  margin-left: 5px;
}
.personas {
  font-size: 14px;
  margin-left: 5px;
  font-family: maven-regular;
}

</style>