import axiosClient from "../../config/axiosClient";

let finishPayment = async function(
  roomId,
  kushkiToken,
  roomPrice,
  guestId = null,
  tokenjwt
) {
  console.log('%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%')
  console.log('FLAG 11')
  let info = JSON.parse(window.localStorage.getItem('info')) 
  let {email, nombre, apellido, telefono, direccion, ciudad, region, pais} = info
  let requestBody = {
    roomId: roomId,
    kushkiFrontendToken: kushkiToken,
    kushkiAmount: {
      subtotalIva: 0,
      subtotalIva0: roomPrice,
      ice: 0,
      iva: 0,
      currency: "CLP",
    },
    kushkiContactDetails: {
/*       documentType: tipo_doc,
      documentNumber: num_doc, */
      email: email,
      firstName: nombre ? nombre : info.firstName,
      lastName: apellido ? apellido : info.lastName,
      phoneNumber: telefono ? telefono : info.phoneNumber,
    },
    kushkiOrderDetails: {
      siteDomain: "www.divinotarot.com",
      billingDetails: {
        name: (nombre ? nombre : info.firstName) + " " + (apellido ? apellido : info.lastName),
        phone: telefono[0] == "+" ? (telefono ? telefono : info.phoneNumber) : 
        ("+" + (telefono ? telefono : info.phoneNumber)),
        address: direccion ? direccion : (info.adress ? info.adress : info.address),
        city: ciudad ? ciudad : info.city,
        region: region,
        country: pais ? pais : info.country,
        /* zipCode: "5000", */
      },
    },
  };

  console.log('%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%')
  console.log('FLAG 12')
  console.log(requestBody);

  let response;
  if (guestId) {
    console.log('%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%')
    console.log('FLAG 13 FFFFFF')
    requestBody.guestId = guestId;
    response = await axiosClient.post(
      "/payment/kushki-room-credit/guest",
      requestBody
    );
  } else {

    console.log('%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%')
    console.log('FLAG 13 OK')
    try{
      console.log(tokenjwt)
      console.log('%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%')
      console.log(this.jwt)
      console.log('%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%')
    }catch(e){1}
    response = await axiosClient.post(
      "/payment/kushki-room-credit",
      requestBody,
      {
        headers: { Authorization: `Bearer ${tokenjwt ? tokenjwt : this.jwt}` },
      }
    );
  }

  console.log('%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%')
  console.log('FLAG 14')
  console.log(response.data)

  return response.data;
};

export { finishPayment };
