<template>
  <div class="container my-5">
    <div class="row justify-content-center">
      <div class="col-12 mb-3">
        <div class="text-back" @click="goBack">
          <i class="fas fa-chevron-left"></i>
          <span>Volver</span>
        </div>
      </div>
      <div class="col-10">
        <h1 class="general-title">Mis datos</h1>
      </div>
      <div class="col-10 my-4">
        <div class="card shadow">
          <div class="card-body">
            <div class="row">
              <div class="mb-3">
                <label class="label-modal" for="">Email del usuario</label>
                <div>
                  <input
                    type="text"
                    class="form-control inputPadding"
                    aria-label="Username"
                    aria-describedby="basic-addon1"
                    disabled
                    :value="usuarios.email"
                  />
                </div>
              </div>
              <div class="mb-3">
                <label class="label-modal" for="">Nombre</label>
                <div>
                  <input
                    
                    type="text"
                    class="form-control inputPadding"
                    aria-label="Username"
                    aria-describedby="basic-addon1"  
                    disabled
                    :value="usuarios.personName"
                  />
                </div>
              </div>
              <div class="mb-3">
                <label class="label-modal" for="">Teléfono</label>
                <div>
                  <input
                    
                    type="text"
                    class="form-control inputPadding"
                    aria-label="Username"
                    aria-describedby="basic-addon1" 
                    disabled
                    :value="usuarios.phoneNum"
                  />
                </div>
              </div>
              <div class="mb-3">
                <label class="label-modal" for=""
                  >Permiso para el usuario</label
                >
                <div
                  class="form-check"
                  v-for="(permiso, index) in permisos"
                  :key="index"
                >
                  <input
                    class="form-check-input"
                    type="checkbox"
                    value=""
                    :id="permiso.nombre"
                    disabled
                  />
                  <label class="form-check-label" :for="permiso.nombre">
                    {{ permiso.nombre }}
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axiosClient from "../../../config/axiosClient";
import { enableLogs } from '../../../config';

export default {
  name: "MisDatos",
  components: {},
  data: () => {
    return {
      permisos: [],
      usuarios: null,
    };
  },
  created() {
    this.getPermisos();
    this.getAdminsInfo();
  },
  methods: {
    getPermisos() {
      // Estructura interna....
      let permisos = [
        {
          nombre: "Reportes",
        },
        {
          nombre: "Pruebas de comunicación",
        },
        {
          nombre: "Soporte",
        },
        {
          nombre: "Aprobar tarotista",
        },
        {
          nombre: "Ambitos",
        },
        {
          nombre: "Especialidades",
        },
        {
          nombre: "Productos",
        },
        {
          nombre: "Curso y talleres",
        },
        /* {
          nombre: "Blog",
        }, */
        {
          nombre: "Canales de comunicación",
        },
      ];

      // Resultado final
      this.permisos = permisos;
    },
    goBack() {
      this.$router.go(-1);
    },
    async getAdminsInfo() {
      try {
        const { data } = await axiosClient.get("/users/get-admins/my", {
          headers: { Authorization: `Bearer ${this.$store.state.jwt}` },
        });
       if(enableLogs) console.log("successfully fetched all admins");
        this.usuarios = data.ress;
       if(enableLogs) console.log(this.usuarios);
      } catch (err) {
       if(enableLogs) console.log("could not fetch all users");
       if(enableLogs) console.log(err);
      }
    },
  },
};
</script>

<style scoped>
.text-back {
  color: #501682;
  text-transform: uppercase;
  font-family: maven-semibold;
  font-size: 13px;
  cursor: pointer;
}
.text-back:hover {
  color: #934dd8;
}
.text-back span {
  margin-left: 6px;
}
.title {
  color: #501682;
  text-transform: uppercase;
  font-family: source-bold;
  font-size: 19px;
}
.btn-yellow {
  font-family: maven-semibold;
  background: #ffc209;
  color: #501682;
  font-size: 11px;
  text-transform: uppercase;
  padding: 10px 18px;
}
.btn-yellow:hover {
  background: #dba501;
  color: #650bbf;
}

.card {
  border-radius: 10px;
  min-height: 100%;
}
.card-body {
  padding: 20px 20px;
  font-family: maven-regular;
}

.title-ambito {
  font-size: 21px;
  font-family: maven-bold;
}

.title-descripcion {
  font-size: 11px;
}
h5 {
  font-size: 17px;
  font-family: maven-bold;
}
label {
  font-size: 13px;
  font-family: maven-bold;
  color: #707070;
}
input {
  background: #f3f6f9;
  border: 1px solid #f3f6f9;
}
table {
  font-size: 13px;
}
table th {
  font-family: maven-semibold;
}
table td {
  font-family: maven-medium;
}
</style>
