<template>
  <div>
    <b-modal
      :no-close-on-esc="true"
      :no-close-on-backdrop="true"
      id="modalSuscripcion"
      ref="modal-plan-nueva-suscripcion"
      centered
      hide-footer
    >
      <template #modal-title> </template>
      <h3 class="title">Nuevo Plan de Suscripción</h3>
      <div class="mb-3">
        <label class="label-modal" for="">Nombre</label>
        <div>
          <input
            v-model="subscription.planName"
            type="text"
            class="form-control inputPadding"
            aria-label="Username"
            aria-describedby="basic-addon1"
            placeholder="Nombre del plan"
          />
        </div>
      </div>
      <div class="mb-3">
        <label class="label-modal" for="">Precio</label>
        <div>
          <input
            v-model="subscription.planPrice"
            type="text"
            class="form-control inputPadding"
            aria-label="Username"
            aria-describedby="basic-addon1"
            placeholder="$12.345"
          />
        </div>
      </div>
      <div class="mb-3">
        <label class="label-modal" for="">Descripción</label>
        <div>
          <textarea
            class="form-control"
            v-model="subscription.planDescription"
            name=""
            id=""
            cols="3"
            rows="2"
            placeholder=".."
          ></textarea>
        </div>
      </div>
      <div class="mb-3">
        <label class="label-modal" for="">% Descuento Anual</label>
        <div>
          <input
            type="text"
            class="form-control inputPadding"
            aria-label="Username"
            aria-describedby="basic-addon1"
            placeholder="00"
          />
        </div>
      </div>
      <div class="col-12 mt-3">
        <label class="label-modal" for="">Imagen</label>
        <div class="col mt-3 p-0" style="width: 180px; height: 150px">
          <div class="card-img shadow-sm" style="">
            <i class="fas fa-camera"></i>
            <label class="btn-shadow btn-icon">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="10"
                height="10"
                fill="currentColor"
                class="bi bi-pencil-fill"
                viewBox="0 0 16 16"
              >
                <path
                  d="M12.854.146a.5.5 0 0 0-.707 0L10.5 1.793 14.207 5.5l1.647-1.646a.5.5 0 0 0 0-.708l-3-3zm.646 6.061L9.793 2.5 3.293 9H3.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.207l6.5-6.5zm-7.468 7.468A.5.5 0 0 1 6 13.5V13h-.5a.5.5 0 0 1-.5-.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.5-.5V10h-.5a.499.499 0 0 1-.175-.032l-.179.178a.5.5 0 0 0-.11.168l-2 5a.5.5 0 0 0 .65.65l5-2a.5.5 0 0 0 .168-.11l.178-.178z"
                />
              </svg>
              <input
                class="input-file"
                type="file"
                name="profile_avatar"
                accept=".png, .jpg, .jpeg"
              />
            </label>
          </div>
        </div>
      </div>

      <button class="btn btn-yellow" @click="createSubscription">
        Crear Suscripción
      </button>
    </b-modal>
  </div>
</template>

<script>
  import axiosClient from '../../../../config/axiosClient';
  import { enableLogs } from '../../../../config';

  export default {
    name: 'Catalogo',
    components: {},
    data() {
      return {
        subscription: {
          planName: null,
          planPrice: null,
          planDescription: null,
        },
      };
    },
    methods: {
      async createSubscription() {
        try {
          const response = await axiosClient.post(
            'subscriptions/create-subscription',
            { subscription: this.subscription },
            { headers: { Authorization: `Bearer ${this.$store.state.jwt}` } }
          );
         if(enableLogs) console.log(
            'successfully created plan with id: ',
            response.data.createdSubscription
          );
          this.$emit('add-plan', {
            id: response.data.createdSubscription,
            name: this.subscription.planName,
            monthlyPrice: this.subscription.planPrice,
            description: this.subscription.planDescription,
          });
          this.$refs['modal-suscripcion'].hide();
        } catch (error) {
         if(enableLogs) console.log('could not create new subscription');
         if(enableLogs) console.log(error.response);
        }
      },
    },
  };
</script>
<style scoped>
  /deep/.close {
    background: transparent;
    border: none;
  }
  /deep/ .modal-header {
    border-bottom: none;
    padding-bottom: 0px;
  }
  /deep/ .modal-body {
    padding: 48px;
    padding-top: 0px;
    font-family: maven-medium;
  }
  .title {
    font-family: maven-semibold;
    font-size: 18px;
    margin-bottom: 20px;
    text-transform: uppercase;
  }

  .btn-yellow {
    background: #ffc109bb;
    font-family: maven-semibold;
    text-transform: uppercase;
    color: #501682;
    border-radius: 10px;
    padding: 8px 16px;
    font-size: 12px;
    float: right;
  }
  .btn-email {
    width: 100%;
    font-family: maven-semibold;
    border-radius: 10px;
    background: #333;
    color: #fff;
    font-size: 12px;
  }
  label {
    font-size: 14px;
  }
  .label-modal {
    font-family: maven-semibold;
    font-size: 16px;
    margin-bottom: 3px;
  }
  .card-img {
    width: 120px;
    height: 120px;
    border: 3px solid #fff;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
  }
  .card-img i {
    font-size: 50px;
    margin-top: 35px;
    margin-left: 35px;
    color: #707070;
  }
  .btn-icon {
    background-color: #f3f6f9;
    border-color: #f3f6f9;
    color: #3f4254;
    border-radius: 50%;
    height: 24px !important;
    width: 24px;
    align-items: center;
    display: -webkit-inline-box;
    display: inline-flex;
    align-items: center;
    -ms-flex-pack: center;
    justify-content: center;
    padding: 0;
    position: absolute;
    right: -10px;
    top: -10px;
    cursor: pointer;
  }
  .input-file {
    width: 0 !important;
    height: 0 !important;
    overflow: hidden;
    opacity: 0;
  }
</style>
