<template>
  <div>
    <div class="card card-pagos">
      <b-card-body class="card-body">
        <h5 class="title-card-pago margin-title ">Selecciona el Método de Pago</h5>

<!--        <div v-if="tipo == 'agendada'">
          <div
            v-for="(option, index) in opcionesAgendada"
            :key="index"
            class="col-12 mb-4 mt-3"
          >
            <div class="card card-form-input ">
              <div class="body-card">
                <div class="form-check">
                  <b-form-radio
                  class="pagos-check-input"
                    type="radio"
                    name="flexRadioDefault"
                    :id="option.value"
                    :value="option.value"
                    :selected="selectedOption"
                  >
                  </b-form-radio>
                  <label class="form-check-label" :for="option.value">
                    {{ option.name }}
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>-->
        <div v-if="tipo === 'instantanea' || tipo === 'agendada'">
          <div
            class="col-12 mb-3"

          >
            <!-- <div class="card card-form-input" >
              <div class="body-card">
                <div class="form-check">
                  <input
                    class="form-check-input pagos-check-input"
                    type="radio"
                    name="radio-pago"
                    :id="option.value "
                    :value="option.value"
                    v-model="selectedOption"
                    :disabled="option.value === 'Cash'"
                  />
                  <label class="form-check-label label-card-pago" :for="option.value ">
                    {{ option.name }}
                  </label>
                </div>
              </div>
            </div> -->
            <div class="card card-form-input" v-if="mediosPago['divinosaldo'] === 1">
              <div class="body-card">
                <div class="form-check" @click="balanceSelect">
                  <input
                    class="form-check-input pagos-check-input"
                    type="radio"
                    name="radio-pago"
                    id="Balance"
                    value="Balance"
                    v-model="selectedOption"
                  />
                  <label class="form-check-label label-card-pago" for="Balance">
                    Usar mi Divino Saldo
                  </label>
                </div>
              </div>
            </div>
            <div class="card card-form-input mt-3" v-if="duration !== 0 && mediosPago['transbank'] === 1">
              <div class="body-card">
                <div class="form-check" @click="transbankSelect">
                  <input
                    class="form-check-input pagos-check-input"
                    type="radio"
                    name="radio-pago"
                    id="Transbank"
                    value="transbank"
                    v-model="selectedOption"
                  />
                  <label class="form-check-label label-card-pago" for="Transbank">
                    WebPay
                  </label>
                </div>
              </div>
            </div>
            <div class="card card-form-input mt-3" v-if="mediosPago['credito'] === 1">
              <div class="body-card">
                <div class="form-check">
                  <input
                    class="form-check-input pagos-check-input"
                    type="radio"
                    name="radio-pago"
                    id="Credit"
                    value="Credit"
                    v-model="selectedOption"
                  />
                  <label class="form-check-label label-card-pago" for="Credit">
                    Tarjeta de Crédito
                  </label>
                </div>
              </div>
            </div>
            <div class="card card-form-input mt-3" v-if="duration !== 0 && mediosPago['transferencia'] === 1">
              <div class="body-card">
                <div class="form-check">
                  <input
                    class="form-check-input pagos-check-input"
                    type="radio"
                    name="radio-pago"
                    id="Transfer"
                    value="Transfer"
                    v-model="selectedOption"

                  />
                  <label class="form-check-label label-card-pago" for="Transfer">
                    Transferencia
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-else>
          <div
            v-for="(option, index) in opcionesInstantanea"
            :key="index"
            class="col-12 mb-4 mt-3"
          >
            <div class="card card-form-input ">
              <div class="body-card">
                <div class="form-check">
                  <b-form-radio
                    class="pagos-check-input"
                    type="radio"
                    name="flexRadioDefault"
                    :id="option.value"
                    :value="option.value"
                    :selected="selectedOption"
                  >
                  </b-form-radio>
                  <label class="form-check-label" :for="option.value">
                    {{ option.name }}
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div v-if="type === 'agendada' && ((allowForm && divinoSaldo >= 0 && duration !== 0) || (duration === 0 && divinoSaldo >= 5000)) || (selectedOption === 'transbank' && type === 'agendada')">
          <h5 class="title-card-pago">Calendario</h5>
          <div class="col-12 mb-3 text-end text-center" @click="showCalendly">
            <b-button
                class="btn btn-pagar"
                type="button"
                style='padding: 8px 40px!important;'
            >
              Agendar
            </b-button>
          </div>
        </div>
        <div v-else-if="type === 'agendada'">
          <p class="text-medio-pago"><span>Debe recargar su <b>Saldo</b> para poder agendar</span></p>
        </div>
      </b-card-body>
      <div v-if="this.calendlyShow">
        <div class="col-12 mb-3 text-end">
          <ModalShowCalendly :success="success" :linkCalendly='linkCalendly' @modal-closed="onModalClosed"/>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axiosClient from '../../config/axiosClient'
import ModalShowCalendly from '../util/ModalShowCalendly.vue'
export default {
  components: {ModalShowCalendly},
  data: () => {
    return {
      activo: null,
      precioSala: 0,
      type: '',
      linkCalendly: "",
      calendlyShow: false,
      selectedOption: "null",
      opcionesAgendada: [
        {
          name: "Usar mi Divino Saldo",
          value: "Balance",
          libre: true,
        },
/*         {
          name: "Tarjeta de Crédito",
          value: "Credit",
          libre: true,
        }, */
        {
          name: "WebPay",
          value: "transbank",
          libre: true,
        },
        /*{
          name: "Transferencia",
          value: "Transfer",
          libre: false,
        },
        {
          name: "Efectivo",
          value: "Cash",
          libre: false,
        },*/
      ],
      opcionesInstantanea: [
        {
          name: "Usar mi Divino Saldo",
          value: "Balance",
          libre: true,
        },
/*         {
          name: "Tarjeta de Crédito",
          value: "Credit",
          libre: true,
        }, */
        {
          name: "WebPay",
          value: "transbank",
          libre: true,
        },
        /*{
          name: "Transferencia",
          value: "Transfer",
          libre: false,
        },*/
        /*{
          name: "Efectivo",
          value: "Cash",
          libre: false,
        },*/
      ],
       mediosPago: {},
    };
  },
  watch: {
    selectedOption: function (newOption) {
      this.$emit("metodoPagoChange", newOption);
    },
  },
  computed: {
    allowForm: function() {
      return (
        this.divinoSaldo > 0 &&
        this.divinoSaldo >= this.precioSala &&
        this.divinoSaldo - this.finalPrice >= 0
      );
    },
    divinoSaldo: function() {
      return this.$store.state.balance;
    },
    finalPrice: function() {
      return this.precioSala * this.duration;
    },
  },
  props: {
    tarotistaId: Number,
    tipo: String,
    duration: Number,
    isLoggedIn: Boolean,
    existe: Boolean,
    correo: String,
    nombre: String,
    canal:String,
    tarotista: Object,
  },
  created() {
    this.selectedOption = "Balance";
    this.getRoomPrice();
  },
  
 methods:{
   transbankSelect(){
     this.selectedOption = "transbank";
   },
   balanceSelect(){
     this.selectedOption = "Balance";
   },
   async getRoomPrice() {
     let channel;
     switch (this.canal) {
       case 'Chat':
         channel = 'CHAT';
         break;
       case 'Voz':
         channel = 'VOICE';
         break;
       case 'Video':
         channel = 'VIDEO';
         break;
     }
     let response = await axiosClient.post('/rooms/get-room-price', {
       tarotReaderId: this.tarotista.tarotReaderId,
       duration: 1,
       roomType: channel,
     });
     this.precioSala = response.data.roomPrice;
   },
   onModalClosed() {
     this.calendlyShow = false;
   },
   async showCalendly() {
     this.$emit("registroUse");
     await this.getLinkCalendly();
     if (this.type === "agendada" && this.activo === 3) {
       this.calendlyShow = true;
     }else if (this.type === "agendada" && this.activo !== 3){
       this.$store.commit("setMessage", "Disponible Próximamente");
     }
   },
   async getLinkCalendly(){
    let url = await axiosClient.post(
       '/tarot-calendly/get-event-type/linkCalendly',
       {
         idTarotista: this.tarotistaId,
       },
     );
     this.activo = url.data.status.activeInvitation;
     switch (this.duration){
       case 15:
         this.linkCalendly = url.data.status.tarifa_uno+"?email="+this.correo+"&name="+this.nombre;
         break;
       case 30:
         this.linkCalendly = url.data.status.tarifa_dos+"?email="+this.correo+"&name="+this.nombre;
         break;
       case 60:
         this.linkCalendly = url.data.status.tarifa_tres+"?email="+this.correo+"&name="+this.nombre;
         break;
     }
   },
    async getMediosPago(){
      try{
        const response = await axiosClient.get('/status-buttons/')
        return this.mediosPago = response.data
      }catch(e){
        console.log(e)
      }
  
    }

  },
  mounted(){
    this.type = this.$route.query.tipo;
    this.idTarotista = this.$route.query.tarotista;
    this.getMediosPago()
    if(this.$route.query.c){
      this.selectedOption = "Credit";
    }else{
      this.selectedOption = "Balance";
    }
  },
};
</script>
<style scoped>
@media (max-width:768px) {
  .card-pagos {
    min-height: auto !important;
  }
  .card-pagos .btn-pagar {
    width: 100%;
  }
}
.margin-title{
  margin: 25px 20px !important;
}

</style>
