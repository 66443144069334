<template>
  <div class="container box-newTarotista mt-5">
    <div class="row justify-content-between">
      <div class="text-center col-12 p-0">
        <h2 class="general-title">Tarotistas Nuevos</h2>
      </div>
      <div class="col-12">
        <div class="row justify-content-center">
        <Spinner v-if="isLoading" />
        </div>

      </div>
      <div
        v-for="(tarotista, index) in newTarotistas"
        :key="index"
        class="col-md-2 col-sm-12 col-12 tarotista "
      >
        <div class="image">
          <router-link
            :to="'/ficha-tarotista?id_tarotista=' + tarotista.tarotReaderId"
          >
            <img :src="tarotista.avatar" alt="" />
          </router-link>
        </div>
        <p>{{ tarotista.alias }}</p>
        <!-- <span v-for="(ambito, ind) in tarotista.scopes" :key="ind">{{
          ambito.scopeName + (tarotista.scopes.length - 1 == ind ? "" : ", ")
        }}</span> -->
        <span v-if="tarotista.scopes">{{
          tarotista.scopes.length > 0 ? tarotista.scopes[0].scopeName : 'Otra'
        }}</span>
      </div>
    </div>
    <div class="row mt-5">
      <div class="col text-center">
        <router-link to="/catalogo?ranking=1">
          <button class="btn btn-ver-mas">Ver más</button>
        </router-link>
      </div>
    </div>
  </div>
</template>
<script>
import axiosClient from '../../config/axiosClient';
import Spinner from '../loadingspinner/Spinner.vue';
import { enableLogs } from '../../config';

export default {
  data: () => {
    return {
      newTarotistas: [],
      isLoading: false,
    };
  },
  components: {
    Spinner,
  },
  created() {
    this.getTarotistas();
  },
  methods: {
    async getTarotistas() {
      this.isLoading = true;
     if(enableLogs) console.log('Fetching new tarot readers');
      try {
        let response = await axiosClient.get(
          '/tarot-reader/get-new-tarot-readers/'
        );

        this.newTarotistas = response.data.newTarotReaders;
        this.isLoading = false;

       if(enableLogs) console.log('Successfully fetched new tarot readers');

       if(enableLogs) console.log(response.data.newTarotReaders);
      } catch (err) {
       if(enableLogs) console.log('Error fetching new tarot readers');
       if(enableLogs) console.log(err);
        this.isLoading = false;
      }
    },
  },
};
</script>
<style scoped>
.box-newTarotista {
  position: relative;
  padding: 120px 0;
}
h2 {
  color: #fff;
}
.newTarotista {
  margin: 100px 0;
}
.tarotista {
  text-align: center;
  /*  margin: 0 25px; */
  margin-top: 20px;
}
.image {
  margin: auto;
  width: 100px;
  height: 100px;
  margin-bottom: 15px;
  background: #fff;
  border-radius: 50%;
}
.image img {
  width: 100%;
  border-radius: 50%;
  height: 100%;
  object-fit: cover;
}

.tarotista p {
  margin-bottom: 0px;
  color: #fff;
  font-family: maven-semibold;
  font-size: 17px;
}
.tarotista span {
  color: #fff;
  font-family: maven-regular !important;
  font-size: 14px;
}
.btn-ver-mas {
  background: #ffc30b;
  color: #501682;
  font-family: maven-bold;
  padding: 8px 50px;
  border-radius: 50px;
  font-size: 15px;
  transition: all 0.5s ease;
}

.btn-ver-mas:hover {
  background: #04d9b2;
}
</style>
