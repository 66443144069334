<template>
  <div class="container">
    <div class="row">
      <div class="col-12">
        <p class="submenu">Inicio - Precios y planes - <span>Divino Bolsa</span></p>
      </div>
    </div>
    <div class="row justify-content-center mt-3 pb-5">
      <div class="col-12">
        <h5 class="title general-title">Finalizar compra</h5>
      </div>
      <div class="row justify-content-center mt-5" v-if="isLoading">
        <Spinner />
      </div>
      <div class="col-10 content " v-else>
        <div class="row content-icon ">
          <div v-if="divinoBolsa !== null" class="col-lg-4 col-12 mb-lg-0 mb-3 padding-card">
            <Descripcion :divinoBolsa="divinoBolsa" :isLoading="isLoading" />
          </div>
          <div class="col-12 arrow-v">
              <div class="col-triangulo-v text-center">
                <div class="triangulo-one-v"></div>
              </div>
          </div>
          <div class="col-lg-4 col-12 mb-lg-0 mb-3 padding-card">
            <MetodoPago
              v-on:metodoPagoChange="
                (newMethod) => {
                  metodoPago = newMethod;
                }
              "
            />
            <ModalDatos v-if="metodoPago === 'Credit' && info == 1" />
          </div>
          <div class="col-12 arrow-v">
              <div class="col-triangulo-v text-center">
                <div class="triangulo-one-v"></div>
              </div>
          </div>
          <div class="col-lg-4 col-12 mb-lg-0 mb-3 padding-card">
            <DatosTarjeta
              v-on:askForConfirmPayment="askForConfirmPayment"
              v-if="metodoPago === 'Credit'"
            />

            <DatosTarjetaDebito
              v-on:askForConfirmPayment="askForConfirmPayment"
              v-if="metodoPago === 'Debit'"
            />
            <DatosTransferencia
              v-on:askForConfirmPayment="askForConfirmPayment"
              v-if="metodoPago === 'Transfer'"
            />
            <DatosEfectivo
              v-on:askForConfirmPayment="askForConfirmPayment"
              v-if="metodoPago === 'Cash'"
            />
          </div>
            </div>

        <div class="row justify-content-center content-arrow">
        <div class="col-lg-1 col-md-2 text-center">
          <div class="row">
            <div class="col col-triangulo">
              <div class="triangulo-one"></div>
            </div>
          </div>
        </div>

        <div class="col-lg-3 col-md-4 mx-lg-0 mx-md-2">
         
        </div>

        <div class="col-lg-1 col-md-2 text-center " style="padding-left: 38px;">
          <div class="row">
            <div class="col col-triangulo">
              <div class="triangulo-one"></div>
            </div>
          </div>
        </div>
        </div>
      </div>

      <ModalConfirmacion
        v-on:confirmPayment="confirmPayment"
        :triggerModal="modalTriggered === 'modalConfirmacion'"
      />

      <ModalEspera
        :triggerModal="modalTriggered === 'modalEspera'"
        :forceClose="forceClose == 'modalEspera'"
      />

      <ModalPagoExitoso :divinoBolsa="divinoBolsa" :triggerModal="modalTriggered === 'modalPagoExitoso'" />
      <ModalPagoFallo :triggerModal="modalTriggered === 'modalPagoFallo'" />
      <ModalTelefono
        v-on:setPhoneNum="phoneNum = $event"
        :triggerModal="modalTriggered === 'modalTelefono'"
      />
    </div>
  </div>
</template>

<script>
import Descripcion from "./Descripcion";
import MetodoPago from "./MetodoPago";
import DatosTarjeta from "./DatosTarjeta";
import DatosTarjetaDebito from "./DatosTarjetaDebito";
import DatosTransferencia from "./DatosTransferencia";
import DatosEfectivo from "./DatosEfectivo";
import Spinner from '../../loadingspinner/Spinner.vue'
import axiosClient from "../../../config/axiosClient";
import { finishPayment } from "../../../payment/kushki/CreditCardDivinoBolsa";
import {
  getCallbackUrl,
  //checkDebitPayment,
} from "../../../payment/kushki/DebitCardDivinoBolsa";
import {
  getCallbackUrlTransfer,
  //checkDebitPayment,
} from "../../../payment/kushki/TransferDivinobolsa";

import {
  requestKushkiTokenCredit,
  requestKushkiTokenDebit,
  requestKushkiTokenTransfer,
} from "../../../payment/kushki";

import ModalConfirmacion from "./modals/ModalConfirmacion";
import ModalEspera from "./modals/ModalEspera";
import ModalPagoExitoso from "./modals/ModalPagoExitoso";
import ModalPagoFallo from "./modals/ModalPagoFallo.vue";
import ModalTelefono from "./modals/ModalTelefono.vue";
import ModalDatos from '../../util/ModalDatos.vue'
import { siteUrl, ssl, enableLogs } from "../../../config";

export default {
  name: "DashboardUser",
  components: {
    Descripcion,
    MetodoPago,
    DatosTarjeta,
    DatosTarjetaDebito,
    DatosTransferencia,
    DatosEfectivo,
    ModalConfirmacion,
    ModalEspera,
    ModalPagoExitoso,
    ModalPagoFallo,
    ModalTelefono,
    Spinner,
    ModalDatos
  },
  data: () => {
    return {
      divinoBolsaId: null,
      divinoBolsa: null,

      metodoPago: null,

      modalTriggered: null,
      forceClose: null,

      phoneNum: null,

      paymentData: {},
      isLoading: true,

      info:null,
      
      optionDatos: ''
    };
  },
  watch: {
    token: function(jwtToken) {
      if (jwtToken === null) return;

     console.log("Got token!");
     console.log(this.$route.query);
      if (this.$route.query.token && this.$route.query.mode) {
       console.log("Coming back from webpay");
        if (this.$route.query.mode === "kushkiDebitCard") {
         console.log("Confirming debit payment!");
         console.log(this.$route.query);
          let kushkiToken = this.$route.query.token;
          this.confirmDebitDivinobolsa(kushkiToken, this.$route.query.dbs);
        } else if (this.$route.query.mode === "KushkiTransfer") {
         console.log("Confirming trasfer payment!");
         console.log(this.$route.query);
          let kushkiToken = this.$route.query.token;
          this.confirmTransferDivinobolsa(kushkiToken, this.$route.query.dbs);
        }
        else if (this.$route.query.mode === "webpay") {
          console.log("Confirming webpay!");
          let kushkiToken = this.$route.query.token_ws;
          this.confirmWebPay(kushkiToken, this.$route.query.id);
        }
      }
    },

  },
  computed: {
    token: function() {
      return this.$store.state.jwt;
    },
  },
  created() {
    if (this.$route.query.mode && this.$route.query.mode === 'webpay') {
        this.metodoPago = 'Debit';
    }
    // Obtener los datos del tarotista
    this.divinoBolsaId = this.$route.query.id;

    if (this.divinoBolsaId !== null && this.divinoBolsaId !== undefined)
      // Peticion al back para obtener la información del tarotista
      this.getBolsaInfo();

    if (this.$route.query.token) {
      this.triggerModal("modalEspera");
     if(enableLogs) console.log("Checking room payment, waiting for jwt...");
    }

    /* if (this.metodoPago == 'Credit') return console.log("medio de pago es credito") */
    /* this.$store.commit("showModalDatos", true);  */
    this.info = window.localStorage.getItem('info')
    if(this.info != '1' && this.info != null){
      this.$store.commit('setInfoCredit', this.info)
    }
  },
  methods: {
    trackPurchaseEvent() {
      if (!this.divinoBolsa) {
        console.error("divinoBolsa no está definido");
        return;
      }
      const eventParams = {
        transaction_id: this.$route.query.token,
        value: this.divinoBolsa.price,
        currency: "CLP",
        items: [
          {
            item_id: this.divinoBolsa.id,
            item_name: "Compra de Bolsa: " + this.divinoBolsa.description,
            affiliation: "divino tarot",
            price: this.divinoBolsa.price,
            quantity: 1
          }
        ]
      };

      // Lógica para enviar el evento a Facebook Pixel
      if (window.fbq) {
        window.fbq('track', 'Purchase', {
          value: this.divinoBolsa.price,
          currency: "CLP",
          transaction_id: this.$route.query.token
        });
      } else {
        console.error("Facebook Pixel no está cargado");
      }
      // Evento de compra
      this.$gtag.event('purchase', eventParams);

      // Evento de conversión
      this.$gtag.event('conversion_event_purchase', eventParams);
    },
    async payWithWebpay() {
      // let { personEmail } = this.paymentData;
      if(enableLogs) console.log('Starting debit card payment');
      this.jwt = this.$store.state.jwt;
      var data_encrypt = {originprice: this.divinoBolsa.originprice, planPriceRedis: this.divinoBolsa.price, planId: this.divinoBolsa.id};
      let responsenew = await axiosClient.post("/payment/pep-webpay/webpay/planes",data_encrypt);
      window.location.assign((ssl ? 'https' : 'http')+'://'+siteUrl+'/form-webpay?token='+responsenew.data.token+'&url='+responsenew.data.url);
    },
    async confirmWebPay(webPayToken, idBolsa) {
      await this.$nextTick();
      this.jwt = this.$store.state.jwt;
      let tokenWs = this.$route.query.token_ws;
      if (!tokenWs){
        await this.triggerModal('modalPagoFallo');
        return;
      }
      this.triggerModal("modalEspera");
      this.jwt = this.$store.state.jwt;
      let response = await axiosClient.post(
          "/payment/kushki-divinobolsa-webpay/verify/webpay",
          {
            tokenWs: webPayToken,
            idBolsa
          },
          {
            headers: { Authorization: `Bearer ${this.jwt}` },
          }
      );

      if(enableLogs) console.log(response);
      if (response.data.code === 2) {
        this.triggerModal("modalPagoExitoso");
        this.trackPurchaseEvent();
        await new Promise((resolve) => setTimeout(resolve, 4000));
        await this.updateBalance();
        this.$router.push("/planes");
      }else{
        this.triggerModal("modalPagoFallo");
        await new Promise((resolve) => setTimeout(resolve, 4000));
        this.$router.push("/planes");
      }
    },
    async getBolsaInfo() {
      if(enableLogs) console.log("Fetching divinobolsa info");

      try {
        let response = await axiosClient.get(
          `/divino-bolsas/get-divinobolsas/${this.divinoBolsaId}`
        );
        this.divinoBolsa = response.data.divinoBolsa;
        this.divinoBolsa.originprice = this.divinoBolsa.price;
        if (this.divinoBolsa.bonification > 0) {
          this.divinoBolsa.price = parseFloat(
            (
              this.divinoBolsa.price 
              //* (1 - this.divinoBolsa.bonification / 100)
            ).toFixed(2)
          );
        }

       if(enableLogs) console.log("Finished fetching divinobolsa info");
        this.isLoading = false;
      } catch (err) {
       if(enableLogs) console.log("Error fetching divinobolsa info");
        this.isLoading = false;
      }
    },

    async sendConfirmationEmail(email, purchaseDetails) {

      const metodoPago = this.metodoPago;

      try {
        await axiosClient.post("/payment/send-purchase-details", {
          email,
          purchaseDetails,
          metodoPago
        });
       if(enableLogs) console.log("successfully sent purchase details email");
      } catch (error) {
       if(enableLogs) console.log(error);
      }
    },
    async askForConfirmPayment(paymentData) {
      this.paymentData = paymentData;
      this.triggerModal("modalConfirmacion");
    },
    async triggerModal(modal) {
     if(enableLogs) console.log(`Show Modal: ${modal}`);
      switch (modal) {
        case "modalConfirmacion":
          this.modalTriggered = "modalConfirmacion";
          break;
        case "modalEspera":
          this.modalTriggered = "modalEspera";
          break;

        case "modalPagoExitoso":
          this.modalTriggered = "modalPagoExitoso";
          this.forceClose = "modalEspera";
          break;
        case "modalPagoFallo":
          this.modalTriggered = "modalPagoFallo";
          this.forceClose = "modalEspera";
          break;
        case "modalTelefono":
          this.modalTriggered = "modalTelefono";
          break;
      }
      await this.$nextTick();
      this.modalTriggered = null;
      this.forceClose = null;
    },
    async confirmPayment() {
     if(enableLogs) console.log(this.paymentData);
      if (this.paymentData) {
        if (this.paymentData.type === "kushkiCreditCard") {
          this.triggerModal("modalEspera");
          this.payWithCreditCard();
        }
        if (this.paymentData.type === "kushkiDebitCard") {
          this.triggerModal("modalEspera");
          this.payWithDebitCard();
        }
        if (this.paymentData.type === "kushkiTransfer") {
          this.triggerModal("modalEspera");
          this.payWithTransfer();
        }
        if (this.paymentData.type === "WebPay") {
          this.triggerModal("modalEspera");
          this.payWithWebpay();
        }
      }
    },
    async payWithCreditCard() {
      let { personName, cardNum, expiration, cvv } = this.paymentData;
      let { email } = this.$store.state.tokenData;
     if(enableLogs) console.log("Starting credit card payment");
      try {
        this.jwt = this.$store.state.jwt;
       if(enableLogs) console.log("Requesting kushki token");
        let kushkiToken = await requestKushkiTokenCredit(
          this.divinoBolsa.price,
          "CLP",
          personName,
          cardNum,
          cvv,
          expiration.split("/")[0],
          expiration.split("/")[1],
          this.divinoBolsa.id
        );
        if (kushkiToken == undefined)
          throw new Error("Error: error generating kushki token");

       if(enableLogs) console.log(`Succesfully requested kushki token: ${kushkiToken}`);

       if(enableLogs) console.log(`Finish the payment`);

        let fPayment = finishPayment.bind(this);
        await fPayment(kushkiToken, this.divinoBolsa.price, this.divinoBolsa.id, null, this.token);

       if(enableLogs) console.log(`Payment finished`);

        // If payment was successfull, show the modal
        this.triggerModal("modalPagoExitoso");
        this.trackPurchaseEvent();
        await new Promise((resolve) => setTimeout(resolve, 3000));
        await this.updateBalance();
        this.sendConfirmationEmail(email, this.divinoBolsa);
        this.$router.push("/planes");
      } catch (err) {
       if(enableLogs) console.log("Error with credit card payment");
        if (err.response) {
         if(enableLogs) console.log(err.response.data);
        }
       if(enableLogs) console.log(err);
        this.triggerModal("modalPagoFallo");
      }

      await this.$nextTick();
    },

    async payWithDebitCard() {
      let { personEmail } = this.paymentData;
     if(enableLogs) console.log("Starting debit card payment");

      this.jwt = this.$store.state.jwt;

     if(enableLogs) console.log("Requesting kushki token");
      let kushkiToken = await requestKushkiTokenDebit(
        this.divinoBolsa.price,
        "CLP",
        `${ssl ? "https" : "http"}://${siteUrl}/planes/compra?dbs=${this.divinoBolsa.id}&id=${
          this.divinoBolsaId
        }&mode=kushkiDebitCard`,
        personEmail,
        "DivinoTarot",
        this.divinoBolsa.id
      );
     if(enableLogs) console.log(`Succesfully requested kushki token: ${kushkiToken}`);

     if(enableLogs) console.log(`Get payment URL`);

      let gCallbackUrl = getCallbackUrl.bind(this);
      let response = await gCallbackUrl(kushkiToken, this.divinoBolsa.price,this.divinoBolsa.id);
     if(enableLogs) console.log("Got callback url, redirecting in 5 seconds...");
     if(enableLogs) console.log(response.data);
      window.location.href = response.data.redirectUrl;
      this.isLoading = false;
    },
    async payWithTransfer() {
      let { personEmail } = this.paymentData;
     if(enableLogs) console.log("Starting transfer payment");

      this.jwt = this.$store.state.jwt;

     if(enableLogs) console.log("Requesting kushki token");
      let kushkiToken = await requestKushkiTokenTransfer(
        this.divinoBolsa.price,
        "CLP",
        `${ssl ? "https" : "http"}://${siteUrl}/planes/compra?dbs=${this.divinoBolsa.id}&id=${
          this.divinoBolsaId
        }&mode=KushkiTransfer`,
        personEmail,
        "DivinoTarot"
      );
     if(enableLogs) console.log(`Succesfully requested kushki token: ${kushkiToken}`);

     if(enableLogs) console.log(`Get payment URL`);

      let gCallbackUrl = getCallbackUrlTransfer.bind(this);

      let response = await gCallbackUrl(kushkiToken, this.divinoBolsa.price, this.divinoBolsa.id);

     if(enableLogs) console.log("Got callback url, redirecting in 5 seconds...");
     if(enableLogs) console.log(response.data);
      window.location.href = response.data.redirectUrl;
      this.isLoading = false;
    },
    async updateBalance() {
      // Update balance
      let rsp = await axiosClient.get("/users/get-balance", {
        headers: { Authorization: `Bearer ${this.$store.state.jwt}` },
      });

      this.$store.commit("updateUserBalance", rsp.data.balance);
      return;
    },
    async confirmDebitDivinobolsa(kushkiToken, idBolsa) {
      this.triggerModal("modalEspera");
      this.jwt = this.$store.state.jwt;
      let response = await axiosClient.post(
        "/payment/kushki-divinobolsa-webpay/verify",
        {
          kushkiFrontendToken: kushkiToken, 
          idBolsa
        },
        {
          headers: { Authorization: `Bearer ${this.jwt}` },
        }
      );

     if(enableLogs) console.log(response);
      if (response.data.code == 2) {
        this.triggerModal("modalPagoExitoso");
        this.trackPurchaseEvent();
        await new Promise((resolve) => setTimeout(resolve, 4000));
        await this.updateBalance();
        this.$router.push("/planes");
      }else{
        this.triggerModal("modalPagoFallo");
        await new Promise((resolve) => setTimeout(resolve, 4000));
        
        this.$router.push("/planes");
      }
    },
    async confirmTransferDivinobolsa(kushkiToken, idBolsa) {
      this.triggerModal("modalEspera");
     if(enableLogs) console.log(this.$store.state.jwt)
      
      let response = await axiosClient.post(
        "/payment/kushki-divinobolsa-transfer/verify",
        {
          kushkiFrontendToken: kushkiToken, 
          idBolsa
        },
        {
          headers: { Authorization: `Bearer ${this.$store.state.jwt}` },
        }
      );

     if(enableLogs) console.log(response);

      if (response.data.code == 2) {
        this.triggerModal("modalPagoExitoso");
        this.trackPurchaseEvent();
        await new Promise((resolve) => setTimeout(resolve, 4000));
        await this.updateBalance();
        this.$router.push("/planes");
      }else{
        this.triggerModal("modalPagoFallo");
        await new Promise((resolve) => setTimeout(resolve, 4000));
        
        this.$router.push("/planes");
      }
    },
  },
  /*
  methods: {
    getNextLink(roomId) {
      let nextLink = ``;
      switch (this.canal) {
        case "Video":
          nextLink = `/sala-espera?roomId=${roomId}`;
          break;
        case "Chat":
          nextLink = `/chat?roomId=${roomId}`;
          break;
        case "Voice":
          nextLink = `/sala-espera?roomId=${roomId}`;
          break;
      }
      return nextLink;
    },
  
,*/
};
</script>

<style scoped>
.title {
  text-align: center;
}
.padding-card{
  padding: 0 18px !important;
}
/*triangulo*/
.content {
  position: relative;
}
.content-icon {
  position: absolute;
}
.content-arrow {
  margin: 208px 0;
}
.triangulo-one {
  width: 0;
  height: 0;
  border-left: 10px solid #979797;
  border-top: 5px solid transparent;
  border-bottom: 5px solid transparent;
  margin:auto;
}
.col-triangulo-v {
  max-width: 16px;
  padding: 0;
  margin-bottom: 16px;
  margin-left: calc(50% - 6.5px);
}
.triangulo-one-v {
  width: 0;
  height: 0;
  border-left: 10px solid #979797;
  border-top: 5px solid transparent;
  border-bottom: 5px solid transparent;
  transform: rotate(90deg);
}
.arrow-v{
  display: none;
}
@media screen and (max-width: 992px) {
    .content-icon {
    position: relative;
    }
    .content-arrow {
    display: none;
    }
    .arrow-v{
      display: block;
    }
  }
</style>
