const { Kushki } = require('@kushki/js')
const { celcomKushkiMerchantId, isProduction } = require('../../config')
import axiosClient from '../../config/axiosClient'

// La api de kushki funciona solo con callbacks, por eso la envolvemos en una promesa para poder usarla de forma asincrona
const requestKushkiTokenCreditSubscription = (
    nombreTarjeta,
    numeroTarjeta,
    cvcTarjeta,
    mesExpiracion,
    anioExpiracion
) => {
    let kushki = new Kushki({
        merchantId: celcomKushkiMerchantId,
        inTestEnvironment: !isProduction,
        regional: false,
    })

    return new Promise(resolve => {
        kushki.requestSubscriptionToken(
            {
                currency: 'CLP',
                card: {
                    name: nombreTarjeta,
                    number: numeroTarjeta,
                    cvc: cvcTarjeta,
                    expiryMonth: mesExpiracion,
                    expiryYear: anioExpiracion,
                },
            },
            rsp => {
                return resolve(rsp.token)
            }
        )
    })
}

const saveCard = async function(kushkiToken, lastDigits, jwt) {
    let contactDetails = JSON.parse(window.localStorage.getItem('info'))
    let response = await axiosClient.post(
        '/payment/kushki-save-card',
        { kushkiToken, lastDigits, contactDetails },
        {
            headers: { Authorization: `Bearer ${jwt}` },
        }
    )

    return response.data
}

const saveCardGuest = async function(kushkiToken, lastDigits, idGuest, idSocket, ) {
    let contactDetails = JSON.parse(window.localStorage.getItem('info'))
    let response = await axiosClient.post(
        '/payment/kushki-save-card/guest',
        { kushkiToken, lastDigits, idGuest, idSocket , contactDetails},
        {
            headers: {},
        }
    )

    return response.data
}

const getCard = async function(jwt) {
    let response = await axiosClient.get('/payment/kushki-get-card', {
        headers: { Authorization: `Bearer ${jwt}` },
    })
    const { cardSaved, lastDigits, id_subscripcion } = response.data

    let kushki = new Kushki({
        merchantId: celcomKushkiMerchantId,
        inTestEnvironment: !isProduction,
        regional: false,
    })

    kushki.requestTokenCharge({
        subscriptionId: id_subscripcion // Replace with your subscription id
    }, (response2) => {
        if(response2.token){
        console.log(response2.token);
        // Submit your code to your back-end
        } else {
        console.error('Error: ',response2);
        }
    });

    return { cardSaved, lastDigits, id_subscripcion }
}

export { requestKushkiTokenCreditSubscription, saveCard, saveCardGuest, getCard }
