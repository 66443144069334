<template>
  <div class="bg-green">
    <div class="container-fluid content-header p-0">
      <div class="header-bg">
        <Nav />
      </div>
    </div>
    <div class="container-fluid content-body ">
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
import Nav from "../nav/NavRoom";

export default {
  name: "LayoutRoom",
  components: {
    Nav,
  },

};
</script>

<style scoped>
.bg-green{
  background: #501682;
}
.content-header {
  background-image: url("../../assets/img/header.jpg");
  background-repeat: no-repeat;
  background-size: cover;
}

.header-bg {
  background-color: #501682;
}
.content-body {
  /*height: calc(100vh - 150px) !important;*/
  padding-top: 90px !important;
  background-color: #F6F6F6;
  min-height: 80vh;
}
@media screen and (max-width: 992px){
.content-body {
  padding-top: 174px;
}
 }
 @media screen and (max-width: 767px){
.content-body {
  padding-top: 191px;
}
 }
</style>
