<template>
  <div class="col-12 container-ambitos">
    <h5 class="title general-title">Ambitos</h5>
    <div class="row">
      <div
        class="col col-especialidades"
        v-for="scope in tarotista.scopes"
        :key="scope.scopeId"
      >
        <div class="especialidad">
          <div class="especialidad-img">
            <div class="container-img">
              <img :src="scope.scopeImage" alt="" width="100%" />
            </div>
            <div class="border-img"></div>
          </div>
          <div class="speciality-text">
             <span class="speciality-name my-3">{{
              scope.scopeName
            }}</span>

          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    tarotista: Object,
  },
};
</script>

<style scoped>
.container-ambitos {
  color: #ffffff;
  border-bottom: 1px solid #98989838;
  margin: 0 12px;
  padding: 0 0 35px 0px;
  margin-bottom: 35px;
}
.title {
  color: #ffffff;
  margin-bottom: 35px;
  font-size: 28px;
}
.col-especialidades{
  text-align: center;
  max-width: 125px;
}
.especialidad {
    text-align: center;
    width: 125.8px;
    margin: auto;
}
.especialidad-img {
    position: relative;
}
.especialidad-img:hover .container-img {
    background: #04d9b2;
}
.container-img {
    width: 100px;
    height: 100px;
    background: #f6f6f6;
    border-radius: 50%;
    margin: auto;
    border: 1px dotted #f6f6f6;
}
.container-img img {
    max-width: 75px;
    margin-top: calc(50% - 28px);
}
.border-img {
    border: 2px dotted #ffffff;
    padding-top: 2px;
    border-radius: 50%;
    /* border-radius: 50%; */
    width: 110px;
    height: 110px;
    position: absolute;
    top: -5px;
    left: calc(50% - 55px);
    position: absolute;
}

.border-img:hover {
   border-color: #04d9b2;
    -moz-transform: rotate(1450deg);
    -ms-transform: rotate(1450deg);
    -o-transform: rotate(1450deg);
    -webkit-transform: rotate(1450deg);
    -webkit-transition: all 0.25s ease-out;
}

.speciality-text {
    font-family: maven-semibold;
  font-size: 11px;
  margin-top: 15px;
}

@media (max-width:768px) {
  .bg-lila .container-ambitos {
    margin: 1rem 0;
    padding-right: calc(var(--bs-gutter-x)* 0.5);
    padding-left: calc(var(--bs-gutter-x)* 0.5);
  }
  .bg-lila .col-especialidades {
    text-align: left;
    margin-bottom: 1.25rem;
  }
  .bg-lila .col-especialidades .especialidad {
    margin: 0;
  }
}
</style>
