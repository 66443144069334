<template>
    <div class="divserch">
        <input class="divserch-input divserch-transition" v-model="textSerch" id="serchop1" style="opacity: 0"/>
        <div class="divserch-item divserch-transition" id="serchop2" style="opacity: 0">
            <div v-on:click="()=>redirect(item.path)" v-for="item in (this.values.filter(it => textSerch.length == 0 || it.name.toUpperCase().indexOf(textSerch.toUpperCase()) != -1  /*|| it.description.toUpperCase().indexOf(textSerch.toUpperCase()) != -1*/)).slice(0,10)" :key="item">
                <p class="divserch-item-selec">{{item.name}}</p>
                <!-- <p class="divserch-item-selec">{{item.name}} <span>{{'('+item.description+')'}}</span></p> -->
                <hr/>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props:{
        fun: {
            type: Function
        }
    },
    mounted(){
        if(this.$store.state.tokenData.permissions.isTarotReader){
            this.values =[
                {
                    path: '/contactanos',
                    name: 'Contáctanos',
                    description: 'Visualizar tarotistas'
                },
                {
                    path: '/faqs',
                    name: 'Preguntas Frecuentes',
                    description: 'Visualizar tarotistas'
                },
                /* {
                    path: 'https://www.celcom.cl/blog/',
                    name: 'Blog',
                    description: 'Visualizar tarotistas'
                }, */
            ];
        }
    },
    data: () => {
        return {
            textSerch: '',
            values: [
                {
                    path: '/',
                    name: 'Inicio',
                    description: 'Visualizar tarotista'
                },
                {
                    path: '/catalogo',
                    name: 'Tarotistas',
                    description: 'Visualizar tarotistas'
                },
                {
                    path: '/planes',
                    name: 'Planes y Precios',
                    description: 'Visualizar tarotistas'
                },
                {
                    path: '/servicios',
                    name: 'Servicios',
                    description: 'Visualizar tarotistas'
                },
                {
                    path: '/contactanos',
                    name: 'Contáctanos',
                    description: 'Visualizar tarotistas'
                },
                {
                    path: '/faqs',
                    name: 'Preguntas Frecuentes',
                    description: 'Visualizar tarotistas'
                },
               /*  {
                    path: 'https://www.celcom.cl/blog/',
                    name: 'Blog',
                    description: 'Visualizar tarotistas'
                }, */
                {
                    path: '/registro-tarotista',
                    name: 'Quiero ser Tarotista',
                    description: 'Visualizar tarotistas'
                },
            ]
        };
    },
    beforeCreate(){
        setTimeout(() => {
            window.document.getElementById('serchop1').setAttribute('style', '');
            setTimeout(() => {
                window.document.getElementById('serchop2').setAttribute('style', '');
            }, 300);
        }, 10);
    },
    methods: {
        redirect(path){
            this.fun();
            if(path.substr(0,7) == 'http://' || path.substr(0,8) == 'https://')
            {
                window.open(path, "_blank"); 
            }else
            {
                this.$router.push(path)
            }
        }
    }
}
</script>

<style>
.divserch{
    position: absolute;
    left: 0;
    right: 0;
    z-index: 100;
}
.divserch-input{
    background-color: rgb(255 255 255 / 95%);
    width: 100%;
    font-family: maven-medium;
    border-radius: 5px;
    padding: 15px;
    margin-bottom: 15px;
    margin-top: 5px;
}
.divserch-item{
    background-color: rgb(255 255 255 / 95%);
    font-family: maven-medium;
    border-radius: 5px;
    box-shadow: 2px 2px 8px #0000002e;
}
.divserch-item-selec{
    background-color: rgb(255 255 255 / 95%);
    padding: 10px;
    margin-bottom: 0px;
    transition: 0.1s;
    border-radius: 5px;
}
.divserch-item-selec:hover{
    background-color: rgb(226 210 243)
}
hr{
    margin-top: 0px;
    margin-bottom: 0px;
}
p span{
    color: rgb(80 22 130);
    font-size: 14px;
    margin-left: 5px;
}
.divserch-transition{
    transition: 0.5s;
}
</style>