<template>
  <div class="container">
    <div class="row justify-content-center mt-5">
      <div class="col-10">
        <h4 class="title general-title ">Quiero ser DivinoTarotista</h4>
      </div>
      <div class="col-10">
        <PasoUno
          v-if="pasoUno"
          @stepTwo="stepTwo"
          @formDataChange="formDataChange"
          :formData="formData"
        />
        <PasoDos
          v-if="pasoDos"
          @stepOne="stepOne"
          @stepThree="stepThree"
          @formDataChange="formDataChange"
          :formData="formData"
        />
        <PasoTres
          v-if="pasoTres"
          @stepTwo="stepTwo"
          @formDataChange="formDataChange"
          @sendForm="sendForm"
          :formData="formData"
          :isLoading="isLoading"
          :success="success"
        />
      </div>
    </div>
  </div>
</template>

<script>
  import PasoUno from './PasoUno';
  import PasoDos from './PasoDos';
  import PasoTres from './PasoTres';
  import axiosClient from '../../config/axiosClient';
  import { enableLogs } from '../../config';

  export default {
    name: 'RegisterTarotista',
    data: function() {
      return {
        pasoUno: true,
        pasoDos: false,
        pasoTres: false,
        formData: {},
        isLoading: false,
        success: null,
        inscriptions: [],
      };
    },
    created() {
      this.getAllInscriptions();
    },
    components: {
      PasoUno,
      PasoDos,
      PasoTres,
    },
    methods: {
      stepOne() {
        this.pasoUno = true;
        this.pasoDos = false;
        this.pasoTres = false;
      },
      stepTwo() {
        this.pasoDos = true;
        this.pasoUno = false;
        this.pasoTres = false;
      },
      stepThree() {
        this.pasoTres = true;
        this.pasoUno = false;
        this.pasoDos = false;
      },
      formDataChange(fd) {
        if(enableLogs) console.log(fd);
        this.formData = { ...this.formData, ...fd };
       if(enableLogs) console.log(this.formData);
      },
      async sendForm() {
        this.isLoading = true;
        try {
          const response = await axiosClient.post(
            '/tarot-reader/submit-application',
            {
              inscriptionData: this.formData,
            }
          );
          this.success = true;
          this.isLoading = false;

         if(enableLogs) console.log(response);
        } catch (error) {
         if(enableLogs) console.log('could not send inscription form');
         if(enableLogs) console.log(error);
          this.success = false;
          this.isLoading = false;
        }
      },
      async getAllInscriptions() {
        try {
          const response = await axiosClient.get(
            '/inscriptions/get-all-inscriptions',
            { headers: { Authorization: `Bearer ${this.$store.state.jwt}` } }
          );
          this.inscriptions = response.data.tarotReaderInfo;
         if(enableLogs) console.log(response.data.tarotReaderInfo);
          this.isLoading = false;
        } catch (error) {
         if(enableLogs) console.log('could not get inscription requests');
         if(enableLogs) console.log(error);
          this.isLoading = false;
        }
      },
    },
  };
</script>

<style scoped>
  /deep/ .card {
    border-radius: 10px;
  }
  /deep/ .card-body {
    padding: 40px;
  }
  .title {
    margin-bottom: 30px;
  }
</style>
