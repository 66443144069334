<template>
  <!--Modal pago exitoso-->
  <b-modal
    :no-close-on-esc="true"
    :no-close-on-backdrop="true"
    v-model="showModal"
    id="modal-fin-llamada"
    centered
  >
    <p class="my-2 text-modal">Gracias por usar nuestro servicio</p>
    <!--     <p class="my-2 text-body">
      En unos instantes recibirás una llamada telefónica de un tarotista.
    </p>
 -->
    <template #modal-footer="{ hide }">
      <b-button
        size="sm"
        class="col-12 btn-sm btn btn-modal"
        @click="hide('forget')"
        @click.self="redirectHome"
      >
        Salir
      </b-button>
    </template>
  </b-modal>
</template>

<script>
export default {
  watch: {
    triggerModal: function(value) {
      if (value) this.showModal = true;
    },
  },
  props: ["triggerModal"],

  data: () => {
    return {
      showModal: false,
    };
  },
  computed: {
    socket() {
      return this.$store.state.socket;
    },
  },
  methods: {
    hideModal() {
      this.showModal = false;
    },
    redirectHome() {
      

      if (
        this.$store.state.tokenData &&
        this.$store.state.tokenData.permissions.isTarotReader
      )
        this.socket.emit("change-state", "AVAILABLE");
      this.$router.push("/");
    },
  },
};
</script>

<style scoped>
/deep/.close {
  background: transparent;
  border: none;
  display: none;
}
/deep/.modal-header {
  border-bottom: none;
}

/deep/.modal-body {
  padding: 0 50px;
}
.text-modal {
  color: #501682;
  font-family: maven-bold;
  text-align: center;
  text-transform: uppercase;
  font-size: 20px;
}
.text-body {
  text-align: center;
  font-family: maven-semibold;
  font-size: 16px;
}
.text-saldo span {
  font-family: maven-bold;
  color: #3b5998;
}
/deep/.modal-footer {
  border-top: none;
  padding: 10px 50px 30px 50px;
}
/deep/.btn-modal {
  background-color: #04d9b2;
  color: #fff;
  font-family: maven-bold;
  text-transform: uppercase;
  border: none;
}
</style>
