<template>
  <div class="container">
    <div class="row mt-5">
      <h5 class="general-title ">Terminos y Condiciones DivinoTarot</h5>
      <div class="col-12 my-3">
        <Condiciones/>
      </div>
    </div>
  </div>
</template>

<script>
import Condiciones from "./Condiciones.vue";
export default {
  name: "Terminos",
  components: {
    Condiciones
  },
};
</script>

<style scoped>


</style>
