<template>
  <div>
    <div class="container-fluid content-header p-0">
      <div class="header-bg fixed-top">
        <NavRegistrado v-if="isLoggedIn"/>
        <NavLogin v-else/>

      </div>
    </div>
    <div class="container">
        <div class="container-view">
          <router-view></router-view>
        </div>
    </div>
    <div class="container-fluid content-footer p-0" >
      <Footer/>
    </div>
  </div>
</template>

<script>
import NavLogin from '../nav/Nav'
import NavRegistrado from '../nav/NavRegistrado'
import Footer from '../footer/Footer'

export default {
  name: 'Layout',
  components: {
    NavLogin,
    NavRegistrado,
    Footer
  },
  computed: {
    isLoggedIn () {
    return this.$store.state.isLoggedIn
    }
  }
}
</script>

<style scoped>
@import "~@fortawesome/fontawesome-free/css/all.css";
.container-view{
  /*margin-top: 120px;
  margin-bottom: 120px;*/
  margin-top: -150px;
  margin-bottom: 120px;
}
.content-header{
  background-image: url('../../assets/img/header.jpg');
  background-repeat: no-repeat;
  background-size: cover;
}

.header-bg{
  background-color: #501682;
  padding-bottom: 200px;

}
.content-footer{
  background-color: #DDDDDD;
 }
</style>
