<template>
  <div class="container my-5">
    <div class="row">
      <div class="col-12 mb-3">
        <div class="text-back" @click="goBack">
          <i class="fas fa-chevron-left"></i>
          <span>Volver</span>
        </div>
      </div>
      <div class="col-12">
        <div class="row">
          <div class="col-sm-6 col-12">
            <h1 class="general-title">Reportes</h1>
          </div>
        </div>
      </div>

      <div class="col-lg-4 col-md-6 col-12 my-3" v-for="item in items" :key="item">
        <div class="card shadow">
          <div class="card-body">
            <div >
              <img class="img-reportes" :src="item.icon" alt="">
            <span class="title">{{item.title}}</span>
            </div>
            <div>
              <p class="title-descripcion">{{item.description}}</p>
            </div>
            <div class="container-btn">
              <a download :href="item.href">
                <button class="btn button-yellow" >        
                  exportar reporte   
                </button>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {ssl, backendUrl} from '../../../config/index';

export default {
  name: "DashAdminReportes",
  components: {

  },
  data: () =>{
    return {
    items: [
      { id:'1',
        href: (ssl ? 'https':'http') + '://' + backendUrl + '/api/reportes/administradores',
        title: 'Administrador', 
        icon: require('@/assets/img/reportes/settings.png'), 
        description:'Podrás generar y exportar un reporte de los usuarios administradores de Divino Tarot.' },
      { id:'2',
        href: (ssl ? 'https':'http') + '://' + backendUrl + '/api/reportes/trafico',
        title: 'Tráfico', 
        icon: require('@/assets/img/reportes/graph.png'), 
        description:'Podrás generar y exportar un reporte del tráfico de las salas creadas en el sitio.' 
        },
      { id:'3',
        href: (ssl ? 'https':'http') + '://' + backendUrl + '/api/reportes/usuarios',
        title: 'Usuarios', 
        icon:require('@/assets/img/reportes/user.png'), 
        description:'Podrás generar y exportar un reporte de los usuarios registrados en Divino Tarot.' 
      },
      { id:'4',
        href: (ssl ? 'https':'http') + '://' + backendUrl + '/api/reportes/tarotistas',
        title: 'Tarotistas', 
        icon:require('@/assets/img/reportes/tarot.png'), 
        description:'Podrás generar y exportar un reporte de los administradores aceptador, rechazados y pendientes.' 
      },
      
    ]
    }; 
  },

/*   methods:{
    download() {
        var requestOptions = {
          method: "GET",
          redirect: "follow",
        };

        fetch("http://localhost:5000/api/reportes/usuarios", requestOptions)
          .then((response) => response.text())
          .then((result) => console.log(result))
          .catch((error) => console.log("error", error));
      },
       console.log(download())
  } */
 
};
</script>

<style scoped>
.text-back {
  color: #501682;
  text-transform: uppercase;
  font-family: maven-semibold;
  font-size: 13px;
  cursor: pointer;
}
.text-back:hover {
  color: #934dd8;
}
.text-back span {
  margin-left: 6px;
}
.card-title {
  color: #501682;
  text-transform: uppercase;
  font-family: source-bold;
  font-size: 19px;
  float: left;
  margin-top: 9px;
}
.btn-yellow {
  font-family: maven-semibold;
  background: #ffc209;
  color: #501682;
  font-size: 11px;
  text-transform: uppercase;
  padding: 10px 18px;
  float: right;
}
.btn-yellow:hover {
  background: #dba501;
  color: #650bbf;
}

.card {
  border-radius: 10px;
  min-height: 100%;
}
.card-body {
  padding: 25px 25px;
  font-family: maven-regular;
}
.img-reportes{
  width: 25px;
}
.title {
  font-size: 23px;
  font-family: maven-bold;
  vertical-align: middle;
  margin-left: 15px;
}

.title-descripcion {
  margin-top: 10px;
  font-size: 12px;
}

.container-btn {
  text-align: end;
}
.btn-eliminar {
  font-size: 12px;
  background-color: #979797;
  color: #333333;
  font-family: maven-semibold;
  font-size: 10px;
  text-transform: uppercase;
  padding: 8px 10px;
}
.btn-eliminar:hover {
  background-color: #858585;
  color: #333333;
}



</style>
