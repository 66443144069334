const { Kushki } = require("@kushki/js");
const { celcomKushkiMerchantId, isProduction } = require("../../config");

// La api de kushki funciona solo con callbacks, por eso la envolvemos en una promesa para poder usarla de forma asincrona
const requestKushkiTokenCredit = (
  montoAPagar,
  moneda,
  nombreTarjeta,
  numeroTarjeta,
  cvcTarjeta,
  mesExpiracion,
  anioExpiracion,
  idBolsa
) => {
  console.log('%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%')
  console.log('FLAG 8')
  let kushki = new Kushki({
    merchantId: celcomKushkiMerchantId,
    inTestEnvironment: !isProduction,
    regional: false,
  });

  //! @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
  idBolsa

  return new Promise((resolve) => {
    kushki.requestToken(
      {
        amount: montoAPagar,
        currency: moneda,
        card: {
          name: nombreTarjeta,
          number: numeroTarjeta,
          cvc: cvcTarjeta,
          expiryMonth: mesExpiracion,
          expiryYear: anioExpiracion,
        },
      },
      (rsp) => {
        return resolve(rsp.token);
      }
    );
  });
};

const requestKushkiTokenDebit = (
  montoAPagar,
  moneda,
  returnUrl,
  email,
  description,
  idBolsa
) => {
  let kushki = new Kushki({
    merchantId: celcomKushkiMerchantId,
    inTestEnvironment: !isProduction,
    regional: false,
  });

  //! @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
  idBolsa

  return new Promise((resolve) => {
    kushki.requestCardAsyncToken(
      {
        totalAmount: montoAPagar,
        currency: moneda,
        returnUrl: returnUrl,
        email: email,
        description: description,
      },
      (rsp) => {
        return resolve(rsp.token);
      }
    );
  });
};

const requestKushkiTokenTransfer = (
  montoAPagar,
  moneda,
  returnUrl,
  email,
  description,
  idBolsa
) => {
  let kushki = new Kushki({
    merchantId: celcomKushkiMerchantId,
    inTestEnvironment: !isProduction,
  });

  //! @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
  idBolsa

  return new Promise((resolve) => {
    kushki.requestTransferToken(
      {
        amount: {
          subtotalIva: 0, // Set the value to 0 when the Tx has no taxes
          subtotalIva0: montoAPagar, // et here the total amount of the transaction when it does not have taxes , otherwise set it to 0
          iva: 0, // et the value to 0 when the Tx has no taxes
        },
        currency: moneda,
        callbackUrl: returnUrl,
        email: email,
        userType: "0",
        documentType: "RUT",
        documentNumber: "123456789",
        paymentDescription: description,
      },
      (rsp) => {
        return resolve(rsp.token);
      }
    );
  });
};

export {
  requestKushkiTokenCredit,
  requestKushkiTokenDebit,
  requestKushkiTokenTransfer,
};
