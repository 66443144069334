<template>
  <div>
    <div class="col-12">
      <div class="card shadow">
        <div class="card-body">
          <div class="row">
            <div class="col-12">
              <h5 class="title">Mi DivinoSaldo</h5>
            </div>
            <div class="col">
              <div class="wallet">
                <i class="fas fa-wallet"></i>
                <span>${{ numberWithCommas(divinoSaldo) }}</span>
              </div>
            </div>
            <div class="col text-end">
              <router-link
                style="display: inline-block; text-decoration: none"
                to="/planes"
                class=""
              >
                <button class="btn btn-creditos">Agregar Créditos</button>
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row justify-content-center mt-5" v-if="isLoading">
      <Spinner />
    </div>
    <div class="col-12 mt-3" v-else >
      <div class="card shadow card-transaciones">
        <div class="card-body">
          <div class="row">
            <div class="col-12">
              <h5 class="title">Transacciones</h5>
            </div>
            <div
              class="col-12 content-text"
              v-if="latestTransactions.length === 0"
            >
              <p class="text-disponible">No hay registro de transacciones.</p>
            </div>
            <div class="container-transacciones" v-else>
              <div
                class="col-12 mb-3"
                v-for="(transaction, index) in latestTransactions"
                :key="index"
              >
                <div class="row">
                  <div class="col imagen">
                    <img
                      :src="transaction.avatar"
                      alt=""
                      v-if="transaction.avatar"
                    />
                    <div v-else style="text-align:center; margin-top: calc(50% - 12px); text-align: center;">
                      <i class="fas fa-coins" style="color: #ffc209;"></i>
                    </div>
                  </div>
                  <div class="col m-auto">
                    <div class="row">
                      <div class="col detail">
                        <p class="detalle">
                          {{
                            transaction.type === 'DIVINOSALDO'
                              ? 'Compra Divino Saldo'
                              : 'Lectura ' + getRoom(transaction.tipoSala) + " con " + transaction.tarotReader
                          }}
                        </p>

                        <p class="fecha">
                          {{  transaction.type === 'DIVINOSALDO'
                            ? parsePaymentDate(transaction.fecha)
                            : parsePaymentDate(transaction.createdDate) }}
                        </p>

                        <p class="pago">
                          Pagado con {{ parseOrigin(transaction.origen) }}
                        </p>
                      </div>
                      <div class="col" v-if="transaction.type === 'LECTURA' && transaction.realDuration === null">
                        <button @click="atender(transaction.room, transaction.tipoSala)"
                                class="btn"
                                :class="{'button-green': tiempoParaLlegar(transaction.createdDate) >= -5, 'button-red': tiempoParaLlegar(transaction.createdDate) < -5}"
                                :disabled="(tiempoParaLlegar(transaction.createdDate) >= -5 && tiempoParaLlegar(transaction.createdDate) >= 0)|| tiempoParaLlegar(transaction.createdDate) < -5">
                          <i v-if="tiempoParaLlegar(transaction.createdDate) >= -5" :class="{
                                'fas fa-phone': tiempoParaLlegar(transaction.createdDate) >= -5 && transaction.tipoSala === 'VIDEO',
                                'fas fa-envelope': tiempoParaLlegar(transaction.createdDate) >= -5 && transaction.tipoSala === 'CHAT',
                                'fas fa-microphone': tiempoParaLlegar(transaction.createdDate) >= -5 && transaction.tipoSala === 'VOICE',
                                'fas fa-phone-slash': tiempoParaLlegar(transaction.createdDate) < -5
                            }"></i>
                          <i v-else :class="{
                                'fas fa-phone-slash': tiempoParaLlegar(transaction.createdDate) < -5 && transaction.tipoSala === 'VIDEO',
                                'fas fa-envelope-open': tiempoParaLlegar(transaction.createdDate) < -5 && transaction.tipoSala === 'CHAT',
                                'fas fa-microphone-slash': tiempoParaLlegar(transaction.createdDate) < -5 && transaction.tipoSala === 'VOICE',
                            }"></i> <!-- Icono de llamada finalizada --> <!-- Icono de llamada finalizada -->
                        </button>
                      </div>
                      <div class="col duracion">
                        <p class="precio">
                          ${{ numberWithCommas(transaction.monto) }}
                        </p>
                        <p class="tiempo">
                          {{ 
                             transaction.type === 'LECTURA'
                              ? transaction.duration + " min"
                              : transaction.duration
                             }}
                        </p>
                      </div>

                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axiosClient from '../../config/axiosClient';
import Spinner from '../loadingspinner/Spinner.vue';
import { enableLogs } from '../../config';

export default {
  data() {
    return {
      latestTransactions: [],
      isLoading: true,
    };
  },

  components: {
    Spinner
  },

  computed: {
    divinoSaldo() {
      return this.$store.state.balance;
    },
    jwt() {
      return this.$store.state.jwt;
    },
  },
  watch: {
    jwt: function() {
      this.getLatestTransactions();
    },
  },
  methods: {
    tiempoParaLlegar(fechaObjetivo) {

      // Obtener la fecha actual
      const fechaActual = new Date();
      
      // Agregar 3 horas (180 minutos) a la hora actual para tener en cuenta la diferencia GMT
      fechaActual.setMinutes(fechaActual.getMinutes());
      // Convertir la fecha objetivo en un objeto Date
      const fechaObjetivoDate = new Date(fechaObjetivo);
      fechaObjetivoDate.setMinutes(fechaObjetivoDate.getMinutes() + 240);

      // Restar 5 minutos a la fecha objetivo
      fechaObjetivoDate.setTime(fechaObjetivoDate.getTime() - 5 * 60 * 1000);
      // Calcular la diferencia en milisegundos
      const diferenciaEnMilisegundos = fechaObjetivoDate - fechaActual;

      // Convertir la diferencia en minutos
      let diferenciaEnMinutos = diferenciaEnMilisegundos / (60 * 1000);

      //console.log("fecha que sale para tratar boton en minutos", diferenciaEnMinutos)
        return diferenciaEnMinutos;
    },
    atender(roomId, canal){
      this.$router.push(this.getNextLink(roomId, canal));
    },
    getNextLink(roomId, canal) {
      let nextLink = ``;
      switch (canal) {
        case 'VIDEO':
          nextLink = `/sala-espera?roomId=${roomId}`;
          break;
        case 'CHAT':
          nextLink = `/chat?roomId=${roomId}`;
          break;
        case 'VOZ':
          nextLink = `/room-llamada?roomId=${roomId}`;
          break;
      }
      return nextLink;
    },
    changeKeys(items, newKey) {
      return items.reduce((newItems, item) => {
        if(item[newKey])
          newItems[item[newKey]] = item;
        return newItems;
      }, {});
    },

    async getLatestTransactions() {
      try {

        const response = await axiosClient.get( '/users/get-latest-transactions',
          { headers: { Authorization: `Bearer ${this.$store.state.jwt}` } });

        const roomInfo = await axiosClient.get('/users/get-latest-roomsInfo',
          {headers: { Authorization: `Bearer ${this.$store.state.jwt}` }, });


        const roomInfoByIdRoom = this.changeKeys(roomInfo.data.latestRooms, 'idRoom');

        const transactionsFormatted = response.data.latestTransactions.map(transaction => {

          const room = roomInfoByIdRoom[transaction.room];

          transaction = {
            ...transaction,
            ...room && { avatar: room.avatar },
            ...room && { tarotReader: room.tarotReader },
            ...room && { tipoSala: room.tiposala },
            ...room && { realDuration: room.realDuration },
            ...room && { createdDate: room.createdDate },
            ...room && { duration: this.getRoomDuration(room.createdDate, room.finishDate) === 0
                ? this.getRealDuration(room.realDuration)
                : this.getRoomDuration(room.createdDate, room.finishDate) }
          };

          return transaction;
        });

        this.latestTransactions = transactionsFormatted;

      } catch (error) {
        if(enableLogs) console.log(error);
      }
      this.isLoading = false;
    },
    getRoom(type) {

      const equalsTypes = {
        VOICE: 'de Audio',
        VIDEO: 'de Video',
        CHAT: ' de Chat'
      };

      return equalsTypes[type];
    },

    getRealDuration(realDuration) {
      return Math.ceil(realDuration / 60);
    },

    getRoomDuration(createdDate, endDate) {
      const initDate = new Date(createdDate);
      const finishDate = new Date(endDate);
      const difference = finishDate.getTime() - initDate.getTime();

      return difference / 60000;
    },

    parsePaymentDate(date) {
      const parsed = new Date(date);
      parsed.setMinutes(parsed.getMinutes() + 240);
      return parsed.toLocaleString().split('GMT')[0];
    },
    numberWithCommas(x) {
      x = x ? x : 0
      var a = x.toLocaleString('es-AR');
        if(a.indexOf(',') == -1){
            return x.toLocaleString('es-AR');
        }else{
          return a.substr(0, a.indexOf(','));
        }
    },
    parseOrigin(origin) {

      const equalsOrigin = {
        'divinosaldo': 'DivinoSaldo',
        'kushki-cc': 'Tarjeta de Crédito',
        'kushki-webpay': 'webpay',
        'kushki-transfer': 'Transferencia',
        'reserved-funds': 'Tarjeta de Crédito'
      }

      return equalsOrigin[origin] || origin;
    },
  },
  created() {
    if (this.$store.state.jwt) this.getLatestTransactions();
  },
};
</script>

<style scoped>
.button-red {
  background-color: #e61212; /* Cambia el color de fondo a rojo */
  font-weight: bold !important;
  text-transform: uppercase !important;
  font-size: 11px !important;
  padding: 6px 16px !important;
  font-family: maven-bold !important;
}
.button-green {
  background-color: #1bcc2c; /* Cambia el color de fondo a verde */
  font-weight: bold !important;
  text-transform: uppercase !important;
  font-size: 11px !important;
  padding: 6px 16px !important;
  font-family: maven-bold !important;
}
.card {
  border-radius: 10px;
}
.card-transaciones {
  height: calc(674.8px - 87px - 16px);
}
.title {
  font-family: maven-bold;
  color: #501682;
  font-size: 15px;
}
.wallet i {
  margin-right: 8px;
}
.wallet span {
  font-family: maven-bold;
}
.btn-creditos {
  background: #ffc209;
  color: #501682;
  font-family: maven-bold;
  font-size: 9px;
  text-transform: uppercase;
  padding: 6px 10px;
}
.imagen {
    max-width: 62px;
    max-height: 50px;
    padding-right: 0;
}
.imagen img {
  width: 100%;
  border-radius: 50%;
  height: 100%;
  object-fit: cover;
}

.descripcion {
  font-size: 12px;
  font-family: maven-regular;
}
.duracion {
  text-align: right;
  max-width: 150px;
}
.detalle,
.fecha,
.pago,
.precio,
.tiempo {
  font-family: maven-regular;
  font-size: 11px;
  margin-bottom: 0;
}
.detalle,
.precio {
  font-weight: bold;
}
.content-text {
  padding-top: 250px;
}
.text-disponible {
  font-family: maven-semibold;
  font-size: 13px;
  color: #707070;
  text-align: center;
}
.container-transacciones {
  max-height: 512px;
  overflow: auto;
}
/*color scroll*/
.container-transacciones {
  --scrollbarBG: #d1d1d1;
  --thumbBG: #333333;
}
.container-transacciones::-webkit-scrollbar {
  width: 11px;
}
.container-transacciones {
  scrollbar-width: thin;
  scrollbar-color: var(--thumbBG) var(--scrollbarBG);
}
.container-transacciones::-webkit-scrollbar-track {
  background: var(--scrollbarBG);
}
.container-transacciones::-webkit-scrollbar-thumb {
  background-color: var(--thumbBG);
  border-radius: 6px;
  border: 3px solid var(--scrollbarBG);
}
</style>
