<template>
  <div class="container">
    <div class="row mt-5">
        <h5 class="general-title ">Políticas de privacidad</h5>
      <div class="col-12 my-3">
          <Privacidad/>
      </div>
    </div>
  </div>
</template>

<script>
import Privacidad from "./Privacidad.vue";
export default {
  name: "Politicas",
  components: {
    Privacidad,
  },
};
</script>

<style scoped>


</style>
