<template>
    <div>
        <div class="row justify-content-center">
            <b-card class="col-7 shadow">
                <div class="row">
                    <h3 class="title">Restablecer mi contraseña</h3>
                    <form action="">
                        <div class="mb-3">
                            <label for="">Nueva contraseña</label>    
                            <div>
                                <i class="fas fa-lock iconoInput"></i>
                                <input type="password" class="form-control inputPadding" aria-label="Username" aria-describedby="basic-addon1">
                            </div>  
                        </div>
                        <div class="mb-3">
                            <label for="">Repetir contraseña</label>    
                            <div>
                                <i class="fas fa-lock iconoInput"></i>
                                <input type="password" class="form-control inputPadding" aria-label="Username" aria-describedby="basic-addon1">
                            </div>  
                        </div>
                        <div>
                            <button class="btn btn-yellow">Confirmar</button>
                        </div>
                    </form>
                </div>

            </b-card>
        </div>
    </div>
</template>
<script>
export default {
	name: 'Password',
};
</script>
<style scoped>

/deep/ .card{
    border-radius: 10px;
}
/deep/ .card-body{
    padding: 40px;
    
}
.title{
    font-family: maven-semibold;
    margin-bottom: 30px;
    color: #30075B;
}
label{
    color:#333333;
    font-family: maven-semibold;
    text-decoration: none;
    padding-bottom: 5px;
}
.btn-yellow{
    background: #ffc109bb;
    font-family: maven-semibold;
    text-transform: uppercase;
    color: #501682;
    padding:10px 50px;
    border-radius: 10px;
    margin-top:24px
}
.iconoInput {
    color: #30075B;
    font-size: 15px;
    position: absolute;
    left: 65px;
    padding-top: 15px;
    font-size: 20px;
}

.inputPadding {
    border: 1px solid #30075B;
    height: 50px;
    padding-left: 40px;
    font-family: maven-semibold;
    background: #efefef;
    border-radius: 10px;
}
.inputPadding:focus{
    color:#650BBF !important;
}
@media screen and (max-width: 1366px) {

    .title{
        font-size: 22px;
    }
    label, .link{
        font-size: 13px;
    }
    .iconoInput {
        font-size: 16px;
        padding-top: 12px;
    }

    .inputPadding {
        height: 40px;

    }
    .btn-yellow{
        padding:8px 40px;
        font-size: 13px;
    }
    .text-account{
        font-size: 15px;

    }
}
</style>