<template>
  <div>
    <div class="row justify-content-center mt-5" v-if="isLoading">
      <Spinner v-if="isLoading" />
    </div>
    <div class="card shadow" v-else>
      <div class="card-body">
        <div class="row">
          <div class="col">
            <h5>Tipo de lectura definida</h5>
            <div class="form-check">
              <input
                class="form-check-input"
                type="radio"
                name="flexRadioDefault"
                id="flexRadioDefault1"
                checked
                disabled
              />
              <label
                class="form-check-label label-lectura"
                for="flexRadioDefault1"
              >
               {{(tarotista.type === "agendada") ? "Lectura Agendada" : "Lectura Instantánea"}}
              </label>
            </div>
            <!-- <div class="form-check">
              <input
                class="form-check-input"
                type="radio"
                name="flexRadioDefault"
                id="flexRadioDefault2"
              />
              <label
                class="form-check-label label-lectura"
                for="flexRadioDefault2"
              >
                Lectura Agendada
              </label>
              
            </div> -->
          </div>
          <div class="col">
            <div class="col-img">
              <div class="image">
                <img :src="tarotista.avatar" alt="" />
              </div>
              <div class="button">
                <label class="btn btn-sm btn-grey">
                  <span>Subir foto</span>
                  <input
                    type="file"
                    class="input-file"
                    id="sf"
                    @change="upfile"
                  />
                </label>
              </div>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-12">
            <h5>Datos</h5>
          </div>
          <div class="col-md-6 col-12 mb-3">
            <div class="form-group">
              <label for="exampleInputEmail1">Apodos o Alias</label>
              <input
                type="text"
                class="form-control"
                v-model="tarotista.alias"
              />
            </div>
          </div>
          <div class="col-md-6 col-12 mb-3">
            <div class="form-group">
              <label for="exampleInputEmail1">Idiomas</label>

              <b-dropdown id="dropdown-1" text="Dropdown Button" class="">
                <template #button-content>
                  <span>Idioma</span>
                </template>
                <b-dropdown-group>
                  <div class="mx-3 mb-3">
                    <div
                      class="custom-control custom-checkbox"
                      v-for="language in allLanguages"
                      :key="language.idioma"
                    >
                      <input
                        type="checkbox"
                        class="custom-control-input"
                        :checked="checkTarotistaLanguages(language.idioma)"
                        v-model="idiomas"
                        :value="language.idioma"
                      />
                      <label class="custom-control-label label-margin">
                        {{ language.idioma }}
                      </label>
                    </div>
                  </div>
                </b-dropdown-group>
              </b-dropdown>
            </div>
          </div>
          <div class="col-md-6 col-12 mb-3">
            <div class="form-group">
              <label for="exampleInputEmail1">Especialidades</label>
              <b-dropdown id="dropdown-1" text="Dropdown Button" class="">
                <template #button-content>
                  <span>Especialidades</span>
                </template>
                <b-dropdown-group>
                  <div class="mx-3 mb-3">
                    <div
                      class="custom-control custom-checkbox"
                      v-for="specialty in allSpecialties"
                      :key="specialty.specialtyId"
                    >
                      <input
                        type="checkbox"
                        class="custom-control-input"
                        :checked="
                          checkTarotistaSpecialties(specialty.specialtyName)
                        "
                        v-model="especialidades"
                        :value="specialty.specialtyName"
                      />
                      <label class="custom-control-label label-margin">
                        {{ specialty.specialtyName }}
                      </label>
                    </div>
                  </div>
                </b-dropdown-group>
              </b-dropdown>
            </div>
          </div>
          <div class="col-md-6 col-12 mb-3">
            <div class="form-group">
              <label for="exampleInputEmail1">Ámbitos</label>
              <b-dropdown id="dropdown-1" text="Dropdown Button" class="">
                <template #button-content>
                  <span>Ámbitos</span>
                </template>
                <b-dropdown-group>
                  <div class="mx-3 mb-3">
                    <div
                      class="custom-control custom-checkbox"
                      v-for="scope in allScopes"
                      :key="scope.scopeId"
                    >
                      <input
                        type="checkbox"
                        class="custom-control-input"
                        :checked="checkTarotistaScopes(scope.scopeName)"
                        :value="scope.scopeName"
                        v-model="ambitos"
                      />
                      <label class="custom-control-label label-margin">
                        {{ scope.scopeName }}
                      </label>
                    </div>
                  </div>
                </b-dropdown-group>
              </b-dropdown>
            </div>
          </div>
          <div class="col-12 mb-3">
            <div class="form-group">
              <label for="exampleInputEmail1"
                >Mensaje personal que te refleje para los usuarios</label
              >
              <textarea
                class="form-control"
                name=""
                id=""
                cols="30"
                rows="1"
                style="resize: none"
                v-model="tarotista.description"
              ></textarea>
            </div>
          </div>
          <div class="col-12 mb-3">
            <div class="form-group">
              <label for="exampleInputEmail1"
                >Reseña profesional que resuma tus conocimientos, experiencia y
                trayectoria en el mundo místico del Tarot:</label
              >
              <textarea
                class="form-control"
                name=""
                id=""
                cols="30"
                rows="3"
                style="resize: none"
                v-model="tarotista.profReview"
              >
              </textarea>
            </div>
          </div>
          <div class="row mt-2">
            <div class="col-md-12 col-12 mb-2">
              <div class="form-group">
                <label for="exampleInputEmail1">Link</label>
                <input
                    type="text"
                    class="form-control"
                    v-model="tarotista.link"
                />
              </div>
            </div>
          </div>
          <div class="col-12 mb-3">
            <label for="" class="label-text">Canales de comunicación</label>
            <div class="form-check">
              <input
                class="form-check-input position-static"
                type="checkbox"
                id="blankCheckbox"
                value="Video"
                aria-label="..."
                v-model="allCanales"
                :checked="allCanales.includes('Video')"
              />
              <label class="form-check-label" for="gridCheck">
                Videollamadas
              </label>
            </div>
            <div class="form-check">
              <input
                class="form-check-input position-static"
                type="checkbox"
                id="blankCheckbox"
                value="Voz"
                aria-label="..."
                v-model="allCanales"
                :checked="allCanales.includes('Voz')"
              />
              <label class="form-check-label" for="gridCheck">
                Llamadas de Audio
              </label>
            </div>
            <div class="form-check">
              <input
                class="form-check-input position-static"
                type="checkbox"
                id="blankCheckbox"
                value="Chat"
                aria-label="..."
                v-model="allCanales"
                :checked="allCanales.includes('Chat')"
              />
              <label class="form-check-label" for="gridCheck">
                Chat de texto
              </label>
            </div>
          </div>
          <div class="col-12 mb-3">
            <label for="" class="label-text">Tarifas</label>
            <div class="row mt-2">
              <div class="col">
                <span>Videollamada</span>
                <div class="form-check">
                  <input
                    class="form-check-input videollamada"
                    type="radio"
                    name="videollamada"
                    id="v-1"
                    :value="
                      prices.highPriceFactor *
                        prices.basePrice *
                        prices.videoPriceFactor
                    "
                    v-model="videollamada"
                    :disabled="!allCanales.includes('Video')"
                    v-on:click="()=>changeValue(0)"
                  />
                  <label class="form-check-label" for="gridCheck">
                    ${{
                      prices.highPriceFactor *
                        prices.basePrice *
                        prices.videoPriceFactor
                    }}/min <span></span
                  ></label>
                </div>
                <div class="form-check">
                  <input
                    class="form-check-input videollamada"
                    type="radio"
                    name="videollamada"
                    id="exampleRadios1"
                    :value="
                      prices.mediumPriceFactor *
                        prices.basePrice *
                        prices.videoPriceFactor
                    "
                    v-model="videollamada"
                    :disabled="!allCanales.includes('Video')"
                    v-on:click="()=>changeValue(1)"
                  />
                  <label class="form-check-label" for="gridCheck">
                    ${{
                      prices.mediumPriceFactor *
                        prices.basePrice *
                        prices.videoPriceFactor
                    }}/min <span></span
                  ></label>
                </div>
                <div class="form-check">
                  <input
                    class="form-check-input videollamada"
                    type="radio"
                    name="videollamada"
                    id="exampleRadios1"
                    :value="
                      prices.lowPriceFactor *
                        prices.basePrice *
                        prices.videoPriceFactor
                    "
                    v-model="videollamada"
                    :disabled="!allCanales.includes('Video')"
                    v-on:click="()=>changeValue(2)"
                  />
                  <label class="form-check-label" for="gridCheck">
                    ${{
                      Math.round(
                      prices.lowPriceFactor *
                        prices.basePrice *
                        prices.videoPriceFactor)
                    }}/min <span></span
                  ></label>
                </div>
              </div>
              <div class="col">
                <span>Llamadas de Audio</span>
                <div class="form-check">
                  <input
                    class="form-check-input llamada"
                    type="radio"
                    name="llamada"
                    id="exampleRadios1"
                    :value="
                      prices.highPriceFactor *
                        prices.basePrice *
                        prices.voicePriceFactor
                    "
                    v-model="llamada"
                    :disabled="!allCanales.includes('Voz')"
                    v-on:click="()=>changeValue(0)"
                  />
                  <label class="form-check-label" for="gridCheck">
                    ${{
                      Math.round(
                      prices.highPriceFactor *
                        prices.basePrice *
                        prices.voicePriceFactor)
                    }}/min <span></span
                  ></label>
                </div>
                <div class="form-check">
                  <input
                    class="form-check-input llamada"
                    type="radio"
                    name="llamada"
                    id="exampleRadios1"
                    :value="
                      prices.mediumPriceFactor *
                        prices.basePrice *
                        prices.voicePriceFactor
                    "
                    v-model="llamada"
                    :disabled="!allCanales.includes('Voz')"
                    v-on:click="()=>changeValue(1)"
                  />
                  <label class="form-check-label" for="gridCheck">
                    ${{
                      Math.round(
                      prices.mediumPriceFactor *
                        prices.basePrice *
                        prices.voicePriceFactor)
                    }}/min <span></span
                  ></label>
                </div>
                <div class="form-check">
                  <input
                    class="form-check-input llamada"
                    type="radio"
                    name="llamada"
                    id="exampleRadios1"
                    :value="
                      prices.lowPriceFactor *
                        prices.basePrice *
                        prices.voicePriceFactor
                    "
                    v-model="llamada"
                    :disabled="!allCanales.includes('Voz')"
                    v-on:click="()=>changeValue(2)"
                  />
                  <label class="form-check-label" for="gridCheck">
                    ${{
                      prices.lowPriceFactor *
                        prices.basePrice *
                        prices.voicePriceFactor
                    }}/min <span></span
                  ></label>
                </div>
              </div>
              <div class="col">
                <span>Chat de Texto</span>
                <div class="form-check">
                  <input
                    class="form-check-input chat"
                    type="radio"
                    name="chat"
                    id="exampleRadios1"
                    :value="
                      prices.highPriceFactor *
                        prices.basePrice *
                        prices.chatPriceFactor
                    "
                    v-model="chat"
                    :disabled="!allCanales.includes('Chat')"
                    v-on:click="()=>changeValue(0)"
                  />
                  <label class="form-check-label" for="gridCheck">
                    ${{
                      prices.highPriceFactor *
                        prices.basePrice *
                        prices.chatPriceFactor
                    }}/min <span></span
                  ></label>
                </div>
                <div class="form-check">
                  <input
                    class="form-check-input chat"
                    type="radio"
                    name="chat"
                    id="exampleRadios1"
                    :value="
                      prices.mediumPriceFactor *
                        prices.basePrice *
                        prices.chatPriceFactor
                    "
                    v-model="chat"
                    :disabled="!allCanales.includes('Chat')"
                    v-on:click="()=>changeValue(1)"
                  />
                  <label class="form-check-label" for="gridCheck">
                    ${{
                      prices.mediumPriceFactor *
                        prices.basePrice *
                        prices.chatPriceFactor
                    }}/min <span></span
                  ></label>
                </div>
                <div class="form-check">
                  <input
                    class="form-check-input chat"
                    type="radio"
                    name="chat"
                    id="exampleRadios1"
                    :value="
                      prices.lowPriceFactor *
                        prices.basePrice *
                        prices.chatPriceFactor
                    "
                    v-model="chat"
                    :disabled="!allCanales.includes('Chat')"
                    v-on:click="()=>changeValue(2)"
                  />
                  <label class="form-check-label" for="gridCheck">
                    ${{
                      prices.lowPriceFactor *
                        prices.basePrice *
                        prices.chatPriceFactor
                    }}/min <span></span
                  ></label>
                </div>
              </div>
            </div>
          </div>
          <div id="datos-bancarios" class="col-12">
            <label for="" class="label-text"
              >Datos Bancarios para recaudación</label
            >
            <div class="row mt-2">
              <div class="col-md-6 col-12 mb-3">
                <div class="form-group">
                  <label for="exampleInputEmail1">Nombre de Banco</label>
                  <input
                    type="text"
                    class="form-control"
                    v-model="tarotista.bankName"
                  />
                </div>
              </div>

              <div class="col-md-6 col-12 mb-3">
                <div class="form-group">
                  <label>Número de cuenta</label>
                  <input
                    type="number"
                    class="form-control"
                    v-model="tarotista.accountNumber"
                  />
                </div>
              </div>

              <div class="col-md-6 col-12 mb-3">
                <div class="form-group">
                  <label>Tipo de Cuenta</label>
                  <input
                    type="text"
                    class="form-control"
                    v-model="tarotista.typeAccount"
                  />
                </div>
              </div>
              <div class="col-md-6 col-12 mb-3">
                <div class="form-group">
                  <label>RUT</label>
                  <input
                    type="text"
                    class="form-control"
                    v-model="tarotista.rut"
                  />
                </div>
              </div>
            </div>
          </div>

          <div class="mt-3" style="text-align: right">
            <button class="btn button-yellow" @click="updateTarotReaderInfo">
              {{ isLoading ? 'Actualizando...' : 'Actualizar' }}
            </button>
          </div>
        </div>
      </div>
    </div>
    <ModalDatosCorregidos/>
    <ModalAvisoBancario/>
  </div>
</template>
<script>
import axiosClient from '../../../config/axiosClient';
import Spinner from '../../loadingspinner/Spinner.vue';
import { enableLogs } from '../../../config';
import ModalDatosCorregidos from "@/components/DashboardTarotista/Modal/ModalDatosCorregidos.vue";
import ModalAvisoBancario from "@/components/DashboardTarotista/Modal/ModalAvisoBancario.vue";
export default {
  methods: {
     changeValue(n){
      let video = document.getElementsByName('videollamada')
      let audio = document.getElementsByName('llamada')
      let chat = document.getElementsByName('chat')
      //audio[n].checked = true; video[n].checked = true; chat[n].checked = true;
      
      this.chat =  chat[n].value;
      this.videollamada = video[n].value;
      this.llamada = audio[n].value;
    },
    disableRadioButtons() {
      const llamadas = document.querySelectorAll('.llamada');
      llamadas.forEach(column => column.setAttribute('disabled', true));
      const videollamadas = document.querySelectorAll('.videollamada');
      videollamadas.forEach(column => column.setAttribute('disabled', true));
      const chats = document.querySelectorAll('.chat');
      chats.forEach(column => column.setAttribute('disabled', true));
    },
    async fetchLanguages() {
      try {
        const response = await axiosClient.get('/languages/get-all-languages');
        this.allLanguages = response.data.languages;
        if(enableLogs) console.log(this.allLanguages);
      } catch (error) {
        if(enableLogs) console.log(error);
      }
    },

    async fetchSpecialties() {
      try {
        const response = await axiosClient.get(
          '/specialties/get-all-specialties'
        );

        this.allSpecialties = response.data.specialties;
       if(enableLogs) console.log(this.allSpecialties);
      } catch (error) {
       if(enableLogs) console.log(error);
      }
    },
    async fetchScopes() {
      try {
        const response = await axiosClient.get('/scopes/get-all-scopes');
        this.allScopes = response.data.scopes;
      } catch (error) {
       if(enableLogs) console.log(error);
      }
    },
    async getTarotReaderInfo() {
      try {
        const response = await axiosClient.get(
          '/tarot-reader/get-full-information/',
          {
            headers: {
              Authorization: `Bearer ${this.$store.state.jwt}`,
            },
          }
        );
       if(enableLogs) console.log(response.data.tarotReaderInfo);
        this.tarotista = response.data.tarotReaderInfo;
        this.specialties = this.tarotista.specialties
          ? this.tarotista.specialties
          : this.specialties;
        this.scopes = this.tarotista.scopes
          ? this.tarotista.scopes
          : this.scopes;
        this.languages = this.tarotista.languages
          ? this.tarotista.languages
          : this.languages;

       if(enableLogs) console.log('@aaaaaaaa, ', this.tarotista.scopes);

        this.languages.forEach(idioma => {
          this.idiomas.push(idioma.idioma);
        });

        this.scopes.forEach(amb => {
          this.ambitos.push(amb.scopeName);
        });

        this.specialties.forEach(spec => {
          this.especialidades.push(spec.specialtyName);
        });
        let generalValue = 0;
        if (response.data.tarotReaderInfo.chatPrice) generalValue = response.data.tarotReaderInfo.chatPrice;
        if (response.data.tarotReaderInfo.videoPrice) generalValue = response.data.tarotReaderInfo.videoPrice;
        if (response.data.tarotReaderInfo.voicePrice) generalValue = response.data.tarotReaderInfo.voicePrice;

        if (response.data.tarotReaderInfo.chatPrice) {
          this.allCanales.push('Chat');
          this.chat = response.data.tarotReaderInfo.chatPrice;
        }else{
          this.chat = generalValue
        }
        if (response.data.tarotReaderInfo.videoPrice) {
          this.allCanales.push('Video');
          this.videollamada = response.data.tarotReaderInfo.videoPrice;
        }else{
          this.videollamada = generalValue;
        }
        if (response.data.tarotReaderInfo.voicePrice) {
          this.allCanales.push('Voz');
          this.llamada = response.data.tarotReaderInfo.voicePrice;
        }else{
          this.llamada = generalValue;
        }
       if(enableLogs) console.log(this.tarotista);
        this.isLoading = false;
       if(enableLogs) console.log('successfully fetched tarot Reader Info');
      } catch (error) {
       if(enableLogs) console.log(error);
       if(enableLogs) console.log('could not fetch tarotReader Info');
      }
    },
    async upfile() {
      var nameFile = document.getElementById('sf').files[0].name;
      if (
        nameFile.toLowerCase().substr(nameFile.length - 4)  == '.png' ||
        nameFile.toLowerCase().substr(nameFile.length - 4)  == '.jpg' ||
        nameFile.toLowerCase().substr(nameFile.length - 5)  == '.jpeg'
      ) {
        var formdata = new FormData();
        formdata.append('archivo', document.getElementById('sf').files[0]);

        const response = await axiosClient.post('/users/photo/', formdata, {
          headers: {
            Authorization: `Bearer ${this.$store.state.jwt}`,
          },
        });

       if(enableLogs) console.log(response);

        if (response.data.code == 2) {
          alert('archivo se subio exitosamente');
         if(enableLogs) console.log(response.data.path);
          this.tarotista.avatar = response.data.path;
        } else {
          alert('Fallo la subida del archivo');
        }
      } else {
        alert('El archivo debe ser .png, .jpg o .jpeg');
      }
    },
    async updateTarotReaderInfo() {
      try {
        this.isLoading = true;
        const response = await axiosClient.post(
          '/tarot-reader/update-data',
          {
            scopes: this.ambitos,
            specialties: this.especialidades,
            languages: this.idiomas,
            channels: this.allCanales,
            chatPrice: this.parseChatPriceToExperience(this.chat),
            videoPrice: this.parseVideoPriceToExperience(this.videollamada),
            voicePrice: this.parseVoicePriceToExperience(this.llamada),
            tarotReaderId: this.tarotista.tarotReaderId,
            professionalReview: this.tarotista.profReview,
            description: this.tarotista.description,
            alias: this.tarotista.alias,
            link: this.tarotista.link,
            bankInformation: {
              bankName: this.tarotista.bankName,
              bankAccountNumber: this.tarotista.accountNumber,
              bankTypeAccount: this.tarotista.typeAccount,
              bankRut: this.tarotista.rut
            },
          },
          {
            headers: {
              Authorization: `Bearer ${this.$store.state.jwt}`,
            },
          }
        );
        if (response.data.code === 2) {
          this.isLoading = false;
          this.$bvModal.show('modalDatosCorregidos');
          setTimeout(() => {
            this.$router.push('/dashboard-tarotista');
          }, 3000);
        }
      } catch (error) {
        this.isLoading = false;
       if(enableLogs) console.log(
          'Could not update tarot reader information, please try again later',
          error
        );
      }
    },
    async getAllPrices() {
      try {
        const {
          data: { prices },
        } = await axiosClient.get('/prices/get-prices');
        const {
          basePrice,
          chatPriceFactor,
          highPriceFactor,
          lowPriceFactor,
          mediumPriceFactor,
          videoPriceFactor,
          voicePriceFactor,
        } = prices;

        this.prices.basePrice = basePrice;
        this.prices.chatPriceFactor = chatPriceFactor;
        this.prices.highPriceFactor = highPriceFactor;
        this.prices.lowPriceFactor = lowPriceFactor;
        this.prices.mediumPriceFactor = mediumPriceFactor;
        this.prices.videoPriceFactor = videoPriceFactor;
        this.prices.voicePriceFactor = voicePriceFactor;
      } catch (error) {
       if(enableLogs) console.log(error);
      }
    },
    checkTarotistaLanguages(language) {
      const isIncluded = this.languages.some(idiom =>
        idiom.idioma.includes(language)
      );

      return isIncluded;
    },

    checkTarotistaScopes(ambito) {
      const isIncluded = this.scopes.some(amb =>
        amb.scopeName.includes(ambito)
      );

      return isIncluded;
    },

    checkTarotistaSpecialties(specialtyName) {
      const isIncluded = this.specialties.some(especialidad =>
        especialidad.specialtyName.includes(specialtyName)
      );

      return isIncluded;
    },
    parseChatPriceToExperience(price) {
      price = parseInt(price).toFixed(0);
      switch (price) {
        case (this.prices.highPriceFactor * this.prices.basePrice * this.prices.chatPriceFactor).toFixed(0):
          return 'ALTO';
        case (this.prices.mediumPriceFactor * this.prices.basePrice * this.prices.chatPriceFactor).toFixed(0):
          return 'MEDIO';
        case (this.prices.lowPriceFactor * this.prices.basePrice * this.prices.chatPriceFactor).toFixed(0):
          return 'BAJO';
      }
    },

    parseVideoPriceToExperience(price) {
      price = parseInt(price).toFixed(0);
      switch (price) {
        case (this.prices.highPriceFactor * this.prices.basePrice * this.prices.videoPriceFactor).toFixed(0):
          return 'ALTO';
        case (this.prices.mediumPriceFactor * this.prices.basePrice * this.prices.videoPriceFactor).toFixed(0):
          return 'MEDIO';
        case (this.prices.lowPriceFactor * this.prices.basePrice * this.prices.videoPriceFactor).toFixed(0):
          return 'BAJO';
      }
    },

    parseVoicePriceToExperience(price) {
      price = parseInt(price).toFixed(0);
      switch (price) {
        case (this.prices.highPriceFactor * this.prices.basePrice * this.prices.voicePriceFactor).toFixed(0):
          return 'ALTO';
        case (this.prices.mediumPriceFactor * this.prices.basePrice * this.prices.voicePriceFactor).toFixed(0):
          return 'MEDIO';
        case (this.prices.lowPriceFactor * this.prices.basePrice * this.prices.voicePriceFactor).toFixed(0):
          return 'BAJO';
      }
    },
  },
  mounted() {
    if (this.$route.hash.replace('#', '') === 'datos-bancarios'){
      this.$bvModal.show('modalAvisoBancario');
    }
    this.disableRadioButtons();
    this.getTarotReaderInfo();
    this.fetchLanguages();
    this.fetchScopes();
    this.fetchSpecialties();
    this.getAllPrices();
  },
/*  watch: {
    currentHash: function(newVal) {
      this.scrollToElement(newVal);
    },
  },*/
  data: function() {
    return {
      currentHash: '',
      canales: [],
      personalMessage: null,
      professionalReview: null,
      tarotista: null,
      languages: [],
      scopes: [],
      specialties: [],
      isLoading: true,
      allLanguages: [],
      allScopes: [],
      allSpecialties: [],
      ambitos: [],
      especialidades: [],
      idiomas: [],
      llamada: null,
      chat: null,
      videollamada: null,
      allCanales: [],
      prices: {
        basePrice: null,
        chatPriceFactor: null,
        highPriceFactor: null,
        lowPriceFactor: null,
        mediumPriceFactor: null,
        videoPriceFactor: null,
        voicePriceFactor: null,
      },
    };
  },
  components: {
    Spinner,
    ModalDatosCorregidos,
    ModalAvisoBancario,
  },
};
</script>
<style scoped>
  .card-body {
    font-family: maven-semibold;
    padding: 30px 50px;
    font-size: 15px;
    color: #333333;
  }
  h5 {
    font-family: maven-bold;
    font-size: 17px;
    color: #333333;
    margin-top: 15px;
  }
  .button-h {
    margin-top: 15px;
  }
  .col-img {
    max-width: 150px;
    padding-right: 0;
    margin: auto;
  }
   .image{
    width: 150px;
    height: 150px;
  }
  .image img {
    width: 100%;
    border-radius: 50%;
    height: 100%;
    object-fit: cover;
  }
  .button {
    margin-top: 15px;
    text-align: center;
  }
  .btn-grey {
    background-color: #f6f6f6;
    border: 1px solid #501682;
    color: #501682;
    text-transform: uppercase;
    font-size: 10px;
  }
  .btn-grey:hover {
    background-color: #dadada;
   
  }
  .form-check {
    margin-top: 10px;
  }
  .form-check-input:checked {
    border-color: #ffc109;
    background-color: #ffc109;
  }
  label {
    color: #707070;
  }
  .label-lectura {
    font-size: 14px;
  }
  select,
  textarea {
    font-size: 14px;
    color: #707070;
  }

.input-file {
  width: 0 !important;
  height: 0 !important;
  overflow: hidden;
  opacity: 0;
}
.input-submit {
  width: 0 !important;
  height: 0 !important;
  overflow: hidden;
  opacity: 0;
}

/deep/ .b-dropdown {
  width: 100%;
  margin: 0;
}

/deep/ .btn-secondary {
  background-color: white;
  display: block;
  width: 100%;
  padding: 0.375rem 2.25rem 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  color: #707070;
  text-align: left;
}

/deep/.btn-check:checked + .btn-secondary,
.btn-check:active + .btn-secondary,
.btn-secondary:active,
.btn-secondary.active,
.show > .btn-secondary.dropdown-toggle {
  color: #707070 !important;
  background-color: #fff;
}
/deep/ .dropdown-menu {
  width: 100%;
}
.label-margin {
  margin-left: 10px;
}
</style>
