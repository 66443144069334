<template>
  <!-- Modal Fin Llamada -->
  <div>
    <b-modal
      :no-close-on-esc="false"
      :no-close-on-backdrop="false"
      v-model="showModal"
      id="modal-llamada-aviso"
      size="lg"
      centered
      :visible="endCall"
      hide-footer
    >
      <div class="row">
        <div class="col-12 mb-2">
          <p class="text-modal">
            ¡Alguien te esta por llamar! No te vayas<br/><br/>
          </p>
        </div>
      </div>

      <template
        #modal-footer="{
          /*hide*/
        }"
      >
      </template>
    </b-modal>
  </div>
</template>
<script>
  export default {
    watch: {
      triggerModal: function (value) {
        if (value) this.showModal = true;
      },
      forceClose: function(value) {
      if (value) this.showModal = false;
      },
    },
    /* props: {
      triggerModal: Boolean,
/*       endCall: Boolean,
    },*/
    props: ["triggerModal", "forceClose"],
    data: () => {
      return {
        showModal: false,
      };
    },
    methods:{
      hideModal() {
        this.showModal = false;
      },
    }
  };
</script>

<style scoped>
  /deep/.close {
    background: transparent;
    border: none;
  }
  /deep/.modal-header {
    border-bottom: none;
  }

  /deep/.modal-body {
    padding: 0 50px;
    font-family: maven-regular;
  }
  .text-modal {
    color: #333;
    font-family: maven-bold;
    text-align: center;
    text-transform: uppercase;
  }
  .text-saldo {
    text-align: center;
    font-family: maven-regular;
    font-size: 20px;
  }
  .text-saldo span {
    font-family: maven-bold;
    color: #3b5998;
  }
  /deep/.modal-footer {
    border-top: none;
    padding: 10px 50px 30px 50px;
    display: block;
  }
  /deep/.btn-yellow {
    background-color: #ffc209;
    color: #501682;
    font-family: maven-bold;
    text-transform: uppercase;
    border: none;
    padding: 6px 20px;
  }
  /deep/.btn-line {
    border: 1px solid #501682;
    background-color: transparent;
    color: #501682;
    font-family: maven-bold;
    text-transform: uppercase;
    padding: 6px 20px;
  }

  .text-sutitulo {
    font-size: 13px;
  }
</style>
