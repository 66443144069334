<template>
  <div class="container">
    <div class="row justify-content-center mt-5">
      <b-card class="col-7 shadow" v-if="!isLoading && success">
        <h3 class="title">
          Tu contraseña se ha establecido correctamente. Te estamos redirigiendo
          a la pantalla de Inicio
        </h3>
      </b-card>
      <b-card class="col-7 shadow" v-if="!isLoading && !success">
        <div class="row">
          <div v-if="!isLoading && !success">
            <h3 class="title">Establece tu contraseña</h3>
            <div class="mb-3 container-pass">
              <label for="">Nueva contraseña</label>
              <div>
                <i class="fas fa-lock iconoInput"></i>
                 <i class="icon-eye" :class="iconPass" @click="mostrarPassword" id="icon"></i>

                <input
                  class="form-control inputPadding"
                  aria-label="Username"
                  aria-describedby="basic-addon1"
                  v-model="password"
                  autocomplete="off"
                  @click="initvar()"
                  :type="passwordFieldType" 
                />
                <p
                  class="alert"
                  v-if="validarPass(password.toString()) && !noinit"
                >
                  La contraseña debe contener al menos 8 caracteres
                  (letras, números y carácter especial).
                </p>
              </div>
            </div>
            <div class="mb-3 container-pass">
              <label for="">Repetir contraseña</label>
              <div>
                <i class="fas fa-lock iconoInput"></i>
                 <i class="icon-eye" :class="iconPassTwo" @click="mostrarRepeatPassword" id="icon"></i>
                <input
                  class="form-control inputPadding"
                  aria-label="Username"
                  aria-describedby="basic-addon1"
                  v-model="confirmPassword"
                  @click="initvar()"
                  autocomplete="off"
                  :type="passwordFieldTypeTwo" 
                />
                <p class="alert" v-if="checkPasswordsEqual()">
                  Las contraseñas no coinciden.
                </p>
              </div>
            </div>
          </div>
          <div>
            <button
              class="btn btn-yellow"
              :disabled="validFormulario === false"
              @click="setNewPassword"
            >
              Confirmar
            </button>
          </div>
        </div>
      </b-card>
    </div>
  </div>
</template>
<script>
import axiosClient from '../../config/axiosClient';
import { enableLogs } from '../../config';

export default {
  name: 'SetPassword',
  data() {
    return {
      password: '',
      confirmPassword: '',
      isLoading: false,
      success: false,
      passwordsNotEqual: false,
      noinit: true,
       passwordFieldType: "password",
      passwordFieldTypeTwo: "password",
      iconPass: "fas fa-eye",
      iconPassTwo: "fas fa-eye"
    };
  },
  computed: {
    validFormulario() {
      let validForm = true;
      this.validarPass(this.password) ? (validForm = false) : null;
      this.checkPasswordsEqual() ? (validForm = false) : null;
      return validForm;
    },
  },
  mounted() {
    if (
      !window.getParameterByName('hashPass') ||
      !window.getParameterByName('userId')
    ) {
      this.$router.push('/');
    }
  },
  methods: {
    initvar() {
      this.noinit = false;
    },
    async setNewPassword() {
     if(enableLogs) console.log('INICION DE PROCESO');
      this.isLoading = true;
      const hashPass = window.getParameterByName('hashPass');
      const userId = window.getParameterByName('userId');
      if (!this.checkPasswordsEqual()) {
        try {
          const response = await axiosClient.post(
            '/inscriptions/set-password',
            {
              userId,
              password: this.password,
              hashTempPass: hashPass,
            }
          );
         if(enableLogs) console.log(response);
         if(enableLogs) console.log('successfully set new password');
          this.isLoading = false;
          this.success = true;
          setTimeout(() => {
            this.$router.push('/');
          }, 3000);
        } catch (error) {
         if(enableLogs) console.log('could not set new password');
         if(enableLogs) console.log(error);
          this.isLoading = false;
          this.success = false;
        }
      } else {
        return true;
      }
    },
    checkPasswordsEqual() {
      if (this.confirmPassword === this.password) {
        return false;
      } else {
        return true;
      }
    },
    validarPass(valor) {
      if (valor.length > 0) {
        // Expresión regular que permite letras, números y caracteres especiales, con al menos 8 caracteres
        return !/^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d@$!%*?&^#(){}[\]/\\+=\-.,<>;:`~_]{8,}$/.test(valor);
      } else {
        return false;
      }
    },
    mostrarPassword(){
      this.passwordFieldType = this.passwordFieldType === "password" ? "text" : "password";
      this.iconPass = this.iconPass === 'fas fa-eye' ? 'fas fa-eye-slash' : 'fas fa-eye'	
    },
    mostrarRepeatPassword(){
        this.passwordFieldTypeTwo = this.passwordFieldTypeTwo === "password" ? "text" : "password";
        this.iconPassTwo = this.iconPassTwo === 'fas fa-eye' ? 'fas fa-eye-slash' : 'fas fa-eye'	
    },

  },
};
</script>
<style scoped>
/deep/ .card {
  border-radius: 10px;
}
/deep/ .card-body {
  padding: 40px;
}
.title {
  font-family: maven-semibold;
  margin-bottom: 10px;
  color: #30075b;
  font-size: 22px;
}
label {
  color: #333333;
  font-family: maven-semibold;
  text-decoration: none;
  padding-bottom: 5px;
  font-size: 13px;
}
.btn-yellow {
  background: #ffc109bb;
  font-family: maven-semibold;
  text-transform: uppercase;
  color: #501682;
  border-radius: 10px;
  margin-top: 24px;
  padding: 8px 40px;
  font-size: 13px;
}
.btn-yellow:hover {
  background: #dba501;
  color: #650bbf;
}

.container-pass, .container-email, .container-name{
  position: relative;
}
.iconoInput {
  color: #30075b;
  font-size: 15px;
  position: absolute;
  left: 18px;
  font-size: 16px;
  padding-top: 12px;
}
.icon-eye{
  color: #30075b;
  font-size: 15px;
  position: absolute;
  right: 15px;
  font-size: 16px;
  padding-top: 12px;
  cursor: pointer;
}
.inputPadding {
  border: 1px solid #30075b;
  padding-left: 40px;
  font-family: maven-semibold;
  background: #efefef;
  border-radius: 10px;
  height: 40px;
}
.inputPadding:focus {
  color: #650bbf !important;
}

.alert {
  color: red;
  padding: 0px;
  font-size: 11px;
  font-family: maven-medium;
  margin-bottom: 0px;
}
/* @media screen and (max-width: 1366px) {
  .title {
    font-family: maven-semibold;
    margin-bottom: 10px;
    color: #30075b;
    font-size: 22px;
  }
  label {
    color: #333333;
    font-family: maven-semibold;
    text-decoration: none;
    padding-bottom: 5px;
    font-size: 13px;
  }
  .btn-yellow {
    background: #ffc109bb;
    font-family: maven-semibold;
    text-transform: uppercase;
    color: #501682;
    border-radius: 10px;
    margin-top: 24px;
    padding: 8px 40px;
    font-size: 13px;
  }
  .btn-yellow:hover {
    background: #dba501;
    color: #650bbf;
  }

  .iconoInput {
    color: #30075b;
    font-size: 15px;
    position: absolute;
    left: 65px;
    font-size: 16px;
    padding-top: 12px;
  }

  .inputPadding {
    border: 1px solid #30075b;
    padding-left: 40px;
    font-family: maven-semibold;
    background: #efefef;
    border-radius: 10px;
    height: 40px;
  }
  .btn-yellow {
    padding: 8px 40px;
    font-size: 13px;
  }

  .alert {
    color: red;
    padding: 0px;
    font-size: 11px;
    font-family: maven-medium;
    margin-bottom: 0px;
  }
  /* @media screen and (max-width: 1366px) {
  .title {
    font-size: 22px;
  }
  label,
  .link {
    font-size: 13px;
  }
  .iconoInput {
    font-size: 16px;
    padding-top: 12px;
  }

  .inputPadding {
    height: 40px;
  }
  .btn-yellow {
    padding: 8px 40px;
    font-size: 13px;
  }
  .text-account {
    font-size: 15px;
  }
} */
</style>
