<template>
  <div>
    <!--agendada-->
    <div class="card card-pagos" >
      <div class="conteinder-header">
        <img
          class="card-img-top"
          src="../../assets/img/compra/pic.png"
          alt="Card image cap"
        />
        <div class="gradient"></div>

        <div class="header-name">
          <div>
            <img
              class="star-one"
              src="../../assets/img/divinoBolsa/star.png"
              alt=""
            />
            <i v-if="tipo === 'agendada'" class="fas fa-calendar-alt"></i>
            <i v-else class="fas fa-bolt"></i>
            <span>Lectura de tarot</span>
          </div>
          <div class="col-img">
            <div class="image">
              <img :src="tarotista.avatar" alt="" />
            </div>
          </div>
          <div>
            <p class="name-tarotista">{{ tarotista.alias }}</p>
          </div>

          <div>
            <i class="fas fa-comments" v-if="canal=='Chat'"></i>
            <i class="fas fa-phone-alt" v-if="canal=='Voz'"></i>
            <i class="fas fa-video" v-if="canal=='Video'"></i>
            <span class="canal">{{ canal == 'Voz' ? 'Audio' : canal }}</span>
          </div>
        </div>
      </div>
      <div class="card-body">
        <div class="detalle-agendada">
          <div class="card card-form-input mb-2">
            <div class="body-card" @click="quinceMin">
              <div class="form-check">
                <input
                  class="form-check-input pagos-check-input"
                  type="radio"
                  name="radio-time"
                  id="quince"
                  v-model="minutos"
                  :value="15"
                />
                <label class="form-check-label label-card-pago" for="quince">
                  15 min
                </label>
              </div>
            </div>
          </div>
          <div class="card card-form-input mb-2">
            <div class="body-card" @click="treintaMin">
              <div class="form-check">
                <input
                  class="form-check-input pagos-check-input"
                  type="radio"
                  name="radio-time"
                  id="treinta"
                  v-model="minutos"
                  :value="30"
                />
                <label class="form-check-label label-card-pago"  for="treinta">
                  30 min
                </label>
              </div>
            </div>
          </div>
          <div class="card card-form-input mb-2">
            <div class="body-card">
              <div class="form-check" @click="sesentaMin">
                <input
                  class="form-check-input pagos-check-input"
                  type="radio"
                  name="radio-time"
                  id="sesenta"
                  v-model="minutos"
                  :value="60"
                />
                <label class="form-check-label label-card-pago"  for="sesenta">
                  60 min
                </label>
              </div>
            </div>
          </div>
<!--          <div class="card card-form-input mb-2" >
            <div class="body-card">
              <div class="form-check">
                <input
                  class="form-check-input pagos-check-input"
                  type="radio"
                  name="radio-time"
                  id="libre"
                  v-model="minutos"
                  :value="0"
                  :disabled="!isLoggedIn && mediosPago['credito'] == 0"
                />
                <label class="form-check-label label-card-pago"  for="libre">
                  Libre
                </label>
              </div>
            </div>
          </div>-->
        </div>
        <div class="total">
          <div class="row">
            <div class="col">
              <p class="text-total">Total tarifa</p>
            </div>
            <div class="col" style="text-align: end">

              <div v-if="discount != null || !pendingNeedDiscount">
                <span v-if="discount === 0 || this.discount == null" :class="existeDescuento">${{ numberWithCommas(finalPrice) + (minutos == 0 ? '/min':'') }}</span>
                <p v-if="discount !== 1 && existeDescuento !== 'precio'" class="precio-desc-line">${{ numberWithCommas(precioOriginal) + (minutos == 0 ? '/min':'') }}</p>
                <div v-if="discount === 1" class="container-text">
                  <p class="precio-desc">${{ numberWithCommas(finalPrice*(this.porcentajeDesc/100)) + (minutos == 0 ? '/min':'') }}</p>
                  <p class="precio-desc-line">${{ numberWithCommas(finalPrice) + (minutos == 0 ? '/min':'') }}</p>
                  <div class="description d-lg-block d-none">
                    <p><b> ¡FELICITACIONES!</b></p>
                    <p>Por ser tu primera lectura, te regalamos un {{this.porcentajeDesc}}% de descuento.</p>

                  </div>
                </div>
              </div>

            </div>
            <div class="col-12">
              <p class="text-libre" v-if="minutos == 0">* Tu lectura se cortará cuando tu saldo sea insuficiente <b>ó cuando tu lo decidas.</b></p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axiosClient from '../../config/axiosClient';
import { enableLogs } from '../../config';
export default {
  data() {
    return {
      discount: null,
      porcentajeDesc: 50,
      precios: {
        roomPrice15: 0,
        roomPrice30: 0,
        roomPrice60: 0,
        porcentaje15: 0,
        porcentaje30: 0,
        porcentaje60: 0,
        precioOriginal15: 0,
        precioOriginal30: 0,
        precioOriginal60: 0
      },
      roomPrices: null,
      isLoading: true,
      minutos: 15,
      mediosPago: {},
      pendingNeedDiscount:false
    };
  },
  watch: {
    minutos: function (minutos) {
      this.$emit('durationchanged', minutos);
    },
    jwt: function(){
      if(this.pendingNeedDiscount) {this.getDiscount();}
    }
  },
  computed: {
    existeDescuento: function() {
      if (this.precios.porcentaje15 > 0 && this.minutos === 15) {
        return 'precio-desc';
      }else if (this.precios.porcentaje30 > 0 && this.minutos === 30) {
        return 'precio-desc';
      }else if (this.precios.porcentaje60 > 0 && this.minutos === 60) {
        return 'precio-desc';
      }else {
        return 'precio';
      }
    },
    precioOriginal: function(){
      if (this.minutos === 15){
        return this.precios.precioOriginal15;
      }
      if (this.minutos === 30){
        return this.precios.precioOriginal30;
      }
      return this.precios.precioOriginal60;
    },
    finalPrice: function () {
      if (this.minutos === 15){
         this.$emit('priceChanged', this.precios.roomPrice15);
        return this.precios.roomPrice15;
      }
      if (this.minutos === 30){
         this.$emit('priceChanged', this.precios.roomPrice30);
        return this.precios.roomPrice30;
      }
/*      if (this.minutos ===  0){
         this.$emit('priceChanged', this.roomPrice / 5);
        return this.roomPrice / 15;
      }*/
      this.$emit('priceChanged', this.precios.roomPrice60);
      return this.precios.roomPrice60;
    },
    jwt: function(){
      return this.$store.state.jwt;
    }
  },
  props: {
    tarotista: Object,
    tipo: String,
    canal: String,
    isLoggedIn: Boolean
  },
  created() {
    this.getRoomPrice();
    this.getDiscount();
  },
  mounted(){
    this.getMediosPago()
  },
  methods: {
    quinceMin(){
      this.minutos = 15;
    },
    treintaMin(){
      this.minutos = 30;
    },
    sesentaMin(){
      this.minutos = 60;
    },
    async getMediosPago(){
      try{
        const response = await axiosClient.get('/status-buttons/')
        console.log(response.data)
        return this.mediosPago = response.data
      }catch(e){
        console.log(e)
      }
  
    },
    async getRoomPrice() {
      let channel;
      switch (this.canal) {
        case 'Chat':
          channel = 'CHAT';
          break;
        case 'Voz':
          channel = 'VOICE';
          break;
        case 'Video':
          channel = 'VIDEO';
          break;
      }
      
      /*let response = await axiosClient.post('/rooms/get-room-price', {
        tarotReaderId: this.tarotista.tarotReaderId,
        duration: 15,
        roomType: channel,
      });*/

      let response = await axiosClient.post('/rooms/get-room-price/prices', {
        tarotReaderId: this.tarotista.tarotReaderId,
        roomType: channel,
      });
      this.roomPrices = response.data.roomPrices;
      this.precios.roomPrice15 = this.roomPrices[15];
      this.precios.roomPrice30 = this.roomPrices[30];
      this.precios.roomPrice60 = this.roomPrices[60];
      this.precios.porcentaje15 = this.roomPrices.porcentaje15;
      this.precios.porcentaje30 = this.roomPrices.porcentaje30;
      this.precios.porcentaje60 = this.roomPrices.porcentaje60;
      this.precios.precioOriginal15 = this.roomPrices.precioOriginal15;
      this.precios.precioOriginal30 = this.roomPrices.precioOriginal30;
      this.precios.precioOriginal60 = this.roomPrices.precioOriginal60;

      this.isLoading = false;
    },

     async getDiscount() {
      try {
        if(this.jwt){
          let response = await axiosClient.get('/users/need-discount', {
            headers: { Authorization: `Bearer ${this.jwt}` },
          });
          this.discount = response.data.needDiscount;
          if (enableLogs) console.log(this.discount);
        }else{
          this.pendingNeedDiscount = true;
          var th = this;
          setTimeout(() => {
            th.pendingNeedDiscount = false
          }, 3000);
        }
      } catch (err) {
        this.discount = 0;
        if (enableLogs) console.log('Error fetching latest room');
        if (enableLogs) console.log(err);
      }
    },

    numberWithCommas(x) {
      return Math.round(x).toLocaleString('es-AR');
    },
  },
};
</script>
<style scoped>
@media (max-width:768px) {
  .card-pagos {
    min-height: auto !important;
  }
  .card-pagos .btn-pagar {
    width: 100%;
  }
}
.card-body{
  padding-bottom: 5px;
}
.detalle-agendada{
  margin-top: 12px;
}
.total{
margin: 5px;
margin-bottom: 5px ;
}


.text-total {
  margin-top: 10px;
  margin-bottom: 0;
  color: #979797;
  font-size: 13px;
  font-family: maven-bold;
}
.precio {
  font-size: 22px;
  color: #333;
  text-align: right;
  font-family: maven-bold;
}
.precio-desc {
  font-size: 22px;
  color: #501682;
  text-align: right;
  font-family: maven-bold;
  margin-bottom: 0px;
}
.precio-desc-line{
  text-decoration: line-through;
  font-size: 15px;
  color: #333;
  text-align: right;
  font-family: maven-regular;
  margin-bottom: 0px;
}
.fas{
  color: #fff;
  font-size: 13px;
  margin-right: 5px;
}
/*header*/
.gradient {
  position: absolute;
  height: 150px;
  left: 0px;
  right: 0px;
  bottom: 0;
  background: linear-gradient(#fff0, #06273c);
  border-radius: 33px 33px 0 0;
}
.conteinder-header {
  position: relative;
  min-height: 150px;
}
.conteinder-header .card-img-top {
  filter: brightness(0.8);
}

.card-img-top {
  border-radius: 15px 15px 0 0;
  height: 150px;
  object-fit: cover;
}

.conteinder-header .gift {
  position: absolute;
  right: 15px;
  top: 10px;
  color: #ffc30b;
  max-width: 55px;
}
.conteinder-header .header-text {
  position: absolute;
  right: 18px;
  top: 28px;

  padding: 0px 3.5px;
  height: 40px;
  font-family: maven-bold;
  text-align: center;
  border-radius: 5px;
  width: 53.5px;
}

.conteinder-header .header-name {
  position: absolute;
  width: 100%;
  text-align: center;
  top: 10px;
}
.conteinder-header .header-name .llamada {
  width: 25px;
  margin-right: 8px;
}
.conteinder-header .header-name .star-one {
  position: absolute;
  width: 6px;
  right: 35px;
  top: 22px;
}

.conteinder-header .header-name span {
  font-family: source-semibold;
  color: #fff;
  font-size: 18px;
  padding-bottom: 10px;
}
.conteinder-header .header-name .col-img {
  max-width: 60px;
  padding-right: 0;
  margin: auto;
  padding: 5px 0;
}
.conteinder-header .header-name .col-img .image {
  width: 48px;
  height: 48px;
  margin: auto;
}
.conteinder-header .header-name .col-img .image img {
  width: 100%;
  border-radius: 50%;
  height: 100%;
  object-fit: cover;
  background: white;
}
.conteinder-header .header-name .name-tarotista {
  color: white;
  font-size: 14px;
  font-family: source-semibold;
  margin-bottom: 0px;
}

.conteinder-header .header-name .canal {
  color: white;
  font-size: 13px;
  margin-bottom: 0px;
}
.text-libre{
  font-size: 10px;
  margin-bottom: 0px;
  text-align: center;
}
/*fin de header */

.container-text{
  position: relative;
}
.description {
  background-color: teal;
  width: 200px;
  position: absolute;
  left: -38px;
  border-radius: 8px;
  padding: 15px;
  opacity: 0;
  font-size: 12px;
  font-family: maven-regular;
  color: #fff;
  background-color: #30075b;
  z-index: 100;
  text-align: center;
}
.description p{
  margin-bottom: 0;
}
.description::before {
  content: "";

  border-style: solid;
  border-width: 0px 13px 13px 13px;
  border-color: transparent transparent transparent #30075b;
  transform: rotate(45deg);
  position: absolute;
  top: -1px;
  left: 50%;
  margin-left: -10px;
}

.container-text:hover .description {
  opacity: 1;
}
</style>
