<template>
  <div>
    <div class="card card-pagos ">
      <div class="card-body">
        <h5></h5>
        <form>
          <div class="row">
            <div class="form-group">
              <p class="text-medio-pago">Para seguir con el pago te redireccionaremos a la plataforma de <span>Webpay Plus</span></p>
            </div>
            <div class="text-center mt-3">
              <b-button
                @click="askForConfirmPayment()"
                class="btn btn-pagar"
                >Ir a pagar</b-button
              >
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  computed: {
    allowForm: function() {
      return this.validForm();
    },
  },
  methods: {
    validForm() {
      return this.personEmail != "";
    },
    askForConfirmPayment() {
      this.$emit("askForConfirmPayment", {
        type: "kushkiDebitCard",
        personEmail: this.personEmail,
      });
    },
  },
  data: () => {
    return {
      personEmail: "",
    };
  },
};
</script>

<style scoped>
.card-body {
  position: relative;
}
.btn-pagar {
  position: absolute;
  bottom: 20px;
  right: calc(50% - 63px);
}
/deep/.close {
  background: transparent;
  border: none;
}
/deep/.modal-header {
  border-bottom: none;
}

/deep/.modal-body {
  padding: 0 50px;
}
.text-modal {
  color: #501682;
  font-family: maven-bold;
  text-align: center;
  text-transform: uppercase;
  font-size: 20px;
}
.text-saldo {
  text-align: center;
  font-family: maven-regular;
  font-size: 20px;
}
.text-saldo span {
  font-family: maven-bold;
  color: #3b5998;
}
/deep/.modal-footer {
  border-top: none;
  padding: 10px 50px 30px 50px;
}
/deep/.btn-modal {
  background-color: #04d9b2;
  color: #fff;
  font-family: maven-bold;
  text-transform: uppercase;
  border: none;
}
.content-btn {
  position: absolute;
  bottom: 15px;
}
</style>
