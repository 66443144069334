import Layout from './components/layout/Layout.vue';
import LayoutLogin from './components/layout/LayoutLogin.vue';
import Login from './components/login/Login.vue';
import PassChange from './components/login/PasswordEmail.vue';
import Register from './components/login/Register.vue';
import Password from './components/login/Password.vue';
import RegisterTarotista from './components/registerTarotista/RegisterTarotista.vue';
import LayoutRegistrado from './components/layout/LayoutRegistrado.vue';
import SalaEspera from './components/room/SalaEspera.vue';
import Room from './components/room/Room.vue';
import Planes from './components/planes/Planes.vue';
import DashboardUser from './components/DashboardUser/DashboardUser.vue';
import DashboardTarotista from './components/DashboardTarotista/DashboardTarotista.vue';
import Compra from './components/compra/Compra.vue';
import FormWebPay from './components/webpay/form-webpay.vue';
import FormRedirect from './components/webpay/formRedirect.vue';
import CompraPlanes from './components/planes/compra-planes/CompraPlanes.vue';
import Servicios from './components/servicios/Servicios.vue';
import Catalogo from './components/catalogo/Catalogo.vue';
import Agendar from './components/agendar/Agendar.vue';
import Contacto from './components/contacto/Contacto.vue';
import Chat from './components/chat/Chat.vue';
import LayoutRoom from './components/layout/LayoutRoom';
import FichaTarotista from './components/fichaTarotista/FichaTarotista.vue';
import DashboardAdmin from './components/dashboardAdmin/DashboardAdmin';
import DashAdminUser from './components/dashboardAdmin/usuario/Usuario.vue';
import DashAdminReportes from './components/dashboardAdmin/reportes/Reportes.vue';
import DashAdminPlanes from './components/dashboardAdmin/planes/PlanesPrecio.vue';
import Faqs from './components/faqs/Faqs.vue';
import Setting from './components/DashboardTarotista/setting/Setting.vue';
import DashAdminAmbitos from './components/dashboardAdmin/ambitos/Ambitos.vue';
import DashAdminEspecialidades from './components/dashboardAdmin/especialidades/Especialidades.vue';
import DashAdminPrecioLectura from './components/dashboardAdmin/precioLectura/PrecioLectura.vue';
import RoomLlamada from './components/llamada/RoomLlamada.vue';
import Politicas from './components/politicas/Politicas.vue';
import Terminos from './components/terminos/Terminos.vue';
import SetPassword from './components/login/SetPassword.vue';
import MisDatos from './components/dashboardAdmin/misDatos/MisDatos.vue';
import PasswordRecovery from './components/login/PasswordRecovery.vue';
const routes = [
  { path: '/', component: Layout },
  {
    path: '/user',
    component: LayoutLogin,
    children: [
      {
        name: 'Login',
        path: 'login',
        component: Login,
      },
      {
        name: 'PasswordEmail',
        path: 'pass-change',
        component: PassChange,
      },
      {
        name: 'Register',
        path: 'register',
        component: Register,
      },
      {
        name: 'Password',
        path: 'password',
        component: Password,
      },
    ],
  },
  {
    path: '/check',
    component: LayoutRegistrado,
    children: [
      {
        name: 'Planes',
        path: '/planes',
        component: Planes,
      },
/*       {
        name: 'Servicios',
        path: '/servicios',
        component: Servicios,
      }, */
      {
        name: 'DashboardUser',
        path: '/dashboard-user',
        component: DashboardUser,
        meta: { userOnly: true },
      },
      {
        name: 'DashboardTarotista',
        path: '/dashboard-tarotista',
        component: DashboardTarotista,
        meta: { isTarotReader: true },
      },
      {
        name: 'DashboardAdmin',
        path: '/dashboard-admin',
        component: DashboardAdmin,
        meta: { isAdmin: true },
      },
      {
        name: 'Compra',
        path: '/compra',
        component: Compra,
      },
      {
        name: 'FormWebPay',
        path: '/form-webpay',
        component: FormWebPay,
      },{
        name: 'FormWebPay',
        path: '/form-redirect',
        component: FormRedirect,
      },
      {
        name: 'CompraPlanes',
        path: '/planes/compra',
        component: CompraPlanes,
        meta: { isLoggedIn: true },
      },
      {
        name: 'Servicios',
        path: '/servicios',
        component: Servicios,
      },
      {
        name: 'Catalogo',
        path: '/catalogo',
        component: Catalogo,
      },
      {
        name: 'Agendar',
        path: '/agendar',
        component: Agendar,
      },
      {
        name: 'Contacto',
        path: '/contactanos',
        component: Contacto,
      },
      {
        name: 'RegisterTarotista',
        path: '/registro-tarotista',
        component: RegisterTarotista,
      },
      {
        name: 'FichaTarotista',
        path: '/ficha-tarotista',
        component: FichaTarotista,
      },
      {
        name: 'DashAdminUser',
        path: '/usuario',
        component: DashAdminUser,
      },
      {
        name: 'DashAdminReportes',
        path: '/reportes',
        component: DashAdminReportes,
      },
      {
        name: 'DashAdminPlanes',
        path: '/planes-precios',
        component: DashAdminPlanes,
      },
      {
        name: 'Faqs',
        path: '/faqs',
        component: Faqs,
      },
      {
        name: 'Setting',
        path: '/setting',
        component: Setting,
      },
      {
        name: 'DashAdminAmbitos',
        path: '/admin-ambitos',
        component: DashAdminAmbitos,
      },
      {
        name: 'DashAdminEspecialidades',
        path: '/admin-especialidades',
        component: DashAdminEspecialidades,
      },
      {
        name: 'DashAdminPrecioLectura',
        path: '/admin-precio-lectura',
        component: DashAdminPrecioLectura,
      },
      {
        name: 'Politicas',
        path: '/politicas-de-privacidad',
        component: Politicas,
      },
      {
        name: 'Terminos',
        path: '/terminos-y-condiciones',
        component: Terminos,
      },
      {
        name: 'MisDatos',
        path: '/mis-datos',
        component: MisDatos,
      },
    ],
  },
  {
    path: '/layout-room',
    component: LayoutRoom,
    children: [
      {
        path: '/sala-espera',
        name: 'SaleEspera',
        component: SalaEspera,
      },
      {
        name: 'Chat',
        path: '/chat',
        component: Chat,
      },
    ],
  },
  { path: '/room', component: Room },
  { path: '/room-llamada', component: RoomLlamada },
  { name: 'SetPassword', path: '/set-password', component: SetPassword },
  { path: '/password-recovery', component: PasswordRecovery },
];

export default routes;
