<template>
  <div>
    <b-modal
      :no-close-on-esc="true"
      :no-close-on-backdrop="true"
      id="modal-admin-datos"
      centered
      hide-footer
      size="lg"
      v-model="showModal"
    >
      <template #modal-title> </template>
      <h3 class="title">Datos de {{ dato.nombre }} {{ dato.apellido }}</h3>
      <div class="row">
        <div class="col-md-6 col-12 mb-2">
          <label class="label-modal" for="">Email:</label>
          <p>{{ dato.email }}</p>
        </div>
        <div class="col-md-6 col-12 mb-2">
          <label class="label-modal" for="">Nombre Completo:</label>
          <p>{{ dato.nombre }}</p>
        </div>
        <div class="col-md-6 col-12 mb-2">
          <label class="label-modal" for="">Apodo:</label>
          <p>{{ dato.alias }}</p>
        </div>
        <div class="col-md-6 col-12 mb-2">
          <label class="label-modal" for="">Teléfono:</label>
          <p>{{ dato.telefono }}</p>
        </div>
        <div class="col-md-6 col-12 mb-2">
          <label class="label-modal" for="">Pais:</label>
          <p>{{ dato.pais }}</p>
        </div>
        <div class="col-md-6 col-12 mb-2">
          <label class="label-modal" for="">Ciudad:</label>
          <p>{{ dato.ciudad }}</p>
        </div>
        <div class="col-md-6 col-12 mb-2">
          <label class="label-modal" for="">Dirección:</label>
          <p>{{ dato.direccion }}</p>
        </div>
        <div class="col-md-6 col-12 mb-2">
          <label class="label-modal" for="">Experiencia:</label>
          <p>{{ parseExperience(dato.experiencia) }}</p>
        </div>
        <div class="col-md-6 col-12 mb-2">
          <label class="label-modal" for="">Trabajo Similares:</label>
          <p v-if="dato.trabajo === 'trabajoNo'">Sin Trabajos Anteriores</p>
          <p v-if="dato.trabajo === 'trabajoOtro'">
            Trabajo en: {{ dato.trabajoActual }}
          </p>
          <p v-if="dato.trabajo === 'trabajoSi'">
            Trabajo Anterior: {{ dato.trabajoAnterior }}
          </p>
        </div>
        <div class="col-md-6 col-12 mb-2">
          <label class="label-modal" for="">Puede Emitir:</label>
          <p>{{ parseBoletas(dato.comprobante) }}</p>
        </div>
        <div class="col-md-6 col-12 mb-2">
          <label class="label-modal" for="">Idiomas:</label>
          <p v-for="idioma in dato.idiomas" :key="idioma">
            {{ parseLanguage(idioma) }}
          </p>
          <p v-if="dato.otroIdioma !== ''">{{ dato.otroIdioma }}</p>
        </div>
        <div class="col-md-6 col-12 mb-2">
          <label class="label-modal" for="">Especialidades:</label>
          <p v-for="especialidad in dato.especialidades" :key="especialidad">
            {{ parseSpecialty(especialidad) }}
          </p>
          <p v-if="dato.otraEspecialidad !== ''">{{ dato.otraEspecialidad }}</p>
        </div>
        <div class="col-md-6 col-12 mb-2">
          <label class="label-modal" for="">Ambitos:</label>
          <p v-for="ambito in dato.ambitos" :key="ambito">
            {{ parseScopes(ambito) }}
          </p>
          <p v-if="dato.otroAmbito !== ''">{{ dato.otroAmbito }}</p>
        </div>
        <div class="col-md-6 col-12 mb-2">
          <label class="label-modal" for="">Canales:</label>
          <p v-for="canal in dato.canales" :key="canal">
            {{ parseCanal(canal) }}
          </p>
        </div>
        <div class="col-md-6 col-12 mb-2">
          <label class="label-modal" for="">Tipo de lectura:</label>
          <p>{{ parseTipoLectura(dato.lecturas) }}</p>
        </div>
        <div class="col-md-6 col-12 mb-2">
          <label class="label-modal" for="">Tarifa por Minuto:</label>
          <p v-for="channel in dato.canales" :key="channel">
            {{ parseCanal(channel) }}: {{ parseTarifas(dato[channel]) }}
          </p>
          <p></p>
        </div>
        <div class="col-md-6 col-12 mb-2">
          <label class="label-modal" for="">Mensaje Personal:</label>
          <p>{{ dato.mensajePersonal }}</p>
        </div>
        <div class="col-md-6 col-12 mb-2">
          <label class="label-modal" for="">Reseña profesional:</label>
          <p>{{ dato.resenaPersonal }}</p>
        </div>
        <div class="col-md-6 col-12 mb-2">
          <label class="label-modal" for="">Presentación:</label>
          <p>{{ dato.link }}</p>
        </div>
        <div class="col-12 mt-3">
          <label class="label-modal" for="">Imagen</label>
          <div class="col mt-3 p-0" style="width: 180px; height: 150px">
            <div class="card-img shadow-sm" style="">
              <img :src="dato.avatar" alt="" v-if="dato.avatar !== null" />
              <i class="fas fa-camera" v-else></i>
            </div>
          </div>
        </div>
        <div class="col-12">
          <div
            class="container-btn text-end"
            v-if="inscription.estado === 'Pendiente'"
          >
            <p v-if="isLoading">Aceptando Inscripción...</p>
            <button :disabled="isLoading" class="btn btn-editar" @click="acceptInscription">
              Aceptar Inscripción
            </button>
            <button
              class="btn btn-eliminar"
              @click="toggleRejectionModal(`modalRejection-${inscription.id}`)"
            >
              Rechazar Inscripción
            </button>
            <ModalDescription
              :toggleRejectionModal="
                modalRejection === `modalRejection-${inscription.id}`
              "
              :inscription="inscription"
              v-on:editStatus="confirmMotivo"
            />
          </div>
          <div class="container-btn text-end" v-else>
            <p>Estado de la inscripción: {{ inscription.estado }}</p>
          </div>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
  import axiosClient from '../../../config/axiosClient';
  import ModalDescription from './ModalDescription.vue';
  import { enableLogs } from '../../../config';
  export default {
    name: '',
    components: {
      ModalDescription,
    },
    watch: {
      triggerModal: function(value) {
        if (value) this.showModal = true;
      },
    },
    mounted() {
      this.getCurrentPrices();
    },

    data: () => {
      return {
        showModal: false,
        isLoading: false,
        basePrice: null,
        lowPriceFactor: null,
        mediumPriceFactor: null,
        highPriceFactor: null,
        chatPriceFactor: null,
        voicePriceFactor: null,
        videoPriceFactor: null,
        modalRejection: null,
        forceClose: null,
      };
    },

    props: {
      modalTriggered: String,
      triggerModal: Boolean,
      dato: Object,
      inscription: Object,
      updateInscriptionStatus: Function,
    },
    methods: {
      async toggleRejectionModal(modal) {
        this.modalRejection = modal;

        await this.$nextTick();
        this.modalRejection = null;
        this.forceClose = null;
      },

      async getCurrentPrices() {
        try {
          const response = await axiosClient.get('/prices/get-prices');

          const {
            basePrice,
            lowPriceFactor,
            mediumPriceFactor,
            highPriceFactor,
            chatPriceFactor,
            voicePriceFactor,
            videoPriceFactor,
          } = response.data.prices;

          this.basePrice = basePrice;
          this.lowPriceFactor = lowPriceFactor;
          this.mediumPriceFactor = mediumPriceFactor;
          this.highPriceFactor = highPriceFactor;
          this.chatPriceFactor = chatPriceFactor;
          this.voicePriceFactor = voicePriceFactor;
          this.videoPriceFactor = videoPriceFactor;
        } catch (err) {
         if(enableLogs) console.log(err);
         if(enableLogs) console.log('Error fetching prices');
        }
      },
      async acceptInscription() {
        this.isLoading = true;
        try {
          const parsedSpecialties = this.dato.especialidades.map(
            (specialty) => {
              return this.parseSpecialtiesDB(specialty);
            }
          );
          const parsedScopes = this.dato.ambitos.map((scope) => {
            return this.parseScopesDB(scope);
          });
          const parsedLanguages = this.dato.idiomas.map((idioma) => {
            return this.parseLanguagesDB(idioma);
          });
          const response = await axiosClient.post(
            '/inscriptions/accept-inscription',
            {
              inscription: {
                id: this.inscription.id,
                inscriptionData: {
                  email: this.dato.email,
                  nombre: this.dato.nombre,
                  apellido: this.dato.apellido,
                  telefono: this.dato.telefono,
                  direccion: this.dato.direccion,
                  pais: this.dato.pais,
                  ciudad: this.dato.ciudad,
                  alias: this.dato.alias,
                  mensajePersonal: this.dato.mensajePersonal,
                  experiencia: {
                    chatExperience: this.parseTarifas(
                      this.dato.chat
                    ).toUpperCase(),
                    voiceExperience: this.parseTarifas(
                      this.dato.llamada
                    ).toUpperCase(),
                    videoExperience: this.parseTarifas(
                      this.dato.videollamada
                    ).toUpperCase(),
                  },
                  especialidades: parsedSpecialties,
                  idiomas: parsedLanguages,
                  ambitos: parsedScopes,
                  avatar: this.dato.avatar,
                  lecturas: this.dato.lecturas,
                  link: this.dato.link,
                  proReview: this.dato.resenaPersonal,
                },
              },
            },
            { headers: { Authorization: `Bearer ${this.$store.state.jwt}` } }
          );
         if(enableLogs) console.log(response.data);
         if(enableLogs) console.log('successfully accepted tarotista');
          this.isLoading = false;
          this.showModal = false;
          this.$emit('editStatus', {
            id: this.inscription.id,
            estado: 'Aceptada',
          });
          localStorage.setItem('estado', "success");
        } catch (error) {
         if(enableLogs) console.log('could not accept inscription');
         if(enableLogs) console.log(error);
          this.isLoading = false;
          this.showModal = false;
          localStorage.setItem('estado', "error");
        }
      },

      confirmMotivo(inscripcion) {
        this.$emit('editStatus', inscripcion);
      },

      parseExperience(experienceString) {
        switch (experienceString) {
          case 'experienciaUno':
            return 'Menos de un año';
          case 'experienciaTres':
            return 'Entre 1 y 3 años';
          case 'experienciaCinco':
            return 'Entre 3 y 5 años';
          case 'experienciaMasCinco':
            return 'Más de cinco años';
        }
      },
      parseBoletas(tipoPersona) {
        switch (tipoPersona) {
          case 'eirl':
            return 'Boletas';
          case 'personaNatural':
            return 'Facturas';
        }
      },
      parseCanal(canal) {
        switch (canal) {
          case 'videollamada':
            return 'Videollamada';
          case 'llamada':
            return 'Llamada de Voz';
          case 'chat':
            return 'Chat de Texto';
        }
      },
      parseScopes(ambito) {
        switch (ambito) {
          case 'amor':
            return 'Amor y Relaciones';
          case 'trabajo':
            return 'Trabajo y Negocios';
          case 'salud':
            return 'Salud';
          case 'familia':
            return 'Familia';
          case 'pasado':
            return 'Pasado';
          case 'futuro':
            return 'Futuro';
        }
      },
      parseExperienceFactor(experience) {
        switch (experience) {
          case 'uno':
            return this.basePrice * this.highPriceFactor;
          case 'dos':
            return this.basePrice * this.mediumPriceFactor;
          case 'tres':
            return this.basePrice * this.lowPriceFactor;
        }
      },
      parseTipoLectura(lectura) {
        switch (lectura) {
          case 'instantanea':
            return 'Lectura Instantánea';
          case 'agendada':
            return 'Lectura Agendada';
        }
      },
      parseSpecialty(specialty) {
        switch (specialty) {
          case 'tarot':
            return 'Tarot';
          case 'numerologia':
            return 'Numerología';
          case 'sueño':
            return 'Sueño';
          case 'atrologia':
            return 'Astrología';
          case 'clarividencia':
            return 'Clarividencia';
        }
      },
      parseLanguage(language) {
        switch (language) {
          case 'es':
            return 'Español';
          case 'en':
            return 'Inglés';
          case 'pt':
            return 'Portugués';
        }
      },
      parseTarifas(tarifa) {
        switch (tarifa) {
          case 'uno':
            return 'Alto';
          case 'dos':
            return 'Medio';
          case 'tres':
            return 'Bajo';
          default:
            return '';
        }
      },
      parseSpecialtiesDB(specialty) {
       if(enableLogs) console.log(specialty);
        switch (specialty) {
          case 'tarot':
            return 1;
          case 'numerologia':
            return 7;
          case 'sueño':
            return 2;
          case 'atrologia':
            return 5;
          case 'clarividencia':
            return 3;
        }
        return 'asddf';
      },
      parseScopesDB(scope) {
        switch (scope) {
          case 'amor':
            return 1;
          case 'trabajo':
            return 2;
          case 'salud':
            return 3;
          case 'dinero':
            return 4;
          case 'familia':
            return 6;
          case 'pasado':
            return 5;
          case 'futuro':
            return 8;
        }
      },
      parseLanguagesDB(language) {
        switch (language) {
          case 'en':
            return 'EN';
          case 'es':
            return 'ES';
          case 'pt':
            return 'PT';
        }
      },
    },
  };
</script>
<style scoped>
  /deep/.close {
    background: transparent;
    border: none;
  }
  /deep/ .modal-header {
    border-bottom: none;
    padding-bottom: 0px;
  }
  /deep/ .modal-body {
    padding: 48px;
    padding-top: 0px;
    font-family: maven-medium;
  }
  .title {
    font-family: maven-semibold;
    font-size: 18px;
    margin-bottom: 20px;
    text-transform: uppercase;
  }

  .btn-yellow {
    background: #ffc109bb;
    font-family: maven-semibold;
    text-transform: uppercase;
    color: #501682;
    border-radius: 10px;
    padding: 8px 16px;
    font-size: 12px;
    float: right;
  }
  .btn-email {
    width: 100%;
    font-family: maven-semibold;
    border-radius: 10px;
    background: #333;
    color: #fff;
    font-size: 12px;
  }
  label {
    font-size: 14px;
  }
  .label-modal {
    font-family: maven-semibold;
    font-size: 16px;
    margin-bottom: 0px;
  }
  p {
    margin-bottom: 0px;
    font-size: 15px;
  }
  .card-img {
    width: 120px;
    height: 120px;
    border: 3px solid #fff;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    border-radius: 8px;
  }
  .card-img img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 8px;
  }
  .card-img i {
    font-size: 50px;
    margin-top: 35px;
    margin-left: 35px;
    color: #707070;
  }
  .btn-eliminar {
    font-size: 12px;
    background-color: #979797;
    color: #333333;
    font-family: maven-semibold;
    font-size: 10px;
    text-transform: uppercase;
    padding: 8px 10px;
  }
  .btn-eliminar:hover {
    background-color: #858585;
    color: #333333;
  }
  .btn-editar {
    font-family: maven-semibold;
    background: #ffc209;
    color: #501682;
    font-size: 10px;
    text-transform: uppercase;
    padding: 8px 10px;
    margin-right: 8px;
  }
  .btn-editar:hover {
    background: #dba501;
    color: #650bbf;
  }
</style>
