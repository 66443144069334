<template>
  <div>
    <div class="row mt-3">
      <div class="col-md-4 col-12 mb-md-0 mb-3">
        <div class="card shadow card-planes" style="min-height: 100%">
          <div class="card-body">
            <h5>Bolsas de Saldo y Ahorro</h5>
            <span>Aqui podria ir una descripcion</span>
            <router-link
              style="display: inline-block; text-decoration: none"
              to="/planes-precios"
              class=""
            >
              <button
                class="btn btn-purple btn-botton"
                :disabled="checkAccess('Productos')"
              >
                Ingresar
              </button>
            </router-link>
          </div>
        </div>
      </div>
      <div class="col-md-8 col-12">
        <div class="row">
          <div class="col-md-6 col-12 mb-3">
            <div class="card shadow">
              <div class="card-body">
                <h5>Especialidades</h5>
                <router-link
                  style="display: inline-block; text-decoration: none"
                  :to="
                    checkAccess('Especialidades')
                      ? '/dashboard-admin'
                      : '/admin-especialidades'
                  "
                  class=""
                >
                  <button
                    class="btn btn-purple btn-botton "
                    :disabled="checkAccess('Especialidades')"
                  >
                    Ingresar
                  </button>
                </router-link>
              </div>
            </div>
          </div>
          <div class="col-md-6 col-12 mb-3">
            <div class="card shadow">
              <div class="card-body">
                <h5>Precio de lectura por minuto</h5>

                <router-link
                  style="display: inline-block; text-decoration: none"
                  to="/admin-precio-lectura"
                  class=""
                >
                  <button class="btn btn-purple ">Ingresar</button>
                </router-link>
              </div>
            </div>
          </div>
          <div class="col-md-6 col-12 mb-md-0 mb-3">
            <div class="card shadow">
              <div class="card-body">
                <h5>Ámbitos</h5>
                <router-link
                  style="display: inline-block; text-decoration: none"
                  :to="
                    checkAccess('Ambitos')
                      ? '/dashboard-admin'
                      : '/admin-ambitos'
                  "
                  class=""
                >
                  <button
                    class="btn btn-purple "
                    :disabled="checkAccess('Ambitos')"
                  >
                    Ingresar
                  </button>
                </router-link>
              </div>
            </div>
          </div>
          <div class="col-md-6 col-12 mb-md-0 mb-3">
            <div class="card shadow">
              <div class="card-body">
                <h5>Usuarios</h5>
                <router-link
                  style="display: inline-block; text-decoration: none"
                  :to="
                    checkAccess('Usuarios') ? '/dashboard-admin' : '/usuario'
                  "
                  class=""
                >
                  <button
                    class="btn btn-purple"
                    :disabled="checkAccess('Usuarios')"
                  >
                    Ingresar
                  </button>
                </router-link>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-4 col-12 mt-md-3 mb-md-0 mb-3">
        <div class="card shadow" style="min-height: 100%">
          <div class="card-body">
            <h5>Mis Datos</h5>
            <router-link
              style="display: inline-block; text-decoration: none"
              to="/mis-datos"
              class=""
            >
              <button class="btn btn-purple">Ingresar</button>
            </router-link>
          </div>
        </div>
      </div>
      <div class="col-md-8 col-12 mt-md-3 mt-0">
        <div class="card shadow">
          <div class="card-body">
            <h5>Cursos y Talleres</h5>
            <button class="btn btn-purple" disabled>Ingresar</button>
          </div>
        </div>
      </div>
      <div class="col-12 mt-md-3 mt-0">
        <div class="card shadow">
          <div class="card-body">
            <h5>Reportes</h5>
            <router-link
              style="display: inline-block; text-decoration: none"
              to="/reportes"
              class=""
            >
              <button class="btn btn-purple">Ingresar</button>
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    permissionsAvailable: Array,
  },
  methods: {
    checkAccess(permissionName) {
      return !this.permissionsAvailable.some(
        perm => perm.permissionName === permissionName
      );
    },
  },
};
</script>

<style scoped>
.card {
  border-radius: 10px;
  min-height: 100%;
  font-family: maven-semibold;
}
.card-body {
  position: relative;
}
.card h5 {
  font-size: 22px;
}
.card span {
  font-size: 15px;
  font-family: maven-medium;
  color: #707070;
}
.btn-purple {
  background: #501682;
  color: #fff;
  margin-top: 10px;
  font-family: maven-semibold;
  font-size: 11px;
  text-transform: uppercase;
  float: right;
  padding: 8px 22px;
}
.btn-purple:hover {
  background: #7634b8;
  color: #fff;
}
/deep/a {
  float: right;
}
.btn-botton {
  position: absolute;
  bottom: 15px;
  right: 15px;
}

@media screen and (max-width: 760px) {
  .card {
    min-height: 112px;
  }
  .card-planes {
    min-height: 140px !important;
  }
}
</style>
