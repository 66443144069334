<template>
  <div class="box-ambitos">
    <div class="text-center">
      <h2 class="general-title title">¿En qué te ayudamos?</h2>
    </div>
    <div class="row justify-content-between my-5">
      <!-- <div class="grid-container">
        <main class="grid-item main">
          <div class="arrow">
            <i class="fas fa-chevron-left"></i>
          </div> 
          <div class="items">
            <div
              class="item item1"
              v-for="scope in ambitos"
              :key="scope.scopeId"
            >
              <div class="image">
                <img
                  :src="scope.scopeImage"
                  alt=""
                  @click="navigateFilterApplied(scope.scopeId)"
                />
              </div>
              <p>{{ scope.scopeName }}</p>
            </div>
          </div>
          <div class="arrow">
            <i class="fas fa-chevron-right"></i>
          </div> 
        </main>
      </div> -->
      <div
        class="col-lg col-md-4 col-sm-6 col-12 ambito"
        v-for="scope in ambitos"
        :key="scope.scopeId"
        id="tooltip-target-1"
      >
        <div class="image">
          <img
            :src="scope.scopeImage"
            alt=""
            @click="navigateFilterApplied(scope.scopeId)"
          />
        </div>
        <p>{{ scope.scopeName }}</p>

        <div class="description d-lg-block d-none">
          {{ scope.scopeDescription }}
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axiosClient from "../../config/axiosClient";
import { enableLogs } from '../../config';

export default {
  watch: {
    ambitos: async function(esp) {
      await this.$nextTick();

      if (esp.length == 0) return;

      if (document.location.search.match(/type=embed/gi)) {
        window.parent.postMessage("resize", "*");
      }
      const slider = document.querySelector(".items");
      if (slider == null || slider == undefined) return;

      let isDown = false;
      let startX;
      let scrollLeft;

      slider.addEventListener("mousedown", (e) => {
        isDown = true;
        slider.classList.add("active");
        startX = e.pageX - slider.offsetLeft;
        scrollLeft = slider.scrollLeft;
      });
      slider.addEventListener("mouseleave", () => {
        isDown = false;
        slider.classList.remove("active");
      });
      slider.addEventListener("mouseup", () => {
        isDown = false;
        slider.classList.remove("active");
      });
      slider.addEventListener("mousemove", (e) => {
        if (!isDown) return;
        e.preventDefault();
        const x = e.pageX - slider.offsetLeft;
        const walk = (x - startX) * 3;
        slider.scrollLeft = scrollLeft - walk;
       if(enableLogs) console.log(walk);
      });
    },
  },

  data: () => {
    return {
      ambitos: [],
    };
  },
  mounted() {
    this.getAmbitos();
  },
  methods: {
    async getAmbitos() {
      try {
        this.isLoading = true;
        const response = await axiosClient.get("scopes/get-all-scopes");
        this.ambitos = response.data.scopes;
       if(enableLogs) console.log("successfully fetched scopes");
        this.isLoading = false;
      } catch (error) {
        if(enableLogs) console.log(error);
        this.isLoading = false;
      }
    },
    navigateFilterApplied(scopeId) {
      this.$router.push(`/catalogo/?scopeId=${scopeId}`);
    },
  },
};
</script>

<style scoped>
.title{
  margin-bottom: 75px;
}
.box-ambitos {
  margin: 150px 0;
}
.ambito {
  text-align: center;
  max-width: 120px;
  position: relative;
}
.ambito img {
  width: 100%;
}
.ambito p {
  margin-top: 10px;
  color: #30075b;
  font-family: maven-semibold;
  text-align: center;
}
.description {
  background-color: teal;
  width: 200px;
  position: absolute;
  left: -38px;
  border-radius: 8px;
  padding: 15px;
  opacity: 0;
  font-size: 12px;
  font-family: maven-regular;
  color: #fff;
  background-color: #30075b;
  z-index: 100;
}
.description::before {
  content: "";

  border-style: solid;
  border-width: 0px 13px 13px 13px;
  border-color: transparent transparent transparent #30075b;
  transform: rotate(45deg);
  position: absolute;
  top: -1px;
  left: 50%;
  margin-left: -10px;
}

.ambito:hover .description {
  opacity: 1;
}

@media screen and (max-width: 992px) {
  .ambito {
    max-width: 100%;
  }

  .ambito img {
    width: 120px;
  }
}
</style>
