<template>
  <div>
    <b-modal
      :no-close-on-esc="true"
      :no-close-on-backdrop="true"
      id="modalBolsas"
      ref="modal-plan-bolsa-editar"
      centered
      hide-footer
      v-model="showModal"
    >
      <template #modal-title> </template>
      <h3 class="title">Editar DivinoBolsa</h3>
      <div class="mb-3">
        <label class="label-modal" for="">Nombre</label>
        <div>
          <input
            v-model="bolsaInformation.description"
            type="text"
            class="form-control inputPadding"
            aria-label="Username"
            aria-describedby="basic-addon1"
            placeholder="Nombre"
          />
        </div>
      </div>
      <div class="mb-3">
        <label class="label-modal" for="">Precio</label>
        <div>
          <input
            v-model="bolsaInformation.price"
            type="text"
            class="form-control inputPadding"
            aria-label="Username"
            aria-describedby="basic-addon1"
            placeholder="12.345"
          />
        </div>
      </div>
      <div class="mb-3">
        <label class="label-modal" for="">Bonificación</label>
        <div>
          <input
            type="text"
            class="form-control inputPadding"
            aria-label="Username"
            aria-describedby="basic-addon1"
            placeholder="15%"
            v-model="bolsaInformation.bonification"
          />
        </div>
      </div>

      <button class="btn btn-yellow" @click="editDivinoBolsa">
        Editar DivinoBolsa
      </button>
    </b-modal>
  </div>
</template>

<script>
  import axiosClient from '../../../../config/axiosClient';
  import { enableLogs } from '../../../../config';

  export default {
    name: '',

    props: {
      divinoBolsa: Object,
      triggerModal: Boolean,
    },
    data() {
      return {
        bolsaInformation: {
          bolsaId: this.divinoBolsa.id,
          price: this.divinoBolsa.price,
          description: this.divinoBolsa.description,
          bonification: this.divinoBolsa.bonification,
        },
        showModal: false,
      };
    },
    watch: {
      triggerModal: function(value) {
        if (value) {
          this.showModal = true;

          this.bolsaInformation.price = this.divinoBolsa.price;
          this.bolsaInformation.description = this.divinoBolsa.description;
          this.bolsaInformation.bonification = this.divinoBolsa.bonification;
        }
      },
    },
    methods: {
      async editDivinoBolsa() {
        try {
          const response = await axiosClient.post(
            '/divino-bolsas/edit-bolsa',
            {
              bolsaInformation: this.bolsaInformation,
            },
            { headers: { Authorization: `Bearer ${this.$store.state.jwt}` } }
          );
         if(enableLogs) console.log(response.data);
         if(enableLogs) console.log('successfully edited divinobolsa');
          this.$emit('editBolsa', {
            ...this.bolsaInformation,
          });
          this.showModal = false;
        } catch (error) {
         if(enableLogs) console.log('could not update divinobolsa');
         if(enableLogs) console.log(error);
        }
      },
    },
  };
</script>
<style scoped>
  /deep/.close {
    background: transparent;
    border: none;
  }
  /deep/ .modal-header {
    border-bottom: none;
    padding-bottom: 0px;
  }
  /deep/ .modal-body {
    padding: 48px;
    padding-top: 0px;
    font-family: maven-medium;
  }
  .title {
    font-family: maven-semibold;
    font-size: 18px;
    margin-bottom: 20px;
    text-transform: uppercase;
  }

  .btn-yellow {
    background: #ffc109bb;
    font-family: maven-semibold;
    text-transform: uppercase;
    color: #501682;
    border-radius: 10px;
    padding: 8px 16px;
    font-size: 12px;
    float: right;
  }
  .btn-email {
    width: 100%;
    font-family: maven-semibold;
    border-radius: 10px;
    background: #333;
    color: #fff;
    font-size: 12px;
  }
  label {
    font-size: 14px;
  }
  .label-modal {
    font-family: maven-semibold;
    font-size: 16px;
    margin-bottom: 3px;
  }
</style>
