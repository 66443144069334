export default
[
    {
        "city": "Santiago", 
        "lat": "-33.4500", 
        "lng": "-70.6667", 
        "country": "Chile", 
        "iso2": "CL", 
        "admin_name": "Regi\u00f3n Metropolitana", 
        "capital": "primary", 
        "population": "7007000", 
        "population_proper": "236453"
    }, 
    {
        "city": "Antofagasta", 
        "lat": "-23.6464", 
        "lng": "-70.3980", 
        "country": "Chile", 
        "iso2": "CL", 
        "admin_name": "Antofagasta", 
        "capital": "admin", 
        "population": "380695", 
        "population_proper": "380695"
    }, 
    {
        "city": "Vi\u00f1a del Mar", 
        "lat": "-33.0244", 
        "lng": "-71.5517", 
        "country": "Chile", 
        "iso2": "CL", 
        "admin_name": "Valpara\u00edso", 
        "capital": "", 
        "population": "332875", 
        "population_proper": "332875"
    }, 
    {
        "city": "Valpara\u00edso", 
        "lat": "-33.0458", 
        "lng": "-71.6197", 
        "country": "Chile", 
        "iso2": "CL", 
        "admin_name": "Valpara\u00edso", 
        "capital": "admin", 
        "population": "296655", 
        "population_proper": "296655"
    }, 
    {
        "city": "Temuco", 
        "lat": "-38.7399", 
        "lng": "-72.5901", 
        "country": "Chile", 
        "iso2": "CL", 
        "admin_name": "Araucan\u00eda", 
        "capital": "admin", 
        "population": "282415", 
        "population_proper": "282415"
    }, 
    {
        "city": "San Bernardo", 
        "lat": "-33.6000", 
        "lng": "-70.7000", 
        "country": "Chile", 
        "iso2": "CL", 
        "admin_name": "Regi\u00f3n Metropolitana", 
        "capital": "minor", 
        "population": "246762", 
        "population_proper": "241138"
    }, 
    {
        "city": "Puerto Montt", 
        "lat": "-41.4718", 
        "lng": "-72.9396", 
        "country": "Chile", 
        "iso2": "CL", 
        "admin_name": "Los Lagos", 
        "capital": "admin", 
        "population": "245902", 
        "population_proper": "245902"
    }, 
    {
        "city": "Rancagua", 
        "lat": "-34.1654", 
        "lng": "-70.7398", 
        "country": "Chile", 
        "iso2": "CL", 
        "admin_name": "Libertador General Bernardo O\u2019Higgins", 
        "capital": "admin", 
        "population": "231370", 
        "population_proper": "231370"
    }, 
    {
        "city": "La Serena", 
        "lat": "-29.9027", 
        "lng": "-71.2520", 
        "country": "Chile", 
        "iso2": "CL", 
        "admin_name": "Coquimbo", 
        "capital": "admin", 
        "population": "221054", 
        "population_proper": "221054"
    }, 
    {
        "city": "Los \u00c1ngeles", 
        "lat": "-37.4707", 
        "lng": "-72.3517", 
        "country": "Chile", 
        "iso2": "CL", 
        "admin_name": "Biob\u00edo", 
        "capital": "minor", 
        "population": "218000", 
        "population_proper": "218000"
    }, 
    {
        "city": "Concepci\u00f3n", 
        "lat": "-36.8271", 
        "lng": "-73.0503", 
        "country": "Chile", 
        "iso2": "CL", 
        "admin_name": "Biob\u00edo", 
        "capital": "admin", 
        "population": "217537", 
        "population_proper": "217537"
    }, 
    {
        "city": "Coquimbo", 
        "lat": "-29.9532", 
        "lng": "-71.3380", 
        "country": "Chile", 
        "iso2": "CL", 
        "admin_name": "Coquimbo", 
        "capital": "minor", 
        "population": "204068", 
        "population_proper": "204068"
    }, 
    {
        "city": "Arica", 
        "lat": "-18.4784", 
        "lng": "-70.3211", 
        "country": "Chile", 
        "iso2": "CL", 
        "admin_name": "Arica y Parinacota", 
        "capital": "admin", 
        "population": "202131", 
        "population_proper": "202131"
    }, 
    {
        "city": "Talca", 
        "lat": "-35.4550", 
        "lng": "-71.6700", 
        "country": "Chile", 
        "iso2": "CL", 
        "admin_name": "Maule", 
        "capital": "admin", 
        "population": "197479", 
        "population_proper": "175088"
    }, 
    {
        "city": "Iquique", 
        "lat": "-20.2141", 
        "lng": "-70.1524", 
        "country": "Chile", 
        "iso2": "CL", 
        "admin_name": "Tarapac\u00e1", 
        "capital": "admin", 
        "population": "188003", 
        "population_proper": "188003"
    }, 
    {
        "city": "Chill\u00e1n", 
        "lat": "-36.6067", 
        "lng": "-72.1033", 
        "country": "Chile", 
        "iso2": "CL", 
        "admin_name": "Biob\u00edo", 
        "capital": "admin", 
        "population": "164270", 
        "population_proper": "164270"
    }, 
    {
        "city": "Calama", 
        "lat": "-22.4624", 
        "lng": "-68.9272", 
        "country": "Chile", 
        "iso2": "CL", 
        "admin_name": "Antofagasta", 
        "capital": "minor", 
        "population": "157575", 
        "population_proper": "157575"
    }, 
    {
        "city": "Copiap\u00f3", 
        "lat": "-27.3665", 
        "lng": "-70.3323", 
        "country": "Chile", 
        "iso2": "CL", 
        "admin_name": "Atacama", 
        "capital": "admin", 
        "population": "150804", 
        "population_proper": "150804"
    }, 
    {
        "city": "Valdivia", 
        "lat": "-39.8142", 
        "lng": "-73.2459", 
        "country": "Chile", 
        "iso2": "CL", 
        "admin_name": "Los R\u00edos", 
        "capital": "admin", 
        "population": "150048", 
        "population_proper": "150048"
    }, 
    {
        "city": "Quilpu\u00e9", 
        "lat": "-33.0498", 
        "lng": "-71.4415", 
        "country": "Chile", 
        "iso2": "CL", 
        "admin_name": "Valpara\u00edso", 
        "capital": "minor", 
        "population": "147991", 
        "population_proper": "147991"
    }, 
    {
        "city": "Talcahuano", 
        "lat": "-36.7145", 
        "lng": "-73.1141", 
        "country": "Chile", 
        "iso2": "CL", 
        "admin_name": "Biob\u00edo", 
        "capital": "", 
        "population": "147831", 
        "population_proper": "147831"
    }, 
    {
        "city": "Osorno", 
        "lat": "-40.5667", 
        "lng": "-73.1500", 
        "country": "Chile", 
        "iso2": "CL", 
        "admin_name": "Los Lagos", 
        "capital": "minor", 
        "population": "147460", 
        "population_proper": "147460"
    }, 
    {
        "city": "Curic\u00f3", 
        "lat": "-34.9854", 
        "lng": "-71.2394", 
        "country": "Chile", 
        "iso2": "CL", 
        "admin_name": "Maule", 
        "capital": "minor", 
        "population": "125275", 
        "population_proper": "125275"
    }, 
    {
        "city": "Villa Alemana", 
        "lat": "-33.0428", 
        "lng": "-71.3744", 
        "country": "Chile", 
        "iso2": "CL", 
        "admin_name": "Valpara\u00edso", 
        "capital": "", 
        "population": "125140", 
        "population_proper": "125140"
    }, 
    {
        "city": "Punta Arenas", 
        "lat": "-53.1627", 
        "lng": "-70.9081", 
        "country": "Chile", 
        "iso2": "CL", 
        "admin_name": "Magallanes y de la Ant\u00e1rtica Chilena", 
        "capital": "admin", 
        "population": "123403", 
        "population_proper": "123403"
    }, 
    {
        "city": "Coronel", 
        "lat": "-37.0300", 
        "lng": "-73.1600", 
        "country": "Chile", 
        "iso2": "CL", 
        "admin_name": "Biob\u00edo", 
        "capital": "", 
        "population": "92940", 
        "population_proper": "64249"
    }, 
    {
        "city": "Quillota", 
        "lat": "-32.8799", 
        "lng": "-71.2474", 
        "country": "Chile", 
        "iso2": "CL", 
        "admin_name": "Valpara\u00edso", 
        "capital": "minor", 
        "population": "90517", 
        "population_proper": "90517"
    }, 
    {
        "city": "San Antonio", 
        "lat": "-33.5809", 
        "lng": "-71.6132", 
        "country": "Chile", 
        "iso2": "CL", 
        "admin_name": "Valpara\u00edso", 
        "capital": "minor", 
        "population": "86239", 
        "population_proper": "86239"
    }, 
    {
        "city": "Ovalle", 
        "lat": "-30.6031", 
        "lng": "-71.2030", 
        "country": "Chile", 
        "iso2": "CL", 
        "admin_name": "Coquimbo", 
        "capital": "minor", 
        "population": "75864", 
        "population_proper": "75864"
    }, 
    {
        "city": "Linares", 
        "lat": "-35.8454", 
        "lng": "-71.5979", 
        "country": "Chile", 
        "iso2": "CL", 
        "admin_name": "Maule", 
        "capital": "minor", 
        "population": "73602", 
        "population_proper": "73602"
    }, 
    {
        "city": "Los Andes", 
        "lat": "-32.8337", 
        "lng": "-70.5982", 
        "country": "Chile", 
        "iso2": "CL", 
        "admin_name": "Valpara\u00edso", 
        "capital": "minor", 
        "population": "68093", 
        "population_proper": "68093"
    }, 
    {
        "city": "San Felipe", 
        "lat": "-32.7507", 
        "lng": "-70.7251", 
        "country": "Chile", 
        "iso2": "CL", 
        "admin_name": "Valpara\u00edso", 
        "capital": "minor", 
        "population": "64120", 
        "population_proper": "64120"
    }, 
    {
        "city": "San Fernando", 
        "lat": "-34.5839", 
        "lng": "-70.9891", 
        "country": "Chile", 
        "iso2": "CL", 
        "admin_name": "Libertador General Bernardo O\u2019Higgins", 
        "capital": "minor", 
        "population": "58367", 
        "population_proper": "58367"
    }, 
    {
        "city": "Angol", 
        "lat": "-37.7988", 
        "lng": "-72.7086", 
        "country": "Chile", 
        "iso2": "CL", 
        "admin_name": "Araucan\u00eda", 
        "capital": "minor", 
        "population": "53262", 
        "population_proper": "53262"
    }, 
    {
        "city": "Coyhaique", 
        "lat": "-45.5712", 
        "lng": "-72.0685", 
        "country": "Chile", 
        "iso2": "CL", 
        "admin_name": "Ays\u00e9n", 
        "capital": "admin", 
        "population": "49667", 
        "population_proper": "49667"
    }, 
    {
        "city": "Vallenar", 
        "lat": "-28.5700", 
        "lng": "-70.7600", 
        "country": "Chile", 
        "iso2": "CL", 
        "admin_name": "Atacama", 
        "capital": "minor", 
        "population": "44895", 
        "population_proper": "30858"
    }, 
    {
        "city": "Lota", 
        "lat": "-37.0944", 
        "lng": "-73.1563", 
        "country": "Chile", 
        "iso2": "CL", 
        "admin_name": "Biob\u00edo", 
        "capital": "", 
        "population": "43272", 
        "population_proper": "43272"
    }, 
    {
        "city": "Tom\u00e9", 
        "lat": "-36.6171", 
        "lng": "-72.9575", 
        "country": "Chile", 
        "iso2": "CL", 
        "admin_name": "Biob\u00edo", 
        "capital": "", 
        "population": "42312", 
        "population_proper": "42312"
    }, 
    {
        "city": "Alerce", 
        "lat": "-41.3969", 
        "lng": "-72.9037", 
        "country": "Chile", 
        "iso2": "CL", 
        "admin_name": "Los Lagos", 
        "capital": "", 
        "population": "42267", 
        "population_proper": "42267"
    }, 
    {
        "city": "Constituci\u00f3n", 
        "lat": "-35.3296", 
        "lng": "-72.4200", 
        "country": "Chile", 
        "iso2": "CL", 
        "admin_name": "Maule", 
        "capital": "", 
        "population": "37917", 
        "population_proper": "15888"
    }, 
    {
        "city": "Lampa", 
        "lat": "-33.2864", 
        "lng": "-70.8729", 
        "country": "Chile", 
        "iso2": "CL", 
        "admin_name": "Regi\u00f3n Metropolitana", 
        "capital": "", 
        "population": "37599", 
        "population_proper": "37599"
    }, 
    {
        "city": "Castro", 
        "lat": "-42.4824", 
        "lng": "-73.7643", 
        "country": "Chile", 
        "iso2": "CL", 
        "admin_name": "Los Lagos", 
        "capital": "minor", 
        "population": "33417", 
        "population_proper": "33417"
    }, 
    {
        "city": "Villarrica", 
        "lat": "-39.2800", 
        "lng": "-72.2300", 
        "country": "Chile", 
        "iso2": "CL", 
        "admin_name": "Araucan\u00eda", 
        "capital": "", 
        "population": "31602", 
        "population_proper": "2034"
    }, 
    {
        "city": "Cauquenes", 
        "lat": "-35.9671", 
        "lng": "-72.3154", 
        "country": "Chile", 
        "iso2": "CL", 
        "admin_name": "Maule", 
        "capital": "minor", 
        "population": "31362", 
        "population_proper": "16948"
    }, 
    {
        "city": "Curanilahue", 
        "lat": "-37.4800", 
        "lng": "-73.3400", 
        "country": "Chile", 
        "iso2": "CL", 
        "admin_name": "Biob\u00edo", 
        "capital": "", 
        "population": "30611", 
        "population_proper": "14094"
    }, 
    {
        "city": "San Javier", 
        "lat": "-35.5924", 
        "lng": "-71.7353", 
        "country": "Chile", 
        "iso2": "CL", 
        "admin_name": "Maule", 
        "capital": "", 
        "population": "29017", 
        "population_proper": "29017"
    }, 
    {
        "city": "Ancud", 
        "lat": "-41.8682", 
        "lng": "-73.8287", 
        "country": "Chile", 
        "iso2": "CL", 
        "admin_name": "Los Lagos", 
        "capital": "", 
        "population": "28162", 
        "population_proper": "28162"
    }, 
    {
        "city": "Chill\u00e1n Viejo", 
        "lat": "-36.6229", 
        "lng": "-72.1317", 
        "country": "Chile", 
        "iso2": "CL", 
        "admin_name": "Biob\u00edo", 
        "capital": "", 
        "population": "27359", 
        "population_proper": "27359"
    }, 
    {
        "city": "La Uni\u00f3n", 
        "lat": "-40.2952", 
        "lng": "-73.0822", 
        "country": "Chile", 
        "iso2": "CL", 
        "admin_name": "Los R\u00edos", 
        "capital": "minor", 
        "population": "26517", 
        "population_proper": "26517"
    }, 
    {
        "city": "Puerto Varas", 
        "lat": "-41.3178", 
        "lng": "-72.9827", 
        "country": "Chile", 
        "iso2": "CL", 
        "admin_name": "Los Lagos", 
        "capital": "", 
        "population": "26172", 
        "population_proper": "26172"
    }, 
    {
        "city": "Victoria", 
        "lat": "-38.2395", 
        "lng": "-72.3400", 
        "country": "Chile", 
        "iso2": "CL", 
        "admin_name": "Araucan\u00eda", 
        "capital": "", 
        "population": "24555", 
        "population_proper": "11424"
    }, 
    {
        "city": "Tocopilla", 
        "lat": "-22.0941", 
        "lng": "-70.2010", 
        "country": "Chile", 
        "iso2": "CL", 
        "admin_name": "Antofagasta", 
        "capital": "minor", 
        "population": "24460", 
        "population_proper": "20251"
    }, 
    {
        "city": "Lebu", 
        "lat": "-37.6103", 
        "lng": "-73.6561", 
        "country": "Chile", 
        "iso2": "CL", 
        "admin_name": "Biob\u00edo", 
        "capital": "minor", 
        "population": "22345", 
        "population_proper": "10904"
    }, 
    {
        "city": "Casablanca", 
        "lat": "-33.3167", 
        "lng": "-71.4167", 
        "country": "Chile", 
        "iso2": "CL", 
        "admin_name": "Valpara\u00edso", 
        "capital": "", 
        "population": "21874", 
        "population_proper": "21874"
    }, 
    {
        "city": "Illapel", 
        "lat": "-31.6327", 
        "lng": "-71.1683", 
        "country": "Chile", 
        "iso2": "CL", 
        "admin_name": "Coquimbo", 
        "capital": "minor", 
        "population": "20751", 
        "population_proper": "20751"
    }, 
    {
        "city": "Salamanca", 
        "lat": "-31.7796", 
        "lng": "-70.9800", 
        "country": "Chile", 
        "iso2": "CL", 
        "admin_name": "Coquimbo", 
        "capital": "", 
        "population": "20214", 
        "population_proper": "13265"
    }, 
    {
        "city": "La Ligua", 
        "lat": "-32.4494", 
        "lng": "-71.2317", 
        "country": "Chile", 
        "iso2": "CL", 
        "admin_name": "Valpara\u00edso", 
        "capital": "minor", 
        "population": "19127", 
        "population_proper": "19127"
    }, 
    {
        "city": "Puerto Natales", 
        "lat": "-51.7263", 
        "lng": "-72.5062", 
        "country": "Chile", 
        "iso2": "CL", 
        "admin_name": "Magallanes y de la Ant\u00e1rtica Chilena", 
        "capital": "minor", 
        "population": "19023", 
        "population_proper": "19023"
    }, 
    {
        "city": "Puerto Ays\u00e9n", 
        "lat": "-45.4068", 
        "lng": "-72.6977", 
        "country": "Chile", 
        "iso2": "CL", 
        "admin_name": "Ays\u00e9n", 
        "capital": "minor", 
        "population": "17441", 
        "population_proper": "17441"
    }, 
    {
        "city": "Pichilemu", 
        "lat": "-34.3851", 
        "lng": "-72.0049", 
        "country": "Chile", 
        "iso2": "CL", 
        "admin_name": "Libertador General Bernardo O\u2019Higgins", 
        "capital": "minor", 
        "population": "12776", 
        "population_proper": "12776"
    }, 
    {
        "city": "Pozo Almonte", 
        "lat": "-20.2597", 
        "lng": "-69.7862", 
        "country": "Chile", 
        "iso2": "CL", 
        "admin_name": "Tarapac\u00e1", 
        "capital": "minor", 
        "population": "9277", 
        "population_proper": "9277"
    }, 
    {
        "city": "Hanga Roa", 
        "lat": "-27.1492", 
        "lng": "-109.4323", 
        "country": "Chile", 
        "iso2": "CL", 
        "admin_name": "Valpara\u00edso", 
        "capital": "minor", 
        "population": "7163", 
        "population_proper": "7163"
    }, 
    {
        "city": "Porvenir", 
        "lat": "-53.2956", 
        "lng": "-70.3687", 
        "country": "Chile", 
        "iso2": "CL", 
        "admin_name": "Magallanes y de la Ant\u00e1rtica Chilena", 
        "capital": "minor", 
        "population": "5992", 
        "population_proper": "5992"
    }, 
    {
        "city": "Chile Chico", 
        "lat": "-46.5376", 
        "lng": "-71.7293", 
        "country": "Chile", 
        "iso2": "CL", 
        "admin_name": "Ays\u00e9n", 
        "capital": "minor", 
        "population": "3129", 
        "population_proper": "3129"
    }, 
    {
        "city": "Cochrane", 
        "lat": "-47.2539", 
        "lng": "-72.5732", 
        "country": "Chile", 
        "iso2": "CL", 
        "admin_name": "Ays\u00e9n", 
        "capital": "minor", 
        "population": "2789", 
        "population_proper": "2789"
    }, 
    {
        "city": "Puerto Williams", 
        "lat": "-54.9341", 
        "lng": "-67.6109", 
        "country": "Chile", 
        "iso2": "CL", 
        "admin_name": "Magallanes y de la Ant\u00e1rtica Chilena", 
        "capital": "minor", 
        "population": "1868", 
        "population_proper": "1868"
    }, 
    {
        "city": "Chait\u00e9n", 
        "lat": "-42.9165", 
        "lng": "-72.7084", 
        "country": "Chile", 
        "iso2": "CL", 
        "admin_name": "Los Lagos", 
        "capital": "minor", 
        "population": "1639", 
        "population_proper": "1639"
    }, 
    {
        "city": "Puente Alto", 
        "lat": "-33.6117", 
        "lng": "-70.5758", 
        "country": "Chile", 
        "iso2": "CL", 
        "admin_name": "Regi\u00f3n Metropolitana", 
        "capital": "minor", 
        "population": "", 
        "population_proper": ""
    }, 
    {
        "city": "Talagante", 
        "lat": "-33.6639", 
        "lng": "-70.9273", 
        "country": "Chile", 
        "iso2": "CL", 
        "admin_name": "Regi\u00f3n Metropolitana", 
        "capital": "minor", 
        "population": "", 
        "population_proper": ""
    }, 
    {
        "city": "Colina", 
        "lat": "-33.2044", 
        "lng": "-70.6747", 
        "country": "Chile", 
        "iso2": "CL", 
        "admin_name": "Regi\u00f3n Metropolitana", 
        "capital": "minor", 
        "population": "", 
        "population_proper": ""
    }, 
    {
        "city": "Melipilla", 
        "lat": "-33.6855", 
        "lng": "-71.2146", 
        "country": "Chile", 
        "iso2": "CL", 
        "admin_name": "Regi\u00f3n Metropolitana", 
        "capital": "minor", 
        "population": "", 
        "population_proper": ""
    }, 
    {
        "city": "Cha\u00f1aral", 
        "lat": "-26.3479", 
        "lng": "-70.6224", 
        "country": "Chile", 
        "iso2": "CL", 
        "admin_name": "Atacama", 
        "capital": "minor", 
        "population": "", 
        "population_proper": ""
    }, 
    {
        "city": "Putre", 
        "lat": "-18.1964", 
        "lng": "-69.5592", 
        "country": "Chile", 
        "iso2": "CL", 
        "admin_name": "Arica y Parinacota", 
        "capital": "minor", 
        "population": "", 
        "population_proper": ""
    }
]