var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.serarching)?_c('div',{attrs:{"id":"outserch"},on:{"click":_vm.serch}}):_vm._e(),_c('div',{staticClass:"container p-0",staticStyle:{"position":"relative"}},[(_vm.serarching)?_c('SerchMod',{attrs:{"fun":_vm.serch}}):_vm._e(),_c('div',{},[_c('div',{staticClass:"row header py-3"},[_vm._m(0),_c('div',{staticClass:"col-lg col-12 logo"},[_c('router-link',{attrs:{"to":"/"}},[_c('img',{attrs:{"src":require("../../assets/img/logo.png"),"alt":""}})])],1),_c('div',{staticClass:"col-lg col-12 icons align-self-end"},[_c('i',{staticClass:"fas fa-search search",on:{"click":_vm.serch}}),(!this.$store.state.tokenData.permissions.isTarotReader)?_c('router-link',{attrs:{"to":_vm.dashboardLink}},[_c('div',{staticClass:"wallet"},[_c('i',{staticClass:"fas fa-wallet"}),_c('span',[_vm._v("$"+_vm._s(_vm.numberWithCommas(_vm.balance)))])])]):_vm._e(),_c('div',{staticClass:"user"},[_c('DropdownUser')],1),_c('div',{staticClass:"bell"},[_c('DropdownNoti')],1)],1)]),_c('b-navbar',{attrs:{"toggleable":"lg"}},[_c('b-navbar-toggle',{attrs:{"target":"nav-collapse"}}),_c('b-collapse',{attrs:{"id":"nav-collapse","is-nav":""}},[_c('b-navbar-nav',{staticClass:"col-12 mb-1 justify-content-center text-center"},[(
                        !this.$store.state.tokenData.permissions
                            .isTarotReader
                    )?_c('b-nav-item',{staticClass:"nav-border",attrs:{"to":"/","exact":"","exact-active-class":"active"}},[_vm._v("Inicio")]):_vm._e(),(
                !this.$store.state.tokenData.permissions.isTarotReader
                )?_c('b-nav-item',{staticClass:"nav-border",attrs:{"to":"/catalogo","active-class":"active"}},[_vm._v("Tarotistas")]):_vm._e(),(
                        !this.$store.state.tokenData.permissions
                            .isTarotReader
                    )?_c('b-nav-item',{staticClass:"nav-border",attrs:{"to":"/servicios","active-class":"active"}},[_vm._v("Servicios")]):_vm._e(),_c('b-nav-item',{staticClass:"nav-border",attrs:{"to":"/contactanos","active-class":"active","vi":""}},[_vm._v("Contáctanos")]),_c('b-nav-item',{staticClass:"nav-border",attrs:{"to":_vm.dashboardLink}},[_c('span',{staticClass:"dashboard"},[_vm._v(" "+_vm._s(_vm.dashboardText)+" ")])]),(
                        !this.$store.state.tokenData.permissions
                            .isTarotReader
                    )?_c('b-nav-item',{attrs:{"to":"/planes","active-class":"active"}},[_vm._v("Bolsas de Saldo y Ahorro")]):_vm._e()],1)],1)],1)],1)],1)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-lg social-media align-self-end d-lg-block d-none"},[_c('a',{attrs:{"href":"https://www.facebook.com/www.divinotarot.cl","target":"_blank"}},[_c('i',{staticClass:"fab fa-facebook-f",staticStyle:{"font-size":"19px"}})]),_c('a',{attrs:{"href":"https://www.instagram.com/divinotarot_/","target":"_blank"}},[_c('i',{staticClass:"fab fa-instagram"})])])
}]

export { render, staticRenderFns }