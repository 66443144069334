<template>
  <div>
    <b-modal
      :no-close-on-esc="true"
      :no-close-on-backdrop="true"
      id="modalAvisoBancario"
      centered
      hide-footer
      hide-header-close
    >
      <p class="mt-2 mb-5 text-modal">
        Debe rellenar sus datos bancarios para que pueda recibir los pagos. <b>Gracias</b>
      </p>
      <div class="center-flex">
        <a href="#datos-bancarios" @click="closeModal" class="btn btn-modal">Rellenar datos bancarios</a>
      </div>
    </b-modal>
  </div>
</template>
<script>
export default {
  watch: {

  },
  props: [],

  data: () => {
    return {
      showModal: true,
    };
  },
  methods: {
    closeModal() {
      this.$bvModal.hide('modalAvisoBancario');
    },
  },
};
</script>

<style scoped>
/deep/ .center-flex {
  display: flex;
  justify-content: center; /* Centra horizontalmente */
  align-items: center; /* Centra verticalmente si el contenedor tiene altura específica */
  margin: 5px;
}
/deep/.close {
  background: transparent;
  border: none;
}
/deep/.modal-header {
  border-bottom: none;
}
/deep/.modal-body {
  padding: 0 50px;
}
.text-modal {
  color: #501682;
  font-family: maven-bold;
  text-align: center;
  text-transform: uppercase;
  font-size: 20px;
}
.text-saldo {
  text-align: center;
  font-family: maven-regular;
  font-size: 20px;
}
.text-saldo span {
  font-family: maven-bold;
  color: #3b5998;
}
/deep/.modal-footer {
  border-top: none;
  padding: 10px 50px 30px 50px;
}
/deep/.btn-modal {
  background-color: #04d9b2;
  color: #fff;
  font-family: maven-bold;
  text-transform: uppercase;
  border: none;
}
</style>
