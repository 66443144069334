<template>
    <div class="container bg-violeta">
        <div class="row justify-content-center">
            <div class="col-12">
                <h5 class="title general-title">Especialidades</h5>
            </div>
            <div class="col-6">
                <p class="subtitle">
                    En DivinoTarot te ofrecemos una amplia variedad de especialidades según tu preferencia.
                </p>
            </div>
        </div>
        <div class="row justify-content-between">
            <div
                class="col-lg col-sm-12 mt-4"
                v-for="specialty in specialties"
                :key="specialty.specialtyId"
            >
                <div class="especialidad">
                    <div class="especialidad-img">
                        <div class="container-img">
                            <img
                                :src="specialty.specialtyImage"
                                alt=""
                                width="100%"
                            />
                        </div>
                        <div class="border-img"></div>
                    </div>

                    <div class="speciality-text">
                        <span class="speciality-name my-3">{{
                            specialty.specialtyName
                        }}</span>

                        <p>
                            {{specialty.specialtyDescription}}
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axiosClient from '../../config/axiosClient'
import { enableLogs } from '../../config';

export default {
    data() {
        return {
            specialties: [],
        }
    },
    created() {
        this.getSpecialties()
    },
    methods: {
        async getSpecialties() {
            this.isLoading = true
            try {
                const response = await axiosClient.get(
                    '/specialties/get-all-specialties'
                )
                this.specialties = response.data.specialties
                this.isLoading = false
            } catch (error) {
               if(enableLogs) console.log(error)
                this.isLoading = false
            }
        },
    },
}
</script>

<style scoped>
.bg-violeta {
    background: #501682;
    padding: 120px 0;
}
.title {
    text-align: center;
    margin-bottom: 15px;
    color: white;
}
.subtitle {
    color: white;
}
.especialidad {
    text-align: center;

    margin: auto;
}
.especialidad-img {
    position: relative;
}
.especialidad-img:hover .container-img {
    background: #04d9b2;
}
.container-img {
    width: 100px;
    height: 100px;
    background: white;
    border-radius: 50%;
    margin: auto;
    border: 1px dotted white;
}
.container-img img {
    max-width: 65px;
    margin-top: calc(50% - 35px);
}
.border-img {
    border: 2px dotted white;
    padding-top: 2px;
    border-radius: 50%;
    /* border-radius: 50%; */
    width: 110px;
    height: 110px;
    position: absolute;
    top: -5px;
    left: calc(50% - 55px);
    position: absolute;
}

.border-img:hover {
    border-color: #04d9b2;
    -moz-transform: rotate(1450deg);
    -ms-transform: rotate(1450deg);
    -o-transform: rotate(1450deg);
    -webkit-transform: rotate(1450deg);
    -webkit-transition: all 0.25s ease-out;
}

.speciality-text {
    margin-top: 20px;
    font-size: 13px;
    color: white;
    font-family: maven-bold;
}
.speciality-text .speciality-name::after {
    content: '';
    display: block;
    width: 36px;
    height: 2px;
    background: #04d9b2;
    margin-left: calc(50% - 18px);
    margin-top: 10px;
    margin-bottom: 10px;
}

.speciality-text p {
    font-family: maven-regular;
    font-size: 11px;
}
</style>
