<template>
  <!-- Modal Fin Llamada -->
  <div>
    <b-modal
      :no-close-on-esc="true"
      :no-close-on-backdrop="true"
      id="modal-calendly"
      size="md"
      centered
      :visible="true"
      v-model="modalShow"
      @hidden="onModalHidden"
    >
      <template>
        <div class="row">
          <iframe :src="linkCalendly" width="1000" height="800"></iframe>
        </div>
      </template>
    </b-modal>
  </div>
</template>
<script>

/* import ciudadesChile from '../../data/ciudadesChile'; */
export default {
  data: function () {
    return {
      modalShow: true,
    };
  },
   props: ["emailNotUser", "disableBtn", "linkCalendly"],
  computed: {
    tokenData() {
      //console.log(this.$store.state.tokenData);
      return this.$store.state.tokenData;
    },

  },

  methods: {

    onModalHidden() {
      // Aquí puedes cambiar el valor de la variable o ejecutar cualquier acción que desees
      // Por ejemplo, puedes emitir un evento para notificar al componente padre
      this.$emit("modal-closed");
    },

    sendData: async function() {

    },


  },
  mounted(){
  },
  created() {
    
  },
};
</script>



