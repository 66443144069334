<template>
  <div class="col-12">
    <div class="container-title">
      <h5 class="title general-title">Comentarios</h5>
    </div>
    <div id="my-table">
      <div
        class="container-resena"
        v-for="resena in resenas.slice(
          (currentPage - 1) * perPage,
          (currentPage - 1) * perPage + perPage
        )"
        :key="resena.id_usuario"
      >
        <div class="col-img">
          <div class="image">
            <img src="../../assets/img/avatar.jpg" alt="" />
          </div>
        </div>
        <div class="row justify-content-end align-items-end mb-2">
          <div class="col-4 p-0">
            <span class="name-tarotista">{{ resena.nombre }}</span>
          </div>
          <div class="col-6 col-puntaje">
            <div class="row">
              <div class="score col-12">
                <Star :value="resena.valoracion" :shownumer="false" :size="1"/>
              </div>
            </div>
          </div>
        </div>

        <div class="col-12">
          <div class="card py-3 px-5">
            <h5 class="card-title">{{ resena.titulo }}</h5>
            <p class="descripcion">
              {{ resena.comentario }}
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="col-12 my-5" v-if="resenas != 0">
      <b-pagination
        v-model="currentPage"
        :total-rows="resenas.length"
        :per-page="perPage"
        align="center"
      ></b-pagination>
    </div>
    <!-- <div class="col-12 text-center">
      <button class="btn btn-ver-mas" @click='mostrarModal'>Escribir un comentario</button>
    </div>
     -->
  </div>
</template>
<script>
import {activateModal} from '../../valoracion/activateModal.js'
import { valoracionTarotista } from '../../valoracion/valoraciones.js'
import { enableLogs } from '../../config';
import Star from '../util/Stars.vue'
export default {
  components:{
    Star
  },
  data: () => {
    return {
      resenas: [],
      perPage: 6,
      currentPage: 1,
      modalShow: false
    };
  },
  props: {
    tarotista: Object,
  },
  computed: {
    rows() {
      return this.items.length;
    },
  },
  async created() {
    await this.mostrarValoracion();
  },
  methods:{
    async mostrarModal() {
      let tokenUser;
      try {
        tokenUser = this.$store.state.jwt;
      } catch(err) {
        if (enableLogs) console.log(err)
      }

      if(tokenUser && tokenUser != null){
        const result = await activateModal(tokenUser,this.tarotista.tarotReaderId);

        if(result === -1)
          return this.$store.commit("setMessage", "Ocurrio un problema, no pudimos procesar tu comentario.");

        if(result === 1 || !result)
          return this.$store.commit("setMessage", "No puedes dejar un comentario porque tu última llamada no fue con este tarotista.");

        if(result)
          this.modalShow = true;
      }else{
        this.$store.commit("setMessage", "Para dejar un comentario a este tarotista debes tener una lectura y estar logueado.");
      }
      
      
    },

    async mostrarValoracion(){
      this.resenas = await valoracionTarotista(this.tarotista.userId);
    }
  }
};
</script>

<style scoped>
.title {
  color: #ffffff;
  margin-bottom: 0px;
  font-size: 28px;
}
.container-title {
  padding-bottom: 55px;
}
.container-resena {
  position: relative;
  margin-bottom: 20px;
}
.card {
  border-radius: 10px;
  margin: 0 20px 0 30px;
  color: #501682;
}
.card-title,
.descripcion {
  font-family: maven-bold;
  font-size: 12px;
  text-align: justify;
  text-justify: inter-word;
}
.col-img {
  max-width: 70px;
  position: absolute;
  z-index: 99;
}
.image img {
  width: 100%;
  border-radius: 50%;
  height: 100%;
  object-fit: cover;
}
.name-tarotista {
  color: #fff;
  font-size: 12px;
  font-family: maven-regular;
}
.col-puntaje,
.score {
  text-align: right;
  padding-right: 35px;
  margin-left: 4px;
}

.star {
  float: right;
  margin-left: 8px;
  font-size: 12px;
  color: #ffc30b;
}
.descripcion {
  font-family: maven-regular;
  margin-bottom: 0;
}

/*paginacion*/
/deep/ .pagination {
  font-family: maven-bold;
}
/deep/ .page-link {
  color: #fff;
  background-color: transparent;
  border: none;
  margin: 0 10px;
}
/deep/ .page-item.active .page-link {
  background-color: #fff;
  border-color: #fff;
  color: #501682;
  border-radius: 8px;
}
/deep/.page-item.disabled .page-link {
  color: rgb(143, 143, 143);
  pointer-events: none;
  background-color: #501682;
  border-color: #501682;
}

.btn-ver-mas {
  font-family: maven-bold;
  background: transparent;
  border: 1px solid #ffc30b;
  color: #ffc30b;
  padding: 6px 40px;
  border-radius: 50px;
  font-size: 14px;
}
.btn-ver-mas:hover {
  background: transparent;
  border: 1px solid #04d9b2;
  color: #04d9b2;
}
</style>