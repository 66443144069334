<template>
  <div class="container">
    <div class="row justify-content-center mt-5">
      <div class="col-12 mb-3">
          <div class="text-back" @click="goBack">
            <i class="fas fa-chevron-left"></i>
            <span>Volver</span>
          </div>
      </div>
       <h5 class="title general-title">Configuración</h5>
      <div class="col-lg-9 col-md-10 col-12 my-3">
          <Info/>
      </div>
    </div>
  </div>
</template>

<script>
import Info from "./Info.vue";
export default {
  name: "Setting",
  components: {
    Info,
  },
  methods:{
    goBack(){
      window.history.go(-1);
      location.reload();
      }
  }
};
</script>

<style scoped>
.text-back {
  color: #501682;
  text-transform: uppercase;
  font-family: maven-semibold;
  font-size: 13px;
  cursor: pointer;
}
.text-back:hover {
  color: #934dd8;
}
.text-back span {
  margin-left: 6px;
}


</style>

