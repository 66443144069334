<template>
  <div class="container">
    <div class="row mt-5">
        <h5 class="general-title">Preguntas Frecuentes</h5>
      <div class="col-12 my-3">
          <Question/>
      </div>
      <div class="col-12 my-3">
          <QuestionTarotista/>
      </div>
    </div>
  </div>
</template>

<script>
import Question from "./Question.vue";
import QuestionTarotista from "./QuestionTarotista.vue";
export default {
  name: "Sac",
  components: {
    Question,
    QuestionTarotista
  },
};
</script>

<style scoped>


</style>
