<template>
    <div>
        <h5>PASO 1: Información Personal</h5>
        <b-card class="">
            <form action="">
                <div class="row">
                    <div class="col-12 mb-3">
                        <label for="">Email(*)</label>
                        <b-form-input
                            type="email"
                            class="form-control"
                            aria-label="Username"
                            aria-describedby="basic-addon1"
                            v-model="email"
                            autocomplete="off"
                        />
                        <span
                            class="alert"
                            v-if="validarEmail(email.toString())"
                            >Campo obligatorio</span
                        >
                    </div>
                    <div class="col-12 mb-3">
                        <label for="">Repetir email(*)</label>
                        <b-form-input
                            type="email"
                            class="form-control"
                            aria-label="Username"
                            aria-describedby="basic-addon1"
                            v-model="emailConfirm"
                            autocomplete="off"
                        />
                        <span
                            class="alert"
                            v-if="validarEmail(emailConfirm.toString())"
                            >Campo obligatorio</span
                        >
                        <p class="alert" v-if="checkEmailEqual(emailConfirm.toString())">
                        Los emails no coinciden.
                        </p>
                    </div>
                    <div class="col-lg-6 col-12 mb-3">
                        <label for="">Nombre (*)</label>
                        <b-form-input
                            type="text"
                            class="form-control"
                            aria-label="Username"
                            aria-describedby="basic-addon1"
                            v-model="nombre"
                            v-on:click="changeInput"
                            name="editNombre"
                            autocomplete="off"
                        />
                        <span
                            class="alert"
                            v-if="nombre.length === 0 && editNombre"
                            >Campo obligatorio</span
                        >
                    </div>
                    <div class="col-lg-6 col-12 mb-3">
                        <label for="">Apellido(*)</label>
                        <b-form-input
                            type="text"
                            class="form-control"
                            aria-label="Username"
                            aria-describedby="basic-addon1"
                            v-model="apellido"
                            v-on:click="changeInput"
                            name="editApellido"
                            autocomplete="off"
                        />
                        <span
                            class="alert"
                            v-if="apellido.length === 0 && editApellido"
                            >Campo obligatorio</span
                        >
                    </div>

                    <div class="col-lg-6 col-12 mb-3">
                        <label for="">Apodo o Alias(*)</label>
                        <b-form-input
                            type="text"
                            class="form-control"
                            aria-label="Username"
                            aria-describedby="basic-addon1"
                            v-model="alias"
                            v-on:click="changeInput"
                            name="editAlias"
                            autocomplete="off"
                        />
                        <span
                            class="alert"
                            v-if="alias.length === 0 && editAlias"
                            >Campo obligatorio</span
                        >
                    </div>
                    <div class="col-lg-6 col-12 mb-3">
                        <label for="">Teléfono(*)</label>
                        <b-form-input
                            type="number"
                            @keypress="isNumberAndSign"
                            class="form-control"
                            aria-label="Username"
                            aria-describedby="basic-addon1"
                            maxlength="11"
                            v-model="telefono"
                            autocomplete="off"
                            pattern="[0-9_+]*"
                        />
                        <legend class="legend-field text-legend">
                            (El formato debe ser por ej: +56911222333)
                        </legend>
                        <span class="alert" v-if="validarTelefono(telefono)"
                            >Campo obligatorio</span
                        >
                    </div>

                    <div class="col-lg-6 col-12 mb-3">
                        <label for="">País(*)</label>
                        <select
                            class="form-select"
                            name=""
                            id=""
                            v-model="pais"
                        >
                            <option
                                v-for="pais in paises"
                                v-bind:value="pais.value"
                                :key="pais.value"
                            >
                                {{ pais.text }}
                            </option>
                        </select>
                    </div>
                    <div class="col-lg-6 col-12np mb-3">
                        <label for="">Cuidad(*)</label>
                        <select
                            class="form-select"
                            name=""
                            id=""
                            v-model="ciudad"
                        >
                            <option
                                v-for="(ciudad, index) in ciudades"
                                v-bind:value="ciudad.city"
                                :key="ciudad.city + index * 73"
                            >
                                {{ ciudad.city }}
                            </option>
                        </select>
                    </div>

                    <div class="col-12 mb-3">
                        <label for="">Dirección(*)</label>
                        <b-form-input
                            type="text"
                            class="form-control"
                            aria-label="Username"
                            aria-describedby="basic-addon1"
                            v-model="direccion"
                            v-on:click="changeInput"
                            name="editDireccion"
                            autocomplete="off"
                        />
                        <span
                            class="alert"
                            v-if="direccion.length === 0 && editDireccion"
                            >Campo obligatorio</span
                        >
                    </div>
                </div>
                <div class="row">
                    <div class="col-md col-12 m-auto">
                        <span class="text-description"
                            >(*)Los campos son obligatorios para avanzar en el
                            registro</span
                        >
                    </div>
                    <div class="col-md col-12" style="text-align: right">
                        <button
                            :disabled="!validForm"
                            class="btn btn-sm btn-yellow"
                            @click="handleClickNext"
                        >
                            Siguiente
                        </button>
                    </div>
                </div>
            </form>
        </b-card>
    </div>
</template>

<script>
import ciudadesChile from '../../data/ciudadesChile'
export default {
    props: ['stepTwo'],
    methods: {
        handleClickNext() {
            this.$emit('formDataChange', {
                email: this.email,
                nombre: this.nombre,
                apellido: this.apellido,
                alias: this.alias,
                telefono: this.telefono,
                pais: this.pais,
                ciudad: this.ciudad,
                direccion: this.direccion,
            })
            this.$emit('stepTwo')
        },
        /** Esta funcion retorna true si el telefono es invalido */
        validarEmail(valor) {
            if (valor.length > 0) {
                if (
                    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
                        valor
                    )
                ) {
                    return false
                } else {
                    return true
                }
            } else {
                return false
            }
        },
        isNumberAndSign(evt) {
            if (this.telefono.length >= 11) {
                evt.preventDefault()
                return
            }
            const keysAllowed = [
                '0',
                '1',
                '2',
                '3',
                '4',
                '5',
                '6',
                '7',
                '8',
                '9',
                '+',
            ]
            const keyPressed = evt.key
            if (!keysAllowed.includes(keyPressed)) {
                evt.preventDefault()
            }
        },
        /** Esta funcion retorna true si el telefono es invalido */
        validarTelefono(valor) {
            if (valor.length > 0) {
                /*if (/^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/.test(valor)) {
          return false;
        } else {
          return true;
        }*/
                if (valor.length >= 11) {
                    return false
                }
                return true
            } else {
                return false
            }
        },
        changeInput(event) {
            this[event.target.name] = true
        },
        onlyNumber($event) {
            //console.log($event.keyCode); //keyCodes value
            let keyCode = $event.keyCode ? $event.keyCode : $event.which
            if ((keyCode < 48 || keyCode > 57) && keyCode !== 46) {
                // 46 is dot

                $event.preventDefault()
            }
        },
        checkEmailEqual(valor) {
        if (valor.length > 0) {
            if (this.emailConfirm === this.email) {
            return false;
            } else {
            return true;
            }
        }else {
            return false;
        }
        },
    },
    computed: {
        validForm() {
            let validForm = true

            this.validarEmail(this.email) ? (validForm = false) : null
            this.validarTelefono(this.telefono) ? (validForm = false) : null

            this.telefono.length > 0 ? null : (validForm = false)
            this.nombre.length > 0 ? null : (validForm = false)
            this.apellido.length > 0 ? null : (validForm = false)
            this.alias.length > 0 ? null : (validForm = false)
            this.direccion.length > 0 ? null : (validForm = false)
            
            this.checkEmailEqual(this.emailConfirm)
            ? (validForm = false)
            : null;

            return validForm
        },
    },
    data: function() {
        return {
            email: '',
            emailConfirm:'',
            nombre: '',
            apellido: '',
            alias: '',
            telefono: '',
            pais: 'CL',
            ciudad: 'Santiago',
            direccion: '',

            editEmail: false,
            editNombre: false,
            editApellido: false,
            editAlias: false,
            editTelefono: false,
            editPais: false,
            editCiudad: false,
            editDireccion: false,

            paises: [{ value: 'CL', text: 'Chile' }],
            ciudades: ciudadesChile,
        }
    },
}
</script>

<style scoped>
.card-body {
    font-family: maven-regular;
}
h5 {
    color: #501682;
    font-family: maven-semibold;
    font-size: 18px;
}
label {
    margin-bottom: 8px;
    color: #787878;
    font-family: maven-bold;
}
.btn-yellow {
    background: #ffc109bb;
    font-family: maven-bold;
    text-transform: uppercase;
    color: #501682;
    padding: 10px 50px;
    border-radius: 10px;
    font-size: 13px;
}
.btn-yellow:hover {
    background: #dba501;
    color: #650bbf;
}

.alert {
    color: red;
    padding: 0px;
    font-size: 11px;
    font-weight: bold;
}
.text-description {
    padding: 0px;
    font-size: 11px;
    font-weight: bold;
}
.text-legend {
    padding: 0px;
    font-size: 12px;
    font-weight: bold;
}
@media screen and (max-width: 767px) {
    .card-img {
        width: 120px;
        height: 120px;
        border: 3px solid #fff;
        background-repeat: no-repeat;
        background-size: cover;
        position: relative;
        margin: auto;
        border-radius: 8px;
        margin-left: 0px;
    }
    .card-img i {
        font-size: 100px;
        margin-top: 20px;

        color: #707070;
    }

    .btn-yellow {
        padding: 10px 30px;
        border-radius: 10px;
        font-size: 12px;
    }
    .btn-back {
        padding: 10px 30px;
        border-radius: 10px;
        font-size: 12px;
    }
}
</style>
