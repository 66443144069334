<template>
  <div>
    <b-modal
      :no-close-on-esc="true"
      :no-close-on-backdrop="true"
      id="modalPerfil"
      ref="modal-suscripcion"
      centered
      hide-footer
    >
      <template #modal-title> </template>
      <h3 class="title">Perfil</h3>
      <div class="mb-3">
        <label class="label-modal" for="">Nombre</label>
        <div>
          <input
            type="text"
            class="form-control inputPadding"
            aria-label="Username"
            aria-describedby="basic-addon1"
            :value="tokenData != null ? tokenData.personName : null"
            disabled
          />
        </div>
      </div>
      <div class="mb-3">
        <label class="label-modal" for="">Email</label>
        <div>
          <input
            type="text"
            class="form-control inputPadding"
            aria-label="Username"
            aria-describedby="basic-addon1"
            :value="tokenData != null ? tokenData.email : null"
            disabled
          />
        </div>
      </div>
      <!-- <button class="btn btn-yellow">
        Modificar
      </button> -->
<!--      <router-link to="/setting" class="">
        <button class="btn btn-yellow" @click="cerrarModal">
          Administrar Perfil
        </button>
      </router-link>-->

    </b-modal>
  </div>
</template>

<script>
export default {
  computed: {
    tokenData() {
      //console.log(this.$store.state.tokenData);
      return this.$store.state.tokenData;
    },
  },
  mounted() {
    if(this.$store.state.tokenData.permissions.isTarotReader){
      this.$router.push('/dashboard-tarotista');
    }
  },
  methods: {
    cerrarModal() {
      this.$refs['modal-suscripcion'].hide(); // Cierra el modal
    }
  }
};
</script>

<style scoped>
/deep/.close {
  background: transparent;
  border: none;
}
/deep/ .modal-header {
  border-bottom: none;
  padding-bottom: 0px;
}
/deep/ .modal-body {
  padding: 48px;
  padding-top: 0px;
  font-family: maven-medium;
}
.title {
  font-family: maven-semibold;
  font-size: 18px;
  margin-bottom: 20px;
  text-transform: uppercase;
}

.btn-yellow {
  background: #ffc109bb;
  font-family: maven-semibold;
  text-transform: uppercase;
  color: #501682;
  border-radius: 10px;
  padding: 8px 16px;
  font-size: 12px;
  float: right;
}
.btn-email {
  width: 100%;
  font-family: maven-semibold;
  border-radius: 10px;
  background: #333;
  color: #fff;
  font-size: 12px;
}
label {
  font-size: 14px;
}
.label-modal {
  font-family: maven-semibold;
  font-size: 16px;
  margin-bottom: 3px;
}
</style>
