<template>
  <div>
    <b-modal
      :no-close-on-esc="true"
      :no-close-on-backdrop="true"
      ref="modal-especialidad-editar"
      centered
      hide-footer
      v-model="showModal"
    >
      <template #modal-title> </template>
      <h3 class="title">Editar Especialidad</h3>
      <div class="mb-3">
        <label class="label-modal" for="">Nombre</label>
        <div>
          <input
            type="text"
            class="form-control inputPadding"
            aria-label="Username"
            aria-describedby="basic-addon1"
            placeholder="Nombre de la especialidad"
            v-model="specialtyInformation.specialtyName"
            maxlength="50"
          />
        </div>
      </div>
      <div class="col-12 mt-3">
        <label class="label-modal" for="">Imagen</label>
        <div class="col mt-3 p-0" style="width: 180px; height: 180px">
          <div class="card-img shadow-sm" style="">
            <div class="image">
              <img
                :src="specialtyInformation.specialtyImage"
                alt=""
                v-if="specialtyInformation.specialtyImage !== null"
              />
              <img src="../../../../assets/img/avatar.jpg" alt="" v-else />
            </div>

            <label class="btn-shadow btn-icon">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="10"
                height="10"
                fill="currentColor"
                class="bi bi-pencil-fill"
                viewBox="0 0 16 16"
              >
                <path
                  d="M12.854.146a.5.5 0 0 0-.707 0L10.5 1.793 14.207 5.5l1.647-1.646a.5.5 0 0 0 0-.708l-3-3zm.646 6.061L9.793 2.5 3.293 9H3.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.207l6.5-6.5zm-7.468 7.468A.5.5 0 0 1 6 13.5V13h-.5a.5.5 0 0 1-.5-.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.5-.5V10h-.5a.499.499 0 0 1-.175-.032l-.179.178a.5.5 0 0 0-.11.168l-2 5a.5.5 0 0 0 .65.65l5-2a.5.5 0 0 0 .168-.11l.178-.178z"
                />
              </svg>
              <input class="input-file" type="file" id="sf" @change="upfile" />
            </label>
          </div>
        </div>
      </div>

      <button class="btn btn-yellow" @click="editSpecialtyInformation">
        Editar
      </button>
    </b-modal>
  </div>
</template>

<script>
  import axiosClient from '../../../../config/axiosClient';
  import { enableLogs } from '../../../../config';

  export default {
    name: 'ModalEditAmbito',
    props: {
      specialty: Object,
      triggerModal: Boolean,
    },
    watch: {
      triggerModal: function(value) {
        if (value) {
          this.showModal = true;

          this.specialtyInformation.specialtyName = this.specialty.specialtyName;
          this.specialtyInformation.specialtyImage = this.specialty.specialtyImage;
        }
      },
    },
    data() {
      return {
        specialtyInformation: {
          specialtyId: this.specialty.specialtyId,
          specialtyName: this.specialty.specialtyName,
          specialtyImage: this.specialty.specialtyImage,
        },

        showModal: false,
      };
    },
    methods: {
      async editSpecialtyInformation() {
        try {
          const response = await axiosClient.post(
            '/specialties/edit-specialty',
            {
              specialtyInformation: this.specialtyInformation,
            },
            { headers: { Authorization: `Bearer ${this.$store.state.jwt}` } }
          );
         if(enableLogs) console.log(response.data);
          this.$emit('editSpecialty', {
            ...this.specialtyInformation,
          });
          this.showModal = false;
        } catch (error) {
         if(enableLogs) console.log('could not update scope information');
         if(enableLogs) console.log(error);
        }
      },
      async upfile() {
        var nameFile = document.getElementById('sf').files[0].name;
        if (
          nameFile.toLowerCase().substr(nameFile.length - 4)  == '.png' ||
          nameFile.toLowerCase().substr(nameFile.length - 4)  == '.jpg' ||
          nameFile.toLowerCase().substr(nameFile.length - 5)  == '.jpeg'
        ) {
          var formdata = new FormData();
          formdata.append('archivo', document.getElementById('sf').files[0]);

          const response = await axiosClient.post(
            '/users/photo/upfile',
            formdata
          );

         if(enableLogs) console.log(response);

          if (response.data.code == 2) {
            alert('archivo se subio exitosamente');
           if(enableLogs) console.log(response.data.path);
            this.specialtyInformation.specialtyImage = response.data.path;
          } else {
            alert('Fallo la subida del archivo');
          }
        } else {
          alert('El archivo debe ser .png, .jpg o .jpeg');
        }
      },
    },
  };
</script>
<style scoped>
  /deep/.close {
    background: transparent;
    border: none;
  }
  /deep/ .modal-header {
    border-bottom: none;
    padding-bottom: 0px;
  }
  /deep/ .modal-body {
    padding: 48px;
    padding-top: 0px;
    font-family: maven-medium;
  }
  .title {
    font-family: maven-semibold;
    font-size: 18px;
    margin-bottom: 20px;
    text-transform: uppercase;
  }

  .btn-yellow {
    background: #ffc109bb;
    font-family: maven-semibold;
    text-transform: uppercase;
    color: #501682;
    border-radius: 10px;
    padding: 8px 16px;
    font-size: 12px;
    float: right;
  }
  .btn-email {
    width: 100%;
    font-family: maven-semibold;
    border-radius: 10px;
    background: #333;
    color: #fff;
    font-size: 12px;
  }
  label {
    font-size: 14px;
  }
  .label-modal {
    font-family: maven-semibold;
    font-size: 16px;
    margin-bottom: 3px;
  }
  .card-img {
    width: 120px;
    height: 120px;
    border: 3px solid #fff;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
  }
  .card-img i {
    font-size: 50px;
    margin-top: 35px;
    margin-left: 35px;
    color: #707070;
  }
  .card-img img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 8px;
  }
  .btn-icon {
    background-color: #f3f6f9;
    border-color: #f3f6f9;
    color: #3f4254;
    border-radius: 50%;
    height: 24px !important;
    width: 24px;
    align-items: center;
    display: -webkit-inline-box;
    display: inline-flex;
    align-items: center;
    -ms-flex-pack: center;
    justify-content: center;
    padding: 0;
    position: absolute;
    right: -10px;
    top: -10px;
    cursor: pointer;
  }
  .input-file {
    width: 0 !important;
    height: 0 !important;
    overflow: hidden;
    opacity: 0;
  }
</style>
