<template>
  <div>
    <b-modal
      :no-close-on-esc="true"
      :no-close-on-backdrop="true"
      id="modal-puntaje"
      ref="modal-suscripcion"
      centered
      :visible="true"
      hide-footer
      v-model="modalShow"
    >
      <template #modal-title> </template>
      <h3 class="title text-center">Dejanos tu comentario</h3>
      <div class="mb-3">
        <label class="label-modal" for="">Puntaje</label>
        <div>
          <span v-for="star in stars" :key="star.id">
            <i
              class="fas fa-star star"
              :id="star.id"
              @click="calificar(star.id)"
            ></i>
          </span>
        </div>
      </div>
      <div class="mb-3">
        <label class="label-modal" for="">Título</label>
        <div>
          <input
            type="text"
            class="form-control inputPadding"
            aria-label="Username"
            aria-describedby="basic-addon1"
            placeholder="Ingrese un título"
            v-model="title"
          />
        </div>
      </div>
      <div class="mb-3">
        <label class="label-modal" for="">Descripcion</label>
        <div>
          <textarea
            class="form-control"
            name=""
            id=""
            width="100$"
            rows="4"
            maxlength="250"
            placeholder="Describe tu experiencia con el tarotista"
            v-model="description"
          ></textarea>
        </div>
      </div>

      <button class="btn btn-yellow" @click="sendValoration" :disabled="!validForm || cargando">
        Enviar comentario
      </button>
    </b-modal>
  </div>
</template>

<script>
import {createValoracion} from '../../valoracion/createValoracion'
export default {
  data: function () {
    return {
      contador: '',
      title: '',
      description:'',
      stars: [
        { id: '1estrella' },
        { id: '2estrella' },
        { id: '3estrella' },
        { id: '4estrella' },
        { id: '5estrella' },
      ],
      valorationData:{},
      deshabilitar: true,
      cargando: false,
      modalShow: true
      
    };
  },
  props: {
    idTarotista: Number,
  },
   async created() {
    //await this.sendValoration();
  },
  computed: {

    validForm() {
      let validForm = true;
      this.contador > 0 ? null : (validForm = false)
      this.title.length > 0 ? null : (validForm = false)
      this.description.length > 0 ? null : (validForm = false)

      return validForm;
    },
  },
  methods: {

    /* hide(){
      this.$store.commit('showModal', true)
    }, */
    calificar(item) {
/*       console.log(item); */
      this.contador = item.substring(0, 1);
      let nombre = item.substring(1);
      for (let i = 0; i < 5; i++) {
        if (i < this.contador) {
          document.getElementById(i + 1 + nombre).style.color = '#ffc30b';
        } else {
          document.getElementById(i + 1 + nombre).style.color = 'grey';
        }
      }
    },
    async sendValoration() {

      let newValoration = {
        valoracion: this.contador,
        titulo: this.title,
        comentario: this.description,
        id_tarotista: this.idTarotista
      };

      const tokenUser = this.$store.state.jwt;
      this.cargando = true;

      let result = await createValoracion(newValoration, tokenUser);

      if(result) {
        this.modalShow = false;
        this.cargando = true;
        this.$store.commit("setMessage", "¡El comentario fue enviado exitosamente!");
        /* window.location.reload(true); */
      }
      return result
    }
  }
};
</script>

<style scoped>
/deep/.close {
  background: transparent;
  border: none;
}
/deep/ .modal-header {
  border-bottom: none;
  padding-bottom: 0px;
}
/deep/ .modal-body {
  padding: 48px;
  padding-top: 0px;
  font-family: maven-medium;
}
.title {
  font-family: maven-bold;
  font-size: 20px;
  margin-bottom: 20px;
  text-transform: uppercase;
  color: #501682;
}

.btn-yellow {
  background: #ffc109bb;
  font-family: maven-semibold;
  text-transform: uppercase;
  color: #501682;
  border-radius: 10px;
  padding: 8px 16px;
  font-size: 12px;
  float: right;
}
.btn-email {
  width: 100%;
  font-family: maven-semibold;
  border-radius: 10px;
  background: #333;
  color: #fff;
  font-size: 12px;
}
label {
  font-size: 14px;
}
.label-modal {
  font-family: maven-semibold;
  font-size: 16px;
  margin-bottom: 3px;
}

textarea {
  resize: none;
}
.star {
  margin-right: 5px;
  font-size: 12px;
  color: grey;
  cursor: pointer;
}
</style>
