<template>
  <div class="container my-5">
    <div class="row justify-content-center mt-5" v-if="isLoading">
      <Spinner />
    </div>
    <div class="row" v-else>
      <div class="col-12 mb-3">
        <div class="text-back" @click="goBack">
          <i class="fas fa-chevron-left"></i>
          <span>Volver</span>
        </div>
      </div>
      <div class="col-12">
        <h1 class="general-title">Precio de lectura por minuto</h1>
      </div>
      <div class="col-12 my-4">
        <div class="card shadow">
          <div class="card-body">
            <div class="row">
              <div class="col-lg-4 col-md-4 col-12">
                <h5>Precio Base</h5>
                <div class="form-group">
                  <label for="exampleFormControlInput1">Precio</label>
                  <input
                    type="text"
                    class="form-control"
                    id="exampleFormControlInput1"
                    v-model="priceInformation.basePrice"
                    :placeholder="basePrice"
                  />
                </div>
              </div>

              <div class="col-lg-4 col-md-4 col-12">
                <h5>Variable Canal</h5>
                <div class="form-group mb-2">
                  <label for="exampleFormControlInput1">Video</label>
                  <input
                    type="text"
                    class="form-control"
                    id="exampleFormControlInput1"
                    v-model="priceInformation.videoPriceFactor"
                    :placeholder="videoPriceFactor"
                  />
                </div>
                <div class="form-group mb-2">
                  <label for="exampleFormControlInput1">Audio</label>
                  <input
                    type="text"
                    class="form-control"
                    id="exampleFormControlInput1"
                    v-model="priceInformation.voicePriceFactor"
                    :placeholder="voicePriceFactor"
                  />
                </div>
                <div class="form-group mb-2">
                  <label for="exampleFormControlInput1">Chat</label>
                  <input
                    type="text"
                    class="form-control"
                    id="exampleFormControlInput1"
                    v-model="priceInformation.chatPriceFactor"
                    :placeholder="chatPriceFactor"
                  />
                </div>
              </div>

              <div class="col-lg-4 col-md-4 col-12">
                <h5>Variable Experiencia</h5>
                <div class="form-group mb-2">
                  <label for="exampleFormControlInput1">Alta</label>
                  <input
                    type="text"
                    class="form-control"
                    id="exampleFormControlInput1"
                    v-model="priceInformation.highPriceFactor"
                    :placeholder="highPriceFactor"
                  />
                </div>
                <div class="form-group mb-2">
                  <label for="exampleFormControlInput1">Media</label>
                  <input
                    type="text"
                    class="form-control"
                    id="exampleFormControlInput1"
                    v-model="priceInformation.mediumPriceFactor"
                    :placeholder="mediumPriceFactor"
                  />
                </div>
                <div class="form-group mb-2">
                  <label for="exampleFormControlInput1">Baja</label>
                  <input
                    type="text"
                    class="form-control"
                    id="exampleFormControlInput1"
                    v-model="priceInformation.lowPriceFactor"
                    :placeholder="lowPriceFactor"
                  />
                </div>
              </div>
            </div>

            <div class="row justify-content-center">
              <div class="col-11 mt-5">
                <div class="content-table">
                <table class="table table-striped">
                  <thead>
                    <tr>
                      <th scope="col">Canal</th>
                      <th scope="col">Experiencia Alta</th>
                      <th scope="col">Experiencia Media</th>
                      <th scope="col">Experiencia Baja</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>Video</td>
                      <td>
                        ${{
                          basePrice * highPriceFactor * videoPriceFactor
                        }}/min
                      </td>
                      <td>
                        ${{
                          basePrice * mediumPriceFactor * videoPriceFactor
                        }}/min
                      </td>
                      <td>
                        ${{ basePrice * lowPriceFactor * videoPriceFactor }}/min
                      </td>
                    </tr>
                    <tr>
                      <td>Audio</td>
                      <td>
                        ${{
                          basePrice * highPriceFactor * voicePriceFactor
                        }}/min
                      </td>
                      <td>
                        ${{
                          basePrice * mediumPriceFactor * voicePriceFactor
                        }}/min
                      </td>
                      <td>
                        ${{ basePrice * lowPriceFactor * voicePriceFactor }}/min
                      </td>
                    </tr>
                    <tr>
                      <td>Chat</td>
                      <td>
                        ${{ basePrice * highPriceFactor * chatPriceFactor }}/min
                      </td>
                      <td>
                        ${{
                          basePrice * mediumPriceFactor * chatPriceFactor
                        }}/min
                      </td>
                      <td>
                        ${{ basePrice * lowPriceFactor * chatPriceFactor }}/min
                      </td>
                    </tr>
                  </tbody>
                </table>
                </div>
              </div>
              <div class="col-12 text-center">
                <button class="btn btn-yellow mt-3" @click="updatePriceSystem">
                  Guardar
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import axiosClient from '../../../config/axiosClient';
  import Spinner from '../../loadingspinner/Spinner.vue';
  import { enableLogs } from '../../../config';

  export default {
    name: 'DashAdminPrecioLectura',
    data() {
      return {
        basePrice: null,
        lowPriceFactor: null,
        mediumPriceFactor: null,
        highPriceFactor: null,
        chatPriceFactor: null,
        voicePriceFactor: null,
        videoPriceFactor: null,
        isLoading: false,

        priceInformation: {
          basePrice: null,
          lowPriceFactor: null,
          mediumPriceFactor: null,
          highPriceFactor: null,
          chatPriceFactor: null,
          voicePriceFactor: null,
          videoPriceFactor: null,
        },
      };
    },
    components: { Spinner },
    created() {
      this.getCurrentPrices();
    },
    methods: {
      goBack() {
        this.$router.go(-1);
      },
      async updatePriceSystem() {
        this.isLoading = true;
        try {
          const response = await axiosClient.post(
            '/prices/update-prices',
            {
              priceInformation: this.priceInformation,
            },
            { headers: { Authorization: `Bearer ${this.$store.state.jwt}` } }
          );
         if(enableLogs) console.log(response.data);
         if(enableLogs) console.log('successfully updated price system');
          this.getCurrentPrices();
          this.isLoading = false;
        } catch (error) {
         if(enableLogs) console.log(error);
         if(enableLogs) console.log('could not update price system');
          this.isLoading = false;
        }
      },
      async getCurrentPrices() {
        this.isLoading = true;
        try {
         if(enableLogs) console.log('Fetching current prices');
          const response = await axiosClient.get('/prices/get-prices');
         if(enableLogs) console.log(response.data);
          const {
            basePrice,
            lowPriceFactor,
            mediumPriceFactor,
            highPriceFactor,
            chatPriceFactor,
            voicePriceFactor,
            videoPriceFactor,
          } = response.data.prices;

          this.basePrice = basePrice;
          this.lowPriceFactor = lowPriceFactor;
          this.mediumPriceFactor = mediumPriceFactor;
          this.highPriceFactor = highPriceFactor;
          this.chatPriceFactor = chatPriceFactor;
          this.voicePriceFactor = voicePriceFactor;
          this.videoPriceFactor = videoPriceFactor;
          this.isLoading = false;
        } catch (err) {
         if(enableLogs) console.log(err);
         if(enableLogs) console.log('Error fetching prices');
          this.isLoading = false;
        }
      },
    },
  };
</script>

<style scoped>
  .text-back {
    color: #501682;
    text-transform: uppercase;
    font-family: maven-semibold;
    font-size: 13px;
    cursor: pointer;
  }
  .text-back:hover {
    color: #934dd8;
  }
  .text-back span {
    margin-left: 6px;
  }
  .title {
    color: #501682;
    text-transform: uppercase;
    font-family: source-bold;
    font-size: 19px;
  }
  .btn-yellow {
    font-family: maven-semibold;
    background: #ffc209;
    color: #501682;
    font-size: 11px;
    text-transform: uppercase;
    padding: 10px 18px;
  }
  .btn-yellow:hover {
    background: #dba501;
    color: #650bbf;
  }

  .card {
    border-radius: 10px;
    min-height: 100%;
  }
  .card-body {
    padding: 20px 20px;
    font-family: maven-regular;
  }

  .title-ambito {
    font-size: 21px;
    font-family: maven-bold;
  }

  .title-descripcion {
    font-size: 11px;
  }
  h5 {
    font-size: 17px;
    font-family: maven-bold;
  }
  label {
    font-size: 13px;
    font-family: maven-bold;
    color: #707070;
  }
  input {
    background: #f3f6f9;
    border: 1px solid #f3f6f9;
  }
  table {
    font-size: 13px;
  }
  table th {
    font-family: maven-semibold;
  }
  table td {
    font-family: maven-medium;
  }
  @media screen and (max-width: 768px) {
  .content-table {
    overflow-x: scroll;
  }
  .table {
    min-width: 500px;
  }
  .content-table {
    --scrollbarBG: #d1d1d1;
    --thumbBG: #333333;
  }
  .content-table::-webkit-scrollbar {
    height: 11px;
  }
  .content-table {
    scrollbar-width: thin;
    scrollbar-color: var(--thumbBG) var(--scrollbarBG);
  }
  .content-table::-webkit-scrollbar-track {
    background: var(--scrollbarBG);
  }
  .content-table::-webkit-scrollbar-thumb {
    background-color: var(--thumbBG);
    border-radius: 6px;
    border: 3px solid var(--scrollbarBG);
  }
}
</style>
