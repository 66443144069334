<template>
  <div class="box-topTarotista">
    <div class="">
      <div class="text-center">
        <h2 class="title general-title">Tarotistas Destacados</h2>
      </div>
      <div class="row justify-content-center d-none d-md-block" style="margin-top: 20px">
        <Spinner v-if="isLoading" />
        <div class="col-sm-12 col-11">
          <div
            class="box-descripcion mb-3 pb-4"
            v-for="tarotista in filteredAndSortedTarotistas"
            :key="tarotista.userId + '/' + tarotista.hashCode"
          >
            <div class="row justify-content-between">
              <!-- <div
              class="col-md col-6 col-md-2 m-md-auto order-1 col-number"
            >
              <span class="number-top">{{ index + 1 }}</span>
            </div> -->

              <div class="col-lg col-6 p-0 col-img order-1">
                <router-link
                  :to="
                    '/ficha-tarotista?id_tarotista=' + tarotista.tarotReaderId
                  "
                  class="tarotista-estado"
                >
                  <div class="image">
                    <img :src="tarotista.avatar" alt="" />
                    <i
                      :key="tarotista.userId + '/' + seed"
                      v-if="getStatus(tarotista) === 'Disponible'"
                      class="fas fa-circle tarotista-disponible"
                    ></i>
                    <i
                      :key="tarotista.userId + '/' + seed"
                      v-if="getStatus(tarotista) === 'No Disponible'"
                      class="fas fa-circle tarotista-no-disponible"
                    ></i>
                    <i
                      :key="tarotista.userId + '/' + seed"
                      v-if="getStatus(tarotista) === 'Ocupado'"
                      class="fas fa-circle tarotista-occupied"
                    ></i>

                    <span
                      v-if="getStatus(tarotista) === 'No Disponible'"
                      class="estado-tarotista tarotista-no-disponible"
                    >
                      {{getStatus(tarotista)}}
                    </span>
                    <span
                      v-if="getStatus(tarotista) === 'Disponible'"
                      class="estado-tarotista tarotista-disponible"
                    >
                      {{getStatus(tarotista)}}
                    </span>
                    <span
                      :key="tarotista.userId + '/' + seed"
                      v-if="getStatus(tarotista) === 'Ocupado'"
                      class="estado-tarotista tarotista-occupied"
                    >
                      {{getStatus(tarotista)}}
                    </span>
                  </div>
                  
                </router-link>
              </div>

              <div
                class="
                  col-lg col-text
                  mx-md-0
                  mt-lg-0 mt-md-4 mt-4
                  order-lg-2 order-md-4 order-sm-3 order-3
                "
              >
                <div class="row">
                  <div class="col-12">
                    <div style="float: left">
                      <h5 class="name-tarotista">
                        {{ nameReducer(tarotista) }}
                      </h5>
                    </div>
                  </div>

                  <span v-if="tarotista.scopes" class="subtitle">{{
                    tarotista.scopes[0].scopeName
                  }}</span>
                </div>
                <div class="descripcion">
                  <p class="text-descripcion">
                    {{ tarotista.description }}
                  </p>
                  <div class="gradient"></div>
                </div>
              </div>

              <div
                class="col-sm col-12 col-puntaje order-lg-3 order-md-2 order-2"
              >
                <div class="container-puntaje">
                  <div class="row">
                    <div class="score col-12">
                      <!-- <div class="star">
                        <i
                          v-for="i in tarotista.calification"
                          :key="i"
                          class="fas fa-star"
                        ></i>
                        <i
                          v-for="i in 5 - tarotista.calification"
                          :key="i * 77"
                          class="fas fa-star grey-star"
                        ></i>
                      </div>
                      <span class="puntaje"
                        >{{ tarotista.calification }}.0</span
                      > -->
                      <Star :value="tarotista.valoracion_promedio" :shownumer="true" :size="1" :showperson="true" :person="tarotista.cantidad_valoracion" />
                    </div>

                    <div class="readings col-12">
                      <div class="glass">
                        <i class="far fa-eye"></i>
                      </div>
                      <div class="text-read">
                        <h5>
                          <span>{{ tarotista.amountOfReadings }}</span> Lecturas
                        </h5>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="container-lectura">
                  <div
                    class="tipo-lectura"
                    v-if="tarotista.type === 'agendada'"
                  >
                    <p><i class="fas fa-calendar-alt"></i> Lectura Agendada</p>
                  </div>
                  <div class="tipo-lectura" v-else>
                    <p><i class="fas fa-bolt"></i> Lectura Inmediata</p>
                  </div>
                  <div class="btn btn-sm btn-descuento" v-if="tarotista.desc">-50%</div>
                </div>
              </div>

              <div
                class="
                  col-lg col-12
                  order-lg-4 order-md-3 order-sm-4 order-4
                  col-btn
                  mt-md-0 mt-4
                "
              >
                <div class="row">
                  <div class="col-12">
                    <p class="titulo-btn">Comprar</p>
                    <div v-if=" tarotista.chatPrice !== null">
                      <button
                        :disabled="disableButtonFuntion(tarotista, 'chat')
                        "
                        
                        @click="
                          goToPay(
                            tarotista.tarotReaderId,
                            tarotista.type,
                            'Chat'
                          )
                        "
                        type="button"
                        class="btn btn-green btn-block"
                      >
                        <i class="fas fa-comments icon-btn"></i>
                        <span>Chat $ {{ numberWithCommas(tarotista.chatPrice) }}/min</span>
                        <!--<span>Chat ${{numberWithCommas(tarotista.chatPrice)}}/min</span>-->
                      </button>
                     <!--  <label class="descuento">$400/min</label> -->
                    </div>

                    <div v-if=" tarotista.voicePrice !== null">
                      <button
                        :disabled="disableButtonFuntion(tarotista, 'voice')
                        "
                        @click="
                          goToPay(
                            tarotista.tarotReaderId,
                            tarotista.type,
                            'Voz'
                          )
                        "
                        type="button"
                        class="btn btn-green btn-block"
                      >
                        <i class="fas fa-phone-alt icon-btn"></i>
                        <span>Audio ${{ numberWithCommas(tarotista.voicePrice) }}/min</span>
                        <!--<span>Audio ${{ numberWithCommas(tarotista.voicePrice) }}/min</span>-->
                      </button>
                      <!-- <label class="descuento">$400/min</label> -->
                    </div>

                    <div v-if=" tarotista.videoPrice !== null">
                      <button
                        :disabled="disableButtonFuntion(tarotista ,'video')
                        "
                        @click="
                          goToPay(
                            tarotista.tarotReaderId,
                            tarotista.type,
                            'Video'
                          )
                        "
                        type="button"
                        class="btn btn-green btn-block"
                      >
                        <i class="fas fa-video icon-btn"></i>
                        <span>Video ${{ numberWithCommas(tarotista.videoPrice) }}/min</span>
                        <!--<span>Video ${{  numberWithCommas(tarotista.videoPrice)  }}/min</span>-->
                      </button>
                      <!-- <label class="descuento">$400/min</label> -->
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row mt-4">
          <div class="col text-center">
            <router-link to="/catalogo/?rating=1">
              <button class="btn btn-ver-mas">Ver todos los tarotistas</button>
            </router-link>
          </div>
        </div>
      </div>
      <div class="row d-block d-md-none" style="margin-top: 20px">
        <Spinner v-if="isLoading" />
        <div class="col-sm-12">
          <b-carousel
              id="carousel-tarotistas"
              fade
              :interval="3000"
              controls
          >
            <b-carousel-slide
                v-for="tarotista in filteredAndSortedTarotistas"
                :key="tarotista.userId + '/' + tarotista.hashCode"
            >
              <div class="row justify-content-between">
                <!-- <div
                class="col-md col-6 col-md-2 m-md-auto order-1 col-number"
              >
                <span class="number-top">{{ index + 1 }}</span>
              </div> -->

                <div class="col-lg col-6 p-0 col-img order-1">
                  <router-link
                      :to="
                    '/ficha-tarotista?id_tarotista=' + tarotista.tarotReaderId
                  "
                      class="tarotista-estado"
                  >
                    <div class="image">
                      <img :src="tarotista.avatar" alt="" />
                      <i
                          :key="tarotista.userId + '/' + seed"
                          v-if="getStatus(tarotista) === 'Disponible'"
                          class="fas fa-circle tarotista-disponible"
                      ></i>
                      <i
                          :key="tarotista.userId + '/' + seed"
                          v-if="getStatus(tarotista) === 'No Disponible'"
                          class="fas fa-circle tarotista-no-disponible"
                      ></i>
                      <i
                          :key="tarotista.userId + '/' + seed"
                          v-if="getStatus(tarotista) === 'Ocupado'"
                          class="fas fa-circle tarotista-occupied"
                      ></i>

                      <span
                          v-if="getStatus(tarotista) === 'No Disponible'"
                          class="estado-tarotista tarotista-no-disponible"
                      >
                      {{getStatus(tarotista)}}
                    </span>
                      <span
                          v-if="getStatus(tarotista) === 'Disponible'"
                          class="estado-tarotista tarotista-disponible"
                      >
                      {{getStatus(tarotista)}}
                    </span>
                      <span
                          :key="tarotista.userId + '/' + seed"
                          v-if="getStatus(tarotista) === 'Ocupado'"
                          class="estado-tarotista tarotista-occupied"
                      >
                      {{getStatus(tarotista)}}
                    </span>
                    </div>

                  </router-link>
                </div>

                <div
                    class="
                  col-lg col-text
                  mx-md-0
                  mt-lg-0 mt-md-4 mt-4
                  order-lg-2 order-md-4 order-sm-3 order-3
                "
                >
                  <div class="row">
                    <div class="col-12">
                      <div style="float: left">
                        <h5 class="name-tarotista">
                          {{ nameReducer(tarotista) }}
                        </h5>
                      </div>
                    </div>

                    <span v-if="tarotista.scopes" class="subtitle">{{
                        tarotista.scopes[0].scopeName
                      }}</span>
                  </div>
                  <div class="descripcion">
                    <p class="text-descripcion">
                      {{ tarotista.description }}
                    </p>
                    <div class="gradient"></div>
                  </div>
                </div>

                <div
                    class="col-sm col-12 col-puntaje order-lg-3 order-md-2 order-2"
                >
                  <div class="container-puntaje">
                    <div class="row">
                      <div class="score col-12">
                        <!-- <div class="star">
                          <i
                            v-for="i in tarotista.calification"
                            :key="i"
                            class="fas fa-star"
                          ></i>
                          <i
                            v-for="i in 5 - tarotista.calification"
                            :key="i * 77"
                            class="fas fa-star grey-star"
                          ></i>
                        </div>
                        <span class="puntaje"
                          >{{ tarotista.calification }}.0</span
                        > -->
                        <Star :value="tarotista.valoracion_promedio" :shownumer="true" :size="1" :showperson="true" :person="tarotista.cantidad_valoracion" />
                      </div>

                      <div class="readings col-12">
                        <div class="glass">
                          <i class="far fa-eye"></i>
                        </div>
                        <div class="text-read">
                          <h5>
                            <span>{{ tarotista.amountOfReadings }}</span> Lecturas
                          </h5>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="container-lectura">
                    <div
                        class="tipo-lectura"
                        v-if="tarotista.type === 'agendada'"
                    >
                      <p><i class="fas fa-calendar-alt"></i> Lectura Agendada</p>
                    </div>
                    <div class="tipo-lectura" v-else>
                      <p><i class="fas fa-bolt"></i> Lectura Inmediata</p>
                    </div>
                    <div class="btn btn-sm btn-descuento" v-if="tarotista.desc">-50%</div>
                  </div>
                </div>

                <div
                    class="
                  col-lg col-12
                  order-lg-4 order-md-3 order-sm-4 order-4
                  col-btn
                  mt-md-0 mt-4
                "
                >
                  <div class="row">
                    <div class="col-12">
                      <p class="titulo-btn">Comprar</p>
                      <div v-if=" tarotista.chatPrice !== null">
                        <button
                            :disabled="disableButtonFuntion(tarotista, 'chat')
                        "

                            @click="
                          goToPay(
                            tarotista.tarotReaderId,
                            tarotista.type,
                            'Chat'
                          )
                        "
                            type="button"
                            class="btn btn-green btn-block"
                        >
                          <i class="fas fa-comments icon-btn"></i>
                          <span>Chat $ {{ numberWithCommas(tarotista.chatPrice) }}/min</span>
                          <!--<span>Chat ${{numberWithCommas(tarotista.chatPrice)}}/min</span>-->
                        </button>
                        <!--  <label class="descuento">$400/min</label> -->
                      </div>

                      <div v-if=" tarotista.voicePrice !== null">
                        <button
                            :disabled="disableButtonFuntion(tarotista, 'voice')
                        "
                            @click="
                          goToPay(
                            tarotista.tarotReaderId,
                            tarotista.type,
                            'Voz'
                          )
                        "
                            type="button"
                            class="btn btn-green btn-block"
                        >
                          <i class="fas fa-phone-alt icon-btn"></i>
                          <span>Audio ${{ numberWithCommas(tarotista.voicePrice) }}/min</span>
                          <!--<span>Audio ${{ numberWithCommas(tarotista.voicePrice) }}/min</span>-->
                        </button>
                        <!-- <label class="descuento">$400/min</label> -->
                      </div>

                      <div v-if=" tarotista.videoPrice !== null">
                        <button
                            :disabled="disableButtonFuntion(tarotista ,'video')
                        "
                            @click="
                          goToPay(
                            tarotista.tarotReaderId,
                            tarotista.type,
                            'Video'
                          )
                        "
                            type="button"
                            class="btn btn-green btn-block"
                        >
                          <i class="fas fa-video icon-btn"></i>
                          <span>Video ${{ numberWithCommas(tarotista.videoPrice) }}/min</span>
                          <!--<span>Video ${{  numberWithCommas(tarotista.videoPrice)  }}/min</span>-->
                        </button>
                        <!-- <label class="descuento">$400/min</label> -->
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </b-carousel-slide>
          </b-carousel>
        </div>
        <div class="col-12 mt-5 text-center">
          <router-link to="/catalogo/?rating=1">
            <button class="btn btn-ver-mas">Ver todos los tarotistas</button>
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axiosClient from '../../config/axiosClient';
import Spinner from '../loadingspinner/Spinner.vue';
import { enableLogs } from '../../config';
import Star from '../util/Stars.vue'

export default {
  data: () => {
    return {
      topTarotistas: [],
      pageReady: {
        socketReady: false,
        topListReady: false,
      },
      seed: Date.now(),
      isLoading: false,
      statust: {}
    };
  },
  mounted(){
    this.statust = this.$store.state.statust;
    if (this.socket){
      if(! this.socket._callbacks.$statusTarotReaderChange.length < 2){
        this.pageReady = { ...this.pageReady, socketReady: true };
        this.socket.on('statusTarotReaderChange', ()=>{
          setTimeout(() => {
            this.statust = {...this.$store.state.statust}
          }, 100);
        })
      }
    }
  },
  computed: {
    filteredAndSortedTarotistas() {
      return this.topTarotistas
          .filter(tarotista => this.getStatus(tarotista) === 'Disponible' || this.getStatus(tarotista) === 'Ocupado')
          .sort((a, b) => {
            if (a.type === 'instantanea' && b.type !== 'instantanea') {
              return -1;
            }
            if (a.type !== 'instantanea' && b.type === 'instantanea') {
              return 1;
            }
            return 0;
          });
    },
    socket: function() {
      return this.$store.state.socket;
    }
  },
  components: {
    Spinner,
    Star
  },
  watch: {
    socket: async function() {
      await this.$nextTick();
     if(enableLogs) console.log('A')
      if (this.socket){
      if(enableLogs)  console.log('B')
        this.pageReady = { ...this.pageReady, socketReady: true };
      if(enableLogs)   console.log('C')
        this.socket.on('statusTarotReaderChange', ()=>{
        if(enableLogs)  console.log('D')
          setTimeout(() => {
            this.statust = {...this.$store.state.statust}
          }, 100);
        })
      }
    },
    topTarotistas: async function() {
      await this.$nextTick();
      if (this.topTarotistas.length > 0)
        this.pageReady = { ...this.pageReady, topListReady: true };
    },
    pageReady: function() {
      const { socketReady, topListReady } = this.pageReady;
      if(enableLogs) console.log(socketReady, topListReady);
      if (socketReady && topListReady) this.handleTarotReaderStates();
    },
  },
  created() {
    this.getTopTarotReaders();
    if (this.socket) this.pageReady = { ...this.pageReady, socketReady: true };
  },

  methods: {
    getStatus(tarotista) {
      if (tarotista.type === 'agendada') {
        return 'Disponible';
      } else if (this.statust['t'+tarotista.userId] && this.statust['t'+tarotista.userId].status === 'Disponible') {
        return 'Disponible';
      } else if (this.statust['t'+tarotista.userId] && this.statust['t'+tarotista.userId].status === 'Ocupado') {
        return 'Ocupado';
      } else {
        return 'No Disponible';
      }
    },
    disableButtonFuntion(tarotista, tipo){
      if((tarotista.type === 'agendada') && (this.statust['t'+tarotista.userId] ? this.statust['t'+tarotista.userId].chanels[tipo] : false)){
        return false;
      }else if (tarotista.type === 'agendada' && !this.statust['t'+tarotista.userId]) {
        return false;
      }else if ((this.statust['t'+tarotista.userId] ? (this.statust['t'+tarotista.userId].status === 'Disponible') : false) &&
          (this.statust['t'+tarotista.userId] ? this.statust['t'+tarotista.userId].chanels[tipo] : false)) {
        return false;
      }else {
        return true;
      }
    },
    numberWithCommas(x) {
            return x.toLocaleString('es-AR')
        },
    isTarotista() {
      if (
        this.$store.state.tokenData == null ||
        this.$store.state.tokenData == undefined
      )
        return false;
      return this.$store.state.tokenData.permissions.isTarotReader;
    },
    goToPay(tarotReaderId, type, channel) {
      this.$router.push(
        `/compra?tarotista=${tarotReaderId}&tipo=${type}&canal=${channel}`
      );
    },
    handleTarotReaderStates() {
      const topTarotistas = this.topTarotistas;

      const updateTarotista = (id, state) => {
        const index = topTarotistas.findIndex(tr => tr.userId == id);
        this.$set(this.topTarotistas[index], 'state', state);
      };

      this.socket.emit('get-all-status', states => {
        //console.log(states)
       if(enableLogs) console.log('Fetching tarot reader states:');
        this.topTarotistas.forEach(tr => {
          updateTarotista(tr.userId, states[tr.userId]);
        });
        if(enableLogs) console.log('Finished setting states');
      });

      this.socket.removeAllListeners('state-update');

      this.socket.on('state-update', (id, state) => {
       if(enableLogs) console.log(`New state detected: ${id} ${state}`);
        updateTarotista(id, state);
      });
    },

    async getTopTarotReaders() {
      this.isLoading = true;
     if(enableLogs) console.log('Fetching top tarot readers');
      try {
        let response = await axiosClient.get(
          '/tarot-reader/get-top-tarot-readers'
        );
       if(enableLogs) console.log(response.data);
        this.topTarotistas = response.data.topTarotReaders;
        this.isLoading = false;
       if(enableLogs) console.log('Finished fetching top tarot readers');
      } catch (err) {
       if(enableLogs) console.log('Error fetching top tarot readers');
       if(enableLogs) console.log(err);
        this.isLoading = false;
      }
    },
    nameReducer(tarotista) {
      if (tarotista.alias.length < 15) {
        return tarotista.alias;
      } else {
        return tarotista.alias.substring(0, 15) + '...';
      }
    },
  },
};
</script>

<style scoped>
.title{
  margin-bottom: 70px;
}
.box-topTarotista {
  margin: 100px 0;
}
.box-descripcion {
  background: #f6f6f6;
  border-radius: 25px;
  padding: 25px 30px;
}
.col-img {
  max-width: 150px;
  position: relative;
}
.col-img i {
  position: absolute;
  right: 25px;
  top: 2px;
  border: 2px solid #fff;
  border-radius: 50%;
  font-size: 15px;
}

.tarotista-disponible {
  color: #04d925;
}

.tarotista-no-disponible {
  color: grey;
}

.tarotista-occupied {
  color: #ffc30b;
}

.image {
  position: relative;
}
.image img {
  width: 130px;
  height: 130px;
  border-radius: 50%;
  object-fit: cover;
}

.number-top {
  font-size: 40px;
  font-family: maven-semibold;
  color: #501682;
}
.col-text {
  max-width: 270px;
}
.col-number {
  max-width: 80px;
}
.col-btn {
  max-width: 200px;
}

.name-tarotista {
  font-family: maven-semibold;
  margin-bottom: 0px;
  margin-right: 15px;
  color: #501682;
}
.col-text i {
  color: #707070;
}

.subtitle {
  color: #707070;
  font-family: maven-regular;
  text-align: left;
  margin-bottom: 3px;
}
.text-descripcion {
  font-family: maven-regular;
  font-size: 12px;
/*   text-align: justify;
  text-justify: inter-word; */
}
.col-puntaje {
  max-width: 180px;
  font-family: maven-semibold;
  font-size: 14px;
  color: #979797;
}
.puntaje {
  margin-top: 1px;
  float: left;
  margin-left: 8px;
}
.star {
  float: left;
  /* margin-left: 5px; */
}

.star i {
  color: #ffc209;
  margin-left: 2px;
  font-size: 12px;
}

.readings {
  margin: 0px;
  /*  margin-top: 10px; */
}
.glass {
  float: left;
}
.glass i {
  margin-top: 3px;
  font-size: 10px;
  color: #979797;
}
.text-read {
  float: left;
  margin-left: 8px;
  /* text-align: center; */
  font-size: 10px;
}
.text-read h5 {
  margin-bottom: 0;
  color: #3f4254;
  font-size: 12px;
  font-family: maven-regular;
  margin-top: 4px;
  color: #979797;
}
.text-read span {
  font-family: maven-semibold;
}
.btn-green {
  background: #04d9b2;
  color: #ffffff;
  margin-bottom: 10px;
  font-family: maven-semibold;
  font-size: 12px;
  text-align: center;
  border-radius: 15px;
  width: 165px;
}
.btn-green:hover {
  background: #00bf9c;

  color: #ffffff;
}

.btn-green:disabled {
  background-color: #e8e8e8;
  color: #dddddd;
}
.btn-servicio {
  background: #04d9b2c4;
  color: #ffffff;
  width: 100%;
  margin-bottom: 5px;
  font-family: maven-semibold;
  text-transform: uppercase;
  font-size: 12px;
}

.btn-servicio:hover {
  background: #05b897c4;

  color: #ffffff;
}

.btn-descuento {
  background: #501682;
  font-family: maven-semibold;
  color: #ffffff;
  margin-right: 12px;
  padding: 3px 22px;
  /* margin-left: 20px; */
  cursor: auto;
  border-radius: 0px;
  margin-top: -10px;
  font-size: 12px;
}
.price {
  margin-top: 8px;
  font-family: maven-semibold;
  font-size: 20px;
  margin-bottom: 0;
}

.icon-btn {
  float: left;
  margin: auto;
  margin-top: 3px;
}
.btn-ver-mas {
  font-family: maven-bold;
  background: #fff;
  border: 1px solid #650bbf;
  color: #501682;
  padding: 6px 40px;
  border-radius: 50px;
  font-size: 14px;
}
.btn-ver-mas:hover {
  background: #fff;
  border: 1px solid #04d9b2;
  color: #04d9b2;
}

.price {
  margin-top: 8px;
  font-family: maven-semibold;
  margin-bottom: 0;
}

.icon-btn {
  float: left;
  margin: auto;
  margin-top: 3px;
}
.descripcion {
  font-family: maven-bold;
  font-size: 12px;
/*   text-align: justify;
  text-justify: inter-word; */
  min-height: 60px;
  max-height: 60px;
  overflow: hidden;
  position: relative;
  margin-top: 10px;
}
.gradient {
  position: absolute;
  bottom: -30px;
  height: 30px;
  left: 0;
  right: 0;
  box-shadow: 0px -2px 30px 11px #f4f4f4;
}

.descuento {
  color: #979797;
  font-family: maven-regular;
  margin-left: 10px;
  font-size: 14px;
  vertical-align: middle;
  margin-bottom: 10px;
  text-decoration: line-through;
}
.tipo-lectura{
  font-family: maven-bold;
}
@media screen and (max-width: 1366px) {
  .col-img {
    max-width: 130px;
  }
  .name-tarotista {
    font-size: 15px;
    margin-top: 5px;
  }
  .subtitle {
    font-size: 13px;
  }

  .star i {
    color: #ffc209;
    font-size: 12px;
  }

  .readings {
    margin: 0px;
    /* margin-top: 10px; */
  }
  .glass i {
    margin-top: 3px;
    font-size: 15px;
  }
  .text-read {
    /* margin-left: 8px; */

    font-size: 12px;
  }
  .btn-green,
  .btn-servicio {
    font-size: 12px;
    padding: 4px 12px;
  }
  .price {
    font-size: 15px;
  }


  .col-img i {
    right: 25px;
    font-size: 18px;
  }
}

.grey-star {
  color: #cccccc !important;
}
.container-puntaje {
  padding-bottom: 8px;
  border-bottom: 1px solid #e9e9e9;
}
.container-lectura {
  padding-top: 8px;
  font-size: 14px;
  color: #501682;
}
.container-lectura i {
  margin-right: 8px;
}

@media screen and (max-width: 990px) {
  .col-text {
    max-width: 100%;
  }
  .col-img {
    /* max-width: 80px; */
    margin: auto;
  }
  .col-puntaje {
    max-width: 250px;
  }
  .image img {
    width: 120px;
    height: 120px;
  }
  .col-number {
    max-width: 50px;
  }
  /* .col-btn{
    max-width: 100%;
  } */
}
@media screen and (max-width: 767px) {
  .col-number {
    max-width: 40px;
  }
  .col-btn {
    margin: auto;
  }
}
@media screen and (max-width: 575px) {
  /* .col-btn {
    margin: auto;
    margin-top: 24px;
    max-width: 100%;
  }
  .col-number {
    margin: auto 0px;
  } */
}

@media screen and (max-width: 320px) {
  .col-img {
    max-width: 70px;
    margin: auto;
  }
  .image img {
    width: 70px;
    height: 70px;
  }
   .col-img i {
    right: 2px;
    font-size: 15px;
  }
  /* .col-btn{
margin: auto;
margin-top: 24px;

   } */
  .col-puntaje {
    max-width: 100%;
  }
  .container-puntaje {
    max-width: 160px;
    margin: auto;
  }
  .glass i {
    margin-top: 3px;
    font-size: 15px;
  }
  .col-number {
    margin: auto 0px;
  }
  .col-number span {
    font-size: 24px;
  }

  .star i {
    font-size: 11px;
  }
}

/* Nuevos cambios */

.tarotista-estado {
  text-align: center;
  text-decoration: none;
  font-family: 'maven-bold';
  margin: 0;
}
.titulo-btn {
  font-family: 'maven-bold';
  color: #501682;
  text-align: center;
}

</style>
<style>
@media (max-width:768px) {
  #carousel-tarotistas .carousel-item {
    position: relative;
    overflow: hidden;
    height: 550px;
    border-radius: 30px;
    text-align: left;
  }
  #carousel-tarotistas .carousel-control-prev,
  #carousel-tarotistas .carousel-control-next {
    width: 10%;
  }
  #carousel-tarotistas .col-12 {
    padding: 0;
  }
  #carousel-tarotistas .carousel-caption {
    height: 100%;
    bottom: 0;
    padding: 0;
  }
  #carousel-tarotistas .carousel-control-next-icon {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23000'%3e%3cpath d='M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
  }
  #carousel-tarotistas .carousel-control-prev-icon {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23000'%3e%3cpath d='M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z'/%3e%3c/svg%3e");
  }
}
</style>