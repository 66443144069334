
<template>
    <div class="container bg">
        <div class="row justify-content-center">
            <div class="col-12">
                <h5 class="title general-title">Ámbitos de Lectura</h5>
            </div>
            <div class="col-6">
                <p class="subtitle">
                    No importa la duda o problema que tengas, siempre habrá un asesor dispuesto a ayudarte.
                </p>
            </div>
        </div>
        <div class="row justify-content-between">
            <div class="grid-container">
                <main class="grid-item main">
                    <div class="arrow" @click="scrollLeft">
                        <i class="fas fa-arrow-left"></i>
                    </div>
                    <div class="items" id="items">
                        <!-- <div class="item item1 col imagen">
                            <div class="image">
                                <img />
                            </div>
                            <span class="name-tarotista"></span>
                        </div> -->
                        <div
                            class="item item1 col imagen mt-4"
                            v-for="(ambito, index) in ambitos"
                            :key="index"
                        >
                            <div class="especialidad">
                                <div class="especialidad-img">
                                    <div class="container-img">
                                        <img
                                            :src="ambito.scopeImage"
                                            alt=""
                                            width="100%"
                                        />
                                    </div>
                                    <div class="border-img"></div>
                                </div>

                                <div class="speciality-text">
                                    <span class="speciality-name my-3">
                                        {{ ambito.scopeName }}
                                    </span>

                                    <p>
                                        {{ ambito.scopeDescription }}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="arrow" @click="scrollRight">
                        <i class="fas fa-arrow-right"></i>
                    </div>
                </main>
            </div>
        </div>
    </div>
</template>

<script>
import axiosClient from '../../config/axiosClient'
import { enableLogs } from '../../config';

export default {
    name: 'Catalogo',
    components: {},
    data: () => {
        return {
            ambitos: [],
        }
    },
    created() {
        this.getAmbitos()
    },
    methods: {
        async getAmbitos() {
            try {
                this.isLoading = true
                const response = await axiosClient.get('scopes/get-all-scopes')
                this.ambitos = response.data.scopes
               if(enableLogs) console.log('successfully fetched scopes')
                this.isLoading = false
            } catch (error) {
               if(enableLogs) console.log(error)
                this.isLoading = false
            }
        },
        scrollRight() {
        window.document.getElementById('items').scrollLeft += 140;

        },
        scrollLeft() {
        window.document.getElementById('items').scrollLeft -= 140;

        }
    },
}
</script>


<style scoped>
.bg {
    padding: 120px 0 90px 0;
}
.title {
    text-align: center;
    margin-bottom: 15px;
}
.especialidad {
    text-align: center;
    width: 160.8px;
    margin: auto;
}
.especialidad-img {
    position: relative;
}
.especialidad-img:hover .container-img {
    background: #04d9b2;
}
.container-img {
    width: 100px;
    height: 100px;
    background: #f6f6f6;
    border-radius: 50%;
    margin: auto;
    border: 1px dotted #f6f6f6;
}
.container-img img {
    max-width: 75px;
    margin-top: calc(50% - 35px);
}
.border-img {
    border: 2px dotted #501682;
    padding-top: 2px;
    border-radius: 50%;
    /* border-radius: 50%; */
    width: 110px;
    height: 110px;
    position: absolute;
    top: -5px;
    left: calc(50% - 55px);
    position: absolute;
}

.border-img:hover {
    -moz-transform: rotate(1450deg);
    -ms-transform: rotate(1450deg);
    -o-transform: rotate(1450deg);
    -webkit-transform: rotate(1450deg);
    -webkit-transition: all 0.25s ease-out;
}

.speciality-text {
    margin-top: 20px;
    font-size: 13px;
    font-family: maven-bold;
}
.speciality-text .speciality-name {
    color: #501682;
}
.speciality-text .speciality-name::after {
    content: '';
    display: block;
    width: 36px;
    height: 2px;
    background: #04d9b2;
    margin-left: calc(50% - 18px);
    margin-top: 10px;
    margin-bottom: 10px;
}

.speciality-text p {
    font-family: maven-regular;
    font-size: 11.9px;
    white-space: break-spaces;
    color: rgb(36, 36, 36);
    FONT-WEIGHT: 500;
}

/* /*scroll*/
@supports (display: grid) {
    .grid-container {
        /* display: grid; */
        grid-template-columns: 1fr 1fr 1fr;
        grid-template-rows: auto 1fr auto;
        grid-template-areas: 'header header header' 'title title footer' 'main main main';
    }

    @media screen and (max-width: 500px) {
        .grid-container {
            grid-template-columns: 1fr;
            grid-template-rows: 0.3fr 1fr auto 1fr;
            grid-template-areas: 'header' 'title' 'main' 'footer';
        }
    }

    .grid-item {
        color: #fff;
        padding: 3.5em 1em;
        font-size: 1em;
        font-weight: 700;
    }

    .header {
        background-color: #092a37;
        grid-area: header;
        padding: 1em;
    }

    .main {
        color: #959595;
        background-color: white;
        grid-area: main;
        padding: 0;
    }

    .footer {
        background-color: #5bbce4;
        grid-area: footer;
        padding: 0.6em;
    }
.items::-webkit-scrollbar{
    height: 0px;
    background-color: white;
}
.items::-webkit-scrollbar-thumb{
    background-color: purple;
    border-radius: 10px;
}

    .items {
        position: relative;
        width: 98%;
        overflow-x: auto;
        overflow-y: hidden;
        white-space: nowrap;
        transition: all 0.2s;
        transform: scale(0.98);
        will-change: transform;
        user-select: none;
        /* cursor: pointer; */
        text-align: center;
        float: left;
    }

  /*   .items:active {
        background: rgba(255, 255, 255, 0.3);
        cursor: grabbing;
        cursor: -webkit-grabbing;
        transform: scale(1);
    }
 */
    .item {
        display: inline-block;
        margin: 2em 1em;
            vertical-align: top;
    }
    .arrow{
        width: 1%;
        color: #04D9B2;
        margin-top: calc(50% - 320px);
        float: left;
        cursor: pointer
    }
}

</style>
