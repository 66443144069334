import axiosClient from "../../config/axiosClient";
import { enableLogs } from '../../config';

let getCallbackUrl = async function(
  roomId,
  kushkiToken,
  amount,
  guestId = null
) {
  if (guestId)
    return await axiosClient.post("/payment/kushki-room-webpay/init/guest", {
      kushkiFrontendToken: kushkiToken,
      kushkiAmount: {
        subtotalIva: 0,
        subtotalIva0: amount,
        iva: 0,
      },
      roomId,
      guestId,
    });
  return await axiosClient.post(
    "/payment/kushki-room-webpay/init",
    {
      kushkiFrontendToken: kushkiToken,
      kushkiAmount: {
        subtotalIva: 0,
        subtotalIva0: amount,
        iva: 0,
      },
      roomId,
    },
    {
      headers: { Authorization: `Bearer ${this.jwt}` },
    }
  );
};

let checkDebitPayment = async function(roomId, kushkiToken, guestId = null) {
  if(enableLogs) console.log(guestId);

  if (guestId)
    return await axiosClient.post("/payment/kushki-room-webpay/verify/guest", {
      kushkiFrontendToken: kushkiToken,
      guestId,
      roomId,
    });

  return await axiosClient.post(
    "/payment/kushki-room-webpay/verify",
    {
      kushkiFrontendToken: kushkiToken,
      roomId,
    },
    {
      headers: { Authorization: `Bearer ${this.jwt}` },
    }
  );
};


let checkDebitPaymentWebpay = async function(roomId, kushkiToken, guestId = null, tokenWs, jwt) {
  if(enableLogs) console.log(guestId);

  console.log('entramos checkDebitPaymentWebpay aca');
  console.log('roomId: '+roomId);
  console.log('kushkiToken: '+kushkiToken);
  console.log('guestId: '+guestId);
   console.log('token_ws: '+tokenWs);
  
  if (guestId)
    return await axiosClient.post("/payment/kushki-room-webpay/verifyWebpay/guest", {
      kushkiFrontendToken: kushkiToken,
      guestId,
      roomId,
      tokenWs,
    });

  return await axiosClient.post(
    "/payment/kushki-room-webpay/verify",
    {
      tokenWs: tokenWs,
      roomId,
    },
    {
      headers: { Authorization: `Bearer ${jwt}` },
    }
  );
  
};


export { getCallbackUrl, checkDebitPayment, checkDebitPaymentWebpay };

