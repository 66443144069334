<template>
  <div class="container my-5">
    <div class="row justify-content-center">
      <div class="col-lg-3 col-md-4 col-10 mb-3 mb-md-0">
        <InfoUser />
      </div>
      <div class="col-lg-6 col-md-7 col-10">
        <Dinero />
      </div>
      <!-- <div class="col-lg-9 col-md-11 col-10">
        <Favoritos :favourites="favourites" :isLoading="isLoading" />
      </div> -->
    </div>
    <div v-if='url !== null'>
      <ModalLlamadaAgendada :url='url'/>
    </div>
  </div>
</template>

<script>
  import InfoUser from './InfoUser';
  import Dinero from './Dinero';
/*   import Favoritos from './Favoritos'; */
  import axiosClient from '../../config/axiosClient';
  import { enableLogs } from '../../config';
  import ModalLlamadaAgendada from '@/components/util/ModalLlamadaAgendada.vue';

  export default {
    name: 'DashboardUser',
    components: {
      ModalLlamadaAgendada,
      InfoUser,
      Dinero,
/*       Favoritos, */
    },
    data() {
      return {
        url: null,
        canal: "",
        favourites: [],
        isLoading: true,
      };
    },
    mounted() {
      this.getEnvioNotificacion();
    },
    methods: {
      async getFavouriteTarotistas() {
        try {
          const response = await axiosClient.get(
            '/tarot-reader/get-favourites',
            {
              headers: { Authorization: `Bearer ${this.$store.state.jwt}` },
            }
          );
          this.favourites = response.data.favourites;
          this.isLoading = false;
        } catch (error) {
         if(enableLogs) console.log('could not get favourite tarot readers for the user');
         if(enableLogs) console.log(error);
          this.isLoading = false;
        }
      },
      async getEnvioNotificacion(){
        const envio = await axiosClient.post(
          '/tarot-calendly/post-notificacion/envio',
          {},
          {
            headers: { Authorization: `Bearer ${this.$store.state.jwt}` },
          }
        );
        if (envio.data.roomID !== null){
          this.canal = envio.data.canal;
          this.url = this.getNextLink(envio.data.roomID);
        }
      },
      getNextLink(roomId) {
        let nextLink = ``;
        switch (this.canal) {
          case 'VIDEO':
            nextLink = `/sala-espera?roomId=${roomId}`;
            break;
          case 'CHAT':
            nextLink = `/chat?roomId=${roomId}`;
            break;
          case 'VOZ':
            nextLink = `/room-llamada?roomId=${roomId}`;
            break;
        }
        return nextLink;
      },
    },
    created() {
      this.getFavouriteTarotistas();
    },
  };
</script>
