<template>
  <!--Modal confirmacion pago-->
  <div>
    <b-modal
      :no-close-on-esc="true"
      :no-close-on-backdrop="true"
      v-model="showModal"
      id="modal-compra-datos-llamada"
      size="lg"
      centered
    >
      <div class="row">
        <div class="col-12 mb-2">
          <p class="text-modal">COMPRAR LECTURA INSTANTANEA</p>
        </div>
        <div class="col-12 mb-2">
          <span class="text-sutitulo">Ingrese su número telefónico</span>
        </div>
        <div class="col-12 mb-2">
          <div class="form-group">
            <input
              @keypress="isNumberAndSign"
              v-model="phoneNum"
              class="form-control"
              type="number"
              name=""
              id=""
              autocomplete="off"
              pattern="[0-9_+]*"
              maxlength="15"
            />
            <legend class="legend-field text-legend">
              (El formato correcto del número ingresado debe ser por ejemplo:
              56978582563)
            </legend>
            <span class="alert" v-if="validarTelefono(phoneNum)"
              >Campo obligatorio</span
            >
          </div>
        </div>
      </div>

      <template
        #modal-footer="{
          /*hide*/
        }"
      >
        <div class="row">
          <div class="col-12 text-center">
            <b-button
              :disabled="!validForm"
              size="sm"
              class="btn-sm btn btn-yellow"
              v-on:click="confirmPayment"
            >
              Aceptar
            </b-button>
          </div>
        </div>
      </template>
    </b-modal>
  </div>
</template>
<script>
export default {
  watch: {
    triggerModal: function (value) {
      if (value) this.showModal = true;
    },
  },
  props: ['triggerModal'],

  data: () => {
    return {
      showModal: false,
      phoneNum: '',
    };
  },
  methods: {
    isNumberAndSign(evt) {
            if (this.phoneNum.length >= 11 ) {
                evt.preventDefault()
                return
            }
            const keysAllowed = [
                '0',
                '1',
                '2',
                '3',
                '4',
                '5',
                '6',
                '7',
                '8',
                '9',
                '+',
            ]
            const keyPressed = evt.key
            if (!keysAllowed.includes(keyPressed)) {
                evt.preventDefault()
            }
        },
        /** Esta funcion retorna true si el telefono e n           nnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnn                                               s invalido */
        validarTelefono(valor) {
            if (valor.length > 0) {
                if (valor.length >= 11) {
                    return false
                }
                return true
            } else {
                return false
            }
        },
    confirmPayment() {
      this.$emit('setPhoneNum', this.phoneNum);
      this.$emit('askForConfirmation');
      this.hideModal();
    },
    hideModal() {
      this.showModal = false;
    },
  },
  computed: {
        validForm() {
            let validForm = true

            this.validarTelefono(this.phoneNum) ? (validForm = false) : null

            this.phoneNum.length > 0 ? null : (validForm = false)
          

            return validForm
        },
    },
};
</script>

<style scoped>
/deep/.close {
  background: transparent;
  border: none;
}
/deep/.modal-header {
  border-bottom: none;
}

/deep/.modal-body {
  padding: 0 50px;
  font-family: maven-regular;
}
.text-modal {
  color: #333;
  font-family: maven-bold;
  text-align: center;
  text-transform: uppercase;
}
.text-saldo {
  text-align: center;
  font-family: maven-regular;
  font-size: 20px;
}
.text-saldo span {
  font-family: maven-bold;
  color: #3b5998;
}
/deep/.modal-footer {
  border-top: none;
  padding: 10px 50px 30px 50px;
  display: block;
}
/deep/.btn-yellow {
  background-color: #ffc209;
  color: #501682;
  font-family: maven-bold;
  text-transform: uppercase;
  border: none;
  padding: 6px 20px;
}
/deep/.btn-line {
  border: 1px solid #501682;
  background-color: transparent;
  color: #501682;
  font-family: maven-bold;
  text-transform: uppercase;
  padding: 6px 20px;
}

.text-sutitulo {
  font-size: 13px;
}

.text-legend {
  padding: 0px;
  font-size: 12px;
  font-weight: bold;
  margin-bottom: 0px;
}
.alert {
    color: red;
    padding: 0px;
    font-size: 11px;
    font-weight: bold;
}
</style>
