<template>
  <!--Modal confirmacion pago-->
  <div>
    <b-modal
      :no-close-on-esc="true"
      :no-close-on-backdrop="true"
      id="modal-admin-descripcion"
      centered
      hide-footer
      v-model="showModal"
    >
      <form action="">
        <label class="mt-2 mb-3 text-body">
          Por favor, ingrese aquí el motivo del rechazo de la solicitud del
          tarotista
        </label>
        <textarea
          name=""
          id=""
          rows="5"
          class="form-control mb-3"
          v-model="reason"
        ></textarea>
      </form>

      <div class="col-12 text-end">
        <button class="btn btn-eliminar" @click="rejectInscription">
          {{ loading ? 'Rechazando...' : 'Rechazar Inscripción' }}
        </button>
      </div>
    </b-modal>
  </div>
</template>
<script>
  import axiosClient from '../../../config/axiosClient';
  import { enableLogs } from '../../../config';

  export default {
    watch: {
      toggleRejectionModal: function(value) {
        if (value) this.showModal = true;
      },
    },
    props: ['toggleRejectionModal', 'inscription'],
    mounted() {
     if(enableLogs) console.log(this.inscription);
    },

    data: () => {
      return {
        showModal: false,
        reason: null,
        loading: false,
      };
    },
    methods: {
      hideModal() {
        this.showModal = false;
      },
      async rejectInscription() {
        try {
          this.loading = true;
          const response = await axiosClient.post(
            'inscriptions/reject-inscription',
            {
              inscription: {
                id: this.inscription.id,
                email: this.inscription.data.email,
              },
              rejectionReason: this.reason,
            },
            { headers: { Authorization: `Bearer ${this.$store.state.jwt}` } }
          );
         if(enableLogs) console.log(response.data);
          this.loading = false;
          this.$emit('editStatus', {
            id: this.inscription.id,
            estado: 'Rechazada',
          });
          this.showModal = false;
        } catch (error) {
         if(enableLogs) console.log('could not reject inscription');
         if(enableLogs) console.log(error);
          this.loading = false;
        }
      },
    },
  };
</script>

<style scoped>
  /deep/.close {
    background: transparent;
    border: none;
  }
  /deep/.modal-header {
    border-bottom: none;
  }
  /deep/.modal-body {
    padding: 48px;
    padding-top: 10px;
  }

  .text-body {
    text-align: center;
    font-family: maven-semibold;
    font-size: 16px;
  }
  textarea {
    resize: none;
    font-family: maven-regular;
  }
  .btn-eliminar {
    font-size: 12px;
    background-color: #979797;
    color: #333333;
    font-family: maven-semibold;
    font-size: 10px;
    text-transform: uppercase;
    padding: 8px 10px;
  }
  .btn-eliminar:hover {
    background-color: #858585;
    color: #333333;
  }
</style>
