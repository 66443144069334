<template>
  <div class="container">
    <div class="row justify-content-center mt-5">
      <div class="col-7 shadow" v-if="!isLoading && success">
        <p>Tu contraseña se ha establecido correctamente</p>
      </div>
      <b-card class="col-7 shadow" v-if="!isLoading && !success">
        <div class="row">
          <p>Reestablece tu contraseña</p>

          <div class="mb-3 container-pass">
            <label for="">Nueva contraseña</label>
            <div>
              <i class="fas fa-lock iconoInput"></i>
              <i class="icon-eye" :class="iconPass" @click="mostrarPassword" id="icon"></i>

              <input
                class="form-control inputPadding"
                aria-label="Username"
                aria-describedby="basic-addon1"
                v-model="password"
                @click="initvar()"
                autocomplete="off"
                :type="passwordFieldType"
              />
              <p class="alert" v-if="validarPass(password.toString())">
                La contraseña debe contener al menos 8 caracteres
                (letras, números y carácter especial).
              </p>
            </div>
          </div>
          <div class="mb-3 container-pass">
            <label for="">Repetir contraseña</label>
            <div>
              <i class="fas fa-lock iconoInput"></i>
               <i class="icon-eye" :class="iconPassTwo" @click="mostrarRepeatPassword" id="icon"></i>

              <input
                class="form-control inputPadding"
                aria-label="Username"
                aria-describedby="basic-addon1"
                v-model="passwordConfirm"
                @click="initvar()"
                autocomplete="off"
                 :type="passwordFieldTypeTwo"
              />
              <p
                class="alert"
                v-if="!checkPasswordsEqual(passwordConfirm.toString())"
              >
                Las contraseñas no coinciden.
              </p>
            </div>
          </div>
          <button
            :disabled="!validForm"
            @click="setNewPassword"
            class="btn btn-yellow"
          >
            Enviar
          </button>

          <div class="col-12 text-center my-4" v-if="isLoading">
            <br /><br />
            <b-spinner variant="" label="Spinning"></b-spinner>
            <br />
            <h3>Cargando</h3>
            <br />
          </div>
        </div>
      </b-card>
    </div>
  </div>
</template>
<script>
  import axiosClient from '../../config/axiosClient';
  import { enableLogs } from '../../config';


  export default {
    name: 'SetPassword',
    data() {
      return {
        password: '',
        passwordConfirm: '',
        isLoading: false,
        success: false,
        passwordsNotEqual: false,
        token: null,
        noinit: true,
        passwordFieldType: "password",
        passwordFieldTypeTwo: "password",
        iconPass: "fas fa-eye",
        iconPassTwo: "fas fa-eye"
      };
    },
    created() {
      const token = this.$route.query.token;
      this.token = token;
    },
    computed: {
      validForm() {
        console.log(!this.validarPass(this.password.toString()))
        console.log(this.checkPasswordsEqual())
        if (!this.validarPass(this.password) && this.checkPasswordsEqual()) {
          console.log('valid form')
          return true;
        }
        console.log('invalid form')
        return false;
      },
    },
    methods: {
      initvar(){
        this.noinit = false
      },
      async setNewPassword() {
        this.isLoading = true;
        if (this.checkPasswordsEqual()) {
          try {
            const response = await axiosClient.post(
              '/users/forgot-pass/confirm',
              {
                token: this.token,
                newPass: this.password,
              }
            );
           if(enableLogs) console.log(response);
           if(enableLogs) console.log('successfully set new password');
            this.isLoading = false;
            this.success = true;
            setTimeout(() => {
              this.$router.push("/");
            }, 5000);
          } catch (error) {
           if(enableLogs) console.log('could not set new password');
           if(enableLogs) console.log(error);
            this.isLoading = false;
            alert('La clave caducó, solicite restablecer contraseña nuevamente')
            this.$router.push("/");
            this.success = false;
          }
        } else {
          this.passwordsNotEqual = true;
        }
      },
      validarPass(valor) {
        if (valor.length > 0) {
          // Expresión regular que permite letras, números y caracteres especiales, con al menos 8 caracteres
          return !/^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d@$!%*?&^#(){}[\]/\\+=\-.,<>;:`~_]{8,}$/.test(valor);
        } else {
          return false;
        }
      },
      checkPasswordsEqual() {
        if (this.password !== this.passwordConfirm) {
          return false;
        } else {
          return true;
        }
      },
      mostrarPassword(){
      this.passwordFieldType = this.passwordFieldType === "password" ? "text" : "password";
      this.iconPass = this.iconPass === 'fas fa-eye' ? 'fas fa-eye-slash' : 'fas fa-eye'	
      },
      mostrarRepeatPassword(){
          this.passwordFieldTypeTwo = this.passwordFieldTypeTwo === "password" ? "text" : "password";
          this.iconPassTwo = this.iconPassTwo === 'fas fa-eye' ? 'fas fa-eye-slash' : 'fas fa-eye'	
      },
    },
  };
</script>
<style scoped>
/deep/ .card {
  border-radius: 10px;
}
/deep/ .card-body {
  padding: 40px;
}
.title {
  font-family: maven-semibold;
  margin-bottom: 10px;
  color: #30075b;
  font-size: 22px;
}
label {
  color: #333333;
  font-family: maven-semibold;
  text-decoration: none;
  padding-bottom: 5px;
  font-size: 13px;
}
.btn-yellow {
  background: #ffc109bb;
  font-family: maven-semibold;
  text-transform: uppercase;
  color: #501682;
  border-radius: 10px;
  margin-top: 24px;
  padding: 8px 40px;
  font-size: 13px;
}
.btn-yellow:hover {
  background: #dba501;
  color: #650bbf;
}

.container-pass, .container-email, .container-name{
  position: relative;
}
.iconoInput {
  color: #30075b;
  font-size: 15px;
  position: absolute;
  left: 18px;
  font-size: 16px;
  padding-top: 12px;
}
.icon-eye{
  color: #30075b;
  font-size: 15px;
  position: absolute;
  right: 15px;
  font-size: 16px;
  padding-top: 12px;
  cursor: pointer;
}
p{
  font-family: maven-semibold;
}
h3{
  font-family: maven-semibold;
}
.inputPadding {
  border: 1px solid #30075b;
  padding-left: 40px;
  font-family: maven-semibold;
  background: #efefef;
  border-radius: 10px;
  height: 40px;
}
.inputPadding:focus {
  color: #650bbf !important;
}

.alert {
  color: red;
  padding: 0px;
  font-size: 11px;
  font-family: maven-medium;
  margin-bottom: 0px;
}
/* @media screen and (max-width: 1366px) {
  .title {
    font-size: 22px;
  }
  label,
  .link {
    font-size: 13px;
  }
  .iconoInput {
    font-size: 16px;
    padding-top: 12px;
  }

  .inputPadding {
    height: 40px;
  }
  .btn-yellow {
    padding: 8px 40px;
    font-size: 13px;
  }
  .text-account {
    font-size: 15px;
  }
} */
</style>
