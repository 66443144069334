<template>
  <div>
    <div class="card shadow p-3">
      <div class="card-body">
        <h5 class="title">Dejanos tu consulta y nos pondremos en contacto.</h5>
        <form class="contact-form" @submit.prevent="sendEmail">
          <div class="row">
            <div class="col-md-6 col-12 mb-3">
              <div class="form-group">
                <label for="">Nombre</label>
                <input
                  type="text"
                  class="form-control"
                  id=""
                  aria-describedby=""
                  placeholder=""
                  v-model="nombre"
                  v-on:click="changeInput"
                  name="editNombre"
                />
                <span class="alert" v-if="nombre.length === 0 && editNombre"
                  >Campo obligatorio</span
                >
              </div>
            </div>
            <div class="col-md-6 col-12 mb-3">
              <div class="form-group">
                <label for="">Apellido</label>
                <input
                  type="text"
                  class="form-control"
                  id=""
                  aria-describedby=""
                  placeholder=""
                  v-model="apellido"
                  v-on:click="changeInput"
                  name="editApellido"
                />
                <span class="alert" v-if="apellido.length === 0 && editApellido"
                  >Campo obligatorio</span
                >
              </div>
            </div>
            <div class="col-md-6 col-12 mb-3">
              <div class="form-group">
                <label for="">Telefono</label>
                <input
                  type="number"
                  @keypress="isNumberAndSign"
                  class="form-control"
                  placeholder=""
                  v-model="telefono"
                  autocomplete="off"
                  pattern="[0-9]*"
                />
                <legend class="legend-field text-legend">
                  (El formato debe ser por ej: +56911222333)
                </legend>
                <span class="alert" v-if="validarTelefono(telefono)"
                  >Campo obligatorio</span
                >
              </div>
            </div>
            <div class="col-md-6 col-12 mb-3">
              <div class="form-group">
                <label for="">Email</label>
                <input
                  type="email"
                  class="form-control"
                  id=""
                  aria-describedby=""
                  placeholder=""
                  v-model="email"
                />
                <span class="alert" v-if="validarEmail(email.toString())"
                  >Campo obligatorio</span
                >
              </div>
            </div>
            <div class="col-12 mb-3">
              <div class="form-group">
                <label for="">Consulta</label>
                <textarea
                  class="form-control"
                  name="message"
                  id=""
                  cols="30"
                  rows="3"
                  v-model="consulta"
                  v-on:click="changeInput"
                ></textarea>
                <span class="alert" v-if="consulta.length === 0 && message"
                  >Campo obligatorio</span
                >
              </div>
            </div>
            <div class="col-12 mb-3 text-end">
              <button
                type="submit"
                class="btn btn-yellow"
                value="Send"
                v-b-modal.modal-contacto-exitoso
                :disabled="!validForm"
              >
                Enviar
              </button>
              <ModalExito :success="success" />
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>
<script>
import emailjs, { init } from 'emailjs-com';
import ModalExito from './ModalExito.vue';
import { enableLogs } from '../../config';
import axiosClient from "@/config/axiosClient";
export default {
  props: ['stepTwo'],
  components: {
    ModalExito,
  },
  data: function () {
    return {
      email: '',
      nombre: '',
      apellido: '',
      telefono: '',
      consulta: '',

      editEmail: false,
      editNombre: false,
      editApellido: false,
      editTelefono: false,
      message: false,
      success: true,
    };
  },
  methods: {
    handleClickNext() {
      this.$emit('stepTwo');
    },
    /** Esta funcion retorna true si el telefono es invalido */
    validarEmail(valor) {
      if (valor.length > 0) {
        if (
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
            valor
          )
        ) {
          return false;
        } else {
          return true;
        }
      } else {
        return false;
      }
    },
    /** Esta funcion retorna true si el telefono es invalido */
    validarTelefono(valor) {
      if (valor.length > 0) {
        /*if (/^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/.test(valor)) {
          return false;
        } else {
          return true;
        }*/
        if (valor.length >= 11) {
          return false;
        }
        return true;
      } else {
        return false;
      }
    },
    changeInput(event) {
      this[event.target.name] = true;
    },
    async sendEmail() {
      let data = {
        nombre: this.nombre,
        apellido: this.apellido,
        telefono: this.telefono,
        consulta: this.consulta,
      };
      try {
        const response = await axiosClient.post(
            '/email-send/email-notificacion',
            {
              email: this.email,
              datosVariados: data,
              type: 'contactanos',
            }
        );
        if (response.status === 200) {
          this.success = true;
          if (enableLogs) console.log('consulta enviada exitosamente');
        }
        if(enableLogs) console.log(response);
      } catch (error) {
        console.log('tu consulta no se ha podido enviar exitosamente ', error);
        this.success = false;
      }
    },
    async sendEmaila(e) {
      init('user_uHlwiLPs5Nv2ybzMnLQIA');
      try {
        const response = await emailjs.sendForm(
          'service_epor1zp',
          'template_w9fbxc8',
          e.target,
          'user_uHlwiLPs5Nv2ybzMnLQIA'
        );
        if (enableLogs) console.log(response);
        if (response.status === 200) {
          this.success = true;
          if (enableLogs) console.log('consulta enviada exitosamente');
        }
      } catch (error) {
        this.success = false;
        if (enableLogs)
          console.log('tu consulta no se ha podido enviar exitosamente ', error);
      }
    },
    isNumberAndSign(evt) {
      if (this.telefono.length >= 11) {
        evt.preventDefault();
        return;
      }
      const keysAllowed = [
        '0',
        '1',
        '2',
        '3',
        '4',
        '5',
        '6',
        '7',
        '8',
        '9',
        '+',
      ];
      const keyPressed = evt.key;
      if (!keysAllowed.includes(keyPressed)) {
        evt.preventDefault();
      }
    },
    /* onlyNumber($event) {
      //console.log($event.keyCode); //keyCodes value
      let keyCode = $event.keyCode ? $event.keyCode : $event.which;
      if ((keyCode < 48 || keyCode > 57) && keyCode !== 46) {
        // 46 is dot
        $event.preventDefault();
      } 
    },*/
  },
  computed: {
    validForm() {
      let validForm = true;

      this.validarEmail(this.email) ? (validForm = false) : null;
      this.validarTelefono(this.telefono) ? (validForm = false) : null;

      this.telefono.length > 0 ? null : (validForm = false);
      this.nombre.length > 0 ? null : (validForm = false);
      this.apellido.length > 0 ? null : (validForm = false);
      this.consulta.length > 0 ? null : (validForm = false);

      return validForm;
    },
  },
};
</script>
<style scoped>
.title {
  font-family: maven-semibold;
  font-size: 18px;
  margin-bottom: 30px;
}

label {
  margin-bottom: 8px;
  color: #787878;
  font-family: maven-bold;
}
input,
textarea {
  color: #333;
  font-family: maven-regular;
}
.btn-yellow {
  background: #ffc209;
  font-weight: bold;
  text-transform: uppercase;
  color: #501682;
  padding: 10px 50px;
  border-radius: 10px;
  font-size: 13px;
  font-family: maven-bold;
}
.btn-yellow:hover {
  background: rgb(253, 216, 106);
  color: #501682;
}

.btn-yellow:focus {
  outline: none !important;
}
.text-legend {
  padding: 0px;
  font-size: 12px;
  font-weight: bold;
  font-family: maven-regular;
}
.alert {
  color: red;
  padding: 0px;
  font-size: 11px;
  font-family: maven-semibold;
}
</style>
