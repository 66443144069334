<template>
  <div>
    <b-modal
      :no-close-on-esc="true"
      :no-close-on-backdrop="true"
      id="modalPass"
      ref="modalPass"
      centered
      hide-footer
      @hide="cleanValues"
    >
      <template #modal-title> </template>

      <div class="col-12 text-center my-4" v-if="screen == 2">
        <br /><br />
        <b-spinner variant="" label="Spinning"></b-spinner>
        <br />
        <h3>Cargando</h3>
        <br />
      </div>

      <div v-if="screen == 3">
        <br />
        <h3>{{ messageFinish }}</h3>
        <br />
      </div>

      <div v-if="screen == 1">
        <h3 class="title">Contraseña</h3>
        <div class="mb-3 container-pass">
          <label class="label-modal" for="">Contraseña actual</label>
          <div>
            <i class="fas fa-lock iconoInput"></i>
             <i class="icon-eye" :class="iconPass" @click="mostrarPassword" id="icon"></i>
            <input
              class="form-control inputPadding"
              aria-label="Username"
              aria-describedby="basic-addon1"
              placeholder="Ingrese contraseña actual"
              @click="initvar()"
              v-model="actualPassword"
              :type="passwordFieldType" 
            />
            <p class="alert" v-if="actualPassword.length == 0 && !noinit">
              Ingrese la contraseña actual
            </p>
          </div>
        </div>
        <div class="mb-3 container-pass">
          <label class="label-modal" for="">Nueva contraseña</label>
          <div>
            <i class="fas fa-lock iconoInput"></i>
            <i class="icon-eye" :class="iconPassTwo" @click="mostrarNewPassword" id="icon"></i>

            <input
              class="form-control inputPadding"
              aria-label="Username"
              aria-describedby="basic-addon1"
              placeholder="Ingrese nueva contraseña"
              @click="initvar()"
              v-model="newPassword"
              :type="passwordFieldTypeTwo" 
            />
            <p
              class="alert"
              v-if="validarPass(newPassword.toString()) && !noinit"
            >
              La contraseña debe contener al menos 8 caracteres
              (letras, números y carácter especial).
            </p>
          </div>
        </div>
        <div class="mb-3 container-pass">
          <label class="label-modal" for="">Repetir nueva contraseña</label>
          <div>
            <i class="fas fa-lock iconoInput"></i>
            <i class="icon-eye" :class="iconPassThree" @click="mostrarRepeatPassword" id="icon"></i>
            <input
              class="form-control inputPadding"
              aria-label="Username"
              aria-describedby="basic-addon1"
              placeholder="Repita nueva contraseña"
              @click="initvar()"
              v-model="repeatPassword"
              :type="passwordFieldTypeThree"
            />
            <p class="alert" v-if="!checkPasswordsEqual()">
              Las contraseñas no coinciden.
            </p>
          </div>
        </div>
        <button
          class="btn btn-yellow"
          v-on:click="changePassword"
          :disabled="!validForm"
        >
          Modificar
        </button>
      </div>
    </b-modal>
  </div>
</template>

<script>
import axiosClient from "../../../config/axiosClient";
import { enableLogs } from '../../../config';

export default {
  data() {
    return {
      actualPassword: "",
      newPassword: "",
      repeatPassword: "",
      screen: 1,
      messageFinish: "",
      noinit: true,
      passwordFieldType: "password",
      passwordFieldTypeTwo: "password",
      passwordFieldTypeThree: "password",
      iconPass: "fas fa-eye",
      iconPassTwo: "fas fa-eye",
      iconPassThree: "fas fa-eye",
    };
  },
  computed: {
    validForm() {
      let validForm = !this.noinit;
      this.validarPass(this.newPassword) ? (validForm = false) : null;
      !this.checkPasswordsEqual() ? (validForm = false) : null;
      if (this.actualPassword.length == 0) {
        validForm = false;
      }
      return validForm;
    },
  },
  methods: {
    initvar() {
      this.noinit = false;
    },
    async changePassword() {
      if (this.actualPassword.length > 0) {
        if (
          this.newPassword == this.repeatPassword &&
          this.repeatPassword.length > 0
        ) {
          this.screen = 2;
          var response = null;
          try {
            response = await axiosClient.post(
              "users/change-pass",
              {
                pass: this.actualPassword,
                newPass: this.newPassword,
              },
              {
                headers: { Authorization: `Bearer ${this.$store.state.jwt}` },
              }
            );
            if (response.data.code == 2) {
              this.screen = 3;
              this.messageFinish = "Contraseña modificada exitosamente";
              setTimeout(() => {
                this.screen = 1;
                this.cleanValues();
                this.$refs["modal-suscripcion"].hide();
              }, 3000);
            } else {
              this.screen = 3;
              this.messageFinish = "Error al modificar contraseña";
             if(enableLogs) console.log("Contraseña cambiada", response);
              setTimeout(() => {
                this.screen = 1;
                this.cleanValues();
              }, 3000);
            }
          } catch (error) {
            this.screen = 3;
            this.messageFinish =
              "Error al modificar contraseña, por favor revise que la contraseña actual sea válida";
            setTimeout(() => {
              this.screen = 1;
              this.cleanValues();
            }, 3000);
          }
        }
      }
    },
    cleanValues() {
      this.actualPassword = "";
      this.newPassword = "";
      this.repeatPassword = "";
      this.noinit = true;
    },
    validarPass(valor) {
      if (valor.length > 0) {
        // Expresión regular que permite letras, números y caracteres especiales, con al menos 8 caracteres
        return !/^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d@$!%*?&^#(){}[\]/\\+=\-.,<>;:`~_]{8,}$/.test(valor);
      } else {
        return false;
      }
    },
    checkPasswordsEqual() {
      return this.newPassword === this.repeatPassword;
    },
    mostrarPassword(){
      this.passwordFieldType = this.passwordFieldType === "password" ? "text" : "password";
      this.iconPass = this.iconPass === 'fas fa-eye' ? 'fas fa-eye-slash' : 'fas fa-eye'	
	},
  mostrarNewPassword(){
      this.passwordFieldTypeTwo = this.passwordFieldTypeTwo === "password" ? "text" : "password";
      this.iconPassTwo = this.iconPassTwo === 'fas fa-eye' ? 'fas fa-eye-slash' : 'fas fa-eye'	
	},
  mostrarRepeatPassword(){
      this.passwordFieldTypeThree = this.passwordFieldTypeThree === "password" ? "text" : "password";
      this.iconPassThree = this.iconPassThree === 'fas fa-eye' ? 'fas fa-eye-slash' : 'fas fa-eye'	
	},
  },
};
</script>


<style scoped>
/deep/.close {
  background: transparent;
  border: none;
}
/deep/ .modal-header {
  border-bottom: none;
  padding-bottom: 0px;
}
/deep/ .modal-body {
  padding: 48px;
  padding-top: 0px;
  font-family: maven-medium;
}
.title {
  font-family: maven-semibold;
  font-size: 18px;
  margin-bottom: 20px;
  text-transform: uppercase;
}

.btn-yellow {
  background: #ffc109bb;
  font-family: maven-semibold;
  text-transform: uppercase;
  color: #501682;
  border-radius: 10px;
  padding: 8px 16px;
  font-size: 12px;
  float: right;
}

.container-pass, .container-email, .container-name{
  position: relative;
}
.iconoInput {
  color: #30075b;
  font-size: 15px;
  position: absolute;
  left: 18px;
  font-size: 16px;
  padding-top: 12px;
}
.icon-eye{
  color: #30075b;
  font-size: 15px;
  position: absolute;
  right: 15px;
  font-size: 16px;
  padding-top: 12px;
  cursor: pointer;
}
.inputPadding {
  border: 1px solid #30075b;
  height: 40px;
  padding-left: 40px;
  font-family: maven-semibold;
  background: #efefef;
  border-radius: 10px;
}
.btn-email {
  width: 100%;
  font-family: maven-semibold;
  border-radius: 10px;
  background: #333;
  color: #fff;
  font-size: 12px;
}
label {
  font-size: 14px;
}
.label-modal {
  font-family: maven-semibold;
  font-size: 16px;
  margin-bottom: 3px;
}
.alert {
  color: red;
  padding: 0px;
  font-size: 11px;
  font-weight: bold;
}
</style>
