<template>
  <div class="container">
    <div class="row justify-content-center  mt-5">
      <div class="col-10">
        <h5 class="title general-title">Contáctanos</h5>
      </div>
      <div class="col-10 my-3">
          <Formulario/>
      </div>
    </div>
  </div>
</template>

<script>
import Formulario from "./Formulario.vue";
export default {
  name: "Sac",
  components: {
    Formulario,
  },
};
</script>

<style scoped>

</style>
