<template>
  <div>
    <div class="card card-pagos">
      <b-card-body class="card-body">
          <h5 class="title-card-pago margin-title ">Selecciona el Método de Pago</h5>
           <div
            v-for="(option, index) in opciones"
            :key="index"
            class="col-12 mb-4 mt-3"
          >
            <div class="card card-form-input">
              <div class="body-card">
                <div class="form-check">
                  <input
                    class="form-check-input pagos-check-input"
                    type="radio"
                    name="radio-pago"
                    :id="option.value"
                    :value="option.value"
                    v-model="selectedOption"
                  />
                  <label class="form-check-label label-card-pago" :for="option.value">
                    {{ option.name }}
                  </label>
                </div>
              </div>
            </div>
          </div>
          <div
            class="col-12 mb-4 mt-3"
          >
            <div class="card card-form-input" v-if="mediosPago['debito'] == 1">
              <div class="body-card">
                <div class="form-check">
                  <input
                    class="form-check-input pagos-check-input"
                    type="radio"
                    name="radio-pago"
                    id="Debit"
                    value="Debit"
                    v-model="selectedOption"
                  />
                  <label class="form-check-label label-card-pago" for="Debit">
                    WebPay
                  </label>
                </div>
              </div>
            </div>
            <div class="card card-form-input mt-3" v-if="mediosPago['credito'] == 1">
              <div class="body-card">
                <div class="form-check">
                  <input
                    class="form-check-input pagos-check-input"
                    type="radio"
                    name="radio-pago"
                    id="Credit"
                    value="Credit"
                    v-model="selectedOption"
 
                  />
                  <label class="form-check-label label-card-pago" for="Credit">
                   Tarjeta de Crédito
                  </label>
                </div>
              </div>
            </div>
            <div class="card card-form-input mt-3" v-if="mediosPago['transferencia'] == 1">
              <div class="body-card">
                <div class="form-check">
                  <input
                    class="form-check-input pagos-check-input"
                    type="radio"
                    name="radio-pago"
                    id="Transfer"
                    value="Transfer"
                    v-model="selectedOption"
                  />
                  <label class="form-check-label label-card-pago" for="Transfer">
                    Transferencia
                  </label>
                </div>
              </div>
            </div>
          </div>
      </b-card-body>
    </div>
  </div>
</template>
<script>
import axiosClient from '../../../config/axiosClient'
export default {
  data: () => {
    return {
      selectedOption: null,
      opciones: [
                {
          name: "WebPay",
          value: "Debit",
        },
/*         {
          name: "Tarjeta de Crédito",
          value: "Credit",
        }, */
       /* {
          name: "Transferencia",
          value: "Transfer",
        },*/
        /* {
          name: "Efectivo",
          value: "Cash",
        }, */
      ],
      mediosPago: {},
    };
  },  
  watch: {
    selectedOption: function(newOption) {
      this.$emit("metodoPagoChange", newOption);
    },
  },
  created() {
    
  },
  methods:{
    async getMediosPago(){
      try{
        const response = await axiosClient.get('/status-buttons/')
        console.log(response.data)
        return this.mediosPago = response.data
      }catch(e){
        console.log(e)
      }


      
    }

  },
  mounted(){
    this.getMediosPago()
    if(this.$route.query.c){
      this.selectedOption = "Credit";
    }else{
      this.selectedOption = "Debit";
    }
  },
};
</script>
<style scoped>

</style>
