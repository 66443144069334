<template>
  <div>
    <div id="outserch" v-on:click="serch" v-if="serarching"></div>
    <div class="container p-0" style="position: relative">
      <SerchMod v-if="serarching" :fun="serch" />
      <div class="">
        <div class="row header py-3">
          <div class="col-lg social-media align-self-end d-lg-block d-none">
            <a
              href="https://www.facebook.com/www.divinotarot.cl"
              target="_blank"
              ><i class="fab fa-facebook-f" style="font-size: 19px"></i
            ></a>
            <a href="https://www.instagram.com/divinotarot_/" target="_blank"
              ><i class="fab fa-instagram"></i
            ></a>
            <!-- <a
              href="https://www.linkedin.com/company/celcom-s-a-/?viewAsMember=true"
              target="_blank"
              ><i class="fab fa-linkedin-in"></i
            ></a> -->
          </div>

          <div class="col-lg col-12 logo">
            <router-link to="/">
              <img src="../../assets/img/logo.png" alt="" />
            </router-link>
          </div>

          <div class="col-lg col-12 icons align-self-end">
            <i class="fas fa-search search" v-on:click="serch"></i>
            <router-link
              :to="dashboardLink"
              v-if="!this.$store.state.tokenData.permissions.isTarotReader"
            >
              <div class="wallet">
                <i class="fas fa-wallet"></i>
                <span>${{ numberWithCommas(balance) }}</span>
              </div>
            </router-link>

            <div class="user">
              <DropdownUser />
            </div>

            <div class="bell">
              <DropdownNoti />
            </div>
          </div>
          <!-- <div class="col-md col-12 icons mt-md-0 mt-3 align-self-end" v-else>
              <div
                class="row justify-content-md-end justify-content-center px-3"
              >
                <div class="col col-search">
                  <i class="fas fa-search search" v-on:click="serch"></i>
                </div>
                <div class="col col-bell bell">
                  <DropdownNoti />
                </div>
                <div class="col col-user user">
                  <DropdownUser />
                </div>
                <div class="col col-wallet">
                  <router-link :to="dashboardLink">
                    <div class="wallet col">
                      <i class="fas fa-wallet"></i>
                      <span>${{ numberWithCommas(balance) }}</span>
                    </div>
                  </router-link>
                </div>
              </div>
            </div> -->
        </div>

        <b-navbar toggleable="lg">
          <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

            <b-collapse id="nav-collapse" is-nav>
                <!-- Right aligned nav items -->
                <b-navbar-nav
                    class="col-12 mb-1 justify-content-center text-center"
                >
                  <b-nav-item
                      to="/"
                      exact
                      exact-active-class="active"
                      class="nav-border"
                      v-if="
                          !this.$store.state.tokenData.permissions
                              .isTarotReader
                      "
                      >Inicio</b-nav-item
                  >
                  <b-nav-item 
                  to="/catalogo" 
                  active-class="active" 
                  class="nav-border"
                  v-if="
                  !this.$store.state.tokenData.permissions.isTarotReader
                  ">Tarotistas</b-nav-item>
                  
                  <b-nav-item
                      to="/servicios"
                      active-class="active"
                      class="nav-border"
                      v-if="
                          !this.$store.state.tokenData.permissions
                              .isTarotReader
                      "
                      >Servicios</b-nav-item
                  >
                  <b-nav-item
                      to="/contactanos"
                      active-class="active"
                      class="nav-border"
                      vi
                      >Contáctanos</b-nav-item
                  >
                  <!-- <b-nav-item
                      href="https://www.celcom.cl/blog/"
                      target="_blank"
                      class="nav-border"
                      v-if="
                          !this.$store.state.tokenData.permissions
                              .isTarotReader
                      "
                      >Blog</b-nav-item
                  > -->

                  <b-nav-item :to="dashboardLink" class="nav-border">
                    <span class="dashboard">
                      {{ dashboardText }}
                    </span>
                  </b-nav-item>
                  <b-nav-item
                      to="/planes"
                      active-class="active"
                      v-if="
                          !this.$store.state.tokenData.permissions
                              .isTarotReader
                      "
                      >Bolsas de Saldo y Ahorro</b-nav-item
                  >
              </b-navbar-nav>
          </b-collapse>
        </b-navbar>
      </div>
    </div>
  </div>
</template>

<script>
import DropdownUser from './DropdownUser';
import DropdownNoti from './DropdownNoti';
import SerchMod from './Search.vue';

export default {
  props: {
    msg: String,
  },
  data: () => {
    return {
      modalTriggered: null,
      serarching: false,
      windowWidth: window.innerWidth,
    };
  },
  components: {
    DropdownUser,
    DropdownNoti,
    SerchMod,
  },
  computed: {
    dashboardLink() {
      if (this.$store.state.tokenData.permissions.isTarotReader)
        return '/dashboard-tarotista';
      if (this.$store.state.tokenData.permissions.isAdmin)
        return '/dashboard-admin';
      return '/dashboard-user';
    },
    dashboardText() {
      if (this.$store.state.tokenData.permissions.isAdmin) return 'Admin';
      return 'MI DT';
    },
    balance: function () {
      return this.$store.state.balance;
    },
  },
  methods: {
    numberWithCommas(x) {
       var a = x.toLocaleString('es-AR');
        if(a.indexOf(',') == -1){
            return x.toLocaleString('es-AR');
        }else{
          return a.substr(0, a.indexOf(','));
        }
    },
    serch() {
      if (this.serarching) {
        window.document
          .getElementById('serchop1')
          .setAttribute('style', 'opacity: 0');
        window.document
          .getElementById('serchop2')
          .setAttribute('style', 'opacity: 0');
        window.document
          .getElementById('outserch')
          .setAttribute('style', 'background-color: rgb(0 0 0 / 0%);');
        setTimeout(() => {
          this.serarching = false;
        }, 500);
      } else {
        this.serarching = true;
        setTimeout(() => {
          window.document
            .getElementById('outserch')
            .setAttribute('style', 'background-color: rgb(0 0 0 / 50%);');
        }, 10);
      }
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
@media (max-width:768px) {
  .header-bg .icons {
    font-size: .875rem;
    padding-top: .25rem;
  }
  .icons .bell,
  .icons .wallet i,
  .icons .search {
    margin: 0;
    margin-right: .5rem;
    height: auto;
  }
  .icons .search {
    padding-top: .5rem;
  }
  .icons .wallet,
  .icons .btn {
    display: flex;
    align-items: center;
    height: auto;
  }
  .icons .fas {
    font-size: 1rem;
  }
}
@media (max-width:768px) {
  .header-bg .nav-item {
    padding: .75rem 0;
    border: 0;
  }
  .header-bg .nav-item .nav-link {
    text-transform: uppercase;
    font-family: maven-medium;
    font-size: .875rem;
  }
}
@media (max-width:768px) {
  .icons {
    position: absolute;
    right: 0;
    top: 100px;
    z-index: 5;
    width: auto;
    margin: 0 !important;
  }
  .social-media {
    position: relative;
  }
  .header .logo img {
    margin: 0;
  }
}
a {
  text-decoration: none;
}
nav {
  padding: 6px 0;
  border-top: 1px solid rgba(255, 255, 255, 0.185);
}
.social-media {
  margin-left: 15px;
}
i {
  margin-right: 15px;
  color: #ffffff;
  font-size: 18px;
}

.logo {
  text-align: center;
}
.logo img {
  max-width: 160px;
}

.icons {
  text-align: right;
}
/deep/ .navbar-toggler-icon {
  filter: invert(1);
}
/*   .navbar-collapse {
    height: 24px;
  } */
.nav-item {
  padding: 0px 25px;
}
.nav-border {
  border-right: 1px solid rgba(255, 255, 255, 0.185);
}
/deep/.nav-link {
  color: #ffffff;
  font-size: 13px;
  padding: 0;
  font-family: maven-regular;
}
/deep/.navbar-light .navbar-nav .nav-link {
  color: #ffffff;
}
/deep/.navbar-light .navbar-nav .nav-link:hover {
  color: #ececec;
}
.nav-link:hover {
  color: #ececec;
}
.bell {
  padding: 0px;
  float: right;
  margin-right: 15px;
  height: 30px;
}

.bell {
  padding: 0px;
  float: right;
  margin-right: 15px;
  height: 30px;
}

.user {
  float: right;
  margin-right: 15px;
  color: #ffffff;
  font-family: maven-semibold;
}

.wallet {
  float: right;
  color: #ffffff;
  font-family: maven-semibold;
  padding-top: 4px;
  height: 30px;
}
.search {
  color: #ffc209;
  cursor: pointer;
  margin-top: 8px;
  font-size: 15px;
}

.btn-search {
  background: transparent;
  border: none;
  padding: 0px;
  margin: auto;
}

.btn-search:hover {
  background: #5016826c;
  border: none;
  padding: 0px;
}

/deep/.router-link-active {
  color: #04d9b2 !important;
}
/deep/.router-link-exact-active {
  color: #04d9b2 !important;
}

/deep/.dashboard {
  background: #7d26c7;
  border-radius: 8px;
  padding: 0px 5px;
  box-shadow: 0px 0px 4px 0px #6f00decc;
}
/*   /deep/.dashboard .nav-link {
  }
 */
#outserch {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 50;
  transition: 0.5s;
}

.col-search {
  max-width: 20px;
  padding: 0px;
  margin-right: 15px;
}
.col-wallet {
  /* max-width: 90px; */
  padding: 0px;
}
.col-user {
  max-width: 20px;
  padding: 0px;
}
.col-bell {
  max-width: 20px;
}

@media screen and (max-width: 765px) {
  /*   .logo {
    text-align: left;
  } */
  .logo img {
    max-width: 150px;
    margin-bottom: 20px;
  }
}
</style>
