<template>
  <div class="container my-5">
    <div class="row">
      <div class="col-12 mb-3">
        <div class="text-back" @click="goBack">
          <i class="fas fa-chevron-left"></i>
          <span>Volver</span>
        </div>
      </div>
      <div class="col-12">
        <div class="row">
          <div class="col-sm-6 col-12">
            <h1 class="general-title">Especialidades</h1>
          </div>
          <div class="col-sm-6 col-12">
            <button class="btn btn-yellow" v-b-modal.modalEspeciliades>
              Crear nueva
            </button>
            <ModalEspecilidades />
          </div>
        </div>
      </div>

      <div
        class="col-md-6 col-12 my-3"
        v-for="specialty in specialties"
        :key="specialty.specialtyId"
      >
        <div class="card shadow">
          <div class="card-body">
            <h5 class="title-ambito">{{ specialty.specialtyName }}</h5>

            <div class="mt-2 p-0" style="width: 90px; height: 70px">
              <div class="card-img shadow-sm" style="">
                <img
                  :src="specialty.specialtyImage"
                  v-if="specialty.specialtyImage !== null"
                />
                <i class="fas fa-camera" v-else></i>
              </div>
            </div>
            <div class="container-btn">
              <button
                class="btn btn-editar"
                @click="
                  triggerModal(`modalEditSpecialty-${specialty.specialtyId}`)
                "
              >
                Editar
              </button>
              <button
                class="btn btn-eliminar"
                @click="deleteSpecialty(specialty.specialtyId)"
              >
                Eliminar
              </button>
            </div>
          </div>
          <ModalEditSpecialty
            :triggerModal="
              modalTriggered === `modalEditSpecialty-${specialty.specialtyId}`
            "
            :specialty="specialty"
            v-on:editSpecialty="editSpecialty"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axiosClient from "../../../config/axiosClient";
import ModalEspecilidades from "./modal/ModalEspecialidades.vue";
import ModalEditSpecialty from "./modal/ModalEditSpecialty.vue";
import { enableLogs } from '../../../config';

export default {
  name: "DashAdminEspecialidades",
  components: {
    ModalEspecilidades,
    ModalEditSpecialty,
  },
  data: () => {
    return {
      specialties: [],
      isLoading: false,
      modalTriggered: null,
      forceClose: null,
    };
  },
  created() {
    this.getSpecialties();
  },
  // watch: {
  //   specialties: function() {
  //     this.getSpecialties();
  //   },
  // },
  methods: {
    async getSpecialties() {
      this.isLoading = true;
      try {
        const response = await axiosClient.get(
          "/specialties/get-all-specialties"
        );
        this.specialties = response.data.specialties;
        this.isLoading = false;
      } catch (error) {
       if(enableLogs) console.log(error);
        this.isLoading = false;
      }
    },
    goBack() {
      this.$router.go(-1);
    },
    methods: {
      async getSpecialties() {
        this.isLoading = true;
        try {
          const response = await axiosClient.get(
            '/specialties/get-all-specialties'
          );
          this.specialties = response.data.specialties;
          this.isLoading = false;
        } catch (error) {
         if(enableLogs) console.log(error);
          this.isLoading = false;
        }
      },
      goBack() {
        this.$router.go(-1);
      },
      async triggerModal(modal) {
       if(enableLogs) console.log(`Show Modal: ${modal}`);

      this.modalTriggered = modal;

        await this.$nextTick();
        this.modalTriggered = null;
        this.forceClose = null;
      },
      async deleteSpecialty(specialtyId) {
        if (!this.$store.state.jwt) {
          return;
        }
        try {
          const response = await axiosClient.post(
            '/specialties/delete-specialty',
            {
              specialtyId,
            },
            { headers: { Authorization: `Bearer ${this.$store.state.jwt}` } }
          );
         if(enableLogs) console.log('successfully deleted specialty');
         if(enableLogs) console.log(response.data);
          this.specialties = this.specialties.filter((specialty) => {
            return specialty.specialtyId !== specialtyId;
          });
        } catch (error) {
         if(enableLogs) console.log(error);
         if(enableLogs) console.log('could not delete specialty');
        }
      },
      addSpecialty(specialty) {
        this.specialties.push(specialty);
      },
      editSpecialty(specialty) {
        for (let i = 0; i < this.specialties.length; i++) {
          if (this.specialties[i].specialtyId == specialty.specialtyId) {
            this.specialties[i].specialtyName = specialty.specialtyName;
            this.specialties[i].specialtyImage = specialty.specialtyImage;
            break;
          }
        }
      },
    },
  },
};
</script>

<style scoped>
.text-back {
  color: #501682;
  text-transform: uppercase;
  font-family: maven-semibold;
  font-size: 13px;
  cursor: pointer;
}
.text-back:hover {
  color: #934dd8;
}
.text-back span {
  margin-left: 6px;
}
.title {
  color: #501682;
  text-transform: uppercase;
  font-family: source-bold;
  font-size: 19px;
  float: left;
  margin-top: 9px;
}
.btn-yellow {
  font-family: maven-semibold;
  background: #ffc209;
  color: #501682;
  font-size: 11px;
  text-transform: uppercase;
  padding: 10px 18px;
  float: right;
}
.btn-yellow:hover {
  background: #dba501;
  color: #650bbf;
}

.card {
  border-radius: 10px;
  min-height: 100%;
}
.card-body {
  padding: 20px 20px;
  font-family: maven-regular;
}

.title-ambito {
  font-size: 21px;
  font-family: maven-bold;
}

.title-descripcion {
  font-size: 11px;
}

.container-btn {
  text-align: end;
}
.btn-eliminar {
  font-size: 12px;
  background-color: #979797;
  color: #333333;
  font-family: maven-semibold;
  font-size: 10px;
  text-transform: uppercase;
  padding: 8px 10px;
}
.btn-eliminar:hover {
  background-color: #858585;
  color: #333333;
}
.btn-editar {
  font-family: maven-semibold;
  background: #ffc209;
  color: #501682;
  font-size: 10px;
  text-transform: uppercase;
  padding: 8px 10px;
  margin-right: 8px;
}
.btn-editar:hover {
  background: #dba501;
  color: #650bbf;
}

.card-img {
  width: 90px;
  height: 70px;
  border: 3px solid #fff;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  margin: auto;
}
.card-img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 8px;
}
.card-img i {
  font-size: 35px;
  margin-top: 10px;
  margin-left: 25px;
  color: #707070;
}
.btn-icon {
  background-color: #f3f6f9;
  border-color: #f3f6f9;
  color: #3f4254;
  border-radius: 50%;
  height: 24px !important;
  width: 24px;
  align-items: center;
  display: -webkit-inline-box;
  display: inline-flex;
  align-items: center;
  -ms-flex-pack: center;
  justify-content: center;
  padding: 0;
  position: absolute;
  right: -10px;
  top: -10px;
  cursor: pointer;
}
.input-file {
  width: 0 !important;
  height: 0 !important;
  overflow: hidden;
  opacity: 0;
}
</style>
