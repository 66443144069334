<template>
  <div>
    <!--agendada-->
    <div class="card card-pagos">
      <div class="conteinder-header">
        <img
          class="card-img-top"
          src="../../../assets/img/header-card.jpg"
          alt="Card image cap"
        />
        <div class="gradient"></div>
        <div v-if="divinoBolsa.bonification > 0">
          <img
            class="gift"
            src="../../../assets/img/divinoBolsa/gift.png"
            alt=""
          />
          <div class="header-text">
            <div class="text-boni">Ahorras</div>
            <div class="boni">{{ divinoBolsa.bonification }}%</div>
          </div>
        </div>
        <div class="header-name">
          <img
            class="star-one"
            src="../../../assets/img/divinoBolsa/star.png"
            alt=""
          />
          <img
            class="bolsa"
            src="../../../assets/img/divinoBolsa/bolsa.png"
            alt=""
          />
          <span>{{ tituloPlan(divinoBolsa.price) }}</span>
        </div>
      </div>
      <div class="card-body px-4">
        <div class="container-price">
          <p class="text-price">OBTIENES:</p>
          <h5 class="price">${{  numberWithCommas(parseFloat(divinoBolsa.price * 100 / (100 - divinoBolsa.bonification))) }}</h5>
        </div>
        <div class="container-gift" v-if="divinoBolsa.bonification > 0">
          <p class="text-gift">Pagas:</p>
          <h5 class="gift">
            ${{
              numberWithCommas(
                  numberWithCommas(divinoBolsa.price)
              )
            }}
          </h5>
          <p class="text-gift">{{ divinoBolsa.description }}</p>
        </div>
        <div class="container-gift" v-if="divinoBolsa.bonification === 0">
          <div class="empty-text"></div>
        </div>

      </div>

    </div>
  </div>
</template>
<script>
export default {
  props: {
    divinoBolsa: Object,
  },
  methods: {
    tituloPlan(price) {
      if (price === 48000) return 'DivinoMaestro'
      if (price === 34000) return 'DivinoExperto'
      if (price === 22000) return 'DivinoOráculo'
      if (price === 9000) return 'DivinoAprendiz'
    },
    numberWithCommas(x) {
      return x.toLocaleString('es-AR');
    },
  },
};
</script>
<style scoped>
/*header*/
.gradient {
  position: absolute;
  height: 190px;
  left: 0px;
  right: 0px;
  bottom: 0;
  background: linear-gradient(#fff0, #06273c);
  border-radius: 33px 33px 0 0;
}
.conteinder-header {
  position: relative;
  min-height: 190px;
}
.conteinder-header .card-img-top {
  filter: brightness(0.8);
}

.card-img-top {
  border-radius: 15px 15px 0 0;
  height: 190px;
  object-fit: cover;
}

.conteinder-header .gift {
  position: absolute;
  right: 15px;
  top: 10px;
  color: #ffc30b;
  max-width: 65px;
}
.conteinder-header .header-text {
  position: absolute;
  right: 15px;
  top: 23px;
  padding: 0px 3.5px;
  height: 40px;
  font-family: maven-bold;
  text-align: center;
  border-radius: 5px;
  width: 65px;
}

.conteinder-header .header-text .text-boni {
  font-size: 15px;
}
.conteinder-header .header-text .boni {
  font-size: 17px;
  margin-bottom: 0px;
  margin-top: -5px;
}
.conteinder-header .header-name {
  position: absolute;
  width: 100%;
  text-align: center;
  bottom: 16px;
}
.conteinder-header .header-name .bolsa {
  width: 38px;
  margin-right: 8px;
}
.conteinder-header .header-name .star-one {
  position: absolute;
  width: 6px;

  right: 65px;
}

.conteinder-header .header-name span {
  font-family: source-semibold;
  color: #fff;
  font-size: 20px;
}
.card-body {
    font-family: maven-regular;
    text-align: center;
}

.container-price {
    font-family: maven-bold;
    color: #707070;
    padding-bottom: 15px;
    color: #ffc30b;
}
.text-price {
    margin-bottom: 0px;
}
.price {
    font-size: 45px;
    margin-bottom: 0;
    color: #ffc30b;
}

.container-gift {
    border-top: 1px solid #70707038;
    padding-top: 15px;
    font-family: maven-bold;
    color: #501682;
}
.text-gift {
    margin-bottom: 0px;
}
.gift {
    font-size: 30px;
}
.empty-text{
    height: 60px;
    width: 100%;
}
.container-button {
    text-align: center;
    margin-top: 25px;
}

.button-purple {
    background: #501682;
    text-transform: uppercase;
    padding: 10px 50px;
    font-size: 12px;
    color: #ffc30b;
    font-family: maven-bold;
    border-radius: 25px;
}

</style>
