<template>
    <div>
        <div class="card shadow">
          <div class="card-body">
            <div class="row">
              <div class="col">
                <FullCalendar
                  :options="calendarOptions"
                  :header="{
                    left: 'title',
                    center: 'dayGridMonth',
                    right: 'prev today next',
                  }"
                />
              </div>
              <div class="col horarios">
                <p>Horarios disponibles</p>
                <div>
                  <button class="btn btn-sm btn-dark">11:00</button>
                </div>
                <div>
                  <button class="btn btn-sm btn-dark">12:15</button>
                </div>
                <div>
                  <button class="btn btn-sm btn-dark">12:30</button>
                </div>
                <div>
                  <button class="btn btn-sm btn-dark">12:45</button>
                </div>
                <div>
                  <button class="btn btn-sm btn-dark">13:00</button>
                </div>
                <div>
                  <button class="btn btn-sm btn-dark">13:15</button>
                </div>
                <div>
                  <button class="btn btn-sm btn-dark">15:00</button>
                </div>
                <div>
                  <button class="btn btn-sm btn-dark">17:00</button>
                </div>
              </div>
            </div>
          </div>
        </div>
    </div>
</template>

<script>
import FullCalendar from "@fullcalendar/vue";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import timeGridPlugin from '@fullcalendar/timegrid';
import esLocale from '@fullcalendar/core/locales/es';

export default {
  components: {
    FullCalendar, // make the <FullCalendar> tag available
  },
  data() {
    return {
      calendarOptions: {
        plugins: [dayGridPlugin, interactionPlugin, timeGridPlugin],
        initialView: "dayGridMonth",
        headerToolbar: {
          left: "prev,next",
          center: "title",
          right: "dayGridMonth,timeGridWeek,timeGridDay",
        },
        locale: esLocale,
        selectable: true,
        views: {
          dayGridMonth: {
            titleFormat: { month: "long", year: "numeric" },
          },
          timeGridWeek: {
            titleFormat: { month: "long", year: "numeric" },
            weekNumbers: true,
          },
          timeGridDay: {
            titleFormat: { day: "numeric", month: "long", year: "numeric" },
          },
        },
      },
    };
  },
  methods: {
    toggleWeekends: function () {
      this.calendarOptions.weekends = !this.calendarOptions.weekends; // toggle the boolean!
    },
  },
};
</script>

<style scoped>

.horarios {
  max-width: 140px;
  text-align: center;
}
.horarios p {
  font-family: maven-bold;
  font-size: 11px;
}

.horarios button {
  margin: 10px auto;
  width: 70px;
  font-size: 11px;
  border: 1px solid #501682;
  color: #501682;
  background: transparent;
  font-family: maven-bold;
}
.horarios button:hover, .horarios button:active, .horarios button:focus {
  color: #ffffff;
  background: #501682;
}

</style>