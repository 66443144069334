import axiosClient from "../../config/axiosClient";

let finishPayment = async function(kushkiToken, price, idBolsa) {
  let info = JSON.parse(window.localStorage.getItem('info')) 
  let {email, nombre, apellido, telefono, direccion, ciudad, region, pais} = info
  let requestBody = {
    kushkiFrontendToken: kushkiToken,
    kushkiAmount: {
      subtotalIva: 0,
      subtotalIva0: price,
      ice: 0,
      iva: 0,
      currency: "CLP",
    },

    kushkiContactDetails: {
/*       documentType: tipo_doc,
      documentNumber: num_doc, */
      email: email,
      firstName: nombre ? nombre : info.firstName,
      lastName: apellido ? apellido : info.lastName,
      phoneNumber: telefono ? telefono : info.phoneNumber,
    },
    kushkiOrderDetails: {
      siteDomain: "www.divinotarot.com",
      billingDetails: {
        name: (nombre ? nombre : info.firstName) + " " + (apellido ? apellido : info.lastName),
        phone: telefono ? telefono : info.phoneNumber,
        address: direccion ? direccion : (info.adress ? info.adress : info.address),
        city: ciudad ? ciudad : info.city,
        region: region,
        country: pais ? pais : info.country,
        /* zipCode: "5000", */
      },
    },
    idBolsa
  };

  let response = await axiosClient.post(
    "/payment/kushki-divinobolsa-credit",
    requestBody,
    {
      headers: { Authorization: `Bearer ${this.jwt}` },
    }
  );
  return response.data;
};

export { finishPayment };
