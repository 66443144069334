<template>
  <div class="container container-violeta">
    <div class="row justify-content-between">
      <div class="col-lg-7 col-12">
        <div class="row">
          <div class="col-12 about-me" v-if="tarotista.profReview">
            <h5 class="title general-title">Sobre mí</h5>
            <p>
              {{ tarotista.profReview }}
            </p>
          </div>
          <Video :tarotista="tarotista" v-if="tarotista.link" />
          <AmbitosTarotistas :tarotista="tarotista" v-if="tarotista.scopes"/>
          <EspecialidadesTarotistas :tarotista="tarotista" v-if="tarotista.specialties" />
          <Resena :tarotista="tarotista" />
        </div>
      </div>
      <div class="col-lg-4 d-lg-block d-none">
        <div class="col">
          <div class="card card-tarotista">
            <div class="card-header">
              <div class="dstco" v-if="tarotista.descuento">
                <span>Dstco</span>
                <p>-{{ tarotista.descuento }}%</p>
              </div>
            </div>
            <div class="card-body">
              <div class="row">
                <div class="col-12 col-img">
                  <div class="image">
                    <img :src="tarotista.avatar" alt="" />
                    <i
                      v-if="(statust ? statust.status == 'Disponible': false) || tarotista.type === 'agendada'"
                      class="fas fa-circle tarotista-disponible"
                    ></i>
                    <i v-if="(!(statust ? statust.status == 'Ocupado': false) && !(statust ? statust.status == 'Disponible': false)) && tarotista.type !== 'agendada'" class="fas fa-circle tarotista-no-disponible"></i>
                    <i
                      :key="tarotista.userId + '/' + seed"
                      v-if="(statust ? statust.status == 'Ocupado': false) && tarotista.type !== 'agendada'"
                      class="fas fa-circle tarotista-occupied"
                    ></i>
                  </div>
                  <p
                    v-if="statust ? statust.status == 'Disponible': false"
                    class="tarotista-estado tarotista-disponible"
                  >
                    {{tarotista.type === 'agendada' ? 'Disponible' : 'Disponible'}}
                  </p>
                  <p
                    v-if="!(statust ? statust.status == 'Ocupado': false) && !(statust ? statust.status == 'Disponible': false)"
                    class="tarotista-estado tarotista-no-disponible"
                  >
                    {{tarotista.type === 'agendada' ? 'Disponible' : 'No Disponible'}}
                  </p>
                  <p
                    :key="tarotista.userId + '/' + seed"
                    v-if="statust ? statust.status == 'Ocupado': false"
                    class="tarotista-estado tarotista-occupied"
                  >
                    {{tarotista.type === 'agendada' ? 'Disponible' : 'Ocupado'}}
                  </p>
                </div>
              </div>
              <div class="row">
                <div class="col-12 text-center">
                  <div class="text-name mt-5">
                    <h5>
                      {{ tarotista.alias }}
                    </h5>
                    
                    <p v-if="tarotista.specialties" class="subtitle">{{
                    tarotista.specialties[0].specialtyName
                  }}</p>
                  <p v-else class="subtitle" style="opacity: 0">lorem</p>
                    
                  </div>
                </div>
                <div class="col-12">
                  <div class="mt-4 first-container">
                    <div class="col-puntaje">
                      <div class="score text-left">
                        <!-- <div class="star">
                          <i
                            v-for="i in tarotista.calification"
                            :key="i"
                            class="fas fa-star"
                          ></i>
                          <i
                            v-for="i in 5 - tarotista.calification"
                            :key="i * 77"
                            class="fas fa-star grey-star"
                          ></i>
                          <span class="puntaje"
                            >{{ tarotista.calification }}.0</span
                          >
                          <span class="personas">(154)</span>
                        </div> -->
                        <Star :value="tarotista.valoracion_promedio" :shownumer="true" :size="1" :showperson="true" :person="tarotista.cantidad_valoracion"/>

                      </div>
                    </div>
                    <div class="lecturas">
                      <i class="far fa-eye"></i>
                      <span class="num-lectura"
                        >{{ tarotista.amountOfReadings }}
                      </span>
                      <span> Lecturas</span>
                    </div>
                  </div>
                  <div class="tipo">
                    <div class="tipo-lectura" v-if="tarotista.type === 'agendada'">
                      <p><i class="fas fa-calendar-alt"></i> Lectura Agendada</p>
                    </div>
                    <div class="tipo-lectura" v-else>
                      <p><i class="fas fa-bolt"></i> Lectura Inmediata</p>
                    </div>
                  </div>
                </div>
              </div>
              <div class="text-center mt-4">
                <p class="titulo-btn">Comprar</p>
                <div v-if="tarotista.chatPrice !== null">
                  <button
                    :disabled="disableButtonFuntion(tarotista, 'chat')"
                    type="button"
                    class="btn btn-green btn-block"
                    @click="
                      goToPay(
                        tarotista.tarotReaderId,
                        tarotista.type,
                        'Chat'
                      )
                    "
                  >
                    <i class="fas fa-comments icon-btn"></i>
                    <span>Chat $ {{ numberWithCommas(tarotista.chatPrice) }}/min</span>
                  </button>
                  <!-- <label class="descuento" v-if="tarotista.descuento"
                    >$400/min</label
                  > -->
                </div>
                <div v-if="tarotista.voicePrice !== null">
                  <button
                    :disabled="disableButtonFuntion(tarotista, 'voice')"
                    type="button"
                    class="btn btn-green btn-block"
                    @click="
                      goToPay(
                        tarotista.tarotReaderId,
                        tarotista.type,
                        'Voz'
                      )
                    "
                  >
                    <i class="fas fa-phone-alt icon-btn"></i>
                    <span>Audio ${{ numberWithCommas(tarotista.voicePrice) }}/min</span>
                  </button>
                  <!-- <label class="descuento" v-if="tarotista.descuento"
                    >$400/min</label
                  > -->
                </div>
                <div v-if="tarotista.videoPrice !== null">
                  <button
                    :disabled="disableButtonFuntion(tarotista ,'video')"
                    type="button"
                    class="btn btn-green btn-block"
                    @click="
                      goToPay(
                        tarotista.tarotReaderId,
                        tarotista.type,
                        'Video'
                      )
                    "
                  >
                    <i class="fas fa-video icon-btn"></i>
                    <span>Video ${{ numberWithCommas(tarotista.videoPrice) }}/min</span>
                  </button>
                  <!-- <label class="descuento" v-if="tarotista.descuento"
                    >$400/min</label
                  > -->
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { enableLogs } from '../../config';
import Video from './Video.vue';
import AmbitosTarotistas from './AmbitosTarotistas.vue';
import EspecialidadesTarotistas from './EspecialidadesTarotista.vue';
import Resena from './Resena';
import Star from '../util/Stars.vue'
export default {
  components: {
    Video,
    AmbitosTarotistas,
    EspecialidadesTarotistas,
    Resena,
    Star
  },
  props: {
    tarotista: Object,
    status: Object,
    scopes: Array,
    languages: Array,
    specialties: Array,
  },
  data() {
    return {
      statust: {}
    };
  },
  mounted() {
    this.statust = this.$store.state.statust;
    if (enableLogs){
      if(enableLogs) console.log({
        tarotista: this.tarotista,
        scopes: this.scopes,
        languages: this.languages,
        specialties: this.specialties,
      });
    }
    /*this.$store.state.socket.on('statusTarotReaderChange', ()=>{
      console(this.status.status)
      console.log(this.$store.state.statust['t'+this.tarotista.idUser].status)
      this.status.status = this.$store.state.statust['t'+this.tarotista.idUser].status
    })*/
    this.statust = this.status;
  },
  methods: {
    disableButtonFuntion(tarotista, tipo){
      if((tarotista.type === 'agendada') && (this.statust['t'+tarotista.userId] ? this.statust['t'+tarotista.userId].chanels[tipo] : false)){
        return false;
      }else if (tarotista.type === 'agendada' && !this.statust['t'+tarotista.userId]) {
        return false;
      }else if ((this.statust['t'+tarotista.userId] ? (this.statust['t'+tarotista.userId].status === 'Disponible') : false) &&
          (this.statust['t'+tarotista.userId] ? this.statust['t'+tarotista.userId].chanels[tipo] : false)) {
        return false;
      }else {
        return true;
      }
    },
    numberWithCommas(x) {
      return x.toLocaleString('es-AR')
    },

    goToPay(tarotReaderId, type, channel) {

    if(enableLogs)  console.log('TAROTISTA:::', this.tarotista)

      this.$router.push(
        `/compra?tarotista=${tarotReaderId}&tipo=${type}&canal=${channel}`
      );
    },
  }
};
</script>

<style scoped>
.about-me,
.video {
  border-bottom: 1px solid #98989838;
  margin: 0px 12px 35px 12px;
  padding-bottom: 35px;
}
/* .video {
  padding: 35px 0;
} */
.about-me p {
  font-family: maven-regular !important;
  color: #ffffff !important;
}
.about-me .title {
  color: #ffffff;
  margin-bottom: 35px;
}
/*CARD*/
.card {
  border-radius: 33px;
  min-height: 100%;
  max-width: 300px;
  margin: auto;
  border: none;
  background: #501682;
  font-family: maven-regular;
  border: 1px solid #a57fc6;
}
.card-header {
  background: #04d9b2;
  border-radius: 33px 33px 0px 0px;
  border-bottom: none;
  height: 110px;
}
.card-body {
  padding: 30px 20px;
  position: relative;
  padding-top: 70px;
}

.col-img i {
  position: absolute;
  right: 10px;
  top: 8px;
  border: 2px solid #fff;
  border-radius: 50%;
  font-size: 18px;
}
.tarotista-disponible {
  color: #04D925;
}

.tarotista-no-disponible {
  color: grey;
}
.tarotista-occupied {
  color: #ffc30b;
}

.col-img {
  max-width: 130px;
  padding: 0;
  position: absolute;
  top: -65px;
  left: calc(50% - 65px);
  text-align: center;
}

.image {
  width: 130px;
  height: 130px;
}
.image img {
  width: 100%;
  border-radius: 50%;
  height: 100%;
  object-fit: cover;
  background: #fff;
}
.dstco {
  border-radius: 0 33px;
  position: absolute;
  background: #f6f6f6;
  padding: 8px 10px 8px;
  top: 3px;
  right: 4px;
  font-size: 10px;
  color: #501682;
  font-family: maven-bold;
  text-align: center;
}
.dstco p {
  font-size: 15px;
  margin-bottom: 0;
}
.text-name {
  color: #ffffff;
  font-family: maven-bold;
}
.text-name h5 {
  font-weight: bold;
  font-size: 24px;
  margin-bottom: 0px;
}
.text-name p {
  font-size: 16px;
  margin-bottom: 0;
  margin-top: 3px;
  font-family: maven-regular;
}
.text span {
  font-size: 11px;
}
.text span i {
  font-size: 12px;
  color: #04d9b2;
  margin-left: 8px;
}
.first-container {
  border-bottom: 1px solid #e0e0e0;
  margin-right: 36px;
  margin-left: 36px;
  padding-bottom: 6px;
}

.col-puntaje {
  margin-top: 3px;
  color: #979797;
}

.puntaje {
  font-family: maven-bold;
  margin-top: 1px;
  font-size: 14px;
  margin-left: 5px;
}
.personas {
  font-size: 14px;
  margin-left: 5px;
}
.star {
  font-size: 12px;
  margin-right: 6px;
  margin-top: 2px;
}
.star i {
  color: #ffc30b;
}
.grey-star {
  color: #979797 !important;
}
.lecturas {
  margin-top: 1px;
  font-size: 14px;
  color: #979797;
  text-align: left;
  font-family: maven-regular;
}

.lecturas i {
  margin-right: 5px;
}
.lecturas .num-lectura {
  font-family: maven-bold;
}

.tipo {
  margin-top: 1px;
  font-size: 14px;
  color: #ffffff;
  text-align: left;
  font-family: maven-bold;
  margin-left: 36px;
  margin-right: 36px;
  padding-top: 6px;
}
.tipo i {
  margin-right: 5px;
}

.btn-green {
  background: #04d9b2;
  color: #ffffff;
  margin-bottom: 10px;
  font-family: maven-semibold;
  font-size: 10px;
  text-align: center;
  border-radius: 15px;
  width: 125px;
}
.btn-green:hover {
  background: #00bf9c;

  color: #ffffff;
}
.btn-green:disabled {
  background-color: #e8e8e8;
  color: #dddddd;
}
.icon-btn {
  float: left;
  margin: auto;
  margin-top: 3px;
}
.descuento {
  color: #979797;
  font-family: maven-regular;
  margin-left: 10px;
  font-size: 11px;
  vertical-align: middle;
  margin-bottom: 10px;
  text-decoration: line-through;
}

/* Nuevos cambios */

.tarotista-estado {
  text-align: center;
  text-decoration: none;
  font-family: 'maven-bold';
  margin: 10px 0 0;
}
.titulo-btn {
  font-family: 'maven-bold';
  color: #ffffff;
}
@media (max-width:768px) {
  .bg-lila .about-me{
    margin: 0;
  }
}
</style>
