<template>
  <div>
    <div class="card card-pagos">
      <div class="card-body">
        <form>
          <div class="row">
            <div class="col">
              <div >
                <h5 class="title-card-divino-saldo margin-title">Divino Saldo actual: <span>${{ numberWithCommas(divinoSaldo) }}</span></h5>
                
              </div>
              <div>

                <p class="text-medio-pago">Estas por abonar con tu<span>Divino Saldo</span></p>
                <p class="text-medio-pago" v-if="divinoSaldo < 5000 && minutos == 0">Para iniciar llamada libre necesitas <span>$5.000</span></p>
                <p class="text-medio-pago" v-if="!allowForm && minutos != 0"><span>Saldo insuficiente</span></p>
              </div>
            </div>
            <div class="text-center">
              <b-button
                v-if="(allowForm && divinoSaldo >= 0 && minutos != 0) || (minutos == 0 && divinoSaldo >= 5000)"
                @click="askForConfirmPayment()"
                v-show="(existe != false || tipo != 'agendada')"
                class="btn btn-pagar"
                >Ir a pagar</b-button
              >

              <router-link class="btn btn-delete-card mt-4" v-if="!allowForm || (minutos == 0 && divinoSaldo < 5000)" to="/planes">Comprar DivinoBolsas</router-link>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
  import axiosClient from '../../config/axiosClient';
  export default {
    data() {
      return {
        precioSala: 0,
        //canal: null,
      };
    },
    props: {
      tarotista: Object,
      minutos: Number,
      canal: String,
      existe: Boolean,
      tipo: String,
    },
    mounted() {
    },
    created() {
      this.canal = this.$route.query.canal;
      this.getRoomPrice();
    },
    methods: {
      askForConfirmPayment() {
        if (this.tipo == 'agendada') {
          this.$emit('registroMod');
        }
        this.$emit('askForConfirmPayment', {
          type: 'balance',
        });
      },
      async getRoomPrice() {
        let channel;
        switch (this.canal) {
          case 'Chat':
            channel = 'CHAT';
            break;
          case 'Voz':
            channel = 'VOICE';
            break;
          case 'Video':
            channel = 'VIDEO';
            break;
        }
        let response = await axiosClient.post('/rooms/get-room-price', {
          tarotReaderId: this.tarotista.tarotReaderId,
          duration: 1,
          roomType: channel,
        });
        this.precioSala = response.data.roomPrice;
      },
      numberWithCommas(x) {
        var a = x.toLocaleString('es-AR');
        if(a.indexOf(',') == -1){
            return x.toLocaleString('es-AR');
        }else{
          return a.substr(0, a.indexOf(','));
        }
      },
    },
    computed: {
      allowForm: function() {
        return (
          this.divinoSaldo > 0 &&
          this.divinoSaldo >= this.precioSala &&
          this.divinoSaldo - this.finalPrice >= 0
        );
      },
      divinoSaldo: function() {
        return this.$store.state.balance;
      },
      finalPrice: function() {
        return this.precioSala * this.minutos;
      },
    },
  };
</script>

<style scoped>
.card-body {
  position: relative;
}
.btn-pagar {
  position: absolute;
  bottom: 20px;
  right: calc(50% - 63px);
}
.margin-title{
  margin: 25px 10px !important;
}
.margin-title span{
  color: #501682;
}
/deep/.close {
  background: transparent;
  border: none;
}
/deep/.modal-header {
  border-bottom: none;
}

  /deep/.modal-body {
    padding: 0 50px;
  }
  .text-modal {
    color: #501682;
    font-family: maven-bold;
    text-align: center;
    text-transform: uppercase;
    font-size: 20px;
  }
  .text-saldo {
    text-align: center;
    font-family: maven-regular;
    font-size: 20px;
  }
  .text-saldo span {
    font-family: maven-bold;
    color: #3b5998;
  }
  /deep/.modal-footer {
    border-top: none;
    padding: 10px 50px 30px 50px;
  }
  /deep/.btn-modal {
    background-color: #04d9b2;
    color: #fff;
    font-family: maven-bold;
    text-transform: uppercase;
    border: none;
  }
.btn-pagar {
  /* Estilos normales del botón */
  background-color: #007bff;
  color: #ffffff;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  transform-origin: center; /* Establece el punto de origen de la transformación en el centro */
  animation: growAndHighlight 1s infinite alternate; /* Aplica la animación llamada "growAndHighlight" */
}

@keyframes growAndHighlight {
  0% {
    transform: scale(1); /* Tamaño normal */
  }
  100% {
    transform: scale(1.2); /* Tamaño al 120% */
  }
}
</style>
