<template>
  <div class="container my-5">
    <div class="row">
      <div class="col-12 mb-3">
        <h5 class="general-title">Dashboard Administrador</h5>
      </div>
      <div class="col-12" v-if="checkAccess('Inscripciones')">
        <Tarotistas :inscriptions="inscriptions" :isLoading="isLoading" />
      </div>
      <div class="col-12 mt-3">
        <Secciones :permissionsAvailable="permissionsAvailable" />
      </div>
    </div>
  </div>
</template>

<script>
import Tarotistas from './Tarotistas.vue'
import Secciones from './Secciones.vue'
import axiosClient from '../../config/axiosClient'
import { enableLogs } from '../../config';
export default {
  name: 'DashboardAdmin',
  components: {
    Tarotistas,
    Secciones,
  },
  data() {
    return {
      inscriptions: [],
      isLoading: true,
      permissionsAvailable: [],
    };
  },
  created() {
    this.getMyPermissions();
    this.getAllInscriptions();
  },
  methods: {
    async getAllInscriptions() {
      try {
        const response = await axiosClient.get(
          '/inscriptions/get-all-inscriptions',
          {
            headers: {
              Authorization: `Bearer ${this.$store.state.jwt}`,
            },
          }
        );
        this.inscriptions = response.data.tarotReaderInfo;
        if (enableLogs) console.log(response.data.tarotReaderInfo);
        this.isLoading = false;
      } catch (error) {
        if (enableLogs) console.log('could not get inscription requests');
        if (enableLogs) console.log(error);
        this.isLoading = false;
      }
    },
    async getMyPermissions() {
      try {
        const response = await axiosClient.get('users/fetch-my-permissions', {
          headers: { Authorization: `Bearer ${this.$store.state.jwt}` },
        });
        this.permissionsAvailable = response.data.permissionsAvailable;

        if(enableLogs) console.log('successfully fetched permissions for this admin user');
      } catch (error) {
        if(enableLogs) console.log(error);
        if(enableLogs) console.log('could not fetch permissions for logged admin user');
      }
    },
    checkAccess(permissionName) {
      return this.permissionsAvailable.some(
        perm => perm.permissionName === permissionName
      );
    },
  },
};
</script>

<style scoped></style>
