<template>
    <div>
        <div class="container">
            <div class="row">
                <div class="col-12">
                    <p class="submenu">
                        Inicio - <span>Bolsas de Saldo y Ahorro</span>
                    </p>
                </div>
            </div>
        </div>
        <!-- <Suscripcion/> -->
        <DivinoBolsas />
        <!--  <Regalo/>
      <Referidos/> -->
    </div>
</template>
<script>
/* import Suscripcion from "./Suscripcion"; */
import DivinoBolsas from './DivinoBolsa'
/*import Regalo from "./Regalo";
 import Referidos from "./Referidos"; */

export default {
    name: 'Servicios',
    components: {
        /* Suscripcion, */
        DivinoBolsas,
        /* Regalo,
    Referidos */
    },
}
</script>

<style scoped>
/*toggle*/
.toggle {
    text-align: center;
}
.switch {
    position: relative;
    display: inline-block;
    width: 55px;
    height: 25px;
}

.switch input {
    opacity: 0;
    width: 0;
    height: 0;
}

.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #fff;
    -webkit-transition: 0.4s;
    transition: 0.4s;
}

.slider:before {
    position: absolute;
    content: '';
    height: 18px;
    width: 18px;
    left: 4px;
    bottom: 3px;
    background-color: #ffc209;
    border: 1px solid #ffc209;
    -webkit-transition: 0.4s;
    transition: 0.4s;
}

input:checked + .slider {
    background-color: #fff;
    border: 1px solid #ffc209;
}

input:focus + .slider {
    box-shadow: 0 0 3px #ffc209;
}

input:checked + .slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
}
/* Rounded sliders */
.slider.round {
    border-radius: 34px;
}

.slider.round:before {
    border-radius: 50%;
}

.title {
    color: #501682;
    text-align: center;
    margin-bottom: 20px;
    font-weight: bold;
}
.subtitle {
    text-align: center;
    margin-bottom: 20px;
    font-family: maven-regular;
}
.month,
.year {
    margin: 0 15px;
    color: #501682;
}

.card-img-overlay {
    color: #fff;
    font-family: maven-regular;
}

.card-img-overlay .card-title {
    font-size: 30px;
    margin-bottom: 50px;
    padding-top: 10px;
    font-weight: bold;
}
.card-img-overlay .price {
    font-weight: bold;
    font-size: 35px;
}
.card-img-overlay .price span {
    font-size: 20px;
}
.card-body {
    font-family: maven-regular;
}
.btn-comprar {
    background-color: #ffc209;
    color: #501682;
    font-weight: bold;
}


</style>