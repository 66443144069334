<template>
    <div id="app">
        <router-view></router-view>
        <ModalConfirmacion
            :personName="modalProps.personName"
            :channel="modalProps.channel"
            :roomId="modalProps.roomId"
            :triggerModal="modalTriggered === 'modalLlamada'"
        />
        <ModalAvisoLlamada
            :triggerModal="modalTriggered === 'modelAvisoCompra'"
            :forceClose="forceClose == 'modelAvisoCompra'"
        />
        <audio src="./assets/audio/llamada.mp3" id="audioLlamada" loop></audio>
        <audio src="./assets/audio/notificacion.mp3" id="audioRing"></audio>
        
        <ModalGenerico v-if="$store.state.message.length > 0" />
        <ModalPuntaje  v-if="$store.state.show && idTarotista != null" :idTarotista="idTarotista" />
        <ModalDatos v-if="$store.state.modalShow"/>
        <ModalDatosNotLogin v-if="$store.state.modalShowNotLogin"/>
    </div>
</template>

<script>
import axiosClient from './config/axiosClient'
import { io } from 'socket.io-client'
import ModalConfirmacion from './components/llamada/modal/ModalConfirmacion.vue'
import ModalAvisoLlamada from './components/llamada/modal/ModalAvisoLlamada.vue'
import ModalGenerico from './components/util/ModalGenerico.vue'
import ModalPuntaje from './components/util/ModalPuntaje.vue'
import ModalDatos from './components/util/ModalDatos.vue'
import ModalDatosNotLogin from './components/util/ModalDatosNotLogin.vue'
import { v4 as uuidv4 } from 'uuid'
import { ssl, backendUrl, enableLogs } from './config'

export default {
    name: 'App',
    data() {
        return { modalTriggered: null, modalProps: {}, idTarotista: null }
    },
    components: {
        ModalConfirmacion,
        ModalAvisoLlamada,
        ModalGenerico,
        ModalPuntaje,
        ModalDatos,
        ModalDatosNotLogin
    },
    watch: {
        loggedIn: async function() {
            await this.disconnectSocket()
            // This watcher looks for a jwt so that it cann connect to the socket server
            if (this.$store.state.isLoggedIn === false) {
                if(enableLogs) console.log('Trying to connect to socket server as guest')

                await this.connectGuestSocket()

                return
            }

            // Connect to the socket server
            if(enableLogs) console.log('Trying to connect to socket server')
            await this.connectSocket()

            // Fetching user balance
            axiosClient
                .get('/users/get-balance', {
                    headers: {
                        Authorization: `Bearer ${this.$store.state.jwt}`,
                    },
                })
                .then(response =>
                    this.$store.commit(
                        'updateUserBalance',
                        response.data.balance
                    )
                )
        },
    },
    computed: {
        loggedIn: function() {
            return this.$store.state.isLoggedIn
        },
    },
  mounted() {

  },
  created() {

        this.handleAutoLogin()
        this.$store.state.minutesOfCall = 15

        if (
            this.$store.state.tokenData &&
            this.$store.state.tokenData.permissions.isTarotReader
        ) {
            this.socket.emit('change-state', 'AVAILABLE')
        }
    },
    methods: {
        async connectSocket() {
            // This method tries to connect to the socket server (logged clients only)

            try {
                const socket = io(`${ssl ? 'wss' : 'ws'}://${backendUrl}`, {
                    reconnectionDelayMax: 10000,
                    auth: {
                        jwt: this.$store.state.jwt,
                    },
                })

                this.$store.commit('setSocket', socket)

                if(enableLogs) console.log('Socket connected succesfully')

                if (
                    this.$store.state.tokenData &&
                    this.$store.state.tokenData.permissions.isTarotReader
                ) {
                    socket.emit('change-state', 'AVAILABLE')
                    this.handleReadingRequests()
                }

                /*socket.on('otherWindowUserConnect', async ()=>{
                    this.$store.commit("setMessage", "Ingresaron con tu usuario desde otra ventana");
                    setTimeout(() => {
                        window.location.href = 'https://www.celcom.cl/';
                    }, 3000);
                });*/
                
                /*socket.on('otherIPUserConnect', async ()=>{
                    this.$store.commit("setMessage", "Ingresaron con tu usuario desde otro equipo");
                    await axiosClient.post('/users/logout');
                    this.$store.commit('logout');
                    setTimeout(() => {
                        window.location.href = '/?logout';
                    }, 3000);
                });*/

                socket.on('discountDS', async (amount)=>{
                    if(enableLogs) console.log(amount)
                    this.$store.commit("updateUserBalance", this.$store.state.balance-amount);
                });

              const device = this.detectDevice();
              if (device !== 'iphone') {
                socket.on('disconnect', async () => {
                  window.location.href = '/?reconect';
                });
              }

                socket.on('valoracion', async (idTarotista) => {
                    this.idTarotista = idTarotista;
                    if(!this.$store.state.tokenData.permissions.isTarotReader) this.$store.commit("showModal", true);
                });

                this.$store.commit('captureStatusT', socket)
                
            } catch (err) {
               if(enableLogs) console.log(err)
               if(enableLogs) console.log('Error connecting to socket')
            }
        },
      detectDevice() {
        const userAgent = window.navigator.userAgent.toLowerCase();
        if (userAgent.includes('android')) {
          return 'android';
        } else if (userAgent.includes('iphone')) {
          return 'iphone';
        } else {
          return 'pc';
        }
      },
        async connectGuestSocket() {
            // This method tries to connect to the socket server (logged clients only)

            try {
                const socket = io(`${ssl ? 'wss' : 'ws'}://${backendUrl}`, {
                    reconnectionDelayMax: 10000,
                    auth: {
                        guestId: this.$store.state.guestId,
                    },
                })

                this.$store.commit('setSocket', socket)

                this.$store.commit('captureStatusT', socket)

               if(enableLogs) console.log('Socket connected succesfully as guest')
            } catch (err) {
               if(enableLogs) console.log(err)
               if(enableLogs) console.log('Error connecting to socket as guest')
            }
        },

        handleReadingRequests() {
            this.$store.state.socket.on(
                'reading-request',
                ({ roomId, personName, channel }) => {
                    this.modalProps = { personName, channel, roomId }
                    this.triggerModal('modalLlamada')
                    window.document.getElementById('audioLlamada').play()
                   if(enableLogs) console.log(`Request ${roomId}`)
                }
            )
            this.$store.state.socket.on('notify-tarot-reader', objIdUser => {
               if(enableLogs) console.log(
                    '@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@',
                    objIdUser
                )
                this.triggerModal('modelAvisoCompra')
                window.document.getElementById('audioRing').play()
            })
        },

        async triggerModal(modal) {
           if(enableLogs) console.log(`Show Modal: ${modal}`)
            switch (modal) {
                case 'modalLlamada':
                    this.modalTriggered = 'modalLlamada'
                    this.forceClose = 'modelAvisoCompra'
                    window.document.getElementById('audioRing').pause()
                    break
                case 'modelAvisoCompra':
                    this.modalTriggered = 'modelAvisoCompra'
                    break
            }
            await this.$nextTick()
            this.modalTriggered = null
            this.forceClose = null
        },

        async disconnectSocket() {
            // This method removes the socket from the store and disconnects it, if any
            let socket = this.$store.state.socket

           if(enableLogs) console.log('Trying to disconnect socket')
            if (socket === null || socket === undefined) {
               if(enableLogs) console.log('No socket to disconnect found, return')
                return
            }
            socket.disconnect()
            this.$store.commit('setSocket', null)
           if(enableLogs) console.log('Socket disconnected')
        },
        handleGuestLogin() {
            if (localStorage.guestId == null) localStorage.guestId = uuidv4()

            const guestId = localStorage.guestId

            this.$store.commit('guestLogin', guestId)
        },
        async handleAutoLogin() {
           if(enableLogs) console.log(
                'Checking if refresh token is valid, and getting an auth token if so.'
            )
            // Get a new token;
            let token

            try {
                // This gets a new auth token
              let response = await axiosClient.post('/users/refresh-token', {}, { withCredentials: true });
              console.log("respuesta del back reconect " + JSON.stringify(response.data))
                token = response.data.token
                // This updates the cookie
                await axiosClient.post('/users/new-refresh-token')

               if(enableLogs) console.log('Got new auth token!')
            } catch (err) {
               if(enableLogs) console.log(
                    'Error fetching new token, no refresh token cookie found, cookie expired or not present'
                )

                this.$store.commit('loginFailed')
                this.handleGuestLogin()
                return
            }

            this.$store.commit('login', token)

          console.log("esta handleAutoLogin 1 " + this.$store.state.isLoggedIn)
          console.log("esta handleAutoLogin 2 " + this.$store.state.jwt)
            if (
                this.$store.state.tokenData.permissions.isTarotReader === true
            ) {
                let routesArray = [
                    'Planes',
                    'Servicios',
                    'Compra',
                    'CompraPlanes',
                    'Servicios',
                ]
                if (routesArray.includes(this.$route.name)) {
                    this.$router.push('/')
                    return
                }
            } else if (
                this.$store.state.tokenData.permissions.isAdmin === false
            ) {
                let routesArray = [
                    'DashAdminAmbitos',
                    'DashAdminEspecialidades',
                    'DashAdminPrecioLectura',
                ]

                if (routesArray.includes(this.$route.name)) {
                    this.$router.push('/')
                }
            }
            // Create a function to fetch new tokens every 1 hour
            setInterval(() => {
                if (this.$store.state.isLoggedIn) {
                    this.handleAutoLogin()
                }
            }, 60000 * 60)
        },
    },
}
</script>
<style>
@font-face {
    font-family: source-black;
    src: url('../public/font/SourceSerifPro-Black.ttf') format('truetype');
}
@font-face {
    font-family: source-bold;
    src: url('../public/font/SourceSerifPro-Bold.ttf') format('truetype');
}
@font-face {
    font-family: source-extralight;
    src: url('../public/font/SourceSerifPro-ExtraLight.ttf') format('truetype');
}
@font-face {
    font-family: source-light;
    src: url('../public/font/SourceSerifPro-Light.ttf') format('truetype');
}
@font-face {
    font-family: source-regular;
    src: url('../public/font/SourceSerifPro-Regular.ttf') format('truetype');
}
@font-face {
    font-family: source-semibold;
    src: url('../public/font/SourceSerifPro-SemiBold.ttf') format('truetype');
}

@font-face {
    font-family: maven-black;
    src: url('../public/font/MavenPro-Black.ttf') format('truetype');
}
@font-face {
    font-family: maven-bold;
    src: url('../public/font/MavenPro-Bold.ttf') format('truetype');
}
@font-face {
    font-family: maven-extrabold;
    src: url('../public/font/MavenPro-ExtraBold.ttf') format('truetype');
}
@font-face {
    font-family: maven-medium;
    src: url('../public/font/MavenPro-Medium.ttf') format('truetype');
}
@font-face {
    font-family: maven-regular;
    src: url('../public/font/MavenPro-Regular.ttf') format('truetype');
}
@font-face {
    font-family: maven-semibold;
    src: url('../public/font/MavenPro-SemiBold.ttf') format('truetype');
}

body {
    font-family: source-regular !important;
    color: #333333 !important;
}
*::selection {
    background: #e7d7f5;
    color: #501682;
}

/* css generales */

.btn-yellow-ver-mas {
    font-family: maven-semibold !important;
    background: #ffc209 !important;
    color: #501682 !important;
    padding: 6px 30px !important;
    border-radius: 50px !important;
    text-transform: uppercase !important;
    font-size: 10px !important;
}
.btn-yellow-ver-mas:hover {
    background: #dba501 !important;
    color: #650bbf !important;
    box-shadow: #650bbf;
}

.btn-yellow-ver-mas:active:focus {
    outline: 0;
}

.button-yellow {
    background-color: #ffc209 !important;
    color: #501682 !important;
    font-weight: bold !important;
    text-transform: uppercase !important;
    font-size: 11px !important;
    padding: 6px 16px !important;
    font-family: maven-bold !important;
}

.button-yellow:hover,
.button-violet:hover,
.btn-yellow:hover,
.btn-loguot:hover,
.btn-filtro:hover,
.btn-creditos:hover,
.btn-pagar:hover {
    background: #04d9b2 !important;
    transition: all 0.5s ease !important;
}

.button-yellow:focus,
.btn-yellow:focus,
.btn-loguot:focus,
.btn-filtro:focus {
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075),
        0 0 8px rgba(255, 0, 0, 0.6);
}
.general-title {
    color: #501682;
    /* text-transform: uppercase; */
    font-size: 35px;
  margin-bottom: 35px;
    font-family: maven-bold;
}
.subtitle {
    text-align: center;
    margin-bottom: 27px;
    font-family: maven-regular;
    font-size: 15px;
}

/*sacar fechas en input type="number"*/
input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.submenu{
  font-family: maven-regular;
  color: #501682;
  font-size: 11px;
  margin-top: 15px;
}

.submenu span{
  font-family: maven-bold;
  font-size: 11px !important;
}


/*pagos*/ 
.card-pagos {
  min-height: 439px !important;
    background-color: #f6f7f6 !important;
  border: none !important;
  border-radius: 15px !important;
  font-family: maven-regular;
}
.title-card-pago {
  font-family: maven-bold !important;
  text-align: center !important;
  font-size: 18px !important;
  margin: 25px 35px ;
}

.title-card-divino-saldo {
  font-family: maven-bold !important;
  text-align: center !important;
  font-size: 19px !important;
  margin: 25px 35px ;
}

.label-card-pago{
  font-size: 12px;
  font-family: maven-medium;
  color: #333;
}
.text-medio-pago{
  font-size: 18px;
  text-align: center;
  padding-top: 65px;
  margin: 0px 22px
}
.text-medio-pago span{
  font-family: maven-bold !important;
  color: #333;
  font-size: 18px;
}
.form-input {
    font-size: 13px !important;
    border-radius: 20px !important;
    box-shadow: 0px 2px 4px 0px #70707040 !important;
}
.card-form-input {
    font-size: 13px !important;
    border-radius: 20px !important;
    box-shadow: 0px 2px 4px 0px #70707040 !important;
}
.card-form-input .body-card{
    padding:8px 8px 3px 18px
}
.btn-pagar {
    background: #ffc209 !important;
    color: #501682 !important;
    font-family: maven-bold !important;
    padding: 8px 40px !important; 
    border-radius: 50px !important;
    font-size: 12px !important;
    border: none !important;
    transition: all 0.5s ease !important;
}
.btn-pagar:hover {
  background: #04d9b2;
}
.btn-delete-card{
    background: rgb(163, 163, 163) !important;
    color: #333 !important;
    font-family: maven-bold !important;
    padding: 8px 40px !important; 
    border-radius: 50px !important;
    font-size: 10px !important;
    border: none !important;
    transition: all 0.5s ease !important;
}
.btn-delete-card:hover {
  background: rgb(204, 204, 204);
}


.pagos-check-input:checked {
  background-color: #501682 !important;
  border-color: #501682 !important;
  margin-right: 10px;
}
</style>
