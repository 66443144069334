<template>
  <div class="container">
    <div class="row">
      <div class="col-12">
        <h5 class="title general-title">Tarotistas similares</h5>
      </div>
      <div
        class="col-lg-3 col-md-4 col-12 mt-4"
        v-for="(tarotista, index) in similarTarotistas"
        :key="index"
      >
        <div class="card card-tarotista">
          <div class="card-header">
            <div class="dstco" v-if="tarotista.descuento">
              <span>Dstco</span>
              <p>-{{ tarotista.descuento }}%</p>
            </div>
          </div>
          <div class="card-body" >
            <a
              :href="$router.resolve('/ficha-tarotista?id_tarotista=' + tarotista.tarotReaderId).href"
              style="
                display: inline-block;
                text-decoration: none;
                color: #333333;
              "
              
            >
              <div class="row">
                <div class="col-12 col-img">
                  <div class="image">
                    <img :src="tarotista.avatar" alt="" />
                    <i
                          v-if="statust ? (statust['t'+tarotista.userId] ? (statust['t'+tarotista.userId].status == 'Disponible') : false): false"
                          class="
                                                        fas
                                                        fa-circle
                                                        tarotista-disponible
                                                    "
                        ></i>
                        <i
                          v-if="!(statust ? (statust['t'+tarotista.userId] ? (statust['t'+tarotista.userId].status == 'Disponible') : false): false)  && !(statust ? (statust['t'+tarotista.userId] ? (statust['t'+tarotista.userId].status == 'Ocupado'):false) : false)"
                          class="
                                                        fas
                                                        fa-circle
                                                        tarotista-no-disponible
                                                    "
                        ></i>
                        <i
                          :key="tarotista.userId + '/' + seed"
                          v-if="statust ? (statust['t'+tarotista.userId] ? (statust['t'+tarotista.userId].status == 'Ocupado'):false) : false"
                          class="
                                                        fas
                                                        fa-circle
                                                        tarotista-occupied
                                                    "
                        ></i>
                  </div>
                  <p
                    v-if="statust ? (statust['t'+tarotista.userId] ? (statust['t'+tarotista.userId].status == 'Disponible') : false): false"
                    class="tarotista-estado tarotista-disponible"
                  >
                    Disponible
                  </p>
                  <p
                    v-if="!(statust ? (statust['t'+tarotista.userId] ? (statust['t'+tarotista.userId].status == 'Disponible') : false): false)  && !(statust ? (statust['t'+tarotista.userId] ? (statust['t'+tarotista.userId].status == 'Ocupado'):false) : false)"
                    class="tarotista-estado tarotista-no-disponible"
                  >
                    No Disponible
                  </p>
                  <p
                    :key="tarotista.userId + '/' + seed"
                    v-if="statust ? (statust['t'+tarotista.userId] ? (statust['t'+tarotista.userId].status == 'Ocupado'):false) : false"
                    class="tarotista-estado tarotista-occupied"
                  >
                    Ocupado
                  </p>
                </div>
              </div>
              <div class="row">
                <div class="col-12 text-center">
                  <div class="text-name">
                    <h5>
                      {{ tarotista.tarotReaderName }}
                    </h5>
                    <p v-if="tarotista.specialties" class="subtitle">{{
                    tarotista.specialties[0].specialtyName
                  }}</p>
                  <p v-else class="subtitle" style="opacity: 0">lorem</p>
                    
                  </div>
                </div>
                <div class="col-12">
                  <div class="col-puntaje">
                    <div class="score text-center">
                      <!-- <div class="star">
                        <i
                          v-for="i in tarotista.rating"
                          :key="i"
                          class="fas fa-star"
                        ></i>
                        <i
                          v-for="i in 5 - tarotista.rating"
                          :key="i * 77"
                          class="fas fa-star grey-star"
                        ></i>
                        <span class="puntaje">{{ tarotista.rating }}.0</span>
                        <span class="personas">(154)</span>
                      </div> -->
                      <Star :value="tarotista.valoracion_promedio" :shownumer="true" :size="1" :showperson="true" :person="tarotista.cantidad_valoracion"/>
                    </div>
                  </div>
                  <div class="lecturas">
                    <i class="far fa-eye"></i>
                    <span class="num-lectura"
                      >{{ tarotista.amountOfReadings }}
                    </span>
                    <span> Lecturas</span>
                  </div>
                  <div class="tipo">
                    <i class="fas fa-bolt"></i>
                    <span>Lectura1 {{ tarotista.type }}</span>
                  </div>
                </div>
              </div>
            </a>
            <div class="text-center mt-4">
              <p class="titulo-btn">Comprar</p>
              <div v-if="tarotista.chatPrice !== null">
                    <button
                      :disabled="
                      (statust['t'+tarotista.userId] ? (statust['t'+tarotista.userId].status != 'Disponible') : true) ||
                      (statust['t'+tarotista.userId] ? !statust['t'+tarotista.userId].chanels.chat : true)
                      "
                      type="button"
                      class="btn btn-green btn-block"
                      @click="
                        goToPay(
                          tarotista.tarotReaderId,
                          tarotista.tarotReaderType,
                          'Chat'
                        )
                      "
                    >
                      <i class="fas fa-comments icon-btn"></i>
                      <span>Chat $ {{ numberWithCommas(tarotista.chatPrice) }}/min</span>
                    </button>
                    <!-- <label class="descuento" v-if="tarotista.descuento"
                      >$400/min</label
                    > -->
                  </div>
                  <div v-if="tarotista.voicePrice !== null">
                    <button
                      :disabled="
                      (statust['t'+tarotista.userId] ? (statust['t'+tarotista.userId].status != 'Disponible') : true) ||
                      (statust['t'+tarotista.userId] ? !statust['t'+tarotista.userId].chanels.voice : true)
                      "
                      type="button"
                      class="btn btn-green btn-block"
                      @click="
                        goToPay(
                          tarotista.tarotReaderId,
                          tarotista.tarotReaderType,
                          'Voz'
                        )
                      "
                    >
                      <i
                        class="
                                                    fas
                                                    fa-phone-alt
                                                    icon-btn
                                                "
                      ></i>
                      <span>Audio ${{ numberWithCommas(tarotista.voicePrice) }}/min</span>
                    </button>
                    <!-- <label class="descuento" v-if="tarotista.descuento"
                      >$400/min</label
                    > -->
                  </div>
                  <div v-if="tarotista.videoPrice !== null">
                    <button
                      :disabled="
                      (statust['t'+tarotista.userId] ? (statust['t'+tarotista.userId].status != 'Disponible') : true) ||
                      (statust['t'+tarotista.userId] ? !statust['t'+tarotista.userId].chanels.video : true)
                      "
                      type="button"
                      class="btn btn-green btn-block"
                      @click="
                        goToPay(
                          tarotista.tarotReaderId,
                          tarotista.tarotReaderType,
                          'Video'
                        )
                      "
                    >
                      <i class="fas fa-video icon-btn"></i>
                      <span>Video ${{ numberWithCommas(tarotista.videoPrice) }}/min</span>
                    </button>
                    <!-- <label class="descuento" v-if="tarotista.descuento"
                      >$400/min</label
                    > -->
                  </div>
            </div>
          </div>
        </div>
      </div>
    </div>
        <div class="row mt-5">
      <div class="col text-center">
        <router-link to="/catalogo?ranking=1">
          <button class="btn btn-ver-mas">Ver todos los tarotistas</button>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import Star from '../util/Stars.vue'
export default {
  props: {
    similarTarotistas: Array,
    tarotista: Object
  },
  watch: {
    socket: function() {
      this.socket.on('statusTarotReaderChange', ()=>{
        setTimeout(() => {
          this.statust = {...this.$store.state.statust}
        }, 100);
      })
    },
  },
  data: () => {
    return {
      statust: {}
    };
  },
  mounted(){
    this.statust = this.$store.state.statust;
    if (this.socket){
      if(! this.socket._callbacks.$statusTarotReaderChange.length < 2){
        this.pageReady = { ...this.pageReady, socketReady: true };
        this.socket.on('statusTarotReaderChange', ()=>{
          setTimeout(() => {
            this.statust = {...this.$store.state.statust}
          }, 100);
        })
      }
    }
  },
  components: {
    Star
  },
   methods: {
    numberWithCommas(x) {
            return x.toLocaleString('es-AR')
        },

        redireccion(){
           this.$router.push('/')
          /* this.$forceUpdate(); */
        }
  },
  computed: {
    socket() {
      return this.$store.state.socket;
    },
  },
};
</script>

<style scoped>

.title {
  margin-bottom: 35px;
  text-align: center;
}
.card {
  border-radius: 33px;
  min-height: 100%;
  max-width: 226px;
  margin: auto;
  border: none;
  background: #f6f6f6;
  font-family: maven-regular;
}
.card-header {
  background: #04d9b2;
  border-radius: 33px 33px 0px 0px;
  border-bottom: none;
  height: 80px;
}
.card-body {
  padding: 30px 20px;
  position: relative;
  padding-top: 70px;
}
.col-img {
  width: 100%;
  padding: 0;
  position: absolute;
  left: 0;
  top: -45px;
  text-align: center;
}
.col-img i {
  position: absolute;
  right: 0px;
  top: 8px;
  border: 2px solid #fff;
  border-radius: 50%;
  font-size: 14px;
}
.tarotista-disponible {
  color: #04D925;
}
.tarotista-no-disponible {
  color: grey;
}
.tarotista-occupied {
  color: #ffc30b;
}
.image {
  position: relative;
  width: 90px;
  margin: 0 auto;
}
.image img {
  width: 90px;
  height: 90px;
  border-radius: 50%;
  object-fit: cover;
  background: #fff;
}
.dstco {
  border-radius: 0 33px;
  position: absolute;
  background: #f6f6f6;
  padding: 8px 10px 8px;
  top: 3px;
  right: 4px;
  font-size: 10px;
  color: #501682;
  font-family: maven-bold;
  text-align: center;
}
.dstco p {
  font-size: 15px;
  margin-bottom: 0;
}
.text-name {
  color: #501682;
  font-family: maven-bold;
}
.text-name h5 {
  font-weight: bold;
  font-size: 22px;
  margin-bottom: 0px;
}
.text-name p {
  font-size: 15px;
  margin-bottom: 0;
  font-family: maven-regular;
}
.text span {
  font-size: 11px;
}
.text span i {
  font-size: 12px;
  color: #04d9b2;
  margin-left: 8px;
}
.col-puntaje {
  margin-top: 3px;
  color: #979797;
}
.puntaje {
  font-family: maven-bold;
  margin-top: 1px;
  font-size: 12px;
  margin-left: 5px;
}
.personas {
  font-size: 12px;
  margin-left: 5px;
}
.star {
  font-size: 10px;
  margin-right: 6px;
  margin-top: 2px;
}
.star i {
  color: #ffc30b;
}
.grey-star {
  color: #979797 !important;
}
.lecturas {
  margin-top: 1px;
  font-size: 12px;
  color: #979797;
  text-align: center;
  font-family: maven-regular;
}

.lecturas i {
  margin-right: 5px;
}
.lecturas .num-lectura {
  font-family: maven-bold;
}

.tipo {
  margin-top: 1px;
  font-size: 14px;
  color: #501682;
  text-align: center;
  font-family: maven-bold;
}
.tipo i {
  margin-right: 5px;
}

.btn-green {
  background: #04d9b2;
  color: #ffffff;
  margin-bottom: 10px;
  font-family: maven-semibold;
  font-size: 10px;
  text-align: center;
  border-radius: 15px;
  width: 125px;
}
.btn-green:hover {
  background: #00bf9c;

  color: #ffffff;
}
.btn-green:disabled {
  background-color: #e8e8e8;
  color: #dddddd;
}
.icon-btn {
  float: left;
  margin: auto;
  margin-top: 3px;
}
.descuento {
  color: #979797;
  font-family: maven-regular;
  margin-left: 10px;
  font-size: 11px;
  vertical-align: middle;
  margin-bottom: 10px;
  text-decoration: line-through;
}
.btn-ver-mas {
  background: #ffc30b;
  color: #501682;
  font-family: maven-bold;
  padding: 6px 40px;
  border-radius: 50px;
  font-size: 13px;
  transition: all 0.5s ease;
}

.btn-ver-mas:hover {
  background: #04d9b2;
}

/* Nuevos cambios */

.tarotista-estado {
  font-size: 14px;
  text-align: center;
  text-decoration: none;
  font-family: 'maven-bold';
  margin: 0;
}
.titulo-btn {
  font-family: 'maven-bold';
  color: #501682;
}

</style>
