<template>
  <div class="container my-5">
    <div class="row">
      <div class="col-12 mb-3">
        <div class="text-back" @click="goBack">
          <i class="fas fa-chevron-left"></i>
          <span>Volver</span>
        </div>
      </div>
      <div class="col-12 mb-3">
        <h5 class="general-title">Usuarios</h5>
      </div>
      <div class="col-12 mb-3">
        <div class="card shadow">
          <div class="card-body">
            <div class="row justify-content-center">
              <div class="col-12">
                <div class="row">
                  <div class="col-md-6 col-12">
                    <h1>Administradores</h1>
                  </div>
                  <div class="col-md-6 col-12">
                    <button class="btn btn-yellow" v-b-modal.modalUsuario>
                      Crear nuevo
                    </button>
                    <ModalUsuario />
                  </div>
                </div>
              </div>
              <div class="col-11 mt-4">
                <div class="content-table">
                  <table class="table table-striped">
                    <thead>
                      <tr>
                        <th scope="col">Nombre</th>
                        <th scope="col">Email</th>
                        <th scope="col">Teléfono</th>
                        <th scope="col">Estado</th>
                        <th scope="col"></th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="user in usuarios" :key="user.userId">
                        <td>{{ user.personName }} {{ user.personLastName }}</td>
                        <td>{{ user.email }}</td>
                        <td>{{ user.phoneNum }}</td>
                        <td v-if="user.deleted === 0">Activo</td>
                        <td v-if="user.deleted === 1">Inhabilitado</td>
                        <td>
                          <button class="btn btn-editar">Editar</button>
                        </td>
                        <td>
                          <button class="btn-table">
                            <i class="fas fa-eye"></i>
                          </button>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axiosClient from '../../../config/axiosClient';
import ModalUsuario from './modal/ModalUsuario.vue';
import { enableLogs } from '../../../config';

export default {
  name: 'DashAdminUser',
  components: {
    ModalUsuario,
  },
  data: () => {
    return {
      usuarios: [],
    };
  },
  created() {
    this.getAdminsInfo();
  },
  methods: {
    async getAdminsInfo() {
      try {
        const { data } = await axiosClient.get('/users/get-admins');
        if (enableLogs) console.log('successfully fetched all admins');
        if (enableLogs) console.log(data);
        this.usuarios = data.users;
        if (enableLogs) console.log(this.usuarios);
      } catch (err) {
        if (enableLogs) console.log('could not fetch all users');
        if (enableLogs) console.log(err);
      }
    },
    goBack() {
      this.$router.go(-1);
    },
  },
};
</script>

<style scoped>
.text-back {
  color: #501682;
  text-transform: uppercase;
  font-family: maven-semibold;
  font-size: 13px;
  cursor: pointer;
}
.text-back:hover {
  color: #934dd8;
}
.text-back span {
  margin-left: 6px;
}
.title {
  color: #501682;
  font-weight: bold;
  text-transform: uppercase;
  font-family: source-bold;
  font-size: 19px;
}
.card {
  border-radius: 10px;
  min-height: 100%;
}
.card-body {
  padding: 30px 20px;
}
.card-body h1 {
  font-size: 25px;
  font-family: maven-semibold;
  float: left;
}

.btn-yellow {
  font-family: maven-semibold;
  background: #ffc209;
  color: #501682;
  font-size: 11px;
  text-transform: uppercase;
  float: right;
  padding: 10px 18px;
}
.btn-yellow:hover {
  background: #dba501;
  color: #650bbf;
}
.btn-table {
  background-color: transparent;
  border: none;
}
.btn-editar {
  font-size: 12px;
  background-color: #979797;
  color: #333333;
}
table {
  font-size: 12px;
}
table th {
  font-family: maven-semibold;
}
table td {
  font-family: maven-medium;
}
/*toggle*/
.switch {
  position: relative;
  display: inline-block;
  width: 40px;
  height: 20px;
  margin-right: 10px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #fff;
  border: 1px solid #ffc209;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: '';
  height: 13px;
  width: 13px;
  left: 4px;
  bottom: 3px;
  background-color: #fff;
  border: 1px solid #ffc209;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: #ffc209;
  border: 1px solid #fff;
}

input:focus + .slider {
  box-shadow: 0 0 3px #fff;
}

input:checked + .slider:before {
  -webkit-transform: translateX(16px);
  -ms-transform: translateX(16px);
  transform: translateX(16px);
}
/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}
@media screen and (max-width: 768px) {
  .content-table {
    overflow-x: scroll;
  }
  .table {
    min-width: 500px;
  }
  .content-table {
    --scrollbarBG: #d1d1d1;
    --thumbBG: #333333;
  }
  .content-table::-webkit-scrollbar {
    height: 11px;
  }
  .content-table {
    scrollbar-width: thin;
    scrollbar-color: var(--thumbBG) var(--scrollbarBG);
  }
  .content-table::-webkit-scrollbar-track {
    background: var(--scrollbarBG);
  }
  .content-table::-webkit-scrollbar-thumb {
    background-color: var(--thumbBG);
    border-radius: 6px;
    border: 3px solid var(--scrollbarBG);
  }
}
</style>
