<template>
  <!-- Modal Fin Llamada -->
  <div>
    <b-modal
      :no-close-on-esc="true"
      :no-close-on-backdrop="true"
      id="modal-datos-not-login"
      size="md"
      centered
      :visible="true"
      v-model="modalShowNotLogin"
    >
      <div class="row">
        <form action="">
          <div class="row">
            <div class="col-md col-12 m-auto mb-3">
              <h4 class="mb-0">Información para compra segura</h4>
              <span class="subtitle"
                >Ingresa todos los datos para poder realizar una compra</span
              >
            </div>
          </div>
          <div class="row">
            <!-- <div class="col-12 mb-3">
              <label for="">Email(*)</label>
              <b-form-input
                type="email"
                class="form-control"
                aria-label="Username"
                aria-describedby="basic-addon1"
                v-model="email"
                autocomplete="off"
                :disabled="email > 0"
              />
            </div> -->
            <div class="col-lg-6 col-12 mb-3">
              <label for="">Nombre (*)</label>
              <b-form-input
                type="text"
                class="form-control"
                aria-label="Username"
                aria-describedby="basic-addon1"
                v-model="nombre"
                v-on:click="changeInput"
                name="editNombre"
                autocomplete="off"
              />
              <span class="alert" v-if="nombre.length === 0 && editNombre"
                >Campo obligatorio</span
              >
            </div>
            <div class="col-lg-6 col-12 mb-3">
              <label for="">Apellido(*)</label>
              <b-form-input
                type="text"
                class="form-control"
                aria-label="Username"
                aria-describedby="basic-addon1"
                v-model="apellido"
                v-on:click="changeInput"
                name="editApellido"
                autocomplete="off"
              />
              <span class="alert" v-if="apellido.length === 0 && editApellido"
                >Campo obligatorio</span
              >
            </div>

           <!--  <div class="col-lg-6 col-12 mb-3">
              <label for="">Tipo de documento(*)</label>
              <select class="form-select" name="" id="" v-model="tipoDoc">
                <option
                  v-for="tipoDoc in documents"
                  v-bind:value="tipoDoc.value"
                  :key="tipoDoc.value"
                >
                  {{ tipoDoc.text }}
                </option>
              </select>
            </div> -->

            <!-- <div class="col-lg-6 col-12 mb-3">
              <label for="">Numero de documento(*)</label>
              <b-form-input
                type="text"
                class="form-control"
                aria-label="Username"
                aria-describedby="basic-addon1"
                v-model="numDoc"
                v-on:click="changeInput"
                name="editnumDoc"
                autocomplete="off"
              />
              <span class="alert" v-if="numDoc.length === 0 && editnumDoc"
                >Campo obligatorio</span
              >
            </div> -->


            <div class="col-lg-6 col-12 mb-3">
              <label for="">Teléfono(*)</label>
              <b-form-input
                type="number"
                @keypress="isNumberAndSign"
                class="form-control"
                aria-label="Username"
                aria-describedby="basic-addon1"
                maxlength="11"
                v-model="telefono"
                autocomplete="off"
                pattern="[0-9_+]*"
              />
              <legend class="legend-field text-legend">
                (Formato de ej: +56911222333)
              </legend>
              <span class="alert" v-if="validarTelefono(telefono)"
                >Campo obligatorio</span
              >
            </div>

            <div class="col-lg-6 col-12 mb-3">
              <label for="">País(*)</label>
              <select class="form-select" name="" id="" v-model="pais">
                <option
                  v-for="pais in paises"
                  v-bind:value="pais.value"
                  :key="pais.value"
                >
                  {{ pais.text }}
                </option>
              </select>
            </div>
            <div class="col-lg-6 col-12np mb-3">
              <label for="">Región(*)</label>
              <select class="form-select" name="" id="" v-model="region">
                <option value="" disabled>Elija una región</option>
                <option
                  v-for="(region, index) in regiones"
                  v-bind:value="region"
                  :key="region + index * 73"
                >
                  {{ region }}
                </option>
                
              </select>
            </div>
            <div class="col-lg-6 col-12np mb-3">
              <label for="">Cuidad(*)</label>
              <select class="form-select" name="" id="" v-model="ciudad">
                <option value="" disabled>Elija una ciudad</option>
                <option
                v-for="(ciudad, index) in regyciudad[region]"
                  :value="ciudad"
                  :key="ciudad + index * 73"
                >
                  {{ ciudad }}
                </option>
              </select>
            </div>
            <div class="col-12 mb-3">
              <label for="">Dirección(*)</label>
              <b-form-input
                type="text"
                class="form-control"
                aria-label="Username"
                aria-describedby="basic-addon1"
                v-model="direccion"
                v-on:click="changeInput"
                name="editDireccion"
                autocomplete="off"
              />
              <span class="alert" v-if="direccion.length === 0 && editDireccion"
                >Campo obligatorio</span
              >
            </div>
          </div>
          <div class="row">
            <div class="col-md col-12 m-auto mb-3">
              <span class="text-description"
                >(*)Los campos son obligatorios para avanzar.</span
              >
            </div>
          </div>
        </form>
        
      </div>

      <template
        #modal-footer="{
          /*hide*/
        }"
      >
        <div class="row">
          <div class="col-12 text-end p-0">
            <!--               <b-button size="sm" class="btn-sm btn btn-secondary mr-2">
              Cancelar
            </b-button> -->
            <button
              :disabled="!validForm"
              class="btn btn-sm btn-yellow ml-3"
              @click="sendData"
            >
              Siguiente
            </button>
          </div>
        </div>
      </template>
    </b-modal>
  </div>
</template>
<script>
import axiosClient from '../../config/axiosClient';
/* import ciudadesChile from '../../data/ciudadesChile'; */
import { enableLogs } from '../../config';
export default {
  data: function () {
    return {
      /* email: '', */
      nombre: '',
      apellido: '',
      telefono: '',
      pais: 'CL',
      direccion: '',
      tipoDoc: 'RUT',
      numDoc: '',

      editNombre: false,
      editApellido: false,
      editTelefono: false,
      editPais: false,
      editCiudad: false,
      editDireccion: false,
      editnumDoc: false,

      paises: [{ value: 'CL', text: 'Chile' }],
      documents: [
        { value: 'RUT', text: 'RUT'},
        { value: 'CC', text: 'CC'},
        { value: 'NIT', text: 'NIT'},
        { value: 'CE', text: 'CE'},
        { value: 'TI', text: 'TI'},
        { value: 'PP', text: 'Passport'},
        { value: 'RUC', text: 'RUC'},
        { value: 'CURP', text: 'CURP'},
        { value: 'RFC', text: 'RFC'},
        { value: 'DNI', text: 'DNI'},
        { value: 'PAS', text: 'PAS'}
        ],
      regiones:{},
      ciudades: '',
      regyciudad:'',

      ciudad: '',
      region: '',

      modalShowNotLogin: true,
    };
  },
   props: ["email", "disableBtn"],
  computed: {
    /* tokenData() {
      //console.log(this.$store.state.tokenData);
      
      return this.$store.state.tokenData;
    }, */
   /*  setEmail(){
      return window.localStorage.setItem(this.email)
    }, */
    validForm() {
      let validForm = true;
      this.validarTelefono(this.telefono) ? (validForm = false) : null;

      this.telefono.length > 0 ? null : (validForm = false);
      this.nombre.length > 0 ? null : (validForm = false);
      this.apellido.length > 0 ? null : (validForm = false);
      this.direccion.length > 0 ? null : (validForm = false);

      return validForm;
    },
  },

  methods: {
    getRegiones: async function (){
      try{
        let response = await axiosClient.get('/users/cities/', {
      });
      this.regiones = Object.keys(response.data.chile)
      this.regyciudad = response.data.chile
      if(enableLogs) console.log(response.data.chile)
      return response.data
      }catch(err){
        if(enableLogs) console.log(err)
      }
    },
    /* handleClickNext() {
      this.$emit('formDataChange', {
        email: this.email,
        nombre: this.nombre,
        apellido: this.apellido,
        telefono: this.telefono,
        pais: this.pais,
        ciudad: this.ciudad,
        direccion: this.direccion,
      });
    }, */
    sendData: async function() {
      try {
        let email = window.localStorage.getItem('emailGuest')
        let info = {
            email: email,
            firstName: this.nombre,
            lastName: this.apellido,
            phoneNumber: this.telefono,
            city: this.ciudad,
            country: this.pais,
            region: this.region,
            adress: this.direccion,
            tipo_doc: this.tipoDoc,
            num_doc: this.numDoc,
          }
        const response = await axiosClient.post(
          '/users/additional-info/insert',
        info
        );
        window.localStorage.setItem('info', JSON.stringify(info))
        this.modalShowNotLogin = false
        this.$store.commit("setInfoGuestCredit", info);
        /* if(this.modalShow == false){
          window.location.reload()
          this.$store.commit('setMessage', 'Ahora puedes realizar una compra')
        } */
        if(enableLogs) console.log(response.data);
      } catch (error) {
        if(enableLogs) console.log(error.response);
      }
    },
    /** Esta funcion retorna true si el telefono es invalido */
    validarEmail: function (valor) {
      if (valor.length > 0) {
        if (
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
            valor
          )
        ) {
          return false;
        } else {
          return true;
        }
      } else {
        return false;
      }
    },
    isNumberAndSign: function (evt) {
      if (this.telefono.length >= 11) {
        evt.preventDefault();
        return;
      }
      const keysAllowed = [
        '0',
        '1',
        '2',
        '3',
        '4',
        '5',
        '6',
        '7',
        '8',
        '9',
        '+',
      ];
      const keyPressed = evt.key;
      if (!keysAllowed.includes(keyPressed)) {
        evt.preventDefault();
      }
    },
    /** Esta funcion retorna true si el telefono es invalido */
    validarTelefono: function (valor) {
      if (valor.length > 0) {
        /*if (/^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/.test(valor)) {
          return false;
        } else {
          return true;
        }*/
        if (valor.length >= 11) {
          return false;
        }
        return true;
      } else {
        return false;
      }
    },
    changeInput: function (event) {
      this[event.target.name] = true;
    },
    onlyNumber: function ($event) {
      //console.log($event.keyCode); //keyCodes value
      let keyCode = $event.keyCode ? $event.keyCode : $event.which;
      if ((keyCode < 48 || keyCode > 57) && keyCode !== 46) {
        // 46 is dot

        $event.preventDefault();
      }
    },
  },
  mounted(){
    this.getRegiones()
  },
  created() {
    this.nombre = this.tokenData.personName;
    this.email = this.tokenData.email;
  },
};
</script>

<style scoped>
/deep/.close {
  background: transparent;
  border: none;
  /* display: none; */
}
/deep/.modal-header {
  border-bottom: none;
}

/deep/.modal-body {
  padding: 0 50px;
  font-family: maven-regular;
}
.text-modal {
  color: #333;
  font-family: maven-bold;
  text-align: center;
  text-transform: uppercase;
}

/deep/.modal-footer {
  border-top: none;
  padding: 10px 50px 30px 50px;
  display: block;
}
/deep/.btn {
  font-family: maven-bold;
  text-transform: uppercase;
  border: none;
  padding: 6px 20px;
}
/deep/.btn-yellow {
  background-color: #ffc209;
  color: #501682;
  font-family: maven-bold;
  text-transform: uppercase;
  border: none;
  padding: 6px 20px;
  margin-left: 10px;
}
.btn-yellow:hover {
  background: #dba501 !important;
  color: #501682 !important;
  box-shadow: #501682;
}
/deep/.btn-line {
  border: 1px solid #501682;
  background-color: transparent;
  color: #501682;
  font-family: maven-bold;
  text-transform: uppercase;
  padding: 6px 20px;
}

.text-sutitulo {
  font-size: 13px;
}

.alert {
  color: red;
  padding: 0px;
  font-size: 11px;
  font-weight: bold;
}
.text-description {
  padding: 0px;
  font-size: 11px;
  font-weight: bold;
}
.subtitle {
  padding: 0px;
  font-size: 14px;

}
.text-legend {
  padding: 0px;
  font-size: 12px;
  font-weight: bold;
  margin-bottom: 2px;
}
</style>
