<template>
  <div class="container">
    <div class="row">
      <div class="col-12">
        <p class="submenu">Inicio - <span>Tarotistas</span></p>
      </div>
    </div>
    <div class="row justify-content-center mt-3">
      <div class="col-12">
        <!--dropdown filtros-->
        <b-dropdown no-caret right>
          <template #button-content>
            <button class="btn btn-filter">
              Filtrar
              <i class="fas fa-chevron-down arrow"></i>
            </button>
          </template>
          <b-dropdown-group
            id="dropdown-group-1"
            style=""
            class="content-filter"
          >
            <div class="mb-3">
              <h5>Disponibilidad</h5>
              <div class="custom-control custom-checkbox">
                <input
                  type="checkbox"
                  class="custom-control-input"
                  id="disponibles"
                  value="disponibles"
                  v-model="filterBy.disponibilidad"
                />
                <label class="custom-control-label label-margin" for="disponibles"
                >Disponibles</label
                >
              </div>
              <div class="custom-control custom-checkbox">
                <input
                  type="checkbox"
                  class="custom-control-input"
                  id="noDisponible"
                  value="noDisponible"
                  v-model="filterBy.disponibilidad"
                />
                <label class="custom-control-label label-margin" for="noDisponible"
                >No Disponible</label>
            </div>
            </div>
            <div class="mb-3">
              <h5>Tipo de Lectura</h5>
              <div class="custom-control custom-checkbox">
                <input
                    type="checkbox"
                    class="custom-control-input"
                    id="agendada"
                    value="agendada"
                    v-model="filterBy.tipo"
                />
                <label class="custom-control-label label-margin" for="agendada"
                >Agendada</label
                >
              </div>
              <div class="custom-control custom-checkbox">
                <input
                    type="checkbox"
                    class="custom-control-input"
                    id="instantanea"
                    value="instantanea"
                    v-model="filterBy.tipo"
                />
                <label class="custom-control-label label-margin" for="instantanea"
                >Inmediata</label>
              </div>
            </div>
            <div class="mb-3">
              <h5>Ámbito</h5>
              <div
                class="custom-control custom-checkbox"
                v-for="scope in scopes"
                :key="scope.scopeId"
              >
                <input
                  type="checkbox"
                  class="custom-control-input"
                  :id="'ambito' + scope.scopeId"
                  :value="scope.scopeId"
                  v-model="filterBy.scopes"
                />
                <label
                  class="custom-control-label label-margin"
                  :for="'ambito' + scope.scopeId"
                  >{{ scope.scopeName }}</label
                >
              </div>
            </div>
            <div class="mb-3">
              <h5>Especialidad</h5>
              <div
                class="custom-control custom-checkbox"
                v-for="specialty in specialties"
                :key="specialty.specialtyId"
              >
                <input
                  type="checkbox"
                  class="custom-control-input"
                  name="input-especialidad"
                  :id="'especialidad' + specialty.specialtyId"
                  :value="specialty.specialtyId"
                  v-model="filterBy.specialties"
                />
                <label
                  class="custom-control-label label-margin"
                  :for="'especialidad' + specialty.specialtyId"
                  >{{ specialty.specialtyName }}</label
                >
              </div>
            </div>
            <!-- <div class="mb-3">
              <h5>Rango de Precio</h5>
              <div class="custom-control custom-checkbox">
                <label
                  class="custom-control-label label-margin"
                  for="customCheck1"
                  >Desde</label
                >
                <b-form-input type="text" value="priceFrom"></b-form-input>
              </div>
              <div class="custom-control custom-checkbox">
                <label
                  class="custom-control-label label-margin"
                  for="customCheck1"
                  >Hasta</label
                >
                <b-form-input type="text" value="priceTo"></b-form-input>
              </div>
            </div> -->
            <!-- <div class="mb-3">
              <h5>Sexo</h5>
              <div class="custom-control custom-checkbox">
                <input
                  type="checkbox"
                  class="custom-control-input"
                  id="customCheck1"
                  value="masculino"
                  v-model="filterBy.sexo"
                />
                <label
                  class="custom-control-label label-margin"
                  for="customCheck1"
                  >Masculino</label
                >
              </div>
              <div class="custom-control custom-checkbox">
                <input
                  type="checkbox"
                  class="custom-control-input"
                  id="customCheck1"
                  value="femenino"
                  v-model="filterBy.sexo"
                />
                <label
                  class="custom-control-label label-margin"
                  for="customCheck1"
                  >Femenino</label
                >
              </div>
            </div> -->
            <div class="mb-3">
              <h5>Canal de Comunicación</h5>
              <div class="custom-control custom-checkbox">
                <input
                  type="checkbox"
                  class="custom-control-input"
                  id="voz"
                  value="voz"
                  v-model="filterBy.canalComunicacion"
                />
                <label class="custom-control-label label-margin" for="voz"
                  >Voz</label
                >
              </div>
              <div class="custom-control custom-checkbox">
                <input
                  type="checkbox"
                  class="custom-control-input"
                  id="video"
                  value="video"
                  v-model="filterBy.canalComunicacion"
                />
                <label class="custom-control-label label-margin" for="video"
                  >Video</label
                >
              </div>
              <div class="custom-control custom-checkbox">
                <input
                  type="checkbox"
                  class="custom-control-input"
                  id="chat"
                  value="chat"
                  v-model="filterBy.canalComunicacion"
                />
                <label class="custom-control-label label-margin" for="chat"
                  >Chat</label
                >
              </div>
            </div>
            <div class="mb-3">
              <h5>Idioma</h5>
              <div
                class="custom-control custom-checkbox"
                v-for="language in languages"
                :key="language.idioma"
              >
                <input
                  type="checkbox"
                  class="custom-control-input"
                  :id="'language' + language.idioma"
                  :value="language.idioma"
                  v-model="filterBy.languages"
                />
                <label
                  class="custom-control-label label-margin"
                  :for="'language' + language.idioma"
                  >{{ language.idioma }}</label
                >
              </div>
            </div>
            <div class="text-end" style="position: sticky; bottom: 0">
              <button class="btn btn-filtro" @click="filterResults">
                Aplicar
              </button>
            </div>
          </b-dropdown-group>
        </b-dropdown>
        <!-- fin dropdown filtros-->

        <!-- dropdown ordenar por -->
        <!-- <b-dropdown no-caret right>
          <template #button-content>
            <button class="btn btn-filter">Ordenar Por</button>
          </template>
          <b-dropdown-group
            id="dropdown-group-1"
            style=""
            class="content-order"
          >

            <div class="mb-3" >
             <select class="form-select" v-model="selected" name="" id="">
              <div>
                <input
                  class="form-check-input"
                  type="radio"
                  name="order"
                  id=""
                  value="name"
                />
                <label
                  class="custom-control-label label-margin"
                  for="flexRadioDefault1"
                >
                  Nombre: A-Z
                </label>
              </div>

              <div>
                <input
                  class="form-check-input"
                  type="radio"
                  name="order"
                  id=""
                  value="lectures"
                />
                <label
                  class="custom-control-label label-margin"
                  for="flexRadioDefault1"
                >
                  Cantidad de Lecturas
                </label>
              </div>
              <div>
                <input
                  class="form-check-input"
                  type="radio"
                  name="order"
                  id=""
                   value="priceLowest"
                />
                <label
                  class="custom-control-label label-margin"
                  for="flexRadioDefault1"
                >
                  Precio: de menor a mayor
                </label>
              </div>
              <div>
                <input
                  class="form-check-input"
                  type="radio"
                  name="order"
                  id=""
                  value="priceHighest"
                />
                <label
                  class="custom-control-label label-margin"
                  for="flexRadioDefault1"
                >
                  Precio: de mayor a menor
                </label>
              </div>
              <div>
                <input
                  class="form-check-input"
                  type="radio"
                  name="order"
                  id=""
                  value="rating"
                />
                <label
                  class="custom-control-label label-margin"
                  for="flexRadioDefault1"
                >
                  Rating
                </label>
              </div>
              </select>
            </div>
          
          </b-dropdown-group>
        </b-dropdown> -->
        <!-- fin ordenar por -->
        <div class="filtro">
          <select class="form-select" v-model="selected" name="" id="">
            <option value="" disabled selected hidden>
              Ordenar Por
            </option>
            <option value="name">Nombre: A-Z</option>
            <option value="lectures">Cantidad de Lecturas</option>
            <option value="priceLowest">
              Precio: de menor a mayor
            </option>
            <option value="priceHighest">
              Precio: de mayor a menor
            </option>
            <option value="rating">Valoración</option>
            <option value="tipoLectura">Tipo de Lectura</option>
          </select>
        </div>
      </div>
      <Spinner v-if="isLoading" />
      <p v-if="tarotistas.length === 0 && !isLoading">
        No se han encontrado tarotistas.
      </p>
      <div class="col-12" v-if="!isLoading" >
        <div class="d-none d-md-block">
          <div class="row" id="my-table">
          <div
            class="col-lg-3 col-md-4 col-12 mt-4"
            v-for="(tarotista, index) in [...getDispo(true), ...getDispo(false)].slice(
              (currentPage - 1) * perPage,
              (currentPage - 1) * perPage + perPage
            )"
            :key="index"
          >
            <div class="card card-tarotista shadow-sm">
              <div class="card-header"  >
                <div class="dstco" v-if="tarotista.descuento">
                  <span>Dstco</span>
                  <p>-{{ tarotista.descuento }}%</p>
                </div>
              </div>
              <div class="card-body">
                <router-link
                  :to="
                    '/ficha-tarotista?id_tarotista=' + tarotista.tarotReaderId
                  "
                  style="
                      display: inline-block;
                      text-decoration: none;
                      color: #333333;
                  "
                >
                  <div class="row">
                    <div class="col-12 col-img">
                      <div class="image">
                        <img :src="tarotista.avatar" alt="" />
                        <i
                          v-if="(statust ? (statust['t'+tarotista.userId] ? (statust['t'+tarotista.userId].status == 'Disponible') : false): false) || tarotista.tarotReaderType === 'agendada'"
                          class="
                              fas
                              fa-circle
                              tarotista-disponible
                          "
                        ></i>
                        <i
                          v-if="(!(statust ? (statust['t'+tarotista.userId] ? (statust['t'+tarotista.userId].status == 'Disponible') : false): false)  && !(statust ? (statust['t'+tarotista.userId] ? (statust['t'+tarotista.userId].status == 'Ocupado'):false) : false)) && tarotista.tarotReaderType !== 'agendada'"
                          class="
                              fas
                              fa-circle
                              tarotista-no-disponible
                          "
                        ></i>
                        <i
                          :key="tarotista.userId + '/' + seed"
                          v-if="(statust ? (statust['t'+tarotista.userId] ? (statust['t'+tarotista.userId].status == 'Ocupado'):false) : false) && tarotista.tarotReaderType !== 'agendada'"
                          class="
                              fas
                              fa-circle
                              tarotista-occupied
                          "
                        ></i>
                      </div>
                      <p
                        v-if="statust ? (statust['t'+tarotista.userId] ? (statust['t'+tarotista.userId].status == 'Disponible') : false): false"
                        class="tarotista-estado tarotista-disponible"
                      >
                        Disponible
                      </p>
                      <p
                        v-if="!(statust ? (statust['t'+tarotista.userId] ? (statust['t'+tarotista.userId].status == 'Disponible') : false): false)  && !(statust ? (statust['t'+tarotista.userId] ? (statust['t'+tarotista.userId].status == 'Ocupado'):false) : false)"
                        class="tarotista-estado tarotista-no-disponible"
                      >
                        {{tarotista.tarotReaderType === 'agendada' ? 'Disponible' : 'No Disponible'}}
                      </p>
                      <p
                        :key="tarotista.userId + '/' + seed"
                        v-if="statust ? (statust['t'+tarotista.userId] ? (statust['t'+tarotista.userId].status == 'Ocupado'):false) : false"
                        class="tarotista-estado tarotista-occupied"
                      >
                        Ocupado
                      </p>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-12 text-center">
                      <div class="text-name">
                        <h5>
                          {{ tarotista.tarotReaderName }}
                        </h5>
                         <span v-if="tarotista.specialties" class="subtitle">{{
                    tarotista.specialties[0].specialtyName
                  }}</span>
                  <span v-else class="subtitle" style="opacity: 0">lorem</span>
                        <!-- <p>Astrologer</p> -->
                      </div>
                    </div>
                    <div class="col-12">
                      <div class="col-puntaje">
                        <div class="score text-center">
                          <!-- <div class="star">
                            <i
                              v-for="i in tarotista.rating"
                              :key="i"
                              class="fas fa-star"
                            ></i>
                            <i
                              v-for="i in 5 - tarotista.rating"
                              :key="i * 77"
                              class="
                                                                fas
                                                                fa-star
                                                                grey-star
                                                            "
                            ></i>
                            <span class="puntaje"
                              >{{ tarotista.rating }}.0</span
                            >
                            <span class="personas">(154)</span>
                          </div> -->
                          <Star :value="tarotista.valoracion_promedio" :shownumer="true" :size="1" :showperson="true" :person="tarotista.cantidad_valoracion"/>

                        </div>
                      </div>
                      <div class="lecturas">
                        <i class="far fa-eye"></i>
                        <span class="num-lectura"
                          >{{ tarotista.amountOfReadings }}
                        </span>
                        <span> Lecturas</span>
                      </div>
                      <div class="tipo">
                        <i v-if="!(tarotista.tarotReaderType === 'agendada')" class="fas fa-bolt"></i>
                        <i v-if="tarotista.tarotReaderType === 'agendada'" class="fas fa-calendar-alt"></i>
                        <span>Lectura {{ (tarotista.tarotReaderType === "agendada") ? "Agendada" : "Inmediata" }} </span>
                      </div>
                    </div>
                  </div>
                </router-link>
                <div class="text-center mt-4">
                  <p class="titulo-btn"
                     @click="disablePFuntion(tarotista ,'video') ? goToPay(tarotista.tarotReaderId, tarotista.tarotReaderType, 'Video') : null"
                     :class="{ 'clicable': disablePFuntion(tarotista ,'video') }">Comprar</p>
                  <div v-if="tarotista.chatPrice !== null">
                    <button
                        :disabled="
                      disableButtonFuntion(tarotista, 'chat')
                      "
                      type="button"
                      class="btn btn-green btn-block"
                      @click="
                        goToPay(
                          tarotista.tarotReaderId,
                          tarotista.tarotReaderType,
                          'Chat'
                        )
                      "
                    >
                      <i class="fas fa-comments icon-btn"></i>
                      <span>Chat $ {{ numberWithCommas(tarotista.chatPrice)}}/min</span>
                    </button>
                    <!-- <label class="descuento" v-if="tarotista.descuento"
                      >$400/min</label
                    > -->
                  </div>
                  <div v-if="tarotista.voicePrice !== null">
                    <button
                      :disabled="
                        disableButtonFuntion(tarotista, 'voice')
                      "
                      type="button"
                      class="btn btn-green btn-block"
                      @click="
                        goToPay(
                          tarotista.tarotReaderId,
                          tarotista.tarotReaderType,
                          'Voz'
                        )
                      "
                    >
                      <i
                        class="
                                                    fas
                                                    fa-phone-alt
                                                    icon-btn
                                                "
                      ></i>
                      <span>Audio ${{ numberWithCommas(tarotista.voicePrice) }}/min</span>
                    </button>
                    <!-- <label class="descuento" v-if="tarotista.descuento"
                      >$400/min</label
                    > -->
                  </div>
                  <div v-if="tarotista.videoPrice !== null">
                    <button
                      :disabled="
                       disableButtonFuntion(tarotista ,'video')
                      "
                      type="button"
                      class="btn btn-green btn-block"
                      @click="
                        goToPay(
                          tarotista.tarotReaderId,
                          tarotista.tarotReaderType,
                          'Video'
                        )
                      "
                    >
                      <i class="fas fa-video icon-btn"></i>
                      <span>Video ${{ numberWithCommas(tarotista.videoPrice) }}/min</span>
                    </button>
                    <!-- <label class="descuento" v-if="tarotista.descuento"
                      >$400/min</label
                    > -->
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
          <div class="row">
            <div class="col-12 text-center my-5 m-auto">
              <b-pagination
                  v-model="currentPage"
                  :total-rows="tarotistas.length"
                  :per-page="perPage"
                  align="center"
              ></b-pagination>
            </div>
          </div>
        </div>
        <div class="row d-block d-md-none my-4">
          <Spinner v-if="isLoading" />
          <div class="col-sm-12">
            <b-carousel
                id="carousel-tarotistas-catalogo"
                fade
                :interval="3000"
                controls
            >
              <b-carousel-slide
                  v-for="tarotista in tarotistas"
                  :key="tarotista.userId + '/' + tarotista.hashCode"
              >
                <div class="card card-tarotista shadow-sm">
                  <div class="card-header"  >
                    <div class="dstco" v-if="tarotista.descuento">
                      <span>Dstco</span>
                      <p>-{{ tarotista.descuento }}%</p>
                    </div>
                  </div>
                  <div class="card-body">
                    <router-link
                        :to="
                    '/ficha-tarotista?id_tarotista=' + tarotista.tarotReaderId
                  "
                        style="
                      display: inline-block;
                      text-decoration: none;
                      color: #333333;
                  "
                    >
                      <div class="row">
                        <div class="col-12 col-img">
                          <div class="image">
                            <img :src="tarotista.avatar" alt="" />
                            <i
                                v-if="(statust ? (statust['t'+tarotista.userId] ? (statust['t'+tarotista.userId].status == 'Disponible') : false): false) || tarotista.tarotReaderType === 'agendada'"
                                class="
                              fas
                              fa-circle
                              tarotista-disponible
                          "
                            ></i>
                            <i
                                v-if="(!(statust ? (statust['t'+tarotista.userId] ? (statust['t'+tarotista.userId].status == 'Disponible') : false): false)  && !(statust ? (statust['t'+tarotista.userId] ? (statust['t'+tarotista.userId].status == 'Ocupado'):false) : false)) && tarotista.tarotReaderType !== 'agendada'"
                                class="
                              fas
                              fa-circle
                              tarotista-no-disponible
                          "
                            ></i>
                            <i
                                :key="tarotista.userId + '/' + seed"
                                v-if="(statust ? (statust['t'+tarotista.userId] ? (statust['t'+tarotista.userId].status == 'Ocupado'):false) : false) && tarotista.tarotReaderType !== 'agendada'"
                                class="
                              fas
                              fa-circle
                              tarotista-occupied
                          "
                            ></i>
                          </div>
                          <p
                              v-if="statust ? (statust['t'+tarotista.userId] ? (statust['t'+tarotista.userId].status == 'Disponible') : false): false"
                              class="tarotista-estado tarotista-disponible"
                          >
                            Disponible
                          </p>
                          <p
                              v-if="!(statust ? (statust['t'+tarotista.userId] ? (statust['t'+tarotista.userId].status == 'Disponible') : false): false)  && !(statust ? (statust['t'+tarotista.userId] ? (statust['t'+tarotista.userId].status == 'Ocupado'):false) : false)"
                              class="tarotista-estado tarotista-no-disponible"
                          >
                            {{tarotista.tarotReaderType === 'agendada' ? 'Disponible' : 'No Disponible'}}
                          </p>
                          <p
                              :key="tarotista.userId + '/' + seed"
                              v-if="statust ? (statust['t'+tarotista.userId] ? (statust['t'+tarotista.userId].status == 'Ocupado'):false) : false"
                              class="tarotista-estado tarotista-occupied"
                          >
                            Ocupado
                          </p>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-12 text-center">
                          <div class="text-name">
                            <h5>
                              {{ tarotista.tarotReaderName }}
                            </h5>
                            <span v-if="tarotista.specialties" class="subtitle">{{
                                tarotista.specialties[0].specialtyName
                              }}</span>
                            <span v-else class="subtitle" style="opacity: 0">lorem</span>
                            <!-- <p>Astrologer</p> -->
                          </div>
                        </div>
                        <div class="col-12">
                          <div class="col-puntaje">
                            <div class="score text-center">
                              <!-- <div class="star">
                                <i
                                  v-for="i in tarotista.rating"
                                  :key="i"
                                  class="fas fa-star"
                                ></i>
                                <i
                                  v-for="i in 5 - tarotista.rating"
                                  :key="i * 77"
                                  class="
                                                                    fas
                                                                    fa-star
                                                                    grey-star
                                                                "
                                ></i>
                                <span class="puntaje"
                                  >{{ tarotista.rating }}.0</span
                                >
                                <span class="personas">(154)</span>
                              </div> -->
                              <Star :value="tarotista.valoracion_promedio" :shownumer="true" :size="1" :showperson="true" :person="tarotista.cantidad_valoracion"/>

                            </div>
                          </div>
                          <div class="lecturas">
                            <i class="far fa-eye"></i>
                            <span class="num-lectura"
                            >{{ tarotista.amountOfReadings }}
                        </span>
                            <span> Lecturas</span>
                          </div>
                          <div class="tipo">
                            <i v-if="!(tarotista.tarotReaderType === 'agendada')" class="fas fa-bolt"></i>
                            <i v-if="tarotista.tarotReaderType === 'agendada'" class="fas fa-calendar-alt"></i>
                            <span>Lectura {{ (tarotista.tarotReaderType === "agendada") ? "Agendada" : "Inmediata" }} </span>
                          </div>
                        </div>
                      </div>
                    </router-link>
                    <div class="text-center mt-4">
                      <p class="titulo-btn"
                         @click="disablePFuntion(tarotista ,'video') ? goToPay(tarotista.tarotReaderId, tarotista.tarotReaderType, 'Video') : null"
                         :class="{ 'clicable': disablePFuntion(tarotista ,'video') }">Comprar</p>
                      <div v-if="tarotista.chatPrice !== null">
                        <button
                            :disabled="
                      disableButtonFuntion(tarotista, 'chat')
                      "
                            type="button"
                            class="btn btn-green btn-block"
                            @click="
                        goToPay(
                          tarotista.tarotReaderId,
                          tarotista.tarotReaderType,
                          'Chat'
                        )
                      "
                        >
                          <i class="fas fa-comments icon-btn"></i>
                          <span>Chat $ {{ numberWithCommas(tarotista.chatPrice)}}/min</span>
                        </button>
                        <!-- <label class="descuento" v-if="tarotista.descuento"
                          >$400/min</label
                        > -->
                      </div>
                      <div v-if="tarotista.voicePrice !== null">
                        <button
                            :disabled="
                        disableButtonFuntion(tarotista, 'voice')
                      "
                            type="button"
                            class="btn btn-green btn-block"
                            @click="
                        goToPay(
                          tarotista.tarotReaderId,
                          tarotista.tarotReaderType,
                          'Voz'
                        )
                      "
                        >
                          <i
                              class="
                                                    fas
                                                    fa-phone-alt
                                                    icon-btn
                                                "
                          ></i>
                          <span>Audio ${{ numberWithCommas(tarotista.voicePrice) }}/min</span>
                        </button>
                        <!-- <label class="descuento" v-if="tarotista.descuento"
                          >$400/min</label
                        > -->
                      </div>
                      <div v-if="tarotista.videoPrice !== null">
                        <button
                            :disabled="
                       disableButtonFuntion(tarotista ,'video')
                      "
                            type="button"
                            class="btn btn-green btn-block"
                            @click="
                        goToPay(
                          tarotista.tarotReaderId,
                          tarotista.tarotReaderType,
                          'Video'
                        )
                      "
                        >
                          <i class="fas fa-video icon-btn"></i>
                          <span>Video ${{ numberWithCommas(tarotista.videoPrice) }}/min</span>
                        </button>
                        <!-- <label class="descuento" v-if="tarotista.descuento"
                          >$400/min</label
                        > -->
                      </div>
                    </div>
                  </div>
                </div>
              </b-carousel-slide>
            </b-carousel>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axiosClient from '../../config/axiosClient';
import Spinner from '../loadingspinner/Spinner.vue';
import { enableLogs } from '../../config';
import Star from '../util/Stars.vue';
export default {
  name: 'Catalogo',
  components: {
    Spinner,
    Star,
  },
  beforeRouteUpdate(to, from, next) {
    this.loadFilters();
    next();
  },
  watch: {
    selected: function() {
      this.sortBy();
    },
    filterBy: function() {
      if (enableLogs) console.log(this.filterBy);
    },
    pageReady: function(pr) {
      if (pr) {
        if (enableLogs) console.log('Page ready, fetching tarot reader states');
        this.handleTarotReaderStates();
      }
    },
    socket: function() {
      this.socket.on('statusTarotReaderChange', ()=>{
        setTimeout(() => {
          this.statust = {...this.$store.state.statust}
        }, 100);
      })
    },
  },

  data: () => {
    return {
      tarotistas: [],
      selected: '',
      scopes: [],
      specialties: [],
      languages: [],
      filterBy: {
        scopes: [],
        specialties: [],
        canalComunicacion: [],
        sexo: [],
        languages: [],
        tipo: [],
        disponibilidad: [],
      },
      filterModal: false,
      isLoading: false,
      queryParams: false,

      /*paginacion*/
      perPage: 12,
      currentPage: 1,
      
      statust: {},

      tarotReaderId: null,
      type: null,
      channel: null,
    };
  },

  mounted(){
    this.statust = this.$store.state.statust;
    if (this.socket){
      if(! this.socket._callbacks.$statusTarotReaderChange.length < 2){
        this.pageReady = { ...this.pageReady, socketReady: true };
        this.socket.on('statusTarotReaderChange', ()=>{
          setTimeout(() => {
            this.statust = {...this.$store.state.statust}
          }, 100);
        })
      }
    }
  },

  created() {
    this.getAllSpecialties();
    this.getAllScopes();
    this.getAllLanguages();
    this.checkQueryParamsAndFilter();
  },

  computed: {
    pageReady: function() {
      return this.tarotistas.length > 0 && this.$store.state.socket != null;
    },
    rows() {
      return this.tarotistas.length;
    },
    socket() {
      return this.$store.state.socket;
    },
  },

  methods: {
    async getActivo(id){
      const valorActivo = await axiosClient.get(`/tarot-calendly/get-activo/${id}`);
      return valorActivo.data.estado;
    },
    async loadFilters() {
      const savedFilters = sessionStorage.getItem('filters');
      if (savedFilters) {
        const filters = JSON.parse(savedFilters);
        Object.keys(filters).forEach(key => {
          this.$set(this.filterBy, key, filters[key]);
        });
        await this.filterResults();
      }
    },
    disablePFuntion(tarotista, tipo) {
      // Obtener el estado del tarotista
      const estado = this.statust['t' + tarotista.userId];
      // Si el tarotista es de tipo 'agendada'
      if (tarotista.tarotReaderType === 'agendada') {
        // Si el estado está definido y el canal especificado está disponible
        return (tarotista.videoPrice);
      }

      // Si el tarotista no es de tipo 'agendada'
      return (estado && estado.status === 'Disponible' && estado.chanels[tipo]);

       // Botón deshabilitado
    },
    disableButtonFuntion(tarotista, tipo){
      //let estado = await this.getActivo(tarotista.tarotReaderId);
      if((tarotista.tarotReaderType === 'agendada') && (this.statust['t'+tarotista.userId] ? this.statust['t'+tarotista.userId].chanels[tipo] : false)){
        return false;
      }else if (tarotista.tarotReaderType === 'agendada' && !this.statust['t'+tarotista.userId]) {
        return false;
      }else if (tarotista.tarotReaderType === 'agendada' && (this.statust['t'+tarotista.userId] ? (this.statust['t'+tarotista.userId].status === 'Ocupado') : false)) {
        return false;
      }else if ((this.statust['t'+tarotista.userId] ? (this.statust['t'+tarotista.userId].status === 'Disponible') : false) &&
          (this.statust['t'+tarotista.userId] ? this.statust['t'+tarotista.userId].chanels[tipo] : false)) {
        return false;
      }else {
        return true;
      }
    },
    conocerDisponibilidad(tarotista){
      //let estado = await this.getActivo(tarotista.tarotReaderId);
      if(tarotista.tarotReaderType === 'agendada'){
        return true;
      }else if ((this.statust['t'+tarotista.userId] ? (this.statust['t'+tarotista.userId].status === 'Disponible') : false)) {
        return true;
      }else {
        return false;
      }
    },
    numberWithCommas(x) {
        return x.toLocaleString('es-AR');
      },
    onlinesort() {
      //this.tarotistas = this.tarotistas.sort((a,b) => (a.state == 'AVAILABLE' && b.state != 'AVAILABLE') ? 1 : ((a.state != 'AVAILABLE' && b.state == 'AVAILABLE') ? -1 : 0))
      const auxOnlineArray = [];
      const auxOfflineArray = [];

      this.tarotistas.forEach(tr => {
        if (tr.state == 'AVAILABLE') auxOnlineArray.push(tr);
        else auxOfflineArray.push(tr);
      });

      this.tarotistas = [...auxOnlineArray, ...auxOfflineArray];
    },
    parseStatus(status) {
      switch (status) {
        case 'Disponible':
          return 'AVAILABLE';
        default:
          return status;
      }
    },
    checkQueryParamsAndFilter() {
      let scopeId = this.$route.query.scopeId;

      if (scopeId === undefined) {
        const savedFilters = sessionStorage.getItem('filters');
        if (!savedFilters) {
          this.getTarotistas();
        }
      } else {
        this.filterBy.scopes.push(scopeId);
        this.filterResults();
      }
    },
    sortTarotistasOnMount(tarotistas) {
      let sortNewest = this.$route.query.ranking;
      let sortRating = this.$route.query.valoracion_promedio;
      if (sortNewest !== undefined) {
        if (enableLogs) console.log('ordenando de mas nuevo a mas antiguo');
        tarotistas.sort((a, b) =>
          a.tarotReaderId > b.tarotReaderId
            ? -1
            : b.tarotReaderId > a.tarotReaderId
            ? 1
            : 0
        );
      } else if (sortRating != undefined) {
        if (enableLogs) console.log('ordenando por rating');
        tarotistas.sort((a, b) =>
          a.valoracion_promedio > b.valoracion_promedio ? -1 : b.valoracion_promedio > a.valoracion_promedio ? 1 : 0
        );
      }else {
        if (enableLogs) console.log('ordenando por rating');
        tarotistas.sort((a, b) => {
          if (a.tarotReaderType === 'agendada' && b.tarotReaderType !== 'agendada') {
            return -1; // Coloca 'agendada' antes que cualquier otro valor
          } else if (a.tarotReaderType !== 'agendada' && b.tarotReaderType === 'agendada') {
            return 1; // Coloca 'agendada' después de cualquier otro valor
          } else {
            return 0; // Mantén el orden existente entre elementos que no son 'agendada'
          }
        });
      }
      //Filtro de Disponibilidad
      let contieneDisponibles = this.filterBy.disponibilidad.includes("disponibles");
      let contieneNoDisponible = this.filterBy.disponibilidad.includes("noDisponible");
      if (this.filterBy.disponibilidad.length === 0 || (contieneDisponibles && contieneNoDisponible)){
        this.tarotistas = tarotistas;
      }else {
        if (contieneDisponibles){
          this.tarotistas = tarotistas.filter(tarotista => this.conocerDisponibilidad(tarotista));
        }else {
          this.tarotistas = tarotistas.filter(tarotista => !this.conocerDisponibilidad(tarotista));
        }
      }
      //Fin filtro disponibilidad
    },
    goToPay(tarotReaderId, type, channel) {
      this.tarotReaderId = tarotReaderId;
      this.type = type;
      this.channel = channel;
     /* if (channel === 'Voz') {
        this.$bvModal.show('modalLlamadas');
      }else {
        this.$bvModal.show('modalVideosChat');
      }*/
      this.$router.push(
          `/compra?tarotista=${tarotReaderId}&tipo=${type}&canal=${channel}`
      );
    },
    getDispo(disp){
      var dispo = [];
      var nodispo = [];
      for (let i = 0; i < this.tarotistas.length; i++) {
        let tarotista = this.tarotistas[i];
        if(this.statust ? (this.statust['t'+tarotista.userId] ? (this.statust['t'+tarotista.userId].status == 'Disponible') : false): false){
          dispo.push(tarotista)
        }else{
          nodispo.push(tarotista)
        }
      }
      if(enableLogs) console.log(this.tarotistas)
      if(enableLogs) console.log(dispo)
      if(enableLogs) console.log(nodispo)
      return disp ? dispo : nodispo;
    },
    handleTarotReaderStates() {
      const tarotistas = this.tarotistas;
      const socket = this.$store.state.socket;

      const onlinesort = this.onlinesort;

      const updateTarotista = (id, state) => {
        const index = tarotistas.findIndex(tr => tr.userId == id);
        this.$set(this.tarotistas[index], 'state', state);
      };

      socket.emit('get-all-status', states => {
        if (enableLogs) console.log('Fetching tarot reader states:');
        this.tarotistas.forEach(tr => {
          updateTarotista(tr.userId, states[tr.userId]);
        });
        onlinesort();
        if (enableLogs) console.log('Finished setting states');
      });

      socket.removeAllListeners('state-update');

      socket.on('state-update', (id, state) => {
        if (enableLogs) console.log(`New state detected: ${id} ${state}`);
        updateTarotista(id, state);
      });
    },
    async getTarotistas() {
      this.isLoading = true;
      try {
        const response = await axiosClient.get(
          'tarot-reader/get-tarot-readers'
        );
        if (enableLogs) console.log('fetched tarot readers');
        if (enableLogs) console.log(response.data.tarotistasValidos);
        this.sortTarotistasOnMount(response.data.tarotistasValidos);
        //this.tarotistas = response.data.tarotistasValidos;

        this.isLoading = false;
      } catch (error) {
        if (enableLogs) console.log(error);
        if (enableLogs) console.log('could not fetch tarot readers');
        this.isLoading = false;
      }
    },
    showFilterModal() {
      this.filterModal = !this.filterModal;
    },
    sortBy() {
      if (enableLogs) console.log(this.selected);

      if (this.selected !== '') {
        // Primero aplicamos la ordenación por disponibilidad
        this.onlinesort();

        // Luego aplicamos la ordenación adicional según la selección del usuario
        switch (this.selected) {
          case 'name':
            if (enableLogs) console.log('por nombre');
            this.tarotistas = this.tarotistas.sort((a, b) =>
                a.tarotReaderName.localeCompare(b.tarotReaderName)
            );
            break;
          case 'tipoLectura':
            if (enableLogs) console.log('por tipoLectura');
            this.tarotistas = this.tarotistas.sort((a, b) =>
                a.tarotReaderType.localeCompare(b.tarotReaderType)
            );
            break;
          case 'lectures':
            if (enableLogs) console.log('por lecturas');
            this.tarotistas = this.tarotistas.sort((a, b) =>
                b.amountOfReadings - a.amountOfReadings
            );
            break;
          case 'specialty':
            if (enableLogs) console.log('por especialidad');
            this.tarotistas = this.tarotistas.sort((a, b) =>
                a.especialidad.localeCompare(b.especialidad)
            );
            break;
          case 'rating':
            if (enableLogs) console.log('por rating');
            this.tarotistas = this.tarotistas.sort((a, b) =>
                b.valoracion_promedio - a.valoracion_promedio
            );
            break;
          case 'priceLowest':
            if (enableLogs) console.log('precio menor a mayor');
            this.tarotistas = this.tarotistas.sort((a, b) =>
                a.videoPrice - b.videoPrice
            );
            break;
          case 'priceHighest':
            if (enableLogs) console.log('precio mayor a menor');
            this.tarotistas = this.tarotistas.sort((a, b) =>
                b.videoPrice - a.videoPrice
            );
            break;
        }
      }
    },
    async getAllSpecialties() {
      try {
        const response = await axiosClient.get(
          '/specialties/get-all-specialties'
        );
        if (enableLogs) console.log(response.data);
        this.specialties = response.data.specialties;
      } catch (error) {
        if (enableLogs) console.log('could not fetch specialties');
        if (enableLogs) console.log(error);
      }
    },
    async getAllScopes() {
      try {
        const response = await axiosClient.get('/scopes/get-all-scopes');
        if (enableLogs) console.log(response.data);
        this.scopes = response.data.scopes;
      } catch (error) {
        if (enableLogs) console.log('could not fetch scopes');
        if (enableLogs) console.log(error);
      }
    },
    async getAllLanguages() {
      try {
        const response = await axiosClient.get('/languages/get-all-languages');
        if (enableLogs) console.log('fetched languages successfully');
        this.languages = response.data.languages;
      } catch (error) {
        if (enableLogs) console.log('could not fetch languages');
        if (enableLogs) console.log(error);
      }
    },
    async filterResults() {
      if (enableLogs) console.log('filtering results...');
      this.showFilterModal = false;
      this.isLoading = true;
      this.tarotistas = [];
      if (enableLogs) console.log(this.filterBy.languages);

      try {
        if (
          this.filterBy.scopes.length == 0 &&
          this.filterBy.specialties.length == 0 &&
          this.filterBy.languages.length == 0 &&
          this.filterBy.canalComunicacion.length === 0 &&
          this.filterBy.sexo.length === 0 &&
          this.filterBy.tipo.length === 0
        ) {
          this.getTarotistas();
          return;
        }

        const response = await axiosClient.post(
          '/tarot-reader/get-tarot-readers/filtered',
          {
            filters: this.filterBy,
          }
        );

        if (enableLogs) console.log('filtered results');

        if (enableLogs) console.log(response.data.tarotistasValidos);

        let auxArray = [];

        let filters = {
          chat: this.filterBy.canalComunicacion.includes('chat'),
          video: this.filterBy.canalComunicacion.includes('video'),
          voz: this.filterBy.canalComunicacion.includes('voz'),
          agendada: this.filterBy.tipo.includes('agendada'),
          instantanea: this.filterBy.tipo.includes('instantanea'),
        };

        if (!filters.chat && !filters.video && !filters.voz && !filters.agendada && !filters.instantanea) {
          auxArray = response.data.tarotistasValidos;
        }else {
          response.data.tarotistasValidos.forEach(tarotista => {
            let cumple = false;
            if (filters.chat && tarotista.precioChat !== null) {
              cumple = true;
            }
            if (filters.voz && tarotista.precioVoz !== null && !cumple) {
              cumple = true;
            }
            if (filters.video && tarotista.precioVideo !== null && !cumple) {
              cumple = true;
            }
            if (filters.agendada && tarotista.tarotReaderType === "agendada") {
              cumple = true;
            }
            if (filters.instantanea && tarotista.tarotReaderType === "instantanea" && !cumple) {
              cumple = true;
            }
            if (cumple) {
              auxArray.push(tarotista);
            }
          });
        }

        // Verificar si hay filtros activos para scopes o specialties
        const hasScopeFilter = this.filterBy.scopes.length > 0;
        const hasSpecialtyFilter = this.filterBy.specialties.length > 0;


        // Filtrar tarotistas basado en los filtros activos
        auxArray = auxArray.filter(tarotista => {
          let scopesMatch = true; // Asume true si no hay filtro de scope
          let specialtiesMatch = true; // Asume true si no hay filtro de specialty

          // Si hay filtro de scopes, verifica que todos los scopeId requeridos estén en los del tarotista
          if (hasScopeFilter) {
            const targetScopesSet = new Set(this.filterBy.scopes);
            const tarotistaScopesSet = new Set(tarotista.scopes.map(scope => scope.scopeId));
            scopesMatch = [...targetScopesSet].every(scopeId => tarotistaScopesSet.has(scopeId));
          }

          // Si hay filtro de specialties, verifica que todas las specialties requeridas estén en las del tarotista
          if (hasSpecialtyFilter) {
            const targetSpecialtiesSet = new Set(this.filterBy.specialties);
            const tarotistaSpecialtiesSet = new Set(tarotista.specialties.map(scope => scope.specialtyId));
            specialtiesMatch = [...targetSpecialtiesSet].every(specialtyId => tarotistaSpecialtiesSet.has(specialtyId));
          }

          // Retorna true solo si ambos filtros coinciden (o no están activos)
          return scopesMatch && specialtiesMatch;
        });

        this.tarotistas = auxArray;
        //sessionStorage.setItem('filters', JSON.stringify(this.filterBy));
        this.isLoading = false;

        if (enableLogs) console.log(this.tarotistas);
      } catch (error) {
        if (enableLogs) console.log('could not filter results');
        if (enableLogs) console.log(error);
        this.isLoading = false;
      }
    },
    descriptionReduced: function(tarotista) {
      if (tarotista)
        return tarotista.tarotReaderDescription.substring(0, 80) + '...';
      else return '';
    },
  },
};
</script>

<style scoped>
.clicable {
  cursor: pointer; /* Cambia el cursor a una mano indicando que es clicable */
  transition: background-color 0.3s ease; /* Añadir una transición suave para cambios de color */
}

/* Cambiar el estilo del elemento al pasar el mouse */
.clicable:hover {
  background-color: #f0f0f0; /* Cambiar el color de fondo al pasar el mouse */
  color: #007BFF; /* Cambiar el color del texto al pasar el mouse */
}
.filtro {
  width: 121px;
  border: 1px solid #501682;
  border-radius: 0.25rem;
  font-family: maven-semibold;
  float: right;
  margin-left: 15px;
}
.filtro select {
  color: #501682;
  border: 1px solid transparent;
  font-size: 11px;
}

.btn-filtro {
  border: 1px solid #501682;
  background-color: #fff;
  border-radius: 0.25rem;
  font-family: maven-bold;
  margin-left: 15px;
  color: #501682;
  font-size: 12px;
}
.card {
  border-radius: 33px;
  min-height: 100%;
  max-width: 226px;
  margin: auto;
  border: none;
  background: #f6f6f6;
  font-family: maven-regular;
}
.card-header {
  background: #04d9b2;
  border-radius: 33px 33px 0px 0px;
  border-bottom: none;
  height: 80px;
}
.card-body {
  padding: 30px 20px;
  position: relative;
  padding-top: 70px;
}
.col-img {
  width: 100%;
  padding: 0;
  position: absolute;
  left: 0;
  top: -45px;
  text-align: center;
}
.col-img i {
  position: absolute;
  right: 0px;
  top: 8px;
  border: 2px solid #fff;
  border-radius: 50%;
  font-size: 14px;
}
.tarotista-disponible {
  color: #04D925;
}

.tarotista-no-disponible {
  color: grey;
}
.tarotista-occupied {
  color: #ffc30b;
}
.image {
  position: relative;
  width: 90px;
  margin: 0 auto;
}
.image img {
  width: 90px;
  height: 90px;
  border-radius: 50%;
  object-fit: cover;
  background: #fff;
}
.dstco {
  border-radius: 0 33px;
  position: absolute;
  background: #f6f6f6;
  padding: 8px 10px 8px;
  top: 3px;
  right: 4px;
  font-size: 10px;
  color: #501682;
  font-family: maven-bold;
  text-align: center;
}
.dstco p {
  font-size: 15px;
  margin-bottom: 0;
}
.text-name {
  color: #501682;
  font-family: maven-bold;
  margin-top: 10px;
}
.text-name h5 {
  font-weight: bold;
  font-size: 22px;
  margin-bottom: 0px;
}
.text-name p {
  font-size: 15px;
  margin-bottom: 0;
  font-family: maven-regular;
}
.text span {
  font-size: 11px;
}
.text span i {
  font-size: 12px;
  color: #04d9b2;
  margin-left: 8px;
}
.col-puntaje {
  margin-top: 3px;
  color: #979797;
}
.puntaje {
  font-family: maven-bold;
  margin-top: 1px;
  font-size: 12px;
  margin-left: 5px;
}
.personas {
  font-size: 12px;
  margin-left: 5px;
}
.star {
  font-size: 10px;
  margin-right: 6px;
  margin-top: 2px;
}
.star i {
  color: #ffc30b;
}
.grey-star {
  color: #979797 !important;
}
.lecturas {
  margin-top: 1px;
  font-size: 12px;
  color: #979797;
  text-align: center;
  font-family: maven-regular;
}

.lecturas i {
  margin-right: 5px;
}
.lecturas .num-lectura {
  font-family: maven-bold;
}

.tipo {
  margin-top: 1px;
  font-size: 14px;
  color: #501682;
  text-align: center;
  font-family: maven-bold;
}
.tipo i {
  margin-right: 5px;
}

.btn-green {
  background: #04d9b2;
  color: #ffffff;
  margin-bottom: 10px;
  font-family: maven-semibold;
  font-size: 10px;
  text-align: center;
  border-radius: 15px;
  width: 125px;
}
.btn-green:hover {
  background: #00bf9c;

  color: #ffffff;
}
.btn-green:disabled {
  background-color: #e8e8e8;
  color: #dddddd;
}
.icon-btn {
  float: left;
  margin: auto;
  margin-top: 3px;
}
.descuento {
  color: #979797;
  font-family: maven-regular;
  margin-left: 10px;
  font-size: 11px;
  vertical-align: middle;
  margin-bottom: 10px;
  text-decoration: line-through;
}

/deep/.b-dropdown {
  float: right;
}
/deep/.dropdown-menu {
  width: 300px;
  height: 300px;
  background-color: #fff;
  border-radius: 0px;
  padding: 20px;
  overflow: auto;
  font-family: maven-semibold;
  /*   top: 3px !important; */
  border: 1px solid rgb(71, 71, 71) !important;
  color: #501682 !important;
  box-shadow: 0px 0px 4px 0px #b5b3b3;
}
/deep/.dropdown-toggle {
  color: #501682 !important;
  background-color: #fff !important;
  flex: 0;
  max-width: 300px;
  border: 1px solid #501682;
  border-radius: 0.25rem;
  font-family: maven-semibold;
  float: right;
  margin-left: 15px;
  padding: 0px;
}
/deep/.btn-secondary.active {
  color: #501682;
  background-color: #fff;
}

.custom-control-input:checked {
  background-color: #04d9b2 !important;
  border-color: #04d9b2 !important;
}

.filter-container {
  position: relative;
  background-color: #fff;
}

.label-margin {
  margin-left: 10px;
}

.btn-filter {
  color: #501682;
  width: 120px;
  font-size: 11px;
  padding-left: 0px;
  padding-right: 0px;
}
.btn-filter .arrow {
  font-size: 13px;
  color: #343a40;
  margin-left: 35px;
}

/*paginacion*/
/deep/ .pagination {
  font-family: maven-bold;
}
/deep/ .page-link {
  color: #501682;
  background-color: transparent;
  border: none;
  margin: 0 10px;
}
/deep/ .page-item.active .page-link {
  background-color: #501682;
  border-color: #501682;
  border-radius: 11px;
  padding-left: 15px;
  padding-right: 15px;
}

/* Nuevos cambios */

.tarotista-estado {
  font-size: 14px;
  text-align: center;
  text-decoration: none;
  font-family: 'maven-bold';
  margin: 0;
}
.titulo-btn {
  font-family: 'maven-bold';
  color: #501682;
}

</style>
<style>
@media (max-width:768px) {
  #carousel-tarotistas-catalogo .carousel-item {
    position: relative;
    overflow: hidden;
    height: 520px;
    border-radius: 30px;
    text-align: left;
  }
  #carousel-tarotistas-catalogo .carousel-control-prev,
  #carousel-tarotistas-catalogo .carousel-control-next {
    width: 10%;
  }
  #carousel-tarotistas-catalogo .col-12 {
    padding: 0;
  }
  #carousel-tarotistas-catalogo .carousel-caption {
    height: 100%;
    bottom: 0;
    padding: 0;
  }
  #carousel-tarotistas-catalogo .carousel-control-next-icon {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23000'%3e%3cpath d='M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
  }
  #carousel-tarotistas-catalogo .carousel-control-prev-icon {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23000'%3e%3cpath d='M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z'/%3e%3c/svg%3e");
  }
}
</style>