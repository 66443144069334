<template>
  <div class="container">
    <div class="">
      <div class="row header py-4">

        <div class="col logo">

            <img src="../../assets/img/logo.png" alt="" />

        </div>

      </div>

    </div>
  </div>
</template>

<script >
export default {
  props: {
    msg: String,
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
nav {
  padding: 6px 0;
  border-top: 1px solid rgba(255, 255, 255, 0.747);
}
.social-media{
    margin-left: 15px;
}
i {
  margin-left: 15px;
  color: #ffffff;
  font-size: 20px;
}

.logo {
  text-align: center;
  margin-bottom: 20px;
}
.logo img {
  max-width: 160px;
}


</style>
