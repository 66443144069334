<template>
  <!--Modal pago exitoso-->
  <b-modal
    :no-close-on-esc="true"
    :no-close-on-backdrop="true"
    v-model="showModal"
    id="modal-plan-ingreso-telefono"
    centered
  >
    <p class="my-2 text-modal">Ingrese su teléfono</p>

    <b-input v-model="phoneNum"></b-input>
    <template #modal-footer="{}">
      <b-button size="sm" class="col-12 btn-sm btn btn-modal" @click="submit()">
        Continuar
      </b-button>
    </template>
  </b-modal>
</template>

<script>
export default {
  watch: {
    triggerModal: function (value) {
      if (value) this.showModal = true;
    },
  },
  props: ["triggerModal"],
  data: () => {
    return {
      showModal: false,
      phoneNum: "",
    };
  },
  methods: {
    submit() {
      this.$emit("setPhoneNum", this.phoneNum);
      this.showModal = false;
    },
  },
};
</script>
<style scoped>
/deep/.close {
  background: transparent;
  border: none;
}
/deep/.modal-header {
  border-bottom: none;
}

/deep/.modal-body {
  padding: 0 50px;
}
.text-modal {
  color: #501682;
  font-family: maven-bold;
  text-align: center;
  text-transform: uppercase;
  font-size: 20px;
}
.text-saldo {
  text-align: center;
  font-family: maven-regular;
  font-size: 20px;
}
.text-saldo span {
  font-family: maven-bold;
  color: #3b5998;
}
/deep/.modal-footer {
  border-top: none;
  padding: 10px 50px 30px 50px;
}
/deep/.btn-modal {
  background-color: #04d9b2;
  color: #fff;
  font-family: maven-bold;
  text-transform: uppercase;
  border: none;
}
</style>
