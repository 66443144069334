<template>
  <div class="container my-5">
    <div class="row">
      <div class="col-12 mb-3">
        <div class="text-back" @click="goBack">
          <i class="fas fa-chevron-left"></i>
          <span>Volver</span>
        </div>
      </div>
      <div class="col-12 mb-3">
        <h5 class="general-title">Bolsas de Saldo y Ahorro</h5>
      </div>
      <div class="col-12 mb-5">
        <div class="card shadow">
          <div class="card-body">
            <div class="row justify-content-center">
              <div class="col-12">
                <div class="row">
                  <div class="col-md-6 col-12">
                    <h1>Plan de Suscripción</h1>
                  </div>
                  <div class="col-md-6 col-12">
                    <button class="btn btn-yellow" v-b-modal.modalSuscripcion>
                      Nueva suscripción
                    </button>
                    <ModalSuscripcion v-on:add-plan="planes.push($event)" />
                  </div>
                </div>
              </div>
              <div
                class="row justify-content-center mt-5"
                v-if="isLoadingPlanes"
              >
                <Spinner />
              </div>
              <div class="col-11 mt-4" v-else>
                <div class="content-table">
                  <table class="table table-striped">
                    <thead>
                      <tr>
                        <th scope="col">Nombre</th>
                        <th scope="col">Precio</th>
                        <th scope="col">Descripción</th>
                        <th scope="col" width="8%"></th>
                        <th scope="col" width="8%"></th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="plan in planes" :key="plan.id">
                        <td>{{ plan.name }}</td>
                        <td>${{ numberWithCommas(plan.monthlyPrice) }}</td>
                        <td>{{ plan.description }}</td>
                        <td>
                          <button class="btn btn-editar" disabled>
                            Editar
                          </button>
                        </td>
                        <td class="text-center">
                          <button
                            class="btn-table"
                            @click="removeSubscription(plan.id)"
                          >
                            <i class="fas fa-trash"></i>
                          </button>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-12 mb-5">
        <div class="card shadow">
          <div class="card-body">
            <div class="row justify-content-center">
              <div class="col-12">
                <div class="row">
                  <div class="col-md-6 col-12">
                    <h1>DivinoBolsas</h1>
                  </div>
                  <div class="col-md-6 col-12">
                    <button class="btn btn-yellow" v-b-modal.modalBolsas>
                      Nueva Divino Bolsa
                    </button>
                    <ModalBolsas v-on:add-bolsa="divinoBolsas.push($event)" />
                  </div>
                </div>
              </div>
              <div
                class="row justify-content-center mt-5"
                v-if="isLoadingDivinoBolsas"
              >
                <Spinner />
              </div>
              <div class="col-11 mt-4" v-else>
                <div class="content-table">
                  <table class="table table-striped">
                    <thead>
                      <tr>
                        <th scope="col" width="20%">Nombre</th>
                        <th scope="col">Precio</th>
                        <th scope="col">Bonificación</th>
                        <th scope="col" width="8%"></th>
                        <th scope="col" width="8%"></th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="divinoBolsa in divinoBolsas"
                        :key="divinoBolsa.id"
                      >
                        <td>{{ divinoBolsa.description }}</td>
                        <td>$ {{ numberWithCommas(divinoBolsa.price) }}</td>
                        <td>{{ divinoBolsa.bonification }}%</td>
                        <td>
                          <button
                            class="btn btn-editar"
                            @click="
                              triggerModal(`modalDatos-${divinoBolsa.id}`)
                            "
                          >
                            Editar
                          </button>
                        </td>
                        <td class="text-center">
                          <button
                            class="btn-table"
                            @click="deleteDivinoBolsa(divinoBolsa.id)"
                          >
                            <i class="fas fa-trash"></i>
                          </button>
                          <ModalEditBolsa
                            :triggerModal="
                              modalTriggered === `modalDatos-${divinoBolsa.id}`
                            "
                            :divinoBolsa="divinoBolsa"
                          />
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-12 mb-5">
        <div class="card shadow">
          <div class="card-body">
            <div class="row justify-content-center">
              <div class="col-12">
                <div class="row">
                  <div class="col-md-6 col-12">
                    <h1>Regalos</h1>
                  </div>
                  <div class="col-md-6 col-12">
                    <button class="btn btn-yellow" v-b-modal.modalRegalos>
                      Nuevo Regalo
                    </button>
                    <ModalRegalos />
                  </div>
                </div>
              </div>
              <div class="col-11 mt-4">
                <div class="content-table">
                  <table class="table table-striped">
                    <thead>
                      <tr>
                        <th scope="col">Nombre</th>
                        <th scope="col">Precio</th>
                        <th scope="col">Descripción</th>
                        <th scope="col" width="8%"></th>
                        <th scope="col" width="8%"></th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(tarotista, index) in tarotistas" :key="index">
                        <td>{{ tarotista.nombre }}</td>
                        <td>${{ numberWithCommas(tarotista.precio) }}</td>
                        <td>{{ tarotista.descripcion }}</td>
                        <td>
                          <button class="btn btn-editar">Editar</button>
                        </td>
                        <td class="text-center">
                          <button class="btn-table">
                            <i class="fas fa-trash"></i>
                          </button>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Spinner from "../../loadingspinner/Spinner.vue";
import ModalEditBolsa from "../planes/modal/ModalEditBolsa.vue";
import ModalSuscripcion from "./modal/ModalSuscripcion.vue";
import ModalBolsas from "./modal/ModalBolsas.vue";
import ModalRegalos from "./modal/ModalRegalos.vue";
import axiosClient from "../../../config/axiosClient";
import { enableLogs } from '../../../config';

export default {
  name: "DashAdminPlanes",
  components: {
    ModalSuscripcion,
    ModalBolsas,
    ModalRegalos,
    Spinner,
    ModalEditBolsa,
  },
  data: () => {
    return {
      tarotistas: [],
      divinoBolsas: [],
      planes: [],
      isLoadingDivinoBolsas: true,
      isLoadingPlanes: true,
      modalTriggered: null,
      forceClose: null,
    };
  },
  created() {
    this.getDivinoBolsas();
    this.getPlanes();
  },
  // watch: {
  //   divinoBolsas: function() {
  //     this.getDivinoBolsas();
  //   },
  // },
  methods: {
    async getDivinoBolsas() {
      try {
        const { data } = await axiosClient.get(
          `/divino-bolsas/get-divinobolsas`
        );
        this.divinoBolsas = data.divinoBolsas;
       if(enableLogs) console.log("successfully fetched divino bolsas");
       if(enableLogs) console.log(data.divinoBolsas);
        this.isLoadingDivinoBolsas = false;
      } catch (err) {
       if(enableLogs) console.log("could not fetch divino bolsas");
       if(enableLogs) console.log(err);
        this.isLoadingDivinoBolsas = false;
      }
    },

    async getPlanes() {
      try {
        const { data } = await axiosClient.get(
          "/subscriptions/get-subscriptions"
        );
       if(enableLogs) console.log("successfully fetched plans and subscriptions");
        this.planes = data.subscriptions;
        this.isLoadingPlanes = false;
      } catch (err) {
       if(enableLogs) console.log("could not fetch plans and subscriptions");
       if(enableLogs) console.log(err);
        this.isLoadingPlanes = false;
      }
    },

    async deleteDivinoBolsa(divinoBolsaId) {
      try {
        await axiosClient.post(
          "/divino-bolsas/delete",
          {
            bolsaId: divinoBolsaId,
          },
          { headers: { Authorization: `Bearer ${this.$store.state.jwt}` } }
        );
       if(enableLogs) console.log("successfully deleted divino bolsa: ", divinoBolsaId);
        this.divinoBolsas = this.divinoBolsas.filter(
          (bolsa) => bolsa.id !== divinoBolsaId
        );
      } catch (error) {
       if(enableLogs) console.log("could not delete divino bolsa");
       if(enableLogs) console.log(error.response.data);
      }
    },

    async removeSubscription(planId) {
      try {
        await axiosClient.post(
          "/subscriptions/delete-plan",
          {
            subscriptionId: planId,
          },
          { headers: { Authorization: `Bearer ${this.$store.state.jwt}` } }
        );
       if(enableLogs) console.log("successfully deleted plan: ", planId);
        this.planes = this.planes.filter((plan) => plan.id !== planId);
      } catch (error) {
       if(enableLogs) console.log("could not delete plan: ", planId);
       if(enableLogs) console.log(error);
      }
    },

      numberWithCommas(x) {
        return x.toLocaleString('es-AR');
      },

    goBack() {
      this.$router.go(-1);
    },
    async triggerModal(modal) {
     if(enableLogs) console.log(`Show Modal: ${modal}`);

      this.modalTriggered = modal;

        await this.$nextTick();
        this.modalTriggered = null;
        this.forceClose = null;
      },
      editBolsa(bolsaInformation) {
        for (let i = 0; i < this.divinoBolsas.length; i++) {
          if (this.divinoBolsas[i].id == bolsaInformation.bolsaId) {
            this.divinoBolsas[i].description = bolsaInformation.description;
            this.divinoBolsas[i].price = bolsaInformation.price;
            this.divinoBolsas[i].bonification = bolsaInformation.bonification;
            break;
          }
        }
      },
  },
};
</script>

<style scoped>
.text-back {
  color: #501682;
  text-transform: uppercase;
  font-family: maven-semibold;
  font-size: 13px;
  cursor: pointer;
}
.text-back:hover {
  color: #934dd8;
}
.text-back span {
  margin-left: 6px;
}
.card {
  border-radius: 10px;
  min-height: 100%;
}
.card-body {
  padding: 30px 20px;
}
.card-body h1 {
  font-size: 25px;
  font-family: maven-semibold;
  float: left;
}

.btn-yellow {
  font-family: maven-semibold;
  background: #ffc209;
  color: #501682;
  font-size: 11px;
  text-transform: uppercase;
  float: right;
  padding: 10px 18px;
}
.btn-yellow:hover {
  background: #dba501;
  color: #650bbf;
}
.btn-table {
  background-color: transparent;
  border: none;
}
.btn-editar {
  font-size: 12px;
  background-color: #979797;
  color: #333333;
}
table {
  font-size: 12px;
}
table th {
  font-family: maven-semibold;
}
table td {
  font-family: maven-medium;
  vertical-align: middle;
}
/*toggle*/
.switch {
  position: relative;
  display: inline-block;
  width: 40px;
  height: 20px;
  margin-right: 10px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #fff;
  border: 1px solid #ffc209;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 13px;
  width: 13px;
  left: 4px;
  bottom: 3px;
  background-color: #fff;
  border: 1px solid #ffc209;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: #ffc209;
  border: 1px solid #fff;
}

input:focus + .slider {
  box-shadow: 0 0 3px #fff;
}

input:checked + .slider:before {
  -webkit-transform: translateX(16px);
  -ms-transform: translateX(16px);
  transform: translateX(16px);
}
/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}
@media screen and (max-width: 768px) {
  .content-table {
    overflow-x: scroll;
  }
  .table {
    min-width: 500px;
  }
  .content-table {
    --scrollbarBG: #d1d1d1;
    --thumbBG: #333333;
  }
  .content-table::-webkit-scrollbar {
    height: 11px;
  }
  .content-table {
    scrollbar-width: thin;
    scrollbar-color: var(--thumbBG) var(--scrollbarBG);
  }
  .content-table::-webkit-scrollbar-track {
    background: var(--scrollbarBG);
  }
  .content-table::-webkit-scrollbar-thumb {
    background-color: var(--thumbBG);
    border-radius: 6px;
    border: 3px solid var(--scrollbarBG);
  }
}
</style>
