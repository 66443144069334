<template>
  <div>
    <div class="container-fluid content-header p-0">
        <div :class="{
          'header-bg fixed-top': !(cinta && isTargetUrl) || (isTargetUrl && cinta),
          'header-bg-cinta fixed-top': isTargetUrl && !cinta,
        }">
        <NavRegistrado v-if="isLoggedIn" />
        <Nav v-else />
      </div>
      <div v-if="isTargetUrl && !cinta" class="cinta-amarilla-container">
        <div class="cinta-amarilla">
          <div class="container">
            <div class="row">
              <div class="text-center d-flex align-items-center justify-content-center">
                <p v-if="this.$route.path.includes('/catalogo')" class="me-3">Regístrate y obten 50% en tu primera lectura.</p>
                <p v-if="this.$route.path.includes('compra')" class="me-3">Registrate para aplicar el 50% en el precio final.</p>
                <button  @click="registro" class="btn btn-morado shadow-sm"> ¡Aquí! </button>
                <a @click="cerrarCinta" href="#" class="btn-cerrar"><i class="fas fa-times"></i></a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="container-fluid content-body px-0">
      <router-view></router-view>
    </div>

    <div class="container-fluid content-footer p-0">
      <Footer />
    </div>
    <Register
        v-on:triggerModal="triggerModal"
        :triggerModal="modalTriggered === 'register'"
    />
  </div>
</template>

<script>
import Nav from "../nav/Nav";
import NavRegistrado from "../nav/NavRegistrado";
import Footer from "../footer/Footer";
import {enableLogs} from "@/config";
import Register from "@/components/login/Register.vue";

export default {
  name: "Layout",
  components: {
    Register,
    Nav,
    Footer,
    NavRegistrado,
    //Login
  },
  data() {
    return {
      cinta: false,
      modalTriggered: null,
    };
  },
  computed: {
    isLoggedIn() {
      return this.$store.state.isLoggedIn;
    },
    isTargetUrl() {
      const targetUrls = ['/catalogo', '/compra']; // Reemplaza con tus URLs específicas
      return targetUrls.includes(this.$route.path);
    }
  },
  methods: {
    cerrarCinta(){
      this.cinta = true;
    },
    registro() {
      this.triggerModal("register");
    },
    async triggerModal(modal) {
      if (enableLogs) console.log(`Showing ${modal} modal`);
      switch (modal) {
        case "login":
          this.modalTriggered = "login";
          break;
        case "register":
          this.modalTriggered = "register";
          break;
        case "password":
          this.modalTriggered = "password";
          break;
        case "register-success":
          this.modalTriggered = "register-success";
          break;
      }
      await this.$nextTick();
      this.modalTriggered = null;
    },
  },
};
</script>

<style scoped>
@import "~@fortawesome/fontawesome-free/css/all.css";
.content-header .header-bg {
  top: 0px;
}
.content-header .header-bg-cinta {
  top: 60px;
  background-color: #501682;
}
.cinta-amarilla-container {
  top: 0;
  position: fixed;
  z-index: 2;
  width: 100%
}
.cinta-amarilla {
  padding: 10px 0;
  background-color: #ffc209;
  font-family: maven-regular
}
.cinta-amarilla .btn-morado {
  background: #501682;
  color: #ffc30b;
  text-transform: uppercase;
  font-family: maven-bold;
  padding: 8px 50px;
  border-radius: 50px;
  font-size: 15px;
  transition: all .5s ease
}
.cinta-amarilla p {
  margin: 0
}
.cinta-amarilla .btn-cerrar {
  position: absolute;
  right: 20px;
  color: var(--bs-dark-text-emphasis);
  font-size: 1.25rem;
}

@media (max-width: 768px) {
  .cinta-amarilla p {
    font-size:.875em
  }
  .cinta-amarilla .btn-morado {
    padding: 8px 20px;
    margin-right: 0.75rem;
  }
  .cinta-amarilla .btn-cerrar {
    right: 5px;
    top: 0px;
  }
}
.content-header {
  background-image: url("../../assets/img/header.jpg");
  background-repeat: no-repeat;
  background-size: cover;
}

.banner-bg {
  background-color: #fff;
}

.header-bg {
  background-color: #501682;
}
.content-body {
  padding-top: 128px;
  background-color: #fff;
  min-height: 80vh;
}

.content-banner {
  background-image: url("../../assets/img/header.jpg");
  background-repeat: no-repeat;
  background-size: cover;
}

.content-footer {
  background-color: #dddddd;
}
@media screen and (max-width: 992px){
.content-body {
  padding-top: 174px;
}
 }
 @media screen and (max-width: 767px){
.content-body {
  padding-top: 191px;
}
 }
</style>
