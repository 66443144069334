<template>
  <div>
    <div class="card card-pagos ">
      <div class="card-body">
        <h5></h5>
        <form>
          <div class="row">
            <div class="form-group">
              <p class="text-medio-pago">DEFINIR TEXTO</p>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
export default {

};
</script>

<style scoped>
.text-medio-pago{
  padding-top: 175px !important;
}
</style>
