<template>
  <div>
    <b-modal
      :no-close-on-esc="true"
      :no-close-on-backdrop="true"
      id="modal-password"
      v-model="showModal"
      centered
      hide-footer
      size="lg"
    >
      <template #modal-title> </template>

      <div class="col-12 text-center my-4" v-if="screen == 2">
        <br /><br />
        <b-spinner variant="" label="Spinning"></b-spinner>
        <br />
        <p class="text-modal">Cargando</p>
        <br />
      </div>

      <div v-if="screen == 3">
        <br />
        <p class="text-modal">{{ messageFinish }}</p>
        <br />
      </div>

      <div v-if="screen == 1">
        <h3 class="title">Olvidé mi contraseña</h3>
        <p class="subtitle"
          >Ingresa tu dirección de correo para recuperar tu contraseña.</p
        >
        <form @submit.prevent action="">
          <div>
            <i class="fas fa-envelope iconoInput"></i>
            <input
              v-model="email"
              type="text"
              class="form-control inputPadding"
              aria-label="Username"
              aria-describedby="basic-addon1"
              autocomplete="off"
            />
            <p class="alert" v-if="validarEmail(email.toString())">
              Campo obligatorio
            </p>
          </div>
        </form>
        <button
          v-if="!btnDisabled"
          :disabled="!validForm"
          @click="sendRequest"
          class="btn btn-yellow"
        >
          Enviar
        </button>
      </div>
      <div></div>
    </b-modal>
  </div>
</template>
<script>
  import axiosClient from '../../config/axiosClient';
  import { enableLogs } from '../../config';

  export default {
    name: 'PasswordEmail',
    props: {
      triggerModal: Boolean,
    },
    watch: {
      triggerModal: function(value) {
        if (value) this.showModal = true;
      },
    },
    data: () => {
      return {
        showModal: false,
        email: '',
        editEmail: false,
        screen: 1,
        messageFinish: '',
        btnDisabled: false,
      };
    },
    computed: {
      btnDisabled: function() {
        return this.email == '';
      },
      validForm() {
        let validForm = true;
        this.validarEmail(this.email) ? (validForm = false) : null;
        return validForm;
      },
    },
    methods: {
      async sendRequest() {
        this.btnDisabled = true;
        try {
         if(enableLogs) console.log('Sending password recovery request');
          this.screen = 2;
          const response = await axiosClient.post('/users/forgot-pass', {
            userEmail: this.email,
          });
         if(enableLogs) console.log(response);
          this.$router.push('/');
          this.screen = 3;
          this.messageFinish =
            'Te enviamos un correo para restablecer tu contraseña';
          this.email = '';
        } catch (err) {
          this.screen = 3;
          this.messageFinish =
            'No se pudo restablecer la contraseña, intente nuevamente más tarde';
         if(enableLogs) console.log('Error sending password recovery request');
         if(enableLogs) console.log(err);
        } finally {
          this.btnDisabled = false;
          setTimeout(() => {
            this.screen = 1;
          }, 5000);
        }
      },
      validarEmail(valor) {
        if (valor.length > 0) {
          if (
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
              valor
            )
          ) {
            return false;
          } else {
            return true;
          }
        } else {
          return false;
        }
      },
    },
  };
</script>

<style scoped>
  /deep/ .modal-content {
    width: 560px;
    margin: auto;
  }
  /deep/.close {
    background: transparent;
    border: none;
  }
  /deep/ .modal-header {
    border-bottom: none;
    padding-bottom: 0px;
  }
  /deep/ .modal-body {
    padding: 48px;
    padding-top: 0px;
  }
  .title {
    font-family: maven-semibold;
    margin-bottom: 10px;
    color: #30075b;
    font-size: 22px;
  }
  h3 {
    font-family: maven-semibold;
  }
  .subtitle {
    font-family: maven-semibold;
    font-size: 14px;
    color: #707070;
  }
  label {
    color: #333333;
    font-family: maven-semibold;
    text-decoration: none;
    padding-bottom: 5px;
  }
  label,
  .link {
    font-size: 13px;
  }
  .btn-yellow {
    background: #ffc109bb;
    font-family: maven-semibold;
    text-transform: uppercase;
    color: #501682;
    border-radius: 10px;
    margin-top: 24px;
    padding: 8px 40px;
    font-size: 13px;
  }
  .btn-yellow:hover {
    background: #dba501;
    color: #650bbf;
  }

  .iconoInput {
    color: #30075b;
    font-size: 15px;
    position: absolute;
    left: 65px;
    font-size: 16px;
    padding-top: 12px;
  }

  .inputPadding {
    border: 1px solid #30075b;
    padding-left: 40px;
    font-family: maven-semibold;
    background: #efefef;
    border-radius: 10px;
    height: 40px;
  }
  .inputPadding:focus {
    color: #650bbf !important;
  }
  .text-account {
    font-size: 15px;
  }
  .alert {
    color: red;
    padding: 0px;
    font-size: 11px;
    font-family: maven-medium;
    margin-bottom: 0px;
  }
  .text-modal {
    color: #333;
    font-family: maven-bold;
    text-align: center;
    text-transform: uppercase;
    margin-bottom: 0px;
  }
</style>
