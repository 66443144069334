<template>
  <div>
    <b-carousel
      id="carousel-fade"
      style="text-shadow: 0px 0px 2px #000"
      fade
      interval="4000"
      img-width="1024"
      img-height="480"
      @sliding-start="onSlideStart"
      @sliding-end="onSlideEnd"
      controls
      indicators
    >
    <b-carousel-slide>
        <template #img>
          <img
            class="d-block"
            src="../../assets/img/slider/slider-five.png"
            alt="image slot"
          />
        </template>
        <div class="container p-0">
          <div class="row justify-content-lg-start justify-content-center">
            <div class="col-lg-6 col-md-10">
              <div class="banner text-slider-one">
                <img class="s-yellow-one-left" src="../../assets/img/slider/s-yellow.png" alt="">
                <img class="s-green-one-bottom" src="../../assets/img/slider/s-green.png" alt="">
                <h1>
                  Regístrate ahora 
                  <br class="d-lg-block d-md-none"/>y obtén un 50% de descuento 
                  <br class="d-lg-block d-md-none"/>en tu primera lectura
                </h1>
                
                <router-link to="">
                  <button
                      class="btn btn-banner shadow-sm"
                      @click="triggerModal('register')"
                  >
                    Ver más
                  </button>
                </router-link>
                
              </div>
            </div>
          </div>
        </div>
        <Register
              v-on:triggerModal="triggerModal"
              :triggerModal="modalTriggered === 'register'"
            />
      </b-carousel-slide>
      <b-carousel-slide>
        <template #img>
          <img
            class="d-block"
            src="../../assets/img/slider/slider-one.jpg"
            alt="image slot"
          />
        </template>
        <div class="container p-0">
          <div class="row justify-content-lg-start justify-content-center">
            <div class="col-lg-6 col-md-10">
              <div class="banner text-slider-one">
                <img class="s-yellow-one-left" src="../../assets/img/slider/s-yellow.png" alt="">
                <img class="s-green-one-bottom" src="../../assets/img/slider/s-green.png" alt="">
                <h1>
                  Disfruta ahora la primera 
                  <br class="d-lg-block d-md-none"/>plataforma latinoamerica
                  <br class="d-lg-block d-md-none"/>de lecturas de Tarot en línea
                </h1>
                <router-link to="/catalogo">
                  <button class="btn btn-banner shadow-sm">
                    Ver más
                  </button>
                </router-link>
              </div>
            </div>
          </div>
        </div>
      </b-carousel-slide>

      <b-carousel-slide style="background: #6e2990">
        <template #img>
          <img
            class="d-block"
            src="../../assets/img/slider/slider-two.jpg"
            alt="image slot"
          />
        </template>
        <div class="container p-0">
          <div class="row justify-content-lg-start justify-content-center">
            <div class="col-lg-6 col-md-10">
              <div class="banner text-slider-two">
                <img class="s-yellow-two-left" src="../../assets/img/slider/s-yellow.png" alt="">
                <img class="s-green-two-bottom" src="../../assets/img/slider/s-green.png" alt="">
                <h1>
                  Elige el tarotista que tú quieras.
                </h1>
                <p>¡Mira nuestro catálogo de asesores!</p>
                <router-link to="/catalogo">
                  <button class="btn btn-banner shadow-sm">
                    Ver Catálogo
                  </button>
                </router-link>
              </div>
            </div>
          </div>
        </div>
      </b-carousel-slide>

      <b-carousel-slide style="background: #6e2990">
        <template #img>
          <img
            class="d-block"
            src="../../assets/img/slider/slider-three.jpg"
            alt="image slot"
          />
        </template>
        <div class="container p-0">
          <div class="row justify-content-lg-start justify-content-center">
            <div class="col-lg-6 col-md-10">
              <div class="banner text-slider-two">
                <img class="s-yellow-three-left" src="../../assets/img/slider/s-yellow.png" alt="">
                <img class="s-violet-three-bottom" src="../../assets/img/slider/s-violet.png" alt="">
                <h1 class="text-violet-1">
                  En Divino Tarot tú eliges el <br class="d-lg-block d-md-none"/>método de lectura
                </h1>
                <p class="text-violet-1">Chat, videollamada o llamada</p>
                <router-link to="/catalogo">
                  <button class="btn btn-banner shadow-sm">
                    ¡Selecciona tu favorito!
                  </button>
                </router-link>
              </div>
            </div>
          </div>
        </div>
      </b-carousel-slide>

      <b-carousel-slide style="background: #6e2990">
        <template #img>
          <img
            class="d-block"
            src="../../assets/img/slider/slider-four.jpg"
            alt="image slot"
          />
        </template>
        <div class="container p-0">
          <div class="row justify-content-lg-start justify-content-center">
            <div class="col-lg-6 col-md-10">
              <div class="banner text-slider-two">
                <img class="s-yellow-three-left" src="../../assets/img/slider/s-green.png" alt="">
                <img class="s-violet-three-bottom" src="../../assets/img/slider/s-violet.png" alt="">
                <h1 class="text-violet">
                  ¡Diferentes sistemas de pagos para ti!
                </h1>
                <p class="text-violet">DivinoSaldo - Tarjeta de crédito
                  <br class="d-lg-block d-md-none"/>
                  Tarjeta de débito
                </p>
                <router-link to="/catalogo">
                  <button class="btn btn-banner-violet shadow-sm">
                    Comienza ahora
                  </button>
                </router-link>
              </div>
            </div>
          </div>
        </div>
      </b-carousel-slide>
    </b-carousel>
  </div>
</template>
<script>
import Register from '../login/Register.vue'
export default {
  components: {
    Register
  },
  data: () => {
    return {
      modalTriggered: null,

    };
  },
    methods: {
    async triggerModal(modal) {
      switch (modal) {
        case "register":
          this.modalTriggered = "register";
          break;

      }
     await this.$nextTick();
      this.modalTriggered = null;
    },
    props: {

  },
  watch: {
  },
  }
}
</script>


<style scoped>
@media (max-width:768px) {
  @media (max-width:768px) {
    .carousel-item img.d-block {
      width: 100vw;
      height: 400px;
      object-fit: cover;
      object-position: center;
      content: url("../../assets/img/mobile-banner2.jpg");
    }
    .carousel .banner img {
      height: auto;
    }
  }
  .carousel {
    padding-top: 60px;
  }
  .carousel-caption .banner h1 {
    font-size: calc(20px*1.25);
  }
  .carousel-caption .banner p {
    font-size: .875rem;
  }
  .carousel-caption .banner .btn {
    text-transform: uppercase;
    padding: .5rem;
    width: 100%;
  }
}
.banner {
  text-align: left;
  padding: 120px 0;
  position: relative;
}

.s-yellow-one-left, .s-yellow-two-left, .s-yellow-three-left {
  position: absolute;
      position: absolute;
    left: 0;
    top: 75px;
    width: 20px;
}
.s-green-one-bottom, .s-green-two-bottom, .s-violet-three-bottom{
    position: absolute;
    position: absolute;
    right: 70px;
    bottom: 160px;
    width: 28px;
}

h1 {
  color: #fff;
  font-size: 28px;
  font-family: source-semibold;
}
p{
  color: #fff;
  font-size: 22px;
  font-family: source-regular;
}

.text-violet{
  color: #501682;
  /* -webkit-text-stroke: 0.1px #501682; */
  font-weight: 100s;
  text-shadow: 0.1em 0.1em 0.05em #ffc209
}

.text-violet-1{
  color: #501682;
  /* -webkit-text-stroke: 0.1px #501682; */
  font-weight: 100s;
  text-shadow: 0.1em 0.1em 0.05em #04d9b2
}

.btn-banner {
  margin-top: 20px;
  background: #ffc209;
  color: #501682;
  font-family: maven-bold;
  padding: 8px 45px;
  border-radius: 50px;
  font-size: 16px;
  transition: all 0.5s ease;
}

.btn-banner:hover {
  background: #04d9b2;
}
.btn-banner-violet {
  margin-top: 20px;
  background: #04d9b2;
  color: #501682;
  font-family: maven-bold;
  padding: 8px 45px;
  border-radius: 50px;
  font-size: 16px;
  transition: all 0.5s ease;
}

.btn-banner-violet:hover {
  background: #ffc209;
}
img {
  width: 100vw;
}

@media screen and (max-width: 1552px) {
  h1 {
    font-size: 28px;
  }
  .banner {
    padding: 80px 0 40px 0;
  }

.s-yellow-one-left, .s-yellow-two-left, .s-yellow-three-left {
  position: absolute;
      position: absolute;
    left: 0;
    top: 50px;
    width: 20px;
}
.s-green-one-bottom, .s-green-two-bottom, .s-violet-three-bottom{
    position: absolute;
    position: absolute;
    right: 120px;
    bottom: 80px;
    width: 28px;
}
}

@media screen and (max-width: 1296px) {
   h1 {
    font-size: 25px;
  }
  p{
    font-size: 18px;
  }
  .banner {
    padding: 100px 0 20px 0;
  }

.s-yellow-one-left, .s-yellow-two-left, .s-yellow-three-left {
  position: absolute;
      position: absolute;
    left: 0;
    top: 50px;
    width: 20px;
}
.s-green-one-bottom, .s-green-two-bottom, .s-violet-three-bottom{
    position: absolute;
    position: absolute;
    right: 80px;
    bottom: 60px;
    width: 25px;
}
    
}

@media screen and (max-width: 1148px) {
  img {
    margin-left: auto;
    width: auto;
  }
  .banner {
    text-align: left;
    padding: 20px 0 80px 0 ;
    position: relative;
  }
  h1 {
    font-size: 28px;
  }
    .s-yellow-left{
    left: 0;
    top: -15px;
    width: 12px;
}
.s-yellow-one-left, .s-yellow-two-left, .s-yellow-three-left {
  position: absolute;
      position: absolute;
    left: 0;
    top: -5px;
    width: 20px;
}
.s-green-one-bottom, .s-green-two-bottom, .s-violet-three-bottom{
    position: absolute;
    position: absolute;
    right: 0px;
    bottom: 150px;
    width: 25px;
}

}
@media screen and (max-width: 992px) {
  img {
    margin-left: auto;
    width: auto;
  }
  .banner {
    text-align: center;
    padding: 120px 0 50px 0 ;
    position: relative;
  }
  h1 {
    color: rgb(255, 255, 255);
    font-size: 28px;
  }
  .s-yellow-one-left, .s-yellow-two-left, .s-yellow-three-left {
  position: absolute;
      position: absolute;
    left: 0;
    top: 75px;
    width: 20px;
}
.s-green-one-bottom, .s-green-two-bottom, .s-violet-three-bottom{
    position: absolute;
    position: absolute;
    right: 20px;
    bottom: 20px;
    width: 28px;
}

  
}
@media screen and (max-width: 700px) {
  h1{
    font-size: 20px;
  }
  p{
    font-size: 15px;
  }
      .s-yellow-left{
    left: 0;
    top: 0px;
    width: 12px;
}
.s-green{
    right: 100px;
    top: 0px;
    width: 22px;
}
.s-yellow-bottom{
    right: 40px;
    bottom: 20px;
}
.btn-banner-violet, .btn-banner {
  font-size: 14px;
}
  
}

.text-slider-one h1 {
  color: #ffffff;
}

.text-slider-two {
  color: #ffffff;
}

.text-slider-three {
  color: #ffffff;
}

.text-slider-four {
  color: #ffffff;
}
</style>