<template>
  <!-- Modal Fin Llamada -->
  <div>
    <b-modal
      :no-close-on-esc="true"
      :no-close-on-backdrop="true"
      id="modal-generico-1"
      size="md"
      centered
      :visible="true"
    >
      <div class="row">
        <div class="col-12 mb-2">
          <p class="text-modal">
            {{ $store.state.message }}
            
          </p>
        </div>
      </div>

      <template
        #modal-footer="{
          /*hide*/
        }"
      >
        <div class="row">
          <div class="col-12 text-center">
            <b-button
              size="sm"
              class="btn-sm btn btn-yellow"
              v-on:click="hide"
            >
              Aceptar
            </b-button>
          </div>
        </div>
      </template>
    </b-modal>
  </div>
</template>
<script>
  export default {
    props: {
      endVideo: Boolean,
    },
    methods: {
      /* goHome() {
        this.endLlamada = true;
        this.$router.push('/');
      }, */
      hide(){
      this.$store.commit('setMessage', '')
      },
      
    },
  };
</script>

<style scoped>
  /deep/.close {
    background: transparent;
    border: none;
    display: none;
  }
  /deep/.modal-header {
    border-bottom: none;
  }

  /deep/.modal-body {
    padding: 0 50px;
    font-family: maven-regular;
  }
  .text-modal {
    color: #333;
    font-family: maven-bold;
    text-align: center;
    text-transform: uppercase;
  }
  .text-saldo {
    text-align: center;
    font-family: maven-regular;
    font-size: 20px;
  }
  .text-saldo span {
    font-family: maven-bold;
    color: #3b5998;
  }
  /deep/.modal-footer {
    border-top: none;
    padding: 10px 50px 30px 50px;
    display: block;
  }
  /deep/.btn-yellow {
    background-color: #ffc209;
    color: #501682;
    font-family: maven-bold;
    text-transform: uppercase;
    border: none;
    padding: 6px 20px;
  }
  .btn-yellow:hover {
  background: #dba501 !important;
  color: #501682 !important;
  box-shadow: #501682;
}
  /deep/.btn-line {
    border: 1px solid #501682;
    background-color: transparent;
    color: #501682;
    font-family: maven-bold;
    text-transform: uppercase;
    padding: 6px 20px;
  }

  .text-sutitulo {
    font-size: 13px;
  }
</style>
