<template>
  <!--Modal confirmacion pago-->
  <div>
    <b-modal
      :no-close-on-esc="true"
      :no-close-on-backdrop="true"
      v-model="showModal"
      id="modal-llamada-confirmacion"
      size="lg"
      centered
    >
      <div class="row">
        <div class="col-12 mb-2">
          <p class="text-modal">
            Tienes una lectura{{getTypeRoom(channel)}} con {{ personName }}
          </p>
        </div>
        <div v-if="false" class="col-12 mb-2">
          <span class="text-sutitulo"
            >Selecciona la salida de microfono y video</span
          >
        </div>
        <div v-if="false" class="col-6 mb-2">
          <div class="form-group">
            <label for="exampleFormControlSelect1">Audio</label>
            <select class="form-select" id="exampleFormControlSelect1">
              <option>1</option>
              <option>2</option>
              <option>3</option>
              <option>4</option>
              <option>5</option>
            </select>
          </div>
        </div>
        <div v-if="false" class="col-6 mb-2">
          <div class="form-group">
            <label for="exampleFormControlSelect1">Microfono</label>
            <select class="form-select" id="exampleFormControlSelect1">
              <option>1</option>
              <option>2</option>
              <option>3</option>
              <option>4</option>
              <option>5</option>
            </select>
          </div>
        </div>
      </div>

      <template
        #modal-footer="{
          /*hide*/
        }"
      >
        <div class="row">
          <!-- 
          <div class="col-6 text-end">
            
            <b-button
              size="sm"
              class="btn-sm btn btn-line"
              v-on:click="confirmPayment"
            >
              No atender
            </b-button>
            
          </div>
          -->
          <div class="col-12 text-center">
            <b-button
              size="sm"
              class="btn-sm btn btn-yellow"
              v-on:click="goToCall"
            >
              Atender
            </b-button>
          </div>
        </div>
      </template>
    </b-modal>
  </div>
</template>
<script>
export default {
  watch: {
    triggerModal: async function (value) {
      if (value) this.showModal = true;   

      this.stopModal()
      
      


    },
 
    
  },
  mounted(){
    
  },
  props: {
    triggerModal: Boolean,
    personName: String,
    channel: String,
    roomId: String,
  },

  data: () => {
    return {
      showModal: false,
      timeOutModal: null,
    };
  },
  computed: {
    socket: function() {
      return this.$store.state.socket;
    },
    
  },
  methods: {
    stopModal(){
      var m = this
      window.inactivoCall = true;
      window.setTimeout(function () {
        if(window.inactivoCall){
          window.document.getElementById('audioLlamada').pause();
          m.showModal = false;
          //emitir desconexión
          m.$store.commit('disableNotPresentFun', true);
        }
      }, 60000)
      
    },
    goToCall() {
      window.inactivoCall = false;
      window.clearTimeout(this.timeOutModal)
      window.document.getElementById('audioLlamada').pause();
      let url;
      this.socket.emit("change-state", 'OCCUPIED');
      switch (this.channel) {
        case "CHAT":
          url = `chat?roomId=${this.roomId}`;
          break;
        case "VIDEO":
          url = `sala-espera?roomId=${this.roomId}`;
          break;
        case "VOICE":
          url = `room-llamada?roomId=${this.roomId}`;
          break;
      }
      this.$router.push(url);
      this.hideModal();
    },
    getTypeRoom(type){
      switch (type) {
        case 'VOICE':
          return ' de Llamada';
        case 'VIDEO':
          return ' de Video';
        case 'CHAT':
          return ' de Chat';
        default:
          return '';
      }
    },
    hideModal() {
      this.showModal = false;
    },
  },
};
</script>

<style scoped>
 /deep/.close {
    background: transparent;
    border: none;
    display: none;
  }
/deep/.modal-header {
  border-bottom: none;
}

/deep/.modal-body {
  padding: 0 50px;
  font-family: maven-regular;
}
.text-modal {
  color: #333;
  font-family: maven-bold;
  text-align: center;
  text-transform: uppercase;
}
.text-saldo {
  text-align: center;
  font-family: maven-regular;
  font-size: 20px;
}
.text-saldo span {
  font-family: maven-bold;
  color: #3b5998;
}
/deep/.modal-footer {
  border-top: none;
  padding: 10px 50px 30px 50px;
  display: block;
}
/deep/.btn-yellow {
  background-color: #ffc209;
  color: #501682;
  font-family: maven-bold;
  text-transform: uppercase;
  border: none;
  padding: 6px 20px;
}
.btn-yellow:hover {
  background: #dba501 !important;
  color: #501682 !important;
  box-shadow: #501682;
}
/deep/.btn-line {
  border: 1px solid #501682;
  background-color: transparent;
  color: #501682;
  font-family: maven-bold;
  text-transform: uppercase;
  padding: 6px 20px;
}

.text-sutitulo {
  font-size: 13px;
}
</style>
