<template>
  <div>
    <h5>PASO 2: Experiencia Previa</h5>
    <b-card class="">
      <form action="">
        <div class="row">
          <div class="col-12">
            <label for="" class="label-text"
              >¿Cuántos años de experiencia tienes como tarotista?</label
            >
            <div class="form-check">
              <input
                class="form-check-input"
                type="radio"
                name="experiencia"
                id="experienciaUno"
                value="experienciaUno"
                v-model="experiencia"
              />
              <label class="form-check-label" for="experienciaUno"> 0 - 1 </label>
            </div>
            <div class="form-check">
              <input
                class="form-check-input"
                type="radio"
                name="experiencia"
                id="experienciaTres"
                value="experienciaTres"
                v-model="experiencia"
              />
              <label class="form-check-label" for="experienciaTres"> 1 - 3 </label>
            </div>
            <div class="form-check">
              <input
                class="form-check-input"
                type="radio"
                name="experiencia"
                id="experienciaCinco"
                value="experienciaCinco"
                v-model="experiencia"
              />
              <label class="form-check-label" for="experienciaCinco"> 3 - 5 </label>
            </div>
            <div class="form-check">
              <input
                class="form-check-input"
                type="radio"
                name="experiencia"
                id="experienciaMasCinco"
                value="experienciaMasCinco"
                v-model="experiencia"
              />
              <label class="form-check-label" for="experienciaMasCinco"> Más de 5 </label>
            </div>
            <span class="alert" v-if="experiencia.length === 0"
              >Campo obligatorio</span
            >
          </div>

          <div class="col-12 mt-3">
            <label for="" class="label-text"
              >¿Has trabajado en otros sitios/app/call center de Tarot o
              similar?</label
            >
            <div class="form-check">
              <div class="container-check">
                <input
                  class="form-check-input"
                  type="radio"
                  name="trabajo"
                  id="trabajoSi"
                  value="trabajoSi"
                  v-model="trabajo"
                />
                <label class="form-check-label" for="trabajoSi"> Sí </label>
              </div>
              <div class="container-input">
                <label for="">Cuál:</label>
                <input
                  v-model="trabajoAnterior"
                  :disabled="trabajo !== 'trabajoSi'"
                  type="text"
                />
                <span class="alert" v-if="this.trabajo === 'trabajoSi' && this.trabajoAnterior.length == 0"
              >Campo obligatorio</span
            >
              </div>
            </div>
            <div class="form-check">
              <input
                class="form-check-input"
                type="radio"
                name="trabajo"
                id="trabajoNo"
                value="trabajoNo"
                v-model="trabajo"
              />
              <label class="form-check-label" for="trabajoNo"> No </label>
            </div>
            <div class="form-check">
              <div class="container-check">
                <input
                  class="form-check-input"
                  type="radio"
                  name="trabajo"
                  id="trabajoOtro"
                  value="trabajoOtro"
                  v-model="trabajo"
                />
                <label class="form-check-label" for="trabajoOtro">
                  Actualmente trabajo en uno
                </label>
              </div>
              <div class="container-input">
                <label for="">Cuál:</label>
                <input
                  v-model="trabajoActual"
                  :disabled="trabajo !== 'trabajoOtro'"
                  type="text"
                />
                <span class="alert" v-if="this.trabajo === 'trabajoOtro' && this.trabajoActual.length == 0"
              >Campo obligatorio</span
            >
              </div>
            </div>

            <span class="alert" v-if="trabajo.length === 0"
              >Campo obligatorio</span
            >
          </div>
          <div class="col-12 mt-3">
            <label for="" class="label-text"
              >¿Estas habilitad@ para emitir boletas (como persona natural) o
              facturas (como empresa EIRL)?</label
            >
            <div class="form-check">
              <input
                class="form-check-input"
                type="radio"
                name="comprobante"
                id="eirl"
                value="eirl"
                v-model="comprobante"
              />
              <label class="form-check-label" for="eirl"> Boletas </label>
            </div>
            <div class="form-check">
              <input
                class="form-check-input"
                type="radio"
                name="comprobante"
                id="personaNatural"
                value="personaNatural"
                v-model="comprobante"
              />
              <label class="form-check-label" for="personaNatural"> Facturas </label>
            </div>
            <div class="form-check">
              <input
                class="form-check-input"
                type="radio"
                name="comprobante"
                id="nodefined"
                value="nodefined"
                v-model="comprobante"
              />
              <label class="form-check-label" for="nodefined"> Ninguna </label>
            </div>
            <span class="alert" v-if="comprobante.length === 0"
              >Campo obligatorio</span
            >
          </div>

          <div class="mt-4" style="text-align: right">
            <button class="btn btn-sm btn-back" @click="handleClickBack">
              Volver
            </button>
            <button
              :disabled="!validForm"
              class="btn btn-yellow"
              @click="handleClickNext"
            >
              Siguiente
            </button>
          </div>
        </div>
      </form>
    </b-card>
  </div>
</template>

<script>
  export default {
    props: ['stepOne', 'stepThree'],
    watch: {
      trabajo: function(_, oldSelection) {
        if (oldSelection == 'trabajoSi') this.trabajoAnterior = '';
        if (oldSelection == 'trabajoOtro') this.trabajoActual = '';
      },
    },
    computed: {
      validForm: function() {
        if (this.trabajo === 'trabajoSi' && this.trabajoAnterior.length == 0)
          return false;
        if (this.trabajo === 'trabajoOtro' && this.trabajoActual.length == 0)
          return false;
        return this.comprobante && this.experiencia && this.trabajo;
      },
    },
    methods: {
      handleClickBack() {
        this.$emit('stepOne');
      },
      handleClickNext() {
        this.$emit('formDataChange', {
          experiencia: this.experiencia,
          trabajo: this.trabajo,
          comprobante: this.comprobante,

          trabajoAnterior: this.trabajoAnterior,
          trabajoActual: this.trabajoActual,
        });
        this.$emit('stepThree');
      }
    },
    data: function() {
      return {
        experiencia: '',
        trabajo: '',
        comprobante: '',

        trabajoAnterior: '',
        trabajoActual: '',
      };
    },
  };
</script>

<style scoped>
  .card-body {
    font-family: maven-regular;
  }
  h5 {
  color: #501682;
  font-family: maven-semibold;
  font-size: 18px;
}
  label {
    font-weight: 600;
  }
  .label-text {
    margin-bottom: 8px;
    color: #787878;
    font-family: maven-bold;
  }
  .form-check {
    margin-bottom: 10px;
  }

.btn-back {
  border: 1px solid #501682;
  background: transparent;
   font-family: maven-bold;
  text-transform: uppercase;
  color: #501682;
  padding: 10px 50px;
  border-radius: 10px;
  font-size: 13px;
  margin-right: 15px;
}
.btn-yellow {
  background: #ffc109bb;
   font-family: maven-bold;
  text-transform: uppercase;
  color: #501682;
  padding: 10px 50px;
  border-radius: 10px;
  font-size: 13px;
}
.btn-yellow:hover {
  background: #dba501;
  color: #650bbf;
}

  .container-check,
  .container-input {
    float: left;
  }
  .container-input {
    margin-left: 25px;
  }
  .container-input input {
    font-size: 13px;
    border: none;
    border-bottom: 1px solid #000;
    margin-left: 5px;
  }
  .form-check-input:checked {
    border-color: #ffc109;
    background-color: #ffc109;
  }

  .alert {
    color: red;
    padding: 0px;
    font-size: 11px;
    font-weight: bold;
  }
  @media screen and (max-width: 767px) {
  .card-img {
    width: 120px;
    height: 120px;
    border: 3px solid #fff;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    margin: auto;
    border-radius: 8px;
    margin-left: 0px;
  }
  .card-img i {
    font-size: 100px;
    margin-top: 20px;

    color: #707070;
  }

  .btn-yellow {
    padding: 10px 30px;
    border-radius: 10px;
    font-size: 12px;
  }
  .btn-back {
    padding: 10px 30px;
    border-radius: 10px;
    font-size: 12px;
  }
}
</style>
