<template>
  <div class="container my-5">
    <div class="row">
      <div class="col-12 mb-3">
        <div class="text-back" @click="goBack">
          <i class="fas fa-chevron-left"></i>
          <span>Volver</span>
        </div>
      </div>
      <div class="col-12">
        <div class="row">
          <div class="col-sm-6 col-12">
            <h1 class="general-title">Ámbitos</h1>
          </div>
          <div class="col-sm-6 col-12">
            <button class="btn btn-yellow" v-b-modal.modalAmbito>
              Crear nuevo
            </button>
            <ModalAmbito />
          </div>
        </div>
      </div>

      <div
        class="col-md-6 col-12 my-4"
        v-for="ambito in ambitos"
        :key="ambito.scopeId"
      >
        <div class="card shadow">
          <div class="card-body">
            <h5 class="title-ambito">{{ ambito.scopeName }}</h5>
            <p class="title-descripcion">{{ ambito.scopeDescription }}</p>

            <div class="mt-2 p-0" style="width: 90px; height: 70px">
              <div class="card-img shadow-sm" style="">
                <img
                  :src="ambito.scopeImage"
                  alt=""
                  v-if="ambito.scopeImage !== null"
                />
                <i class="fas fa-camera" v-else></i>
              </div>
            </div>
            <div class="container-btn">
              <button
                class="btn btn-editar"
                @click="triggerModal(`modalDatos-${ambito.scopeId}`)"
              >
                Editar
              </button>

              <button
                class="btn btn-eliminar"
                @click="deleteScope(ambito.scopeId)"
              >
                Eliminar
              </button>
            </div>
            <ModalEditarAmbito
              :triggerModal="modalTriggered === `modalDatos-${ambito.scopeId}`"
              :scope="ambito"
              v-on:editScope="editScope"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axiosClient from "../../../config/axiosClient";
import ModalAmbito from "./modal/ModalAmbito.vue";
import ModalEditarAmbito from "./modal/ModalEditarAmbito.vue";
import { enableLogs } from '../../../config';
export default {
  name: "DashAdminAmbitos",
  components: {
    ModalAmbito,
    ModalEditarAmbito,
  },
  data: () => {
    return {
      ambitos: [],
      isLoading: false,
      modalTriggered: null,
      forceClose: null,
    };
  },
  // watch: {
  //   ambitos: function() {
  //     this.getAmbitos();
  //   },
  // },
  created() {
    this.getAmbitos();
  },
  methods: {
    async getAmbitos() {
      try {
        this.isLoading = true;
        const response = await axiosClient.get("scopes/get-all-scopes");
        this.ambitos = response.data.scopes;
        if(enableLogs) console.log("successfully fetched scopes");
        this.isLoading = false;
      } catch (error) {
       if(enableLogs) console.log(error);
        this.isLoading = false;
      }
    },
    goBack() {
      this.$router.go(-1);
    },
    async triggerModal(modal) {
     if(enableLogs) console.log(`Show Modal: ${modal}`);

      this.modalTriggered = modal;

      await this.$nextTick();
      this.modalTriggered = null;
      this.forceClose = null;
    },
    async deleteScope(scopeId) {
      if (!this.$store.state.jwt) {
        return;
      }
      try {
        const response = await axiosClient.post(
          "/scopes/delete-scope",
          {
            scopeId,
          },
          { headers: { Authorization: `Bearer ${this.$store.state.jwt}` } }
        );
       if(enableLogs) console.log("successfully deleted scope");
        this.ambitos = this.ambitos.filter((ambito) => {
          return ambito.scopeId !== scopeId;
        });
       if(enableLogs) console.log(response);
      } catch (error) {
       if(enableLogs) console.log(error);
       if(enableLogs) console.log("could not delete scope");
      }
    },
    addScope(scopeInformation) {
      this.ambitos.push(scopeInformation);
    },
    editScope(scopeInformation) {
      let scopeFound = this.ambitos.find(
        (scope) => scope.scopeId === scopeInformation.scopeId
      );
      scopeFound.scopeName = scopeInformation.scopeName;
      scopeFound.scopeDescription = scopeInformation.scopeDescription;
      scopeFound.scopeImage = scopeInformation.scopeImage;
    },
  },
};
</script>

<style scoped>
.text-back {
  color: #501682;
  text-transform: uppercase;
  font-family: maven-semibold;
  font-size: 13px;
  cursor: pointer;
}
.text-back:hover {
  color: #934dd8;
}
.text-back span {
  margin-left: 6px;
}
.title {
  color: #501682;
  text-transform: uppercase;
  font-family: source-bold;
  font-size: 19px;
  float: left;
  margin-top: 9px;
}
.btn-yellow {
  font-family: maven-semibold;
  background: #ffc209;
  color: #501682;
  font-size: 11px;
  text-transform: uppercase;
  padding: 10px 18px;
  float: right;
}
.btn-yellow:hover {
  background: #dba501;
  color: #650bbf;
}

.card {
  border-radius: 10px;
  min-height: 100%;
}
.card-body {
  padding: 20px 20px;
  font-family: maven-regular;
}

.title-ambito {
  font-size: 21px;
  font-family: maven-bold;
}

.title-descripcion {
  font-size: 13px;
}

.container-btn {
  text-align: end;
}
.btn-eliminar {
  font-size: 12px;
  background-color: #979797;
  color: #333333;
  font-family: maven-semibold;
  font-size: 10px;
  text-transform: uppercase;
  padding: 8px 10px;
}
.btn-eliminar:hover {
  background-color: #858585;
  color: #333333;
}
.btn-editar {
  font-family: maven-semibold;
  background: #ffc209;
  color: #501682;
  font-size: 10px;
  text-transform: uppercase;
  padding: 8px 10px;
  margin-right: 8px;
}
.btn-editar:hover {
  background: #dba501;
  color: #650bbf;
}

.card-img {
  width: 90px;
  height: 70px;
  border: 3px solid #fff;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  margin: auto;
}
.card-img i {
  font-size: 35px;
  margin-top: 10px;
  margin-left: 25px;
  color: #707070;
}
.card-img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 8px;
}
.btn-icon {
  background-color: #f3f6f9;
  border-color: #f3f6f9;
  color: #3f4254;
  border-radius: 50%;
  height: 24px !important;
  width: 24px;
  align-items: center;
  display: -webkit-inline-box;
  display: inline-flex;
  align-items: center;
  -ms-flex-pack: center;
  justify-content: center;
  padding: 0;
  position: absolute;
  right: -10px;
  top: -10px;
  cursor: pointer;
}
.input-file {
  width: 0 !important;
  height: 0 !important;
  overflow: hidden;
  opacity: 0;
}
</style>
