<template>
  <!--Modal confirmacion pago-->
  <div>
    <b-modal
      :no-close-on-esc="true"
      :no-close-on-backdrop="true"
      hide-footer
      v-model="showModal"
      id="modal-compra-espera-pago"
      class="modal-espera"
      centered
    >
      <p class="my-2 text-modal">
        Estamos procesando tu pago
      </p>
      <div class="col-12 text-center my-4">
        <b-spinner variant="" label="Spinning"></b-spinner>
      </div>

    </b-modal>
  </div>
</template>
<script>
export default {
  watch: {
    triggerModal: function(value) {
      if (value) this.showModal = true;
    },
    forceClose: function(value) {
      if (value) this.showModal = false;
    },
  },
  props: ["triggerModal", "forceClose"],

  data: () => {
    return {
      showModal: false,
    };
  },
  methods: {
    hideModal() {
      this.showModal = false;
    },
  },
};
</script>

<style scoped>
/deep/.close {
  background: transparent;
  border: none;
  display: none;
}
/deep/.modal-header {
  border-bottom: none;
}

/deep/.modal-body {
  padding: 0 50px;
}
.text-modal {
  color: #501682;
  font-family: maven-bold;
  text-align: center;
  text-transform: uppercase;
  font-size: 20px;
}
.text-saldo {
  text-align: center;
  font-family: maven-regular;
  font-size: 20px;
}
.text-saldo span {
  font-family: maven-bold;
  color: #3b5998;
}
/deep/.modal-footer {
  border-top: none;
  padding: 10px 50px 30px 50px;
}
/deep/.btn-modal {
  background-color: #04d9b2;
  color: #fff;
  font-family: maven-bold;
  text-transform: uppercase;
  border: none;
}

.spinner-border{
  color:#04D9B2
}
</style>
