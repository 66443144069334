<template>
  <div class="container">
    <div class="row">
      <div class="text-center mt-5">
        <button class="btn btn-modal mt-3" @click="irWebHref">Href</button>
        <button class="btn btn-modal mt-3" style="margin: 10px;" @click="irWebAssign">Assign</button>
        <button class="btn btn-modal mt-3" @click="irWebReplace">Replace</button>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  components: {

  },
  data: () => {
    return {
    };
  },
  watch() {
     console.log('watch');
  },
  computed() {
      console.log('computed');
  },
  created() {
    console.log('created');
  },
  mounted() {
    console.log('mounted');
  },
  methods: {
    irWebHref(){
      console.log('irWebPay');
      this.$nextTick(() => {
        const userAgent = window.navigator.userAgent.toLowerCase();
        let device = '';

        if (userAgent.includes('android')) {
          device = 'android';
        } else if (userAgent.includes('iphone')) {
          device = 'iphone';
        } else {
          device = 'pc';
        }

        // Mostrar el dispositivo en la consola (puedes eliminar esta línea si no la necesitas)
        console.log('Dispositivo:', device);

        // Ejemplo de ejecución condicional basado en el dispositivo
        if (device === 'iphone') {
          // Código específico para iPhone
          console.log('Accediendo desde un iPhone');
          window.location.href = "https://www.google.com";
        } else if (device === 'android') {
          // Código específico para Android
          console.log('Accediendo desde un Android');
          window.location.href = "https://www.youtube.com";
        } else {
          // Código para PC u otros dispositivos
          console.log('Accediendo desde un PC u otro dispositivo');
          window.location.href = "https://www.instagram.com";
        }
      });
    },
    irWebAssign(){
      console.log('irWebPay');
      this.$nextTick(() => {
        const userAgent = window.navigator.userAgent.toLowerCase();
        let device = '';

        if (userAgent.includes('android')) {
          device = 'android';
        } else if (userAgent.includes('iphone')) {
          device = 'iphone';
        } else {
          device = 'pc';
        }

        // Mostrar el dispositivo en la consola (puedes eliminar esta línea si no la necesitas)
        console.log('Dispositivo:', device);

        // Ejemplo de ejecución condicional basado en el dispositivo
        if (device === 'iphone') {
          // Código específico para iPhone
          console.log('Accediendo desde un iPhone');
          window.location.assign("https://www.google.com");
        } else if (device === 'android') {
          // Código específico para Android
          console.log('Accediendo desde un Android');
          window.location.assign("https://www.youtube.com");
        } else {
          // Código para PC u otros dispositivos
          console.log('Accediendo desde un PC u otro dispositivo');
          window.location.assign("https://www.instagram.com");
        }
      });
    },
    irWebReplace(){
      console.log('irWebPay');
      this.$nextTick(() => {
        const userAgent = window.navigator.userAgent.toLowerCase();
        let device = '';

        if (userAgent.includes('android')) {
          device = 'android';
        } else if (userAgent.includes('iphone')) {
          device = 'iphone';
        } else {
          device = 'pc';
        }

        // Mostrar el dispositivo en la consola (puedes eliminar esta línea si no la necesitas)
        console.log('Dispositivo:', device);

        // Ejemplo de ejecución condicional basado en el dispositivo
        if (device === 'iphone') {
          // Código específico para iPhone
          console.log('Accediendo desde un iPhone');
          window.location.replace("https://www.google.com");
        } else if (device === 'android') {
          // Código específico para Android
          console.log('Accediendo desde un Android');
          window.location.replace("https://www.youtube.com");
        } else {
          // Código para PC u otros dispositivos
          console.log('Accediendo desde un PC u otro dispositivo');
          window.location.replace("https://www.instagram.com");
        }
      });
    },
  },
};
</script>
<style scoped>
.buttonpay {
  display: none;
}
.btn-modal {
  background-color: #04d9b2;
  color: #fff;
  font-family: maven-bold;
  text-transform: uppercase;
  border: none;
  margin-bottom: 20px; /* Añadido margen inferior */
}
</style>

